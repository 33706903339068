import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledDialogTag = styled.div`
  margin: 30px 14px 0;

  .text {
    ${Fonts.FontRegular}
    ${Fonts.size.size16}
    color: ${Colors.color2};
    font-weight: bold;
  }

  .result-text {
    ${Fonts.FontRegular}
    ${Fonts.size.size14}
    text-align: center;
    color: ${Colors.color19};
    margin-top: 30px;
  }

  .card {
    border: 1px solid ${Colors.color7};
    border-radius: 4px;
    margin: 15px auto 25px;
    display: flex;
    justify-content: space-between;
    cursor: pointer;

    .card-content {
      display: flex;
      align-items: center;
      margin: 30px 40px 30px 25px;
      width: 80%;
    }

    .photo-profile-container {
      width: 55px;
      height: 55px;
    }

    .text-container {
      flex: 1;
    }

    .photo-profile {
      background-color: ${Colors.color7};
      width: 100%;
      height: 100%;
      border-radius: ${55/2}px;
      object-fit: cover;
    }

    .name-text { 
      ${Fonts.FontRegular}
      ${Fonts.size.size16}
      font-weight: bold;
      color: ${Colors.color2};
      margin-left: 15px;
    }

    .phone-text { 
      ${Fonts.FontRegular}
      ${Fonts.size.size14}
      font-weight: bold;
      color: ${Colors.color2};
      margin-top: 5px;
      margin-left: 15px;
    }

    .check-image {
      width: 20px;
      height: 20px;
      border-radius: ${20/2}px;
      object-fit: cover;
      margin: 10px;
    }
  }

  .bordered {
    border: 2px solid ${Colors.color36};
  }
`

export const InputStyle = ` 
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color7};
  margin: 10px 0;
  border-radius: 4px;
  .input-field {  
    width: 100% !important;
    border: 0 !important;
    margin: 5px 14px 5px 0px !important;
    outline: none !important;
  }
` 

export const InputReadOnlyStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin: 10px 0;
  .input-field { 
    cursor: pointer !important; 
    margin: 5px 14px 5px 0px !important;
    border: 0 !important;
  }
`;