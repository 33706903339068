import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import './config';
import './index.css';
import App from './App';
import Appconfig from './config.js'
import registerServiceWorker from './registerServiceWorker';
import { Providers } from '@microsoft/mgt-element';
import { Msal2Provider } from '@microsoft/mgt-msal2-provider';

Providers.globalProvider = new Msal2Provider({
    clientId: Appconfig.CLIENT_ID,
});

if(process.env.REACT_APP_ENV && ['production', 'staging'].includes(process.env.REACT_APP_ENV)){
    Sentry.init({
        dsn: process.env.REACT_APP_SENTRY_URL,
        environment: process.env.REACT_APP_ENV,
        integrations: [new Integrations.BrowserTracing()],
        tracesSampleRate: 1.0,
    });
}

ReactDOM.render(<App />, document.getElementById('root'));
registerServiceWorker();
