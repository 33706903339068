import React, { Component } from 'react';

class Card2 extends Component {
  render() {
    return (
      <div style={{
        padding: 'auto',
        backgroundColor: 'white',
        borderRadius: '10px',
        maxWidth: '100%',
        minWidth: '49%'
      }}>
        {this.props.children}
      </div>
    );
  }
}

export default Card2;
