import React, { Component, useState } from "react";
import { connect } from "react-redux";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import { createBrowserHistory } from "history";
import Appconfig from "../config";

import { Container, Row, Col, Hidden, Visible } from "react-grid-system";
import Sidebar from "../components/sidebar";

import RootContainer from "./RootContainer";
import UserManagement from "./UserManagement";
import UserDetail from "./UserDetail";
import Login from "./Login";
import Solution from "./Solution";
import Benefits from "./Benefits";
import AdminManagement from "./AdminManagement";
import Notifications from "./Notifications";
import EditBenefit from "./EditBenefit";
import EditBenefitCategory from "./EditBenefitCategory";
import EditSolution from "./EditSolution";
import EditSolutionCategory from "./EditSolutionCategory";
import CreateNotification from "./CreateNotification";
import UserQRCode from "./UserQRCode";
import UserQRDetail from "./UserQRDetail";
import UserPrintPage from "./UserPrintPage";
import ExportQRCode from "./ExportQRCode";
import ContentManagement from "./ContentManagement";
import EditContent from "./EditContent";
import AdminDetail from "./AdminDetail";
import Analytics from "./Analytics";
import SafeReturn from "./SafeReturn";
import GoToPoints from "./GoToPoints";
import UpsertGoToPoints from "./UpsertGoToPoints";
import PartnerDetail from "./PartnerDetail";
import CheckToken from "./CheckToken";
import ImportUser from "./ImportUser";
import StartupActions from "../redux/StartupRedux";
import DashboardPartner from "./DashboardPartner";
import Forget from "./Forget";
import ShowUsername from "./ShowUsername";
import ReportMissing from "./ReportMissing";
import NotificationDetail from "./DetailNotification";

import { verifyToken } from "../api/AdminAPI";
import { USER_TYPE } from "../constants";

import { Colors } from "../themes/";
import DetailNotification from "./DetailNotification";

const RouteAdmin = ({ component: Component, account, ...rest }) => {
  return (
    <Route
      {...rest}
      render={(props) =>
        account.type === USER_TYPE.ADMIN ||
        account.type === USER_TYPE.SUPERADMIN ||
        account.type === USER_TYPE.VIEWER ? (
          <Component {...props} />
        ) : (
          <Redirect to="/login" />
        )
      }
    />
  );
};

export class AppNavigation extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true,
      validToken: false,
    };
  }

  async componentDidMount() {
    const { account } = this.props;
    const accountData =
      account &&
      account.payload &&
      account.payload.data &&
      account.payload.data.account;
    this.props.initToken();

    if (accountData && accountData.token) {
      const checkToken = await verifyToken(accountData);
      if (checkToken && checkToken.type === "ERROR") {
        localStorage.clear();
        this.setState({ loading: false, validToken: false });
      } else {
        this.setState({ loading: false, validToken: true });
      }
    } else {
      this.setState({ loading: false, validToken: true });
    }
  }

  componentDidUpdate() {
    this.setTimeout = setTimeout(async () => {
      const { account } = this.props;
      const accountData =
        account &&
        account.payload &&
        account.payload.data &&
        account.payload.data.account;

      if (accountData && accountData.token) {
        const checkToken = await verifyToken(accountData);
        if (checkToken && checkToken.type === "ERROR") {
          localStorage.clear();
          this.setState({ loading: false, validToken: false });
        } else {
          this.setState({ loading: false, validToken: true });
        }
      } else {
        this.setState({ loading: false, validToken: true });
      }
    }, 10000);
  }

  componentWillUnmount() {
    clearTimeout(this.setTimeout);
  }
  // static async getDerivedStateFromProps(props, state) {
  //   const { account } = props
  //   const accountData = account && account.payload && account.payload.data && account.payload.data.account
  //   let returnValue = {}

  //   if (accountData && accountData.token) {
  //     const checkToken = await verifyToken(accountData)
  //     if(checkToken && checkToken.type === "ERROR") {
  //       localStorage.clear();
  //       returnValue = {
  //         loading: false,
  //         validToken: false
  //       }
  //     } else {
  //       returnValue = {
  //         loading: false,
  //         validToken: true,
  //       }
  //     }
  //   } else {
  //     returnValue = {
  //       loading: false,
  //       validToken: true,
  //     }
  //   }
  //   return {
  //     ...returnValue,
  //   }
  // }

  render() {
    const { account } = this.props;
    const accountData =
      account &&
      account.payload &&
      account.payload.data &&
      account.payload.data.account;
    return (
      <Router basename={Appconfig.BASE_NAME || ""}>
        <Route exact path={"/"} component={RootContainer} />
        <Route exact path={"/token/:token"} component={CheckToken} />
        <Route exact path={"/login"} component={Login} />
        <Route exact path={"/qrcode/:uid"} component={UserQRCode} />
        <Route exact path={"/user-detail/:uid"} component={UserQRDetail} />
        <Route exact path={"/forget-username"} component={Forget} />
        <Route exact path={"/forget-password"} component={Forget} />
        <Route exact path={"/show-username"} component={ShowUsername} />
        {
          // INIT TOKEN FROM REDUX NEED WAIT UNTIL FINISH FOR SET HEADERS TO AXIOS & APISAUCE
          !this.props.initFinish &&
          account.payload &&
          account.payload.type !== "ERROR" ? (
            <div style={{ textAlign: "center", margin: "auto" }}>
              LOADING...
            </div>
          ) : account && account.payload == null ? (
            <Redirect to="/login" />
          ) : (
            // :
            // account && account.payload && account.payload.type === "SUCCESS" ?
            //   <Route exact path={"/login" component={Login} />
            account.payload &&
            account.payload.type === "SUCCESS" &&
            account.payload.data &&
            (accountData.type === USER_TYPE.ADMIN ||
              accountData.type === USER_TYPE.SUPERADMIN ||
              accountData.type === USER_TYPE.VIEWER ||
              accountData.type === USER_TYPE.PARTNER) && (
              <Container
                fluid
                style={{
                  backgroundColor: Colors.primaryBgColor,
                  padding: 0,
                  height: "100vh",
                }}
              >
                <Row nogutter>
                  <Route>
                    {(props) =>
                      props.location.pathname !== "/user-export" &&
                      props.location.pathname !== "/export-qrcode" && (
                        <Hidden xs>
                          <Col
                            sm={
                              props.location.pathname === "/user-export" ||
                              props.location.pathname === "/export-qrcode"
                                ? 0
                                : 2
                            }
                          >
                            <Sidebar {...props} />
                          </Col>
                        </Hidden>
                      )
                    }
                  </Route>
                  <Route>
                    {(props) => {
                      return (
                        <Col
                          sm={
                            props.location.pathname === "/user-export" ||
                            props.location.pathname === "/export-qrcode"
                              ? 12
                              : 10
                          }
                        >
                          <Switch>
                            {/* can be open by partner and admin */}
                            <Route
                              exact
                              account={accountData}
                              path={"/users"}
                              component={UserManagement}
                            />
                            <Route
                              exact
                              account={accountData}
                              path={"/user/:id"}
                              component={UserDetail}
                            />
                            <Route
                              exact
                              account={accountData}
                              path={"/safe-return"}
                              component={SafeReturn}
                            />
                            <Route
                              exact
                              account={accountData}
                              path={"/relationship"}
                              component={UserManagement}
                            />
                            <Route
                              exact
                              account={accountData}
                              path={"/relationship/:id"}
                              component={UserDetail}
                            />
                            <Route
                              exact
                              account={accountData}
                              path={"/user-export"}
                              component={UserPrintPage}
                            />
                            <Route
                              exact
                              account={accountData}
                              path={"/export-qrcode"}
                              component={ExportQRCode}
                            />
                            <Route
                              exact
                              account={accountData}
                              path={"/import-user"}
                              component={ImportUser}
                            />
                            <Route
                              exact
                              account={accountData}
                              path={"/dashboard"}
                              component={DashboardPartner}
                            />
                            <Route
                              exact
                              account={accountData}
                              path={"/partner/:id"}
                              component={PartnerDetail}
                            />

                            {/* just can open by admin */}
                            <RouteAdmin
                              exact
                              account={accountData}
                              path={"/solution"}
                              component={Solution}
                            />
                            <RouteAdmin
                              exact
                              account={accountData}
                              path={"/benefits"}
                              component={Benefits}
                            />
                            <RouteAdmin
                              exact
                              account={accountData}
                              path={"/benefit/:id"}
                              component={EditBenefit}
                            />
                            <RouteAdmin
                              exact
                              account={accountData}
                              path={"/admin"}
                              component={AdminManagement}
                            />
                            <RouteAdmin
                              exact
                              account={accountData}
                              path={"/admin/:id"}
                              component={AdminDetail}
                            />
                            <RouteAdmin
                              exact
                              account={accountData}
                              path={"/notifications"}
                              component={Notifications}
                            />
                            <RouteAdmin
                              exact
                              account={accountData}
                              path={"/notifications/:id"}
                              component={DetailNotification}
                            />
                            <RouteAdmin
                              exact
                              account={accountData}
                              path={"/benefit-category/:id"}
                              component={EditBenefitCategory}
                            />
                            <RouteAdmin
                              exact
                              account={accountData}
                              path={"/solution/:id"}
                              component={EditSolution}
                            />
                            <RouteAdmin
                              exact
                              account={accountData}
                              path={"/solution-category/:id"}
                              component={EditSolutionCategory}
                            />
                            <RouteAdmin
                              exact
                              account={accountData}
                              path={"/create-notification"}
                              component={CreateNotification}
                            />

                            {/* Go To Points */}
                            <RouteAdmin
                              exact
                              account={accountData}
                              path={"/go-to-points"}
                              component={GoToPoints}
                            />
                            <RouteAdmin
                              exact
                              account={accountData}
                              path={"/go-to-points/create"}
                              component={UpsertGoToPoints}
                            />
                            <RouteAdmin
                              exact
                              account={accountData}
                              path={"/go-to-points/update/:id"}
                              component={UpsertGoToPoints}
                            />

                            <RouteAdmin
                              exact
                              account={accountData}
                              path={"/content"}
                              component={ContentManagement}
                            />
                            <RouteAdmin
                              exact
                              account={accountData}
                              path={"/content/:id"}
                              component={EditContent}
                            />
                            <RouteAdmin
                              exact
                              account={accountData}
                              path={"/analytics"}
                              component={Analytics}
                            />
                            <RouteAdmin
                              exact
                              account={accountData}
                              path={"/reports"}
                              component={ReportMissing}
                            />

                            {/* <Route component={() => <p style={{ color: 'red', fontSize: 62, textAlign: 'center', textAlignVertical: 'center', height: '100vh', margin: '100px auto' }}>404 Not found</p>} /> */}
                          </Switch>
                        </Col>
                      );
                    }}
                  </Route>
                </Row>
              </Container>
            )
          )
        }
      </Router>
    );
  }
}

const mapStateToProps = (state) => ({
  account: state.auth.account,
  initFinish: state.startup.initFinish,
});

const mapDispatchToProps = (dispatch) => {
  return {
    initToken: () => dispatch(StartupActions.initToken()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AppNavigation);
