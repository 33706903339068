import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dialog from '../../components/modal'
import Button from '../../components/button'
import Input from '../../components/input'

import { Container, Row, Col, Hidden, } from 'react-grid-system';

import { StyledEditForm } from '../../containers/styles/EditFormStyles'
import Form from '../form'

import { StyledDialogContent } from './StyledDialogContent'
import { Label, InputStyle } from '../form/StyledForm'

import { Images, Colors, } from '../../themes'

 class CreateContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      idCreate:'',
      titleCreate:'',
      textCreate:'',
      Base64imageCreate:null,
      image:null,
      urlCreate:''
      
    }

    this.update = false
  }

  onChangeText = (name, value) => {
    this.setState({ [name]: value })
  }

  componentDidMount(){
    // console.log(this.props)
  }

  render() {
    
  let imageInput = null
  const { dialogProps, image, } = this.props
  return (
   
    <Dialog
      {...dialogProps}
      title="Create New Banner"
      width="76%"
    > 
      <StyledDialogContent>
        <Row>
          <Col>
            <Label htmlFor="title">Title</Label>
            <Input 
              inputProps={{ 
                id: "title", 
                value:this.props.titleCreate,
                name:"title",
                onChange: (e) => this.props.onChangeText('title', e.target.value),  
              }}
              containerStyle={InputStyle}
            />
            <Label htmlFor="description">Description</Label>
            <Input 
              inputProps={{ 
                id: "description",  
                value: this.props.description,
                onChange: (e) => this.props.onChangeText('description', e.target.value),  
              }}
              containerStyle={InputStyle}
            />
            <Label htmlFor="text">Text</Label>
            <Input 
              inputProps={{ 
                id: "text",  
                value: this.props.textCreate,
                onChange: (e) => this.props.onChangeText('text', e.target.value),  
              }}
              containerStyle={InputStyle} multiline
            />
            <Label htmlFor="buttonText">Button Text</Label>
            <Input 
              inputProps={{ 
                id: "buttonText",  
                value: this.props.buttonText,
                onChange: (e) => this.props.onChangeText('buttonText', e.target.value),  
              }}
              containerStyle={InputStyle}
            />
          </Col>
          <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
            <div>
              <Label>Image</Label>
              <div className="image-container">
                <img src={image ? image : this.props.imageCreate} alt="banner" className="banner-image" />
                <Button
                  text="Edit image"
                  buttonStyle={` margin-top: 35px; `}
                  textStyle={` font-size: 13px; margin: 0 30px; font-weight: 800; `}
                  onClick={() => {
                    imageInput.click()
                  }}
                />
                <input
                  type="file"
                  style={{ display: 'none' }}
                  ref={input => {
                    imageInput = input;
                  }}
                  onChange={this.props.onChangeImageCreate}
                  accept="image/*"
                  required
                />
              </div>
              
              <Label htmlFor="url">URL</Label>
              <Input 
                inputProps={{ 
                  id: "url",  
                  value:this.props.urlCreate,
                  onChange: (e) => this.props.onChangeText('url', e.target.value), 
                }}
                containerStyle={InputStyle}
              />
            </div>
            <div className="button-wrapper">
              <div style={{ width: '48%' }}>
                <Button
                  text="Discard"
                  textColor={Colors.color21}
                  buttonStyle={` background: ${Colors.color22}; box-shadow: 3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color23}; `}
                />
              </div>
              <div style={{ width: '48%' }}> 
                <Button
                  text={"Save" }
                  buttonStyle={` box-shadow: -3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color23}; `}
                onClick={this.props.onClickCreate}
                />
              </div>
            </div>
          </Col>
        </Row>`
        </StyledDialogContent>
    </Dialog>
  )}
}



export default CreateContent


