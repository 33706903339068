import React, { Component } from "react";
import { connect } from "react-redux";
import memoize from "memoize-one";
import { Link } from "react-router-dom";
import axios from "axios";
import {
  AllCheckerCheckbox,
  Checkbox,
  CheckboxGroup,
} from "@createnl/grouped-checkboxes";
import { Container, Row, Col, Hidden } from "react-grid-system";
import querystring from "querystring";
import moment from "moment";
import { getAllUsers } from "../api/AdminAPI";
import { reversingAccountStatus } from "../api/UserAPI";

import {
  StyledUserManagement,
  buttonAllAccounts,
  buttonAllAplicants,
  Label,
  InputStyle,
} from "./styles/StyledUserManagement";

import Table from "../components/table";
import Dialog from "../components/modal";
import Input from "../components/input";
import DialogBulkActions from "../components/dialog-bulk-actions/";
import DialogFilterUser from "../components/dialog-filter-user";
import Pagination from "../components/pagination";
import DialogInfo from "../components/dialog-info";
import UserTopButton from "../components/user-top-button";
import Button from "../components/button";

import { Colors, Images } from "../themes";

import UserActions from "../redux/UserRedux";
import StartupActions from "../redux/StartupRedux";
import AuthActions from "../redux/AuthRedux";

import { ACCOUNT_STATUS, USER_TYPE, EXPORT_TYPE } from "../constants/";
// import AppConfig from '../config/AppConfig'
import AppConfig from "../config.js";

import ProccessData from "../lib/ProccessData";
import {
  deleteUser,
  deleteUser2,
  exportUser,
  exportUserSelected,
  exportUserFiltered,
  getOtherAccount,
  getCountUsers,
  updateAccountStatus,
  updateAccountMetadata,
} from "../api/UserAPI";

import { columnsRelationship } from "../data/TableColumns";
import convertNumberToArray from "../lib/convertNumberToArray";

const InputTypeStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  .input-field { cursor: pointer; }
`;

const InputReadOnlyStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin: 10px 0 25px 0; 
  .input-field { cursor: pointer; }
`;

const dropdownTargetData = [
  {
    id: 1,
    name: "Person with Dementia",
    value: USER_TYPE.DEMENTIA,
  },
  {
    id: 2,
    name: "Caregiver",
    value: USER_TYPE.CAREGIVER,
  },
  {
    id: 3,
    name: "Member of Public",
    value: USER_TYPE.MOP,
  },
];

const exportLastUpdatedDefaultState = {
  dialogExportLastUpdated: false,
  startDate: "",
  endDate: "",
  dateFilter: "",
  accountStatus: {},
  accountStatusFiltered: false,
  typeFiltered: false,
  approvedAccountOnly: false,
  dropdownTarget: false,
  targetUser: {
    id: 1,
    name: "Person with Dementia",
    value: USER_TYPE.DEMENTIA,
  },
};

const bulkActionsDefaultState = {
  dialogBulkActions: false,
  bulkActionsTitle: `Bulk Actions`,
};

const filterUserDefaultState = {
  dialogFilterUser: false,
  filterUserTitle: `Search Filter`,
  filterBy: [],
  additionalFilter: { typeDementia: "", gender: "", language: "" },
  searchText: "",
};

const dropdownProfileData = [
  {
    id: 1,
    name: "Profile",
  },
  {
    id: 2,
    name: "Logout",
  },
];

class UserManagement extends Component {
  constructor(props) {
    super(props);
    let { "?type": type = "dementia" } = querystring.parse(
      this.props.location.search
    );
    // let listBy = localStorage.getItem("listUserBy") ? localStorage.getItem("listUserBy") : "ALL_ACCOUNTS",

    this.state = {
      searching: false,
      listBy: localStorage.getItem("listUserBy")
        ? localStorage.getItem("listUserBy")
        : "ALL_ACCOUNTS",
      // show: localStorage.getItem("showUser") ? localStorage.getItem("showUser") : USER_TYPE.DEMENTIA,
      // show: type,
      show:
        type === "mop"
          ? "MoP"
          : type === "care"
          ? "CARE"
          : type === "partner"
          ? "PARTNER"
          : type === "dementia"
          ? "DEMENTIA"
          : "CAREGIVER",
      // caregiverType: 'MOP',
      caregiverType:
        type === "mop" ? "MOP" : type == "care" ? "CARE_PROFESSIONAL" : null,
      userData: [],
      loading: false,
      loadingDelete: false,
      dialogConfirmation: false,
      dialogConfirmation2: false,
      selectedData: [],
      dialogExport: false,
      exportData: {},
      loadingExportSalesforce: false,
      loadingExportInformation: false,
      dialogExportSelected: false,
      ...exportLastUpdatedDefaultState,
      pageSize: 10,
      totalPages: [],
      result: false,
      searchMax: AppConfig.DEFAULT_LIMIT_USER, // localStorage.getItem("searchMaxUser") ? localStorage.getItem("searchMaxUser") : AppConfig.DEFAULT_LIMIT_USER,
      loadingBulkActions: false,
      sortBy: "createdAt,desc",
      ...bulkActionsDefaultState,
      ...filterUserDefaultState,
      infoDialogShow: false,
      infoDescription: "",
      profileDropdown: false,
      loadingExportAll: false,
      deleteReason: "",
      onlyCreatedByPartner: false,
      dateFilter: {
        startDate: null,
        endDate: null,
      },
      totalData: 0,
    };

    this.page =
      this.state.show === USER_TYPE.DEMENTIA
        ? localStorage.getItem("dementiaPage")
          ? localStorage.getItem("dementiaPage")
          : 1
        : this.state.show === USER_TYPE.CAREGIVER
        ? localStorage.getItem("caregiverPage")
          ? localStorage.getItem("caregiverPage")
          : 1
        : this.state.show === USER_TYPE.MOP
        ? localStorage.getItem("mopPage")
          ? localStorage.getItem("mopPage")
          : 1
        : localStorage.getItem("partnerPage")
        ? localStorage.getItem("partnerPage")
        : 1;
    this.startDate = null;
    this.endDate = null;

    this.getTotalUsers = this.getTotalUsers.bind(this);
  }

  renderFilterExport = () => {
    const {
      listBy,
      show,
      userData,
      loading,
      dialogConfirmation,
      dialogConfirmation2,
      userId,
      loadingDelete,
      selectedData,
      dialogExport,
      exportData,
      loadingExportSalesforce,
      loadingExportInformation,
      dialogExportSelected,
      dialogExportLastUpdated,
      startDate,
      endDate,
      caregiverAccountList,
      dementiaAccountList,
      dateFilter,
      accountStatusFiltered,
      dropdownTarget,
      targetUser,
      typeFiltered,
      page,
      pages,
      pageSize,
      dialogBulkActions,
      bulkActionsTitle,
      deleteReason,
      approvedAccountOnly,
      totalData,
    } = this.state;

    return (
      <div style={{ margin: "20px 6px 0 14px" }}>
        <p className="updated-text">Last Updated Date</p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Col>
            <Col md={12}>
              <Label>Start date</Label>
              <Input
                inputProps={{
                  type: "date",
                  onChange: (e) => this.setState({ startDate: e.target.value }),
                  value: dateFilter === "last updated" ? startDate : "",
                  readOnly: dateFilter !== "last updated",
                }}
                containerStyle={`
                ${InputStyle}                    
                .input-field {
                  cursor: ${
                    dateFilter === "last updated" ? "pointer" : "not-allowed"
                  }
                }
                input {
                  color: ${
                    dateFilter === "last updated"
                      ? Colors.color2
                      : Colors.color34
                  } !important;
                }
              `}
              />
            </Col>
          </Col>
          <div style={{ width: "15px" }} />
          <Col>
            <Col md={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Label>End date</Label>
                <img
                  src={
                    dateFilter === "last updated"
                      ? Images.switchOn
                      : Images.switchOff
                  }
                  style={{ width: "30px", cursor: "pointer" }}
                  onClick={() =>
                    this.setState({
                      dateFilter:
                        dateFilter === "last updated" ? "" : "last updated",
                    })
                  }
                />
              </div>
              <Input
                inputProps={{
                  type: "date",
                  onChange: (e) => this.setState({ endDate: e.target.value }),
                  value: dateFilter === "last updated" ? endDate : "",
                  readOnly: dateFilter !== "last updated",
                }}
                containerStyle={`
                ${InputStyle}                    
                .input-field {
                  cursor: ${
                    dateFilter === "last updated" ? "pointer" : "not-allowed"
                  }
                }
                input {
                  color: ${
                    dateFilter === "last updated"
                      ? Colors.color2
                      : Colors.color34
                  } !important;
                }
              `}
              />
            </Col>
          </Col>
        </div>
        <p className="updated-text" style={{ marginTop: 0 }}>
          Approval Date
        </p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Col>
            <Col md={12}>
              <Label>Start date</Label>
              <Input
                inputProps={{
                  type: "date",
                  onChange: (e) => this.setState({ startDate: e.target.value }),
                  value: dateFilter === "approval date" ? startDate : "",
                  readOnly: dateFilter !== "approval date",
                }}
                containerStyle={`
                ${InputStyle}                    
                .input-field {
                  cursor: ${
                    dateFilter === "approval date" ? "pointer" : "not-allowed"
                  }
                }
                input {
                  color: ${
                    dateFilter === "approval date"
                      ? Colors.color2
                      : Colors.color34
                  } !important;
                }
              `}
              />
            </Col>
          </Col>
          <div style={{ width: "15px" }} />
          <Col>
            <Col md={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Label>End date</Label>
                <img
                  src={
                    dateFilter === "approval date"
                      ? Images.switchOn
                      : Images.switchOff
                  }
                  style={{ width: "30px", cursor: "pointer" }}
                  onClick={() =>
                    this.setState({
                      dateFilter:
                        dateFilter === "approval date" ? "" : "approval date",
                    })
                  }
                />
              </div>
              <Input
                inputProps={{
                  type: "date",
                  onChange: (e) => this.setState({ endDate: e.target.value }),
                  value: dateFilter === "approval date" ? endDate : "",
                  readOnly: dateFilter !== "approval date",
                }}
                containerStyle={`
                ${InputStyle}                    
                .input-field {
                  cursor: ${
                    dateFilter === "approval date" ? "pointer" : "not-allowed"
                  }
                }
                input {
                  color: ${
                    dateFilter === "approval date"
                      ? Colors.color2
                      : Colors.color34
                  } !important;
                }
              `}
              />
            </Col>
          </Col>
        </div>
        <p className="updated-text" style={{ marginTop: 0 }}>
          Sign Up Date
        </p>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Col>
            <Col md={12}>
              <Label>Start date</Label>
              <Input
                inputProps={{
                  type: "date",
                  onChange: (e) => this.setState({ startDate: e.target.value }),
                  value: dateFilter === "sign up date" ? startDate : "",
                  readOnly: dateFilter !== "sign up date",
                }}
                containerStyle={`
                ${InputStyle}                    
                .input-field {
                  cursor: ${
                    dateFilter === "sign up date" ? "pointer" : "not-allowed"
                  }
                }
                input {
                  color: ${
                    dateFilter === "sign up date"
                      ? Colors.color2
                      : Colors.color34
                  } !important;
                }
              `}
              />
            </Col>
          </Col>
          <div style={{ width: "15px" }} />
          <Col>
            <Col md={12}>
              <div
                style={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                }}
              >
                <Label>End date</Label>
                <img
                  src={
                    dateFilter === "sign up date"
                      ? Images.switchOn
                      : Images.switchOff
                  }
                  style={{ width: "30px", cursor: "pointer" }}
                  onClick={() =>
                    this.setState({
                      dateFilter:
                        dateFilter === "sign up date" ? "" : "sign up date",
                    })
                  }
                />
              </div>
              <Input
                inputProps={{
                  type: "date",
                  onChange: (e) => this.setState({ endDate: e.target.value }),
                  value: dateFilter === "sign up date" ? endDate : "",
                  readOnly: dateFilter !== "sign up date",
                }}
                containerStyle={`
                ${InputStyle}                    
                .input-field {
                  cursor: ${
                    dateFilter === "sign up date" ? "pointer" : "not-allowed"
                  }
                }
                input {
                  color: ${
                    dateFilter === "sign up date"
                      ? Colors.color2
                      : Colors.color34
                  } !important;
                }
              `}
              />
            </Col>
          </Col>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Label>Account Status</Label>
          <img
            src={accountStatusFiltered ? Images.switchOn : Images.switchOff}
            style={{ width: "30px", cursor: "pointer" }}
            onClick={() =>
              this.setState({ accountStatusFiltered: !accountStatusFiltered })
            }
          />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            marginTop: "10px",
          }}
        >
          <Col>
            {this.renderInputCheckbox({
              value: "Expired",
              stateName: "accountStatus",
              md: 12,
              filtered: accountStatusFiltered,
              data: { name: "", value: "EXPIRED" },
            })}
          </Col>
          <div style={{ width: "15px" }} />
          <Col>
            {this.renderInputCheckbox({
              value: "New User",
              stateName: "accountStatus",
              md: 12,
              filtered: accountStatusFiltered,
              data: { name: "", value: "INCOMPLETE" },
            })}
          </Col>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Col>
            {this.renderInputCheckbox({
              value: "Pending Approval",
              stateName: "accountStatus",
              md: 12,
              filtered: accountStatusFiltered,
              data: { name: "", value: "PENDING_APPROVAL" },
            })}
          </Col>
          <div style={{ width: "15px" }} />
          <Col>
            {this.renderInputCheckbox({
              value: "Pending Cards",
              stateName: "accountStatus",
              md: 12,
              filtered: accountStatusFiltered,
              data: { name: "", value: "PENDING_CARDS" },
            })}
          </Col>
        </div>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Col>
            {this.renderInputCheckbox({
              value: "Completed",
              stateName: "accountStatus",
              md: 12,
              filtered: accountStatusFiltered,
              data: { name: "", value: "COMPLETED" },
            })}
          </Col>
          <div style={{ width: "15px" }} />
          <Col />
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
          }}
        >
          <Label>Type</Label>
          <img
            src={typeFiltered ? Images.switchOn : Images.switchOff}
            style={{ width: "30px", cursor: "pointer" }}
            onClick={() => this.setState({ typeFiltered: !typeFiltered })}
          />
        </div>
        <div>
          <Col>
            {this.renderInputDropdown({
              showDropdown: dropdownTarget,
              dropdownData: dropdownTargetData,
              dropdownPropertyName: "name",
              onClick: () =>
                this.onPressDropdown("dropdownTarget", dropdownTarget),
              onSelectDropdown: (targetUser) => {
                this.setState({
                  dropdownTarget: false,
                  targetUser,
                });
              },
              value: targetUser.name,
              filtered: typeFiltered,
            })}
          </Col>
        </div>
        <div
          style={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: "center",
            marginTop: "10px",
            marginBottom: "50px",
          }}
        >
          <Label>Approved account only</Label>
          <img
            src={approvedAccountOnly ? Images.switchOn : Images.switchOff}
            style={{ width: "30px", cursor: "pointer" }}
            onClick={() =>
              this.setState({ approvedAccountOnly: !approvedAccountOnly })
            }
          />
        </div>
      </div>
    );
  };

  // async componentDidMount() {
  //   const {account} = this.props
  //   let isPartner = account && account.payload && account.payload.data && account.payload.data.account && account.payload.data.account.type === 'PARTNER' ? true : false
  //   this.setState({ loading: true })
  //   this.getUsersToRedux({ data: { type: this.state.show, showBy: this.state.listBy, sortBy: this.state.sortBy, searchPage: this.page, searchMax: this.state.searchMax, metadata: this.state.show === USER_TYPE.MOP ? true : (this.state.show === USER_TYPE.CAREGIVER && !isPartner) ? false : null, careProff: this.state.show === USER_TYPE.CARE ? true : null,} })
  //   this.props.initToken()
  //   this.getTotalUsers({ type: this.state.show, showBy: this.state.listBy, metadata: this.state.show === USER_TYPE.MOP ? true : (this.state.show === USER_TYPE.CAREGIVER && !isPartner) ? false : null, careProff: this.state.show === USER_TYPE.CARE ? true : null,})

  //   console.log("masuk halaman user management")
  // }

  async componentDidMount() {
    this.getUsers();
    this.interval = setInterval(this.getUsers, 3600000);
  }

  componentWillUnmount() {
    clearInterval(this.interval); // Clear the interval when the component is unmounted to prevent memory leaks
  }

  async componentDidUpdate(prevProps, prevState) {
    if (prevState.pageSize !== this.state.pageSize) {
      this.getUsers();
    } else if (
      prevState.listBy !== this.state.listBy ||
      prevState.show !== this.state.show ||
      prevState.sortBy !== this.state.sortBy ||
      prevState.caregiverType !== this.state.caregiverType ||
      prevState.searching !== this.state.searching ||
      prevState.onlyCreatedByPartner !== this.state.onlyCreatedByPartner
    ) {
      this.page = 1;
      this.getUsers();
    }

    if (this.state.dateFilter != prevState.dateFilter) {
      this.setState({
        startDate: "",
        endDate: "",
      });
    }
  }

  getUsers = async (params) => {
    let { "?type": type = "dementia" } = querystring.parse(
      this.props.location.search
    );
    const { account } = this.props;
    const {
      filterBy,
      searchText,
      additionalFilter,
      onlyCreatedByPartner,
      dateFilter,
    } = this.state;
    const filterParams = filterBy.map((item) => ({ [item]: searchText }));
    let isPartner =
      account &&
      account.payload &&
      account.payload.data &&
      account.payload.data.account &&
      account.payload.data.account.type === "PARTNER"
        ? account.payload.data.account
        : false;

    const careProff = {};
    const carePartner = {};
    const modifiedType = {};

    if (
      this.state.show === USER_TYPE.CARE ||
      this.state.show === USER_TYPE.MOP
    ) {
      careProff.caregiverType = this.state.caregiverType;
      careProff.type = "CAREGIVER";
    }

    switch (type) {
      case "caregiver":
        modifiedType.type = USER_TYPE.CAREGIVER;
        break;
      case "dementia":
        modifiedType.type = USER_TYPE.DEMENTIA;
        break;
      case "care":
        modifiedType.type = USER_TYPE.CARE;
        break;
      case "partner":
        modifiedType.type = USER_TYPE.PARTNER;
        break;
      case "mop":
        modifiedType.type = USER_TYPE.MOP;
        break;
      default:
        modifiedType.type = USER_TYPE.DEMENTIA;
    }

    if (isPartner) {
      if (this.state.show === USER_TYPE.CAREGIVER) {
        careProff.caregiverType = "CARE_PROFESSIONAL";
        careProff.type = "CAREGIVER";
      }

      carePartner.partnerUid = isPartner.uid;
    }

    // Date filter
    let filterDate = {};
    if (dateFilter.startDate !== null && dateFilter.endDate !== null) {
      filterDate = {
        createdAt: `${dateFilter.startDate},${dateFilter.endDate}`,
      };
    }

    this.setState({ loading: true });
    try {
      const { data } = await getAllUsers(
        params
          ? { ...params }
          : {
              type: modifiedType.type,
              limit: this.state.pageSize,
              showBy: this.state.listBy,
              sortBy: this.state.sortBy,
              page: this.page,
              onlyCreatedByPartner:
                this.state.show === "DEMENTIA"
                  ? this.state.onlyCreatedByPartner
                  : false,
              ...filterDate,
              ...Object.assign({}, ...filterParams),
              ...additionalFilter,
              ...careProff,
              ...carePartner,
            }
      );

      this.props.initToken();
      this.page = data.pageInfo.currentPage;

      const totalPages = await convertNumberToArray(data.pageInfo.totalPages);
      this.setState({
        userData: data.results,
        loading: false,
        totalPages,
        searching: false,
        totalData: data.totalData,
      });
    } catch (e) {
      this.setState({
        loading: false,
        userData: [],
        totalPages: [],
        searching: false,
      });
    }
  };

  async getTotalUsers(params) {
    let filter = {
      params: {
        ...params,
        searchBy: this.props.account.payload.data.account.type,
      },
      type: this.props.account.payload.data.account.type,
    };
    let newError = new Error("Error Get User");
    try {
      let resultGet = {};
      resultGet.fetching = false;
      resultGet.payload = await getCountUsers(filter);
      ProccessData(resultGet, "get total user")
        .then((res) => {
          let totalPages = [];
          for (
            let i = 1;
            i <= Math.ceil(res.data.length / this.state.searchMax);
            i++
          ) {
            totalPages.push(i);
          }

          this.setState({
            totalPages,
          });
        })
        .catch((err) => {
          this.onDialogInfo(err);
        });
    } catch (e) {
      this.onDialogInfo(e.message);
    }
  }

  getUsersToRedux = ({ data }) => {
    if (this.props.account.payload.data.account.type === USER_TYPE.PARTNER) {
      delete data.searchFrom;
      delete data.showBy;
    }
    this.props.getUsers({ data: { ...data } });
  };

  // componentWillUnmount() {
  //   localStorage.setItem("dementiaPage", 1)
  //   localStorage.setItem("caregiverPage", 1)
  // }

  deleteUser = async () => {
    this.setState({ loadingDelete: true });
    const { selectedData } = this.state;
    let uidList = [];
    selectedData.map((data) => uidList.push(data.uid));
    try {
      let resultDelete = {};
      resultDelete.fetching = false;
      resultDelete.payload = await deleteUser({ uidList: uidList.join(",") });
      ProccessData(resultDelete, "delete user")
        .then((res) => {
          // this.props.getUsers({ data: { type: this.state.show, searchPage: this.page, searchMax: this.state.searchMax, } })
          this.onDialogInfo("Account Deleted");
          this.setState({
            loadingDelete: false,
            dialogConfirmation: false,
          });
          window.location.reload(); // to uncheck all checked row
        })
        .catch((err) => {
          this.onDialogInfo(err);
        });
    } catch (e) {
      this.onDialogInfo(e.message);
      this.setState({
        loadingDelete: false,
        dialogConfirmation: false,
      });
    }
  };

  deleteUser2 = async () => {
    this.setState({ loadingDelete: true });
    const { selectedData, deleteReason } = this.state;
    let uidList = [];
    selectedData.map((data) => uidList.push(data.uid));
    try {
      let resultDelete = {};
      resultDelete.fetching = false;
      // ganti api dengan yg baru
      resultDelete.payload = await deleteUser2({
        uid: uidList[0],
        uidList: uidList.join(","),
        reason: deleteReason,
      });
      ProccessData(resultDelete, "delete user")
        .then((res) => {
          // this.props.getUsers({ data: { type: this.state.show, searchPage: this.page, searchMax: this.state.searchMax, } })
          this.onDialogInfo("Account Deleted");
          this.setState({
            loadingDelete: false,
            dialogConfirmation: false,
          });
          window.location.reload(); // to uncheck all checked row
        })
        .catch((err) => {
          this.onDialogInfo(err);
        });
    } catch (e) {
      this.onDialogInfo(e.message);
      this.setState({
        loadingDelete: false,
        dialogConfirmation: false,
      });
    }
  };

  onExportUser = async ({
    firstname = "file",
    lastname = "",
    uid,
    exportType = EXPORT_TYPE.SALESFORCE,
  }) => {
    try {
      let resultExport = {};
      resultExport = await exportUser({ uid, exportType });
      let blob = new Blob([resultExport], {
        type: "",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;
      link.setAttribute("download", `${firstname} ${lastname}.csv`);
      document.body.appendChild(link);
      this.setState({
        loadingExportSalesforce: false,
        dialogExport: false,
        loadingExportInformation: false,
      });
      link.click();
      if (
        exportType === EXPORT_TYPE.INFO &&
        this.state.show === USER_TYPE.DEMENTIA
      ) {
        window.open(`/export-qrcode?uid=${uid}`);
      }
    } catch (e) {
      this.onDialogInfo(e.message);
      this.setState({
        loadingExportSalesforce: false,
        dialogExport: false,
        loadingExportInformation: false,
      });
    }
  };

  onCheckboxChange = (checkboxes) => {
    let selectedData = [];

    checkboxes.map((data) => {
      if (data.checked) {
        selectedData.push(data.value);
      }
    });

    this.setState({ selectedData });
  };

  onExportUserSelected = async (exportType) => {
    const { selectedData } = this.state;
    let uidList = [];
    selectedData.map((data) => uidList.push(data.uid));

    try {
      let resultExport = {};
      resultExport = await exportUserSelected({
        uidList: uidList.join(","),
        exportType,
      });
      let blob = new Blob([resultExport], {
        type: "",
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement("a");
      link.href = url;

      link.setAttribute(
        "download",
        `User data ${this.state.targetUser.name}.csv`
      );
      document.body.appendChild(link);
      this.setState({
        loadingExportSalesforce: false,
        dialogExportSelected: false,
        loadingExportInformation: false,
      });
      link.click();
      if (
        exportType === EXPORT_TYPE.INFO &&
        this.state.show === USER_TYPE.DEMENTIA
      ) {
        window.open(`/export-qrcode?uidList=${uidList}`);
      }
    } catch (e) {
      this.onDialogInfo(e.message);
      this.setState({
        loadingExportSalesforce: false,
        dialogExportSelected: false,
        loadingExportInformation: false,
      });
    }
  };

  onExportSalesforceByLastUpdated = async (exportType, loadingStateName) => {
    const {
      startDate,
      endDate,
      dateFilter,
      accountStatus,
      accountStatusFiltered,
      typeFiltered,
      targetUser,
      approvedAccountOnly,
    } = this.state;
    let params = { exportType };

    if (dateFilter) {
      if (!startDate) {
        this.onDialogInfo("Please Select Start Date!");
        return false;
      }

      if (!endDate) {
        this.onDialogInfo("Please Select End Date!");
        return false;
      }

      if (new Date(`${startDate}`) > new Date(`${endDate}`)) {
        this.onDialogInfo(
          "Start date should always be earlier than end date. "
        );
        return false;
      }

      if (dateFilter === "last updated") {
        params.updatedAt = `${startDate},${endDate}`;
      } else if (dateFilter === "approval date") {
        params.approvalDate = `${startDate},${endDate}`;
      } else if (dateFilter === "sign up date") {
        params.createdAt = `${startDate},${endDate}`;
      }
    }

    if (accountStatus.hasOwnProperty("value") && accountStatusFiltered)
      params.accountStatus = accountStatus.value;

    if (typeFiltered) params.type = targetUser.value;

    if (approvedAccountOnly) params.approvedAccountOnly = true;

    this.setState({ [loadingStateName]: true });
    console.log("INI PARAMS => ", params);
    try {
      if (params.exportType === "ALL_FIELD" && params.type === undefined) {
        try {
          params.type = "DEMENTIA";
          let resultExport = {};
          resultExport = await exportUserFiltered(params);
          let blob = new Blob([resultExport], {
            type: "",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `User data ${params.type}.csv`);
          document.body.appendChild(link);
          link.click();
          if (
            exportType === EXPORT_TYPE.INFO &&
            this.state.show === USER_TYPE.DEMENTIA
          ) {
            delete params[`exportType`];
            window.open(
              `/export-qrcode${
                querystring.stringify(params).length > 0
                  ? `?${querystring.stringify(params)}`
                  : ``
              }`
            );
          }
        } catch (e) {
          this.onDialogInfo(e.message);
          this.setState({
            loadingExportSalesforce: false,
            dialogExportLastUpdated: false,
            loadingExportInformation: false,
            ...exportLastUpdatedDefaultState,
            [loadingStateName]: false,
          });
        } finally {
          params.type = "CAREGIVER";
          let resultExport2 = {};
          resultExport2 = await exportUserFiltered(params);
          let blob = new Blob([resultExport2], {
            type: "",
          });
          const url = window.URL.createObjectURL(blob);
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", `User data ${params.type}.csv`);
          document.body.appendChild(link);
          this.setState({
            loadingExportSalesforce: false,
            dialogExportLastUpdated: false,
            loadingExportInformation: false,
            ...exportLastUpdatedDefaultState,
          });
          link.click();
          if (
            exportType === EXPORT_TYPE.INFO &&
            this.state.show === USER_TYPE.DEMENTIA
          ) {
            delete params[`exportType`];
            window.open(
              `/export-qrcode${
                querystring.stringify(params).length > 0
                  ? `?${querystring.stringify(params)}`
                  : ``
              }`
            );
          }

          params.type = "MoP";
          let resultExport3 = {};
          resultExport3 = await exportUserFiltered(params);
          let blob2 = new Blob([resultExport3], {
            type: "",
          });
          const url2 = window.URL.createObjectURL(blob2);
          const link2 = document.createElement("a");
          link.href = url2;
          link.setAttribute("download", `User data ${params.type}.csv`);
          document.body.appendChild(link2);
          this.setState({
            loadingExportSalesforce: false,
            dialogExportLastUpdated: false,
            loadingExportInformation: false,
            ...exportLastUpdatedDefaultState,
          });
          link.click();
          if (
            exportType === EXPORT_TYPE.INFO &&
            this.state.show === USER_TYPE.DEMENTIA
          ) {
            delete params[`exportType`];
            window.open(
              `/export-qrcode${
                querystring.stringify(params).length > 0
                  ? `?${querystring.stringify(params)}`
                  : ``
              }`
            );
          }
        }
      } else {
        let resultExport = {};
        resultExport = await exportUserFiltered(params);
        let blob = new Blob([resultExport], {
          type: "",
        });
        let fileName = "User data.csv";

        if (exportType === "ALL_FIELD") {
          fileName = `User data ${params.type}.csv`;
        } else if (exportType === "INFO") {
          fileName = `User data Info${
            params.type ? " " + params.type : ""
          }.csv`;
        } else if (exportType === "SALESFORCE") {
          fileName = `User data Salesforce${
            params.type ? " " + params.type : ""
          }.csv`;
        }

        const url = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = url;
        link.setAttribute("download", fileName);
        document.body.appendChild(link);
        this.setState({
          loadingExportSalesforce: false,
          dialogExportLastUpdated: false,
          loadingExportInformation: false,
          ...exportLastUpdatedDefaultState,
        });
        link.click();
        if (
          exportType === EXPORT_TYPE.INFO &&
          this.state.show === USER_TYPE.DEMENTIA
        ) {
          delete params[`exportType`];
          window.open(
            `/export-qrcode${
              querystring.stringify(params).length > 0
                ? `?${querystring.stringify(params)}`
                : ``
            }`
          );
        }
      }
      let resultExport = {};
      this.setState({ loadingExportAll: false });
    } catch (e) {
      this.onDialogInfo(e.message);
      this.setState({
        loadingExportSalesforce: false,
        dialogExportLastUpdated: false,
        loadingExportInformation: false,
        ...exportLastUpdatedDefaultState,
        [loadingStateName]: false,
      });
    }
  };

  renderInputCheckbox({
    value,
    stateName = "accountStatus",
    md = 3.8,
    filtered = true,
    data,
  }) {
    const { accountStatus } = this.state;
    return (
      <Col md={md}>
        <Input
          inputProps={{
            value,
            readOnly: true,
            onClick: () =>
              filtered
                ? this.setState({
                    [stateName]: { name: value, value: data.value },
                  })
                : null,
          }}
          containerStyle={`
            ${InputTypeStyle}
            .input-field {
              cursor: ${filtered ? "pointer" : "not-allowed"}
            }
            input {
              color: ${filtered ? Colors.color2 : Colors.color34} !important;
            }
          `}
          iconRight={
            accountStatus.name === value
              ? Images.checkActive
              : Images.checkInactive
          }
          iconRightClick={() =>
            filtered
              ? this.setState({
                  [stateName]: { name: value, value: data.value },
                })
              : null
          }
          iconRightStyle={{ width: "15px" }}
        />
      </Col>
    );
  }

  renderInputDropdown(props) {
    const { value, onClick, filtered } = props;
    return (
      <Input
        inputProps={{
          readOnly: true,
          onClick: filtered ? onClick : null,
          value,
        }}
        containerStyle={`
          ${InputReadOnlyStyle}
          .input-field {
            cursor: ${filtered ? "pointer" : "not-allowed"}
          }
          input {
            color: ${filtered ? Colors.color2 : Colors.color34} !important;
          }
          `}
        iconRight={Images.polygonYellow}
        {...props}
        dropdownWidth={"100%"}
      />
    );
  }

  onPressDropdown = (stateName, state) => {
    this.setState({ [stateName]: !state });
  };

  handlePagination = async (Index) => {
    const { filterBy, searchText, additionalFilter } = this.state;
    const params = filterBy.map((item) => ({ [item]: searchText }));
    const { account } = this.props;
    let isPartner =
      account &&
      account.payload &&
      account.payload.data &&
      account.payload.data.account &&
      account.payload.data.account.type === "PARTNER"
        ? true
        : false;

    this.page = Index;
    this.state.show === USER_TYPE.DEMENTIA
      ? localStorage.setItem("dementiaPage", Index)
      : this.state.show === USER_TYPE.CAREGIVER
      ? localStorage.setItem("caregiverPage", Index)
      : this.state.show === USER_TYPE.MOP
      ? localStorage.setItem("mopPage", Index)
      : localStorage.setItem("partnerPage", Index);
    this.getUsers();
  };

  onSearchKey(event) {
    const code = event.keyCode || event.which;

    // const { filterBy, searchText, } = this.state

    if (code === 13) {
      this.onSearch.bind(this);
    }
  }

  onSearch() {
    this.setState({
      searching: true,
    });
    // const { filterBy, searchText, additionalFilter } = this.state
    // const params = filterBy.map(item => ({ [item]: searchText }))

    // const {account} = this.props
    // let isPartner = account && account.payload && account.payload.data && account.payload.data.account && account.payload.data.account.type === 'PARTNER' ? true : false

    // const careProff = {};

    // if (this.state.show === USER_TYPE.CARE || this.state.show === USER_TYPE.MOP) {
    //   careProff.caregiverType = this.state.caregiverType;
    //   careProff.type = 'CAREGIVER';
    // }

    // this.getUsers({type: this.state.show, showBy: this.state.listBy, sortBy: this.state.sortBy, page: this.page, limit: this.state.pageSize, ...Object.assign({}, ...params),  ...additionalFilter, ...careProff })
  }

  reversingAccountStatus = async () => {
    const { selectedData } = this.state;
    const uidList = selectedData.map((item) => item.uid);

    try {
      await reversingAccountStatus({ uidList });
      // this.setState({
      //   loadingBulkActions: false,
      //   loading: true,
      //  })
      // this.onDialogInfo('Success')
      window.location.reload();
    } catch (e) {
      this.setState({
        loadingBulkActions: false,
      });
      this.onDialogInfo(e);
    }
  };

  onBulkActions({
    action,
    accountStatus,
    isArchived,
    exportType,
    metadata,
    uid,
  }) {
    if (exportType) {
      this.onExportUserSelected(exportType);
    } else if (accountStatus) {
      switch (accountStatus) {
        case ACCOUNT_STATUS.PENDING_CARDS:
          this._onUpdateAccountStatus(
            ACCOUNT_STATUS.PENDING_CARDS,
            "Approve account",
            uid
          );
          break;
        case ACCOUNT_STATUS.REJECTED:
          this._onUpdateAccountStatus(
            ACCOUNT_STATUS.REJECTED,
            "Reject account",
            uid
          );
          break;
        case ACCOUNT_STATUS.COMPLETED:
          this._onUpdateAccountStatus(
            ACCOUNT_STATUS.COMPLETED,
            "Send Card",
            uid
          );
          break;
        case ACCOUNT_STATUS.TO_FOLLOW_UP:
          this._onUpdateAccountStatus(
            ACCOUNT_STATUS.TO_FOLLOW_UP,
            "To Follow-up account",
            uid
          );
          break;
        // case ACCOUNT_STATUS.EXPIRED:
        //   this._onUpdateAccountStatus(ACCOUNT_STATUS.EXPIRED, 'Set account to expire')
        //   break
        default:
          null;
      }
    } else if (isArchived) {
      this._onUpdateAccountStatus(ACCOUNT_STATUS.ARCHIVED, "Archive account");
    } else if (metadata) {
      this.onUpdateMetadata(metadata);
    } else if (action === "REVERSING_ACCOUNT_STATUS") {
      this.reversingAccountStatus();
    } else if (action === "Delete 2") {
      this.setState({
        dialogConfirmation2: true,
      });
    } else {
      // this.deleteUser()
      this.setState({
        dialogConfirmation: true,
      });
    }

    this.setState({ dialogBulkActions: false });
  }

  onUpdateMetadata = async (metadata) => {
    const { account } = this.props;
    let isPartner =
      account &&
      account.payload &&
      account.payload.data &&
      account.payload.data.account &&
      account.payload.data.account.type === "PARTNER"
        ? true
        : false;

    this.setState({ loadingBulkActions: true });
    const { selectedData } = this.state;
    let uidList = [];
    selectedData.map((data) => uidList.push(data.uid));
    let params = { uidList: uidList.join(",") };
    try {
      let resultUpdate = {};
      resultUpdate.fetching = false;
      resultUpdate.payload = await updateAccountMetadata(params, {
        data: { metadata },
      });
      ProccessData(resultUpdate, `Update metadata`)
        .then((res) => {
          this.getUsersToRedux({
            data: {
              type: this.state.show,
              showBy: this.state.listBy,
              sortBy: this.state.sortBy,
              searchPage: this.page,
              searchMax: this.state.searchMax,
              metadata:
                this.state.show === USER_TYPE.MOP
                  ? true
                  : this.state.show === USER_TYPE.CAREGIVER && !isPartner
                  ? false
                  : null,
              careProff: this.state.show === USER_TYPE.CARE ? true : null,
            },
          });
          this.setState({
            loadingBulkActions: false,
            loading: true,
          });
          this.onDialogInfo("Success");
          window.location.reload(); // to uncheck all checked row
        })
        .catch((err) => {
          this.setState({
            loadingBulkActions: false,
          });
          this.onDialogInfo(err);
        });
    } catch (e) {
      this.onDialogInfo(e.message);
      this.setState({
        loadingBulkActions: false,
      });
    }
  };

  async _onUpdateAccountStatus(accountStatus, action, uid) {
    this.setState({ loadingBulkActions: true });
    const { selectedData } = this.state;
    let uidList = [];

    if (uid) {
      uidList = [uid];
    } else {
      selectedData.map((data) => uidList.push(data.uid));
    }

    let params = {};

    if (accountStatus === ACCOUNT_STATUS.ARCHIVED) {
      params = {
        uidList: uidList.join(","),
        isArchived: 1,
        lastUpdatedBy: this.props.account.payload.data.account.email,
      };
    } else {
      params = {
        uidList: uidList.join(","),
        accountStatus,
        processedBy:
          accountStatus === ACCOUNT_STATUS.COMPLETED
            ? `${this.props.account.payload.data.account.email}`
            : undefined,
        lastUpdatedBy: this.props.account.payload.data.account.email,
        isArchived: 0,
      };
    }
    try {
      let resultUpdate = {};
      resultUpdate.fetching = false;
      resultUpdate.payload = await updateAccountStatus(params);
      ProccessData(resultUpdate, action)
        .then((res) => {
          this.getUsersToRedux({
            data: {
              type: this.state.show,
              showBy: this.state.listBy,
              sortBy: this.state.sortBy,
              searchPage: this.page,
              searchMax: this.state.searchMax,
              metadata:
                this.state.show === USER_TYPE.MOP
                  ? true
                  : this.state.show === USER_TYPE.CAREGIVER
                  ? false
                  : null,
              careProff: this.state.show === USER_TYPE.CARE ? true : null,
            },
          });
          this.setState({
            loadingBulkActions: false,
            loading: true,
          });
          this.onDialogInfo("Success");
          window.location.reload(); // to uncheck all checked row
        })
        .catch((err) => {
          this.setState({
            loadingBulkActions: false,
          });
          this.onDialogInfo(err);
        });
    } catch (e) {
      this.onDialogInfo(e.message);
      this.setState({
        loadingBulkActions: false,
      });
    }
  }

  onDialogInfo = (val) => {
    this.setState({ infoDescription: val, infoDialogShow: true });
  };

  onCheckFilter(value) {
    const filterBy = [...this.state.filterBy];

    if (!filterBy.includes(value.params)) {
      //checking weather array contain the id
      filterBy.push(value.params); //adding to array because value doesnt exists
    } else {
      filterBy.splice(filterBy.indexOf(value.params), 1); //deleting
    }

    this.setState({ filterBy });
  }

  onSetAdditionalFilter(value) {
    this.setState({
      additionalFilter: {
        ...this.state.additionalFilter,
        ...value,
      },
    });
  }

  render() {
    const {
      listBy,
      infoDialogShow,
      infoDescription,
      show,
      userData,
      loading,
      dialogConfirmation,
      dialogConfirmation2,
      userId,
      loadingDelete,
      selectedData,
      dialogExport,
      exportData,
      loadingExportSalesforce,
      loadingExportInformation,
      dialogExportSelected,
      dialogExportLastUpdated,
      startDate,
      endDate,
      caregiverAccountList,
      dementiaAccountList,
      dateFilter,
      accountStatusFiltered,
      dropdownTarget,
      targetUser,
      typeFiltered,
      page,
      pages,
      pageSize,
      dialogBulkActions,
      bulkActionsTitle,
      dialogFilterUser,
      filterUserTitle,
      filterBy,
      additionalFilter,
      totalPages,
      loadingBulkActions,
      searchText,
      profileDropdown,
      loadingExportAll,
      deleteReason,
      totalData,
    } = this.state;

    const { users, location } = this.props;
    const { account } = this.props.account.payload.data || {};

    let isPartner = account.type === "PARTNER" ? true : false;

    const isUserManagement = location.pathname === "/users";

    const dementiaList = show === USER_TYPE.DEMENTIA;
    const partnerList = show === USER_TYPE.PARTNER;

    const onClickColumn = (props) => {
      let pathname =
        props.original.type === USER_TYPE.PARTNER
          ? `/partner/${props.original.uid}`
          : `/user/${props.original.uid}`;
      this.props.history.push({
        pathname,
        state: {
          action: "DETAIL",
        },
      });
    };

    const onClickColumnByPartner = (props) => {
      this.props.history.push({
        pathname: `/relationship/${props.original.uid}`,
        state: {
          action: "DETAIL",
        },
      });
    };

    const handleHeaderClick = (params) => {
      const { sortBy } = this.state;
      const [clickedColumn, clickedOrder] = params.split(",");

      let newSort = "";

      // Check if the clicked column is the current sorting column
      if (sortBy.split(",")[0] === clickedColumn) {
        // Toggle sorting order
        newSort = sortBy.includes("asc")
          ? `${clickedColumn},desc`
          : `${clickedColumn},asc`;
      } else {
        // Set new column and default to ascending order
        newSort = params;
      }

      this.setState({ sortBy: newSort });
    };

    let columns = [];

    columns = [
      {
        Header: (props) => (
          <div
            style={{
              display: "flex",
              margin: "10px 0",
              justifyContent: "space-between",
            }}
            onClick={() => !partnerList && handleHeaderClick("firstname,asc")}
          >
            <span>{partnerList ? `Main POC Name` : `First Name`}</span>
            {!partnerList && (
              <img
                src={
                  this.state.sortBy === "firstname,asc"
                    ? Images.ascending
                    : this.state.sortBy === "firstname,desc"
                    ? Images.descending
                    : Images.sortInactive
                }
                style={{ height: "25px", marginLeft: "7px", marginTop: "-5px" }}
              />
            )}
          </div>
        ),

        Cell: (props) => (
          <span className="column-text" onClick={() => onClickColumn(props)}>
            {partnerList
              ? props.original.PartnerDetail &&
                props.original.PartnerDetail.pocName
              : props.original.firstname}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
        sortable: false,
        resizable: false,
      },
      {
        Header: (props) => (
          <div
            style={{
              display: "flex",
              margin: "10px 0",
              justifyContent: "space-between",
            }}
            onClick={() => !partnerList && handleHeaderClick("lastname,asc")}
          >
            <span>{partnerList ? `Organization` : `Last Name`}</span>{" "}
            {!partnerList && (
              <img
                src={
                  this.state.sortBy === "lastname,asc"
                    ? Images.ascending
                    : this.state.sortBy === "lastname,desc"
                    ? Images.descending
                    : Images.sortInactive
                }
                style={{ height: "25px", marginLeft: "7px", marginTop: "-5px" }}
              />
            )}
          </div>
        ),
        Cell: (props) => (
          <span className="column-text" onClick={() => onClickColumn(props)}>
            {partnerList
              ? props.original.PartnerDetail &&
                props.original.PartnerDetail.organizationName
              : props.original.lastname}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
        sortable: false,
        resizable: false,
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            {partnerList ? `Email Address` : `Membership ID`}
          </span>
        ),
        Cell: (props) => (
          <span className="column-text" onClick={() => onClickColumn(props)}>
            {partnerList
              ? props.original.PartnerDetail &&
                props.original.PartnerDetail.pocEmail
              : props.original.membershipId
              ? props.original.membershipId
              : `-`}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
        sortable: false,
        resizable: false,
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            {dementiaList
              ? `NRIC`
              : partnerList
              ? `Tagged PWD`
              : `Email Address`}
          </span>
        ),
        Cell: (props) => (
          <span className="column-text" onClick={() => onClickColumn(props)}>
            {dementiaList
              ? props.original.lastNric
                ? props.original.lastNric
                : "-"
              : partnerList
              ? props.original.taggedAccount &&
                props.original.taggedAccount.Account &&
                props.original.taggedAccount.Account.firstname
                ? `${props.original.taggedAccount.Account.firstname} ${
                    props.original.taggedAccount.Account.lastname || ""
                  }`
                : "-"
              : // partnerList ? props.original.otherAccount && props.original.otherAccount.length > 0 ? props.original.otherAccount[0].uid ? `${props.original.otherAccount[0].firstname} ${props.original.otherAccount[0].lastname ? props.original.otherAccount[0].lastname : ''}` : `-`  : `-`
              props.original.email
              ? props.original.email
              : "-"}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
        sortable: false,
        resizable: false,
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            {dementiaList
              ? `Tagged CG`
              : partnerList
              ? `Phone No.`
              : `Tagged PWD`}
          </span>
        ),
        Cell: (props) => {
          return (
            <span onClick={() => onClickColumn(props)}>
              {partnerList
                ? props.original.PartnerDetail &&
                  props.original.PartnerDetail.pocPhone
                : props.original.taggedAccount &&
                  props.original.taggedAccount.Account
                ? props.original.taggedAccount.Account.uid
                  ? props.original.taggedAccount.Account.type ===
                    USER_TYPE.PARTNER
                    ? `${props.original.taggedAccount.Account.firstname}`
                    : `${props.original.taggedAccount.Account.firstname} ${
                        props.original.taggedAccount.Account.lastname
                          ? props.original.taggedAccount.Account.lastname
                          : ""
                      }`
                  : `-`
                : `-`}
            </span>
          );
        },
        headerClassName: "table-header",
        className: "column",
        sortable: false,
        resizable: false,
      },
      {
        Header: (props) => (
          <div
            style={{
              display: "flex",
              margin: "10px 0",
              justifyContent: "space-between",
            }}
            onClick={() =>
              listBy !== "RECENTLY_DELETED" &&
              this.state.show !== "PARTNER" &&
              handleHeaderClick("accountStatus,asc")
            }
          >
            <span>
              {listBy === "RECENTLY_DELETED" ? `Deletion Date` : `Status`}
            </span>
            {listBy !== "RECENTLY_DELETED" && this.state.show !== "PARTNER" && (
              <img
                src={
                  this.state.sortBy === "accountStatus,asc"
                    ? Images.ascending
                    : this.state.sortBy === "accountStatus,desc"
                    ? Images.descending
                    : Images.sortInactive
                }
                style={{ height: "25px", marginLeft: "7px", marginTop: "-5px" }}
              />
            )}
          </div>
        ),
        Cell: (props) => {
          const { accountStatus, isArchived } = props.original;
          const status = isArchived
            ? `Archived`
            : accountStatus === ACCOUNT_STATUS.INCOMPLETE
            ? "New User"
            : accountStatus === ACCOUNT_STATUS.COMPLETED
            ? "Completed"
            : accountStatus === ACCOUNT_STATUS.PENDING_APPROVAL
            ? "Pending approval"
            : accountStatus === ACCOUNT_STATUS.PENDING_CARDS
            ? "Pending cards"
            : accountStatus === ACCOUNT_STATUS.REJECTED
            ? "Rejected"
            : accountStatus === ACCOUNT_STATUS.EXPIRED
            ? "Expired"
            : accountStatus === ACCOUNT_STATUS.TO_FOLLOW_UP
            ? `To Follow-up`
            : `-`;

          const color = isArchived
            ? Colors.color5
            : accountStatus === ACCOUNT_STATUS.INCOMPLETE ||
              accountStatus === ACCOUNT_STATUS.REJECTED ||
              accountStatus === ACCOUNT_STATUS.EXPIRED ||
              accountStatus === ACCOUNT_STATUS.TO_FOLLOW_UP
            ? Colors.color13
            : accountStatus === ACCOUNT_STATUS.COMPLETED
            ? Colors.color17
            : Colors.color18;

          return (
            <span
              style={{
                color: listBy === "RECENTLY_DELETED" ? Colors.color : color,
              }}
              onClick={() => onClickColumn(props)}
            >
              {listBy === "RECENTLY_DELETED"
                ? moment(props.original.updatedAt).format("DD MMMM YYYY")
                : status}
            </span>
          );
        },
        headerClassName: "table-header",
        className: "column",
        sortable: false,
        resizable: false,
        fieldId: "status",
      },
    ];

    if (account.type === USER_TYPE.PARTNER)
      columns = columns.filter((el) => el.fieldId != "status");

    if (!partnerList) {
      columns.splice(0, 0, {
        Header: (props) => (
          <div
            style={{
              display: "flex",
              margin: "10px 0",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <AllCheckerCheckbox style={{ cursor: "pointer" }} />
          </div>
        ),
        Cell: (props) => (
          <div
            style={{
              display: "flex",
              margin: "10px 0",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {" "}
            <Checkbox style={{ cursor: "pointer" }} value={props.original} />
          </div>
        ),
        headerClassName: "table-header",
        className: "column-checkbox",
        width: 40,
        sortable: false,
        resizable: false,
      });

      columns.splice(6, 0, {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            Phone No.
          </span>
        ),
        Cell: (props) => (
          <span className="column-text" onClick={() => onClickColumn(props)}>
            {props.original.phone === 0 ||
            props.original.phone === "" ||
            !props.original.phone
              ? "-"
              : `${
                  props.original.prefixCountryNum
                    ? `(${props.original.prefixCountryNum})`
                    : ""
                } ${props.original.phone}`}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
        sortable: false,
        resizable: false,
      });

      columns.push({
        Header: (props) => (
          <div
            style={{
              display: "flex",
              margin: "10px 0",
              justifyContent: "space-between",
            }}
            onClick={() =>
              listBy !== "RECENTLY_DELETED" &&
              handleHeaderClick("createdAt,asc")
            }
          >
            <span>
              {listBy === "RECENTLY_DELETED" ? `Days Left` : `Sign-up Date`}
            </span>
            {listBy !== "RECENTLY_DELETED" && (
              <img
                src={
                  this.state.sortBy === "createdAt,asc"
                    ? Images.ascending
                    : this.state.sortBy === "createdAt,desc"
                    ? Images.descending
                    : Images.sortInactive
                }
                style={{ height: "25px", marginLeft: "7px", marginTop: "-5px" }}
              />
            )}
          </div>
        ),
        Cell: (props) => {
          const oneDay = 24 * 60 * 60 * 1000;
          const dateNow = new Date();
          const dateUpdate = new Date(props.original.updatedAt);
          const diffDays = Math.round(
            Math.abs((dateNow.getTime() - dateUpdate.getTime()) / oneDay)
          );
          let leftDay = 5 - diffDays;
          let day = leftDay > 1 ? "days" : "day";
          if (process.env.NODE_ENV == "development") {
            day = "minutes";
            leftDay = 2;
          }

          return (
            <span
              style={{
                color:
                  listBy === "RECENTLY_DELETED" ? Colors.color13 : Colors.color,
              }}
              className="column-text"
              onClick={() => onClickColumn(props)}
            >
              {listBy === "RECENTLY_DELETED"
                ? `${leftDay} ${day}`
                : moment(props.original.createdAt).format("DD MMMM YYYY")}
            </span>
          );
        },
        headerClassName: "table-header",
        className: "column",
        sortable: false,
        resizable: false,
      });

      if (
        this.state.listBy === "ALL_ACCOUNTS" &&
        (this.state.show === "CAREGIVER" || this.state.show === "DEMENTIA")
      ) {
        columns.push({
          Header: (props) => (
            <div
              style={{
                display: "flex",
                margin: "10px 0",
                justifyContent: "space-between",
              }}
              onClick={() => handleHeaderClick("approvalDate,asc")}
            >
              <span>Approval Date</span>
              {
                <img
                  src={
                    this.state.sortBy === "approvalDate,asc"
                      ? Images.ascending
                      : this.state.sortBy === "approvalDate,desc"
                      ? Images.descending
                      : Images.sortInactive
                  }
                  style={{
                    height: "25px",
                    marginLeft: "7px",
                    marginTop: "-5px",
                  }}
                />
              }
            </div>
          ),
          Cell: (props) => (
            <span
              style={{ color: Colors.color }}
              className="column-text"
              onClick={() => onClickColumn(props)}
            >
              {props.original && props.original.approvalDate
                ? moment(props.original.approvalDate).format("DD MMMM YYYY")
                : props.original.createdAt
                ? moment(props.original.createdAt).format("DD MMMM YYYY")
                : "-"}
            </span>
          ),
          headerClassName: "table-header",
          className: "column",
          sortable: false,
          resizable: false,
        });
      }
    }

    if (listBy !== "RECENTLY_DELETED" && this.state.show !== "MoP") {
      columns.push({
        Header: (props) => (
          <div style={{ margin: "10px 0" }}>
            <span>Action</span>
          </div>
        ),
        Cell: (props) => (
          <div style={{ display: "flex" }}>
            {this.state.listBy === "ALL_APPLICANTS" ? (
              <>
                {props.original.isArchived ? (
                  <>
                    <img
                      style={{ width: "25px", marginLeft: "3px" }}
                      src={Images.cancelInactive}
                    />
                    <img
                      style={{ width: "25px", marginLeft: "3px" }}
                      src={Images.checkInactive2}
                    />
                  </>
                ) : props.original.accountStatus === "REJECTED" ? (
                  <img
                    style={{ width: "25px", marginLeft: "3px" }}
                    src={Images.cancelInactive}
                  />
                ) : (
                  <img
                    style={{ width: "25px", marginLeft: "3px" }}
                    src={Images.cancelActive}
                    onClick={() =>
                      this.onBulkActions({
                        accountStatus: ACCOUNT_STATUS.REJECTED,
                        uid: props.original.uid,
                      })
                    }
                  />
                )}

                {!props.original.isArchived && (
                  <img
                    style={{ width: "25px", marginLeft: "3px" }}
                    src={Images.checkActive2}
                    onClick={() =>
                      this.onBulkActions({
                        accountStatus: ACCOUNT_STATUS.PENDING_CARDS,
                        uid: props.original.uid,
                      })
                    }
                  />
                )}
              </>
            ) : this.state.listBy === "ALL_ACCOUNTS" ? (
              <>
                {props.original.isArchived ? (
                  <>
                    <img
                      style={{ width: "25px", marginLeft: "3px" }}
                      src={Images.cancelInactive}
                    />
                    <img
                      style={{ width: "25px", marginLeft: "3px" }}
                      src={Images.checkInactive2}
                    />
                  </>
                ) : (
                  <>
                    <img
                      style={{ width: "25px", marginLeft: "3px" }}
                      src={Images.cancelInactive}
                    />
                    {props.original.accountStatus === "COMPLETED" ? (
                      <img
                        style={{ width: "25px", marginLeft: "3px" }}
                        src={Images.checkInactive2}
                      />
                    ) : (
                      <img
                        style={{ width: "25px", marginLeft: "3px" }}
                        src={Images.checkActive2}
                        onClick={() =>
                          this.onBulkActions({
                            accountStatus: ACCOUNT_STATUS.COMPLETED,
                            uid: props.original.uid,
                          })
                        }
                      />
                    )}
                  </>
                )}
              </>
            ) : null}
            {/* <img style={{width: '25px', marginLeft: '3px'}} src={Images.cancelActive}/>
            <img style={{width: '25px', marginLeft: '3px'}} src={Images.checkActive2}/> */}
          </div>
        ),
        headerClassName: "table-header",
        className: "column",
        width: 70,
        sortable: false,
        resizable: false,
      });
    }

    const params = filterBy.map((item) => ({ [item]: searchText }));

    return (
      <StyledUserManagement>
        <Dialog
          isVisible={dialogConfirmation}
          isClose={() => this.setState({ dialogConfirmation: false })}
          title="Delete Account"
          showXClose={false}
          additionalStyle={{ titleStyle: ` color: ${Colors.color13}; ` }}
          description={`Are you sure you want to delete this account?`}
          width={`37%`}
          height={`auto`}
          cancelText={`Decline`}
          okText={loadingDelete ? `Loading...` : `Delete`}
          okBorderColor={Colors.color13}
          okTextColor={Colors.color13}
          onOk={() => this.deleteUser(userId)}
        />

        {/* delete with reason */}
        <Dialog
          isVisible={dialogConfirmation2}
          isClose={() => this.setState({ dialogConfirmation2: false })}
          title="Are you sure you want to delete this PLWD?"
          showXClose={false}
          additionalStyle={{ titleStyle: ` color: ${Colors.color19}; ` }}
          // description={`Are you sure you want to delete this PLWD?`}
          width={`37%`}
          height={`auto`}
          cancelText={`No`}
          okText={loadingDelete ? `Loading...` : `Yes`}
          okBorderColor={Colors.color41}
          okBackgroundColor={Colors.color41}
          okTextColor={Colors.color3}
          cancelBorderedColor={Colors.color41}
          cancelBackgroundColor={Colors.color41}
          cancelTextColor={Colors.color41}
          onOk={() => this.deleteUser2(userId)}
          isDeletedReason={true}
          deleteReason={deleteReason}
          setDeleteReason={(e) =>
            this.setState({ deleteReason: e.target.value })
          }
          yesOnLeft={true}
        />

        {/* Dialog for export by uid - start */}
        <Dialog
          isVisible={dialogExport}
          isClose={() => this.setState({ dialogExport: false })}
          title="Export Account"
          additionalStyle={{ titleStyle: ` color: ${Colors.color17}; ` }}
          description={`Select export type.`}
          width={`37%`}
          height={`auto`}
          cancelText={
            loadingExportInformation
              ? `Exporting...`
              : `Export card information`
          }
          okText={
            loadingExportSalesforce ? `Exporting...` : `Export salesforce`
          }
          okBorderColor={Colors.color17}
          okTextColor={Colors.color17}
          onOk={() => {
            const { firstname, lastname, uid } = exportData;
            this.setState({ loadingExportSalesforce: true });
            this.onExportUser({ firstname, lastname, uid });
          }}
          onCancel={() => {
            this.setState({ loadingExportInformation: true });
            const { firstname, lastname, uid } = exportData;
            this.onExportUser({
              firstname,
              lastname,
              uid,
              exportType: EXPORT_TYPE.INFO,
            });
            {
              /* this.props.history.push('/user-export', {
              uid: exportData.uid
            }) */
            }
          }}
        />
        {/* Dialog for export by uid - end */}

        {/* Dialog for export by uid list - start */}
        <Dialog
          isVisible={dialogExportSelected}
          isClose={() => this.setState({ dialogExportSelected: false })}
          title="Export Account"
          additionalStyle={{ titleStyle: ` color: ${Colors.color17}; ` }}
          description={`Select export type for this selected account.`}
          width={`37%`}
          height={`auto`}
          cancelText={
            loadingExportInformation
              ? `Exporting...`
              : `Export card information`
          }
          okText={
            loadingExportSalesforce ? `Exporting...` : `Export salesforce`
          }
          okBorderColor={Colors.color17}
          okTextColor={Colors.color17}
          onOk={() => {
            this.setState({ loadingExportSalesforce: true });
            this.onExportUserSelected(EXPORT_TYPE.SALESFORCE);
          }}
          onCancel={() => {
            {
              /* const { selectedData } = this.state
            let uidList = []
            selectedData.map(data => uidList.push(data.uid))
            this.props.history.push('/user-export', {
              uidList: uidList.join(','),
            }) */
            }
            this.setState({ loadingExportInformation: true });
            this.onExportUserSelected(EXPORT_TYPE.INFO);
          }}
        />
        {/* Dialog for export by uid list - end */}

        {/* Dialog for export by last updated - start */}
        <Dialog
          isVisible={dialogExportLastUpdated}
          isClose={() => this.setState({ dialogExportLastUpdated: false })}
          title="Export Account"
          additionalStyle={{ titleStyle: ` color: ${Colors.color17}; ` }}
          width={`47%`}
          cancelText={
            loadingExportInformation
              ? `Exporting...`
              : `Export card information`
          }
          okText={
            loadingExportSalesforce ? `Exporting...` : `Export salesforce`
          }
          okBorderColor={Colors.color17}
          okTextColor={Colors.color17}
          onOk={() => {
            this.onExportSalesforceByLastUpdated(
              EXPORT_TYPE.SALESFORCE,
              "loadingExportSalesforce"
            );
          }}
          onCancel={() => {
            {
              /* const { startDate, endDate, } = this.state
            this.props.history.push('/user-export', {
              startDate,
              endDate,
            }) */
            }
            this.onExportSalesforceByLastUpdated(
              EXPORT_TYPE.INFO,
              "loadingExportInformation"
            );
          }}
        >
          {
            <>
              {this.renderFilterExport()}
              <Button
                text={loadingExportAll ? "Loading..." : "Export"}
                bordered
                textColor={Colors.color11}
                buttonStyle={` margin-left: 18px; margin-right: 4px; `}
                onClick={() =>
                  this.onExportSalesforceByLastUpdated(
                    EXPORT_TYPE.ALL_FIELD,
                    "loadingExportAll"
                  )
                }
              />
            </>
          }
        </Dialog>
        {/* Dialog for export by last updated - end */}

        {/* Dialog Bulk Actions - start */}
        <DialogBulkActions
          dialogProps={{
            isVisible: dialogBulkActions,
            title: bulkActionsTitle,
            isClose: () => this.setState({ dialogBulkActions: false }),
          }}
          onClickButton={(data) => this.onBulkActions.bind(this)(data)}
          type={
            this.props.account.payload &&
            this.props.account.payload.data.account.type
          }
          isRecentlyDeleted={
            this.state.listBy === "RECENTLY_DELETED" ? true : false
          }
        />
        {/* Dialog Bulk Actions - end */}

        {/* Dialog Filter User - start */}
        <DialogFilterUser
          dialogProps={{
            isVisible: dialogFilterUser,
            title: filterUserTitle,
            isClose: () => this.setState({ dialogFilterUser: false }),
          }}
          onSelectCheckbox={(filterBy) => this.setState({ filterBy })}
          settedFilterBy={filterBy}
          onApply={(filterBy, { typeDementia, gender, language }) =>
            this.setState({
              filterBy,
              additionalFilter: { typeDementia, gender, language },
            })
          }
          additionalFilter={additionalFilter}
        />
        {/* Dialog Filter User - end */}
        {/* Dialog info start */}
        <DialogInfo
          isVisible={infoDialogShow}
          textInfo={infoDescription}
          onOkButton={() => this.setState({ infoDialogShow: false })}
        />
        {/* Dialog info end  */}

        {account.type === USER_TYPE.PARTNER && (
          <div
            style={{
              display: "flex",
              justifyContent: "flex-end",
              marginBottom: `20px`,
            }}
          >
            <Button
              text={account && `${account.organizationName}`}
              backgroundColor={Colors.color40}
              textColor={Colors.color11}
              buttonStyle={`
                  padding-right: 20px;
                  justify-content: flex-start;
                  width: auto;
                `}
              iconLeftImage={Images.iconAccount}
              iconLeftStyle={`
                  width: 18px;
                  height: 18px;
                  margin: 0 8px 0 14px;
                `}
              iconRight
              iconRightStyle={`
                  width: 12px; 
                  height: 8px;
                  margin-left: 22px;
                `}
              showDropdown={profileDropdown}
              dropdownData={dropdownProfileData}
              dropdownHeight={`60px`}
              onSelectSort={(data) => {
                if (data.name === "Profile") {
                  this.props.history.push(`/partner/${account.uid}`, {
                    action: "DETAIL",
                  });
                } else {
                  this.props.signout();
                  this.props.history.replace("/");
                }
              }}
              onClick={() =>
                this.setState({ profileDropdown: !profileDropdown })
              }
            />
          </div>
        )}
        <div className="header-container">
          <div>
            <h1 className="title">
              {isUserManagement ? `Membership` : "Relationship"} Management
            </h1>
          </div>
          <UserTopButton
            {...this.props}
            listBy={listBy}
            onClickAllApplicants={() => {
              localStorage.setItem("listUserBy", "ALL_APPLICANTS");
              this.setState({
                listBy: "ALL_APPLICANTS",
                additionalFilter: {
                  ...this.state.additionalFilter,
                  accountStatus: "",
                },
              });
            }}
            onClickAllAccounts={() => {
              localStorage.setItem("listUserBy", "ALL_ACCOUNTS");
              this.setState({
                listBy: "ALL_ACCOUNTS",
                additionalFilter: {
                  ...this.state.additionalFilter,
                  accountStatus: "",
                },
              });
            }}
            onClickRecentlyDeleted={() => {
              localStorage.setItem("listUserBy", "RECENTLY_DELETED");
              this.setState({
                listBy: "RECENTLY_DELETED",
                additionalFilter: {
                  ...this.state.additionalFilter,
                  accountStatus: "",
                },
              });
            }}
          />
        </div>
        <CheckboxGroup onChange={this.onCheckboxChange}>
          <Table
            showTableHeader
            queryType={
              querystring.parse(this.props.location.search)["?type"] === "mop"
                ? "MoP"
                : querystring.parse(this.props.location.search)["?type"]
            }
            columns={
              isUserManagement
                ? columns
                : columnsRelationship({
                    dementiaList,
                    onClickColumn: (data) => onClickColumnByPartner(data),
                  })
            }
            data={userData}
            dataLimit={AppConfig.DEFAULT_LIMIT_USER}
            pageSize={
              this.state.searchMax === "All Data"
                ? parseInt(userData.length)
                : parseInt(this.state.searchMax)
            }
            // show={show}
            show={
              querystring.parse(this.props.location.search)["?type"] === "mop"
                ? "MoP"
                : querystring.parse(this.props.location.search)["?type"]
            }
            onSendFilter={(filterBy, { typeDementia, gender, language }) =>
              this.setState({
                filterBy,
                additionalFilter: { typeDementia, gender, language },
              })
            }
            responsive
            setShow={(show) => {
              if (show === USER_TYPE.MOP) {
                this.setState({
                  caregiverType: "MOP",
                });
              } else if (show === USER_TYPE.CARE) {
                this.setState({
                  caregiverType: "CARE_PROFESSIONAL",
                });
              }
              // this.setState({ show })
              this.setState((currentState) => {
                // if(currentState.show === show) window.alert(`${currentState.show} berubah jadi ${show}`)

                return { show };
              });
              this.props.history.push(
                "/users?type=" + String(show).toLowerCase()
              );
            }}
            loading={loading || loadingBulkActions || loadingDelete}
            onSortBy={(data) => {
              console.log("Data SortBy:", data); // Log data yang diterima
              this.setState({ sortBy: data.sortBy });
              console.log("Updated sortBy in state:", this.state.sortBy);
            }}
            onlyCreatedByPartner={this.state.onlyCreatedByPartner}
            onOnlyCreatedByPartner={(data) => {
              this.setState({ onlyCreatedByPartner: data });
            }}
            selectedData={selectedData}
            onExportSelectedData={() =>
              this.setState({ dialogExportSelected: true })
            }
            onExportByLastUpdated={() =>
              this.setState({ dialogExportLastUpdated: true })
            }
            onClickBulkActions={() =>
              this.setState({ dialogBulkActions: true })
            }
            onClickRow={(row) =>
              this.props.history.push({
                pathname: `/user/${row.original.uid}`,
                state: {
                  action: "DETAIL",
                },
              })
            }
            onClickFilterUser={() => this.setState({ dialogFilterUser: true })}
            onSearch={this.onSearch.bind(this)}
            onChangeSearchText={(searchText) => this.setState({ searchText })}
            tableProps={{ showPageSizeOptions: false, showPagination: false }}
            onCheckFilter={this.onCheckFilter.bind(this)}
            onSearchKey={this.onSearchKey.bind(this)}
            onSetAdditionalFilter={this.onSetAdditionalFilter.bind(this)}
            isDeleted={this.state.listBy === "RECENTLY_DELETED" ? true : false}
            listBy={this.state.listBy}
            dateFilter={this.state.dateFilter}
            onChangeStartDate={(data) =>
              this.setState({
                dateFilter: { ...this.state.dateFilter, startDate: data },
              })
            }
            onChangeEndDate={(data) =>
              this.setState({
                dateFilter: { ...this.state.dateFilter, endDate: data },
              })
            }
          />

          <Pagination
            page={this.page}
            onClickPrevious={() =>
              this.handlePagination(parseInt(this.page) - 1)
            }
            onClickNext={() => this.handlePagination(parseInt(this.page) + 1)}
            onClickPage={(number) => this.handlePagination(number)}
            totalPage={totalPages}
            showLimit
            selectedLimit={this.state.searchMax}
            showTotalData
            totalData={totalData}
            onSelectLimit={(data) => {
              {
                /* localStorage.setItem('searchMaxUser', "ALL_ACCOUNTS") */
              }
              this.setState({ pageSize: data.value });
              // this.getUsers({ type: this.state.show, showBy: this.state.listBy, page: 1, sortBy: this.state.sortBy, limit: data.value, })

              if (data.name === "All Data") {
                this.setState({ searchMax: data.name, totalPages: ["1"] });
              } else {
                this.setState({ searchMax: data.name });
                // this.getTotalUsers({ type: this.state.show, showBy: this.state.listBy, metadata: this.state.show === USER_TYPE.MOP ? true : (this.state.show === USER_TYPE.CAREGIVER && !isPartner) ? false : null, careProff: this.state.show === USER_TYPE.CARE ? true : null,})
              }
            }}
          />
        </CheckboxGroup>
      </StyledUserManagement>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    users: state.user.users,
    account: state.auth.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (params) => dispatch(UserActions.getUsersRequest(params)),
    initToken: () => dispatch(StartupActions.initToken()),
    getPages: (params) => dispatch(UserActions.getUsersRequest(params)),
    signout: () => dispatch(AuthActions.signout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(UserManagement);
