import React, { Component } from 'react'
import { connect } from 'react-redux'
import memoize from 'memoize-one'

import FormEditCategory from '../components/form/FormEditCategory'

import { Images, Colors } from '../themes/'
import { StyledEditCategory } from './styles/StyledEditCategory'
import DialogInfo from '../components/dialog-info'
import DialogPopUp from "../components/modal"

import BenefitsActions from '../redux/BenefitsRedux'

export class EditBenefitCategory extends Component {
  constructor(props) {
    super(props)

    const { benefitCategories, match } = props
    let benefitCategory =  benefitCategories &&  benefitCategories.payload && benefitCategories.payload.payload.data.filter(data => data.id === parseInt(match.params.id))[0]

    this.state = {
      id: benefitCategory.id,
      fixedTitle: benefitCategory.title, 
      title: benefitCategory.title,
      titleChinese: benefitCategory.titleChinese,
      image: benefitCategory.imageUrl,
      imageChinese: benefitCategory.imageUrlChinese,
      newImage: null,
      newImageChinese: null,
      infoDialogShow: false,
      infoDescription: '',
      dialogPopUp: {
        show: false,
        title: 'Confirm Category',
        content: 'Are you sure want to create this category?',
        okText: 'Yes, Confirm',
        cancelText: 'Cancel',
        state: 'category'
      }
    }

    this.update = false
  }

  onChangeText = (name, value) => {
    this.setState({ [name]: value })
  }

  onChangeImage = e => {
    const { imageIndex, } = this.state
    const reader = new FileReader()
    const file = e.target.files[0]

    reader.onloadend = () => {
      this.setState({ image: reader.result, newImage: reader.result, })
    } 

    reader.readAsDataURL(file);
  };

  onChangeImageChinese = e => {
    const { imageIndex, } = this.state
    const reader = new FileReader()
    const file = e.target.files[0]

    reader.onloadend = () => {
      this.setState({ imageChinese: reader.result, newImageChinese: reader.result, })
    } 

    reader.readAsDataURL(file);
  };

  onValidationCategory = () => {
    const { title, titleChinese, newImage, newImageChinese } = this.state

    if(!title || !titleChinese) {
      return this.setState({
        dialogPopUp: {
          ...this.state.dialogPopUp,
          show: true,
          title: 'Confirm Category',
          content: 'Are you sure want to create this category?',
          state: 'category'
        }
      })
    } else {
      return this.onEdit()
    }
  }

  onEdit = () => {
    const { id, title, titleChinese, newImage, newImageChinese, dialogPopUp } = this.state

    this.setState({ loading: true, dialogPopUp: {...dialogPopUp, show: false}  })
    console.log('parameter => ', id, title, newImage)
    this.props.updateCategory({
      id,
      data: {
        "title": title,
        "titleChinese" : titleChinese,
        "description": "",
        "order": 1,
        "base64Image": newImage ? newImage : undefined,
        "base64ImageChinese": newImageChinese ? newImageChinese : undefined
        
      }
    })

    this.update = true
  }

  updateResult = memoize(data => {
    const { fetching, payload, error, } = data

    if (!fetching && this.update) {
      if (payload) {
        if (payload.type === 'SUCCESS') {
          this.setState({
            loading: false,
            errorText: '',
          })
          // this.onDialogInfo('Data Updated')
          this.props.getCategories({})
          this.props.history.goBack()
        } else {
          this.onDialogInfo('ERROR')
          this.setState({
            errorText: payload.error ? payload.error : payload.message ? payload.message : 'ERROR',
            loading: false,
          })
        }
      }

      if (error) {
        this.onDialogInfo(error.message)
        this.setState({
          errorText: 'Something wrong, please check your internet connection',
          loading: false,
        })
      }
      
      this.update = false
    }
  })
  
  render() {
    const {infoDialogShow, infoDescription, dialogPopUp} = this.state
    this.updateResult(this.props.updateCategoryResult)
    return (
      <StyledEditCategory>
        {/* Dialog info start */}
        <DialogInfo
          isVisible={infoDialogShow}
          textInfo={infoDescription}
          onOkButton={()=> this.setState({infoDialogShow: false})}
        />
        {/* Dialog info end  */}
        <DialogPopUp
          isVisible={dialogPopUp.show}
          okText={dialogPopUp.okText}
          cancelText={dialogPopUp.cancelText}
          onOk={this.onEdit}
          onCancel={()=>this.setState({dialogPopUp: {...dialogPopUp, show: false}})}
          isClose={()=>this.setState({dialogPopUp: {...dialogPopUp, show: false}})}
          height={'250px'}
          okBackgroundColor={Colors.color42}
          okTextColor={'white'}
        >
          <p style={{ color: Colors.color19, fontSize: '26px', fontWeight: '800' }}>{dialogPopUp.title}</p>
          <p style={{ color: Colors.color2, fontSize: '17px', fontWeight: '700', marginTop: '30px' }}>Any empty information will only appear in English.</p>
          <p style={{ color: Colors.color2, fontSize: '17px', fontWeight: '700', marginTop: '10px' }}>{dialogPopUp.content}</p>
        </DialogPopUp>
        <FormEditCategory
          {...this.props}
          {...this.state}
          onChangeImage={this.onChangeImage}
          onChangeImageChinese={this.onChangeImageChinese}
          onChangeText={this.onChangeText}
          stateTitle={'title'}
          stateTitleChinese={'titleChinese'}
          // onSave={this.onEdit}
          onSave={this.onValidationCategory}
        />
      </StyledEditCategory>
    )
  }
}

const mapStateToProps = (state) => ({
  benefitCategories: state.benefits.benefitCategories,
  updateCategoryResult: state.benefits.updateCategory
})

const mapDispatchToProps = (dispatch) => {
  return {
    getCategories: (params) => dispatch(BenefitsActions.getCategoriesRequest(params)),
    updateCategory: (params) => dispatch(BenefitsActions.updateCategoryRequest(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EditBenefitCategory)
