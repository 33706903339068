import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  signout: ['payload'],

  signinRequest: ['data'],
  signinSuccess: ['payload'],
  signinFailure: ['error'],

  signupRequest: ['data'],
  signupSuccess: ['payload'],
  signupFailure: ['error'],

  verifyTokenRequest: ['data'],
  verifyTokenSuccess: ['payload'],
  verifyTokenFailure: ['error'], 

  updateAccount: ['payload'],

  getQRCodeRequest: ['data'],
  getQRCodeSuccess: ['payload'],
  getQRCodeFailure: ['error'],

  verifyQRRequest: ['data'],
  verifyQRSuccess: ['payload'],
  verifyQRFailure: ['error'],

  checkAdminAccountRequest: ['data'],
  checkAdminAccountSuccess: ['payload'],
  checkAdminAccountFailure: ['error'],
})

export const AuthTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  account: { fetching: null, error: null, payload: null, data: null },
  qrCode: { fetching: null, error: null, payload: null, data: null },
})

/* ------------- Selectors ------------- */

export const AuthSelectors = {
  account: state => state.auth.account,
  qrCode: state => state.auth.qrCode,
}

/* ------------- Reducers ------------- */

export const signout = (state, props) => {
  return state.merge({ ...state, account: { fetching: null, error: null, payload: null, data: null } })
}

export const signinRequest = (state, { data }) => {
  return state.merge({ ...state, account: { ...state.account, fetching: true, data, }})
}

export const signinSuccess = (state, { payload }) => {
  return state.merge({ ...state, account: {  ...state.account, fetching: false, error: false, payload, }})
}

export const signinFailure = (state, { error }) => {
  return state.merge({ ...state, account: { ...state.account, fetching: false, error, }})
}

export const signupRequest = (state, { data }) => {
  return state.merge({ ...state, account: { ...state.account, fetching: true, data, }})
}

export const signupSuccess = (state, { payload }) => {
  return state.merge({ ...state, account: {  ...state.account, fetching: false, error: false, payload, }})
}

export const signupFailure = (state, { error }) => {
  return state.merge({ ...state, account: { ...state.account, fetching: false, error, }})
}

export const verifyTokenRequest = (state, { data }) => {
  return state.merge({ ...state, account: { ...state.account, fetching: true, data, }})
}

export const verifyTokenSuccess = (state, { payload }) => {
  return state.merge({ ...state, account: {  ...state.account, fetching: false, error: false, payload, }})
}

export const verifyTokenFailure = (state, { error }) => {
  return state.merge({ ...state, account: { ...state.account, fetching: false, error, }})
}

export const updateAccount = (state, { payload }) => {
  let updatedPayload = {
    ...state.account.payload.data.account
  }

   updatedPayload = {
    payload: {
      ...state.account.payload,
      data: {
        account: {
          ...state.account.payload.data.account,
          ...payload.data
        } 
      }
    }
  }
  
  return state.merge({ ...state, account: { ...state.account, fetching: false, error: false, ...updatedPayload, } }) 
}

export const getQRCodeRequest = (state, {data}) => {
  return state.merge({ ...state, account: { ...state.account, fetching: true, data, }})
}

export const getQRCodeSuccess = (state, {payload}) => {
  return state.merge({ ...state, account: { ...state.account, fetching: false, error: null, payload, }})
}

export const getQRCodeFailure = (state, {error}) => {
  return state.merge({ ...state, account: { ...state.account, fetching: false, error, }})
}

export const verifyQRRequest = (state, {data}) => {
  return state.merge({ ...state, account: { ...state.account, fetching: true, data,}})
}

export const verifyQRSuccess = (state, {payload}) => {
  return state.merge({ ...state, account: {...state.account, fetching: false, error: null, payload}})
}

export const verifyQRFailure = (state, {error}) => {
  return state.merge({ ...state, account: { ...state.account, fetching: false, error}})
}

export const checkAdminAccountRequest = (state, {data}) => {
  return state.merge({ ...state, account: { ...state.account, fetching: true, data, }})
}

export const checkAdminAccountSuccess = (state, {payload}) => {
  return state.merge({ ...state, account: { ...state.account, fetching: false, error: null, payload, }})
}

export const checkAdminAccountFailure = (state, {error}) => {
  return state.merge({ ...state, account: { ...state.account, fetching: false, error, }})
}
/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.SIGNOUT]: signout,

  [Types.SIGNIN_REQUEST]: signinRequest,
  [Types.SIGNIN_SUCCESS]: signinSuccess,
  [Types.SIGNIN_FAILURE]: signinFailure,

  [Types.SIGNUP_REQUEST]: signupRequest,
  [Types.SIGNUP_SUCCESS]: signupSuccess,
  [Types.SIGNUP_FAILURE]: signupFailure,

  [Types.VERIFY_TOKEN_REQUEST]: verifyTokenRequest,
  [Types.VERIFY_TOKEN_SUCCESS]: verifyTokenSuccess,
  [Types.VERIFY_TOKEN_FAILURE]: verifyTokenFailure,

  [Types.UPDATE_ACCOUNT]: updateAccount,

  [Types.GET_QR_CODE_REQUEST]: getQRCodeRequest,
  [Types.GET_QR_CODE_SUCCESS]: getQRCodeSuccess,
  [Types.GET_QR_CODE_FAILURE]: getQRCodeFailure,

  [Types.VERIFY_QR_REQUEST]: verifyQRRequest,
  [Types.VERIFY_QR_SUCCESS]: verifyQRSuccess,
  [Types.VERIFY_QR_FAILURE]: verifyQRFailure,

  [Types.CHECK_ADMIN_ACCOUNT_REQUEST]: checkAdminAccountRequest,
  [Types.CHECK_ADMIN_ACCOUNT_SUCCESS]: checkAdminAccountSuccess,
  [Types.CHECK_ADMIN_ACCOUNT_FAILURE]: checkAdminAccountFailure,
})
