import { API } from '../lib/Request'
// import AppConfig from '../config/AppConfig'
import AppConfig from '../config.js'

const accountUrl = AppConfig.ACCOUNT_URL

export const createAdmin = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/signup`, method: 'POST', data })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getDailySignUp = async (data) => {
  return new Promise((resolve, reject) => {
    API({url: `${accountUrl}/admin/dashboard`, method: 'GET', data})
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
};

export const getAllUsers = async (data) => {
  return new Promise((resolve, reject) => {
    API({url: `${accountUrl}/admin/users-admin-panel?${Object.keys(data).map((item, index) => `${item}=${Object.values(data)[index]}`).join('&')}`, method: 'GET'})
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
};

export const signOut = async (data) => {
  return new Promise((resolve, reject) => {
    API({url: `${accountUrl}/admin/signout`, method: 'POST', data})
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
};

export const getAllReport = async (data) => {
  return new Promise((resolve, reject) => {
    API({url: `${accountUrl}/admin/getReportMissing?${Object.keys(data).map((item, index) => `${item}=${Object.values(data)[index]}`).join('&')}`, method: 'GET'})
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
};

export const getCountReport = async (data) => {
  return new Promise((resolve, reject) => {
    API({url: `${accountUrl}/admin/getCountReport`, method: 'GET'})
      .then(res => resolve(res) )
      .catch(err => reject(err))
  })
}

export const downloadPdf = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/generatePdf?id=${data.id}`, method: 'GET', data, responseType: 'blob' })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const EditReport = async (data) => {
  return new Promise((resolve, reject) => {
    API({url: `${accountUrl}/admin/editReportMissing`, method: 'PATCH', data})
      .then(res => resolve(res))
      .catch(err => reject(err))
  })
};

export const verifyToken = async (data) => {
  return new Promise((resolve, reject) => {
    API({url: `${accountUrl}/public/credential/verifyToken`, method: 'POST', data,
    headers: {
      'content-type': 'application/json',
      'Authorization': `${data.token}`
    },
    })
      .then(res => {
        if (res.type === "ERROR") localStorage.clear()
        resolve(res)
      })
      .catch(err => {
        reject(err)
      })
  })
};
export const downloadReport = async (data) => {
  return new Promise((resolve, reject) => {
    // API({ url: `${accountUrl}/admin/report?exportType=${data.exportType}&startDate=${data.startDateReport}&endDate=${data.endDateReport}`, method: 'GET', responseType: 'blob' })
    API({ url: `${accountUrl}/admin/report?exportType=${data.exportType}&startDate=${data.startDateReport}&endDate=${data.endDateReport}`, method: 'GET', responseType: 'blob' })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}
