import storage from 'redux-persist/lib/storage'
import immutablePersistenceTransform from '../services/ImmutablePersistenceTransform'

const REDUX_PERSIST = {
  active: true,
  reducerVersion: '1.0',
  storeConfig: {
    key: 'root',
    storage,
    whitelist: ['auth', 'benefits', 'solutions', 'safeReturn'],
    transforms: [immutablePersistenceTransform],
  }
}

export default REDUX_PERSIST
