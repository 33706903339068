import { API } from '../lib/Request'
// import AppConfig from '../config/AppConfig'
import AppConfig from '../config.js'

const benefitsUrl = AppConfig.BENEFITS_URL

export const getTotalBenefits = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${benefitsUrl}/benefits?limit=${data.limit}&title=${data.title}`, method: 'GET', data })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getTotalBenefitCategories = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${benefitsUrl}/benefit-category?limit=${data.limit}&title=${data.title}`, method: 'GET', data })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getAllBenefitCategories = async () => {
  return new Promise((resolve, reject) => {
    API({ url: `${benefitsUrl}/benefit-category?limit=10000&sortBy=title,ASC`, method: 'GET' })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}