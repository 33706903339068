import styled from "styled-components";
import { Col, } from 'react-grid-system';
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledGrid= styled(Col)`
  margin-bottom: 20px;
  
  .card {
    box-shadow: ${props => props.transparent ? 'transparent' : '0 4px 8px 0 rgba(0, 0, 0, 0.2)'};
    text-align: center;
    background-color: ${props => props.transparent ? 'transparent' : '#ffffff'};
    border-radius: 15px;
    overflow: hidden;
    width: 90%;
    height: 160px;
    margin: 5px 0px 10px 15px;
  }

  .card2 {
    box-shadow: ${props => props.transparent ? 'transparent' : '0 4px 8px 0 rgba(0, 0, 0, 0.2)'};
    text-align: left;
    background-color: ${props => props.transparent ? 'transparent' : '#ffffff'};
    border-radius: 15px;
    overflow: hidden;
    width: 90%;
    height: 90px;
    margin: 5px 10px 20px 15px;
    padding: 10px 10px 10px 10px;
  }

  .image {
    width: 100%;
    height: 160px;
    margin-bottom: 24px;
    background-color: ${Colors.color3};
    object-fit: contain;
    ${props => props.imageStyle}
  }
  .title {
    font-family: NunitoSans;
    ${Fonts.size.size24}
    font-weight: 800;
    color: ${Colors.color2};
    padding-bottom: 5px;
    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 250px;
    word-break: break-word;
    line-height: 30px;
    ${props => props.titleStyle}
  }
  .description {
    font-family: NunitoSans;
    ${Fonts.size.size16}
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 250px;
  }
  .action {
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
  .detail-action {
    display: flex;
  }
  .edit {
    width: 11px;
    height: 11px;
  }
  .delete {
    width: 11px;
    height: 11px;
  }
  .bottom-wrapper {
    margin: 0 13px 13px 31px;
  }
  .trash-wrapper {
    background-color: ${Colors.color13};
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .edit-wrapper {
    background-color: ${Colors.color14};
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    cursor: pointer;
  }
  .position {
    position: absolute;
    right: -47px;
  }
  .number-order {
    width: 60px;
    height: 25px;
    border-radius: 10px;
    background-color: #F5F5F5;
  }
  .text-order {
    font-family: NunitoSans;
    ${Fonts.size.size24}
    font-weight: 800;
    color: ${Colors.color2};
    overflow: hidden;
    ${props => props.titleStyle}
    text-align: center;
    line-height: 25px;
  }
  .text-drag {
    font-family: NunitoSans;
    ${Fonts.size.size14}
    color: ${Colors.color13};
    margin-bottom: 10px;
  }
  ${props => props.additionalStyle}
`