import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/'

export const StyledContentManagement = styled.div`
  min-height: 100vh;
  background-color: ${Colors.primaryBgColor};
  padding: 62px 40px 0;

  .title-container {
    display: flex;
    justify-content: space-between;
    padding-bottom: 39px;
  }
`;
