import React, { useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import * as XLSX from "xlsx";
import _ from "lodash";

import {
  buttonImport,
  buttonCreateNew,
  buttonAllAccounts,
  buttonAllAplicants,
} from "../../containers/styles/StyledUserManagement";

import { Colors } from "../../themes";

import Button from "../button";
import Dialog from "../modal";
import DialogSelectAccount from "../dialog-select-account";
import DialogUserList from "../dialog-user-list";
import { alertError } from "../alert";

import { USER_TYPE } from "../../constants";

import { StyledUserTopButton } from "./StyledUserTopButton";

// import AppConfig from '../../config/AppConfig';
import AppConfig from "../../config.js";

import ProccessData from "../../lib/ProccessData";

import { bulkCreateAccount } from "../../api/UserAPI";

import CaregiverField from "../../document/Import Care Professional Template.xlsx";
import PLWDField from "../../document/Import PLWD Template-2.xlsx";

function UserTopButton({
  listBy,
  onClickAllAccounts,
  onClickAllApplicants,
  onClickRecentlyDeleted,
  account,
  history,
}) {
  const [dialogImport, setDialogImport] = useState(false);
  const [dialogAccount, setDialogAccount] = useState(false);
  const [dialogDownload, setDialogDownload] = useState(false);
  const [createAccountType, setCreateAccountType] = useState(null);
  const [loadingImport, setLoadingImport] = useState(false);

  const [dialogUser, setDialogUser] = useState(false);

  const [columns, setColumns] = useState([]);

  const [data, setData] = useState([]);
  const [duplicatedData, setDuplicatedData] = useState([]);
  const [incompleteData, setIncompleteData] = useState([]);

  let inputUpload = null;

  // process CSV data
  const processData = (dataString) => {
    const dataStringLines = dataString.split(/\r\n|\n/);
    const headers = dataStringLines[0].split(
      /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
    );

    const list = [];
    for (let i = 1; i < dataStringLines.length; i++) {
      const row = dataStringLines[i].split(
        /,(?![^"]*"(?:(?:[^"]*"){2})*[^"]*$)/
      );
      if (headers && row.length == headers.length) {
        const obj = {};
        for (let j = 0; j < headers.length; j++) {
          let d = row[j];
          if (d.length > 0) {
            if (d[0] == '"') d = d.substring(1, d.length - 1);
            if (d[d.length - 1] == '"') d = d.substring(d.length - 2, 1);
          }
          if (headers[j]) {
            obj[headers[j]] = d;
          }
        }

        // remove the blank rows
        if (Object.values(obj).filter((x) => x).length > 0) {
          obj.id = i;
          list.push(obj);
        }
      }
    }

    // prepare columns list from headers
    const columns = headers.map((c) => c);

    if (list.length > 50) {
      setDialogImport(false);
      alertError({
        text: "Data cannot higher than 50 row",
        cb: () => setDialogImport(true),
      });
      return false;
    }

    // check wheteher all type CAREGIVER || DEMENTIA must same have value in 1 excel file
    const typeArr = list.map((item) => item.type.trim());
    const checkType = (list) => list.every((item) => list.indexOf(item) === 0);
    if (!checkType(typeArr)) {
      setDialogImport(false);
      alertError({
        text: "Please match type account in one file",
        cb: () => setDialogImport(true),
      });
      return false;
    }

    setData(list);
    setColumns(columns);

    if (list.length > 0) {
      checkIncomplete({ data: list });
    }

    setDialogUser(true);
  };

  const checkIncomplete = ({ data }) => {
    // data is current data that is in state
    const currentData = data;
    const incomplete = [];

    data.map((item, index) => {
      if (item.username.trim().length < 1) {
        incomplete.push(item);
        currentData.splice(index, 1);
      } else if (item.password.trim().length < 1) {
        incomplete.push(item);
        currentData.splice(index, 1);
      } else if (item.firstname.trim().length < 1) {
        incomplete.push(item);
        currentData.splice(index, 1);
      } else if (item.type.trim().length < 1) {
        incomplete.push(item);
        currentData.splice(index, 1);
      } else if (item.lastNric.trim().length < 1) {
        incomplete.push(item);
        currentData.splice(index, 1);
      } else if (item.gender.trim().length < 1) {
        incomplete.push(item);
        currentData.splice(index, 1);
      } else if (item.birth.trim().length < 1) {
        incomplete.push(item);
        currentData.splice(index, 1);
      } else if (item.streetName.trim().length < 1) {
        incomplete.push(item);
        currentData.splice(index, 1);
      } else if (item.postalCode.trim().length < 1) {
        incomplete.push(item);
        currentData.splice(index, 1);
      } else if (item.cityCountry.trim().length < 1) {
        incomplete.push(item);
        currentData.splice(index, 1);
      }

      // checking for caegiver only
      else if (
        item.type === USER_TYPE.CAREGIVER &&
        item.prefixCountryNum.trim().length < 1
      ) {
        incomplete.push(item);
        currentData.splice(index, 1);
      } else if (
        item.type === USER_TYPE.CAREGIVER &&
        item.phone.trim().length < 1
      ) {
        incomplete.push(item);
        currentData.splice(index, 1);
      }

      //checking for plwd only
      else if (
        item.type === USER_TYPE.DEMENTIA &&
        item.language.trim().length < 1
      ) {
        incomplete.push(item);
        currentData.splice(index, 1);
      } else if (
        item.type === USER_TYPE.DEMENTIA &&
        item.typeDementia.trim().length < 1
      ) {
        incomplete.push(item);
        currentData.splice(index, 1);
      } else if (
        item.type === USER_TYPE.DEMENTIA &&
        item.stageDementia.trim().length < 1
      ) {
        incomplete.push(item);
        currentData.splice(index, 1);
      } else if (
        item.type === USER_TYPE.DEMENTIA &&
        item.hospitalName.trim().length < 1
      ) {
        incomplete.push(item);
        currentData.splice(index, 1);
      } else if (
        item.type === USER_TYPE.DEMENTIA &&
        item.hospitalDepartment.trim().length < 1
      ) {
        incomplete.push(item);
        currentData.splice(index, 1);
      } else if (
        item.type === USER_TYPE.DEMENTIA &&
        item.symptoms.trim().length < 1
      ) {
        incomplete.push(item);
        currentData.splice(index, 1);
      }
    });

    setIncompleteData(incomplete);
    setData(currentData);

    // after check incomplete complete check duplicated data
    checkDuplicate({ data: currentData });
  };

  const checkDuplicate = ({ data }) => {
    let currentData = [];
    let duplicate = [];

    let idDuplicate = {
      username: [],
      phone: [],
      lastname: [],
      lastNric: [],
    };

    if (data[0].type.trim() === USER_TYPE.CAREGIVER) {
      // caregiver handle duplicate

      let uniqueUsername = removeAndAddDuplicates(data, "username");

      // determine where is id of duplicate username
      uniqueUsername.idDuplicates.map((item) => {
        idDuplicate.username.push(item);
      });

      // check duplicate again for unique phone data
      let uniquePhone = removeAndAddDuplicates(
        uniqueUsername.removedDuplicates,
        "phone"
      );

      // determine where is index of duplicatephone
      uniquePhone.idDuplicates.map((item) => {
        idDuplicate.phone.push(item);
      });

      currentData = uniquePhone.removedDuplicates;
      let idValidatedData = currentData.map((item) => item.id);

      // insert duplicated data
      _.union(idDuplicate.username, idDuplicate.phone).map((item) => {
        data.filter((originalData) => {
          if (originalData.id === item) {
            duplicate.push(originalData);
          }
        });
      });

      // remove duplicated data of validated duplicated data
      const duplicatedData = getDuplicatedValue(
        idValidatedData.concat(_.union(idDuplicate.username, idDuplicate.phone))
      );

      duplicate.map((item, index) => {
        duplicatedData.filter(function (yourArray) {
          if (yourArray == item.id) {
            duplicate.splice(index, 1);
          }
        });
      });
    } else {
      // plwd handle duplicate
      let uniqueUsername = removeAndAddDuplicates(data, "username");

      // determine where is id of duplicate username
      uniqueUsername.idDuplicates.map((item) => {
        idDuplicate.username.push(item);
      });

      // check duplicate again for unique lastname data
      let uniqueLastname = removeAndAddDuplicates(
        uniqueUsername.removedDuplicates,
        "lastname"
      );

      // determine where is index of duplicatep lastname
      uniqueLastname.idDuplicates.map((item) => {
        idDuplicate.lastname.push(item);
      });

      // check duplicate again for unique lastnric data
      let uniqueLastNric = removeAndAddDuplicates(
        uniqueLastname.removedDuplicates,
        "lastNric"
      );

      // determine where is index of duplicate last nric
      uniqueLastNric.idDuplicates.map((item) => {
        idDuplicate.phone.push(item);
      });

      currentData = uniqueLastNric.removedDuplicates;
      let idValidatedData = currentData.map((item) => item.id);
      // insert duplicated data
      _.union(
        idDuplicate.username,
        idDuplicate.lastname,
        idDuplicate.lastNric
      ).map((item) => {
        data.filter((originalData) => {
          if (originalData.id === item) {
            duplicate.push(originalData);
          }
        });
      });

      // remove duplicated data of validated duplicated data
      const duplicatedData = getDuplicatedValue(
        idValidatedData.concat(
          _.union(
            idDuplicate.username,
            idDuplicate.lastname,
            idDuplicate.lastNric
          )
        )
      );

      duplicate.map((item, index) => {
        duplicatedData.filter(function (yourArray) {
          if (yourArray == item.id) {
            duplicate.splice(index, 1);
          }
        });
      });
    }

    setDuplicatedData(duplicate);
    setData(currentData);
  };

  function removeAndAddDuplicates(originalArray, prop) {
    let newArray = [];
    let lookupObject = {};
    let allProp = [];

    for (let i in originalArray) {
      lookupObject[originalArray[i][prop]] = originalArray[i];
      allProp.push(originalArray[i][prop]);
    }

    for (let i in lookupObject) {
      newArray.push(lookupObject[i]);
    }

    // add duplicated data
    const propDuplicated = getDuplicatedValue(allProp);
    const idDuplicates = [];
    propDuplicated.map((item) => {
      allProp.filter(function (yourArray, index) {
        if (yourArray == item) {
          idDuplicates.push(originalArray[index].id);
        }
      });
    });
    idDuplicates.pop();

    return {
      removedDuplicates: newArray,
      idDuplicates,
    };
  }

  const getDuplicatedValue = (array) => {
    let uniq = array
      .map((item) => {
        return {
          count: 1,
          data: item,
        };
      })
      .reduce((a, b) => {
        a[b.data] = (a[b.data] || 0) + b.count;
        return a;
      }, {});

    let duplicates = Object.keys(uniq).filter((a) => uniq[a] > 1);
    return duplicates;
  };

  // handle file upload
  const handleFileUpload = (e) => {
    // const file = e.target.files[0];
    // const reader = new FileReader();
    // reader.onload = (evt) => {
    //   /* Parse data */
    //   const bstr = evt.target.result;
    //   const wb = XLSX.read(bstr, { type: 'binary' });
    //   /* Get first worksheet */
    //   const wsname = wb.SheetNames[0];
    //   const ws = wb.Sheets[wsname];
    //   /* Convert array of arrays */
    //   const data = XLSX.utils.sheet_to_csv(ws, { header: 1 });
    //   // processData(data);
    //   history.push('/import-user', {
    //     file: bstr
    //   })
    // };
    // reader.readAsBinaryString(file);
    // event.target.value = ''
    const file = e.target.files[0];
    history.push("/import-user", {
      file,
    });
  };

  const onCreateBulkAccount = async ({ data }) => {
    try {
      let resultAdd = {};
      resultAdd.fetching = false;
      resultAdd.payload = await bulkCreateAccount({ data });
      ProccessData(resultAdd, `add bulk account`)
        .then((res) => {
          setLoadingImport(false);
          setDialogUser(false);
          setData([]);
          setDuplicatedData([]);
          setIncompleteData([]);
          window.location.reload();
        })
        .catch((err) => {
          setLoadingImport(false);
          setDialogUser(false);
          alertError({ text: err });
        });
    } catch (e) {
      setLoadingImport(false);
      setDialogUser(false);
      alertError({ text: e });
    }
  };

  return (
    <StyledUserTopButton>
      <Dialog
        isVisible={dialogImport}
        onOk={() => setDialogImport(false)}
        isClose={() => setDialogImport(false)}
        title="Import Users"
        width="36%"
        height="300px"
      >
        <Button
          text="Upload Document"
          bordered
          buttonStyle={` margin: 38px 0 27px `}
          textColor={Colors.color11}
          onClick={() => {
            setDialogImport(true);
            inputUpload.click();
          }}
        />
        <input
          type="file"
          style={{ display: "none" }}
          ref={(input) => {
            inputUpload = input;
          }}
          accept=".csv,.xlsx,.xls"
          onChange={handleFileUpload}
        />
        <Button
          text="Download template for Upload"
          bordered
          textColor={Colors.color11}
          onClick={() => {
            setDialogDownload(true);
          }}
        />
      </Dialog>
      <DialogSelectAccount
        dialogProps={{
          isVisible: dialogAccount
            ? dialogAccount
            : dialogDownload
            ? dialogDownload
            : false,
          onOk: (accountType) => {
            if (dialogAccount) {
              setDialogAccount(false);
              if (createAccountType === USER_TYPE.CAREGIVER)
                window.open(
                  `${AppConfig.HOSTNAME}/registrationCG?partnerToken=${account.payload.data.account.token}`,
                  "_self"
                );
              else
                window.open(
                  `${AppConfig.HOSTNAME}/registrationPWD?partnerToken=${account.payload.data.account.token}`,
                  "_self"
                );
            } else if (dialogDownload) {
              const link = document.createElement("a");
              const href =
                accountType === "CAREGIVER" ? CaregiverField : PLWDField;
              link.href = href;
              link.setAttribute(
                "download",
                `Import ${
                  accountType === "CAREGIVER" ? "Care Professional" : "PLWD"
                } Template.xlsx`
              );
              document.body.appendChild(link);
              link.click();
              setDialogDownload(false);
              setDialogImport(false);
            }
          },
          isClose: () => {
            setDialogAccount(false);
            setDialogDownload(false);
          },
        }}
        onClickBox={(accountType) => setCreateAccountType(accountType)}
        dialogTitle={
          dialogDownload ? `Select Account Type Template` : undefined
        }
        listAccountType={
          dialogDownload
            ? { cg: "Care Professional", pwd: "Person Living with Dementia" }
            : undefined
        }
        okText={dialogDownload ? `Download` : undefined}
        dialogDownload={dialogDownload ? true : false}
      />

      <DialogUserList
        dialogProps={{
          isVisible: dialogUser,
          onOk: ({ data }) => {
            onCreateBulkAccount({ data });
            setLoadingImport(true);
          },
          isClose: () => {
            setDialogUser(false);
            setData([]);
            setDuplicatedData([]);
            setIncompleteData([]);
          },
          loading: loadingImport,
        }}
        userData={data}
        duplicateData={duplicatedData}
        incompleteData={incompleteData}
      />

      {account.payload.data.account.type === USER_TYPE.ADMIN ||
      account.payload.data.account.type === USER_TYPE.SUPERADMIN ||
      account.payload.data.account.type === USER_TYPE.VIEWER ? (
        <div className="buttonContainer">
          <Button
            text="Recently Deleted"
            buttonStyle={buttonAllAccounts}
            backgroundColor={
              listBy === "RECENTLY_DELETED" ? Colors.color13 : Colors.color9
            }
            textColor={
              listBy === "RECENTLY_DELETED" ? undefined : Colors.color7
            }
            onClick={onClickRecentlyDeleted}
            containerStyle={` width: 160px; `}
          />
          <div className="buttonSeparator" />
          <Button
            text="All Accounts"
            buttonStyle={buttonAllAccounts}
            backgroundColor={
              listBy === "ALL_ACCOUNTS" ? Colors.color10 : Colors.color9
            }
            textColor={listBy === "ALL_ACCOUNTS" ? undefined : Colors.color7}
            onClick={onClickAllAccounts}
            containerStyle={` width: 160px; `}
          />
          <div className="buttonSeparator" />
          <Button
            text="All Applicants"
            buttonStyle={buttonAllAplicants}
            backgroundColor={
              listBy === "ALL_APPLICANTS" ? Colors.color10 : Colors.color9
            }
            textColor={listBy === "ALL_APPLICANTS" ? undefined : Colors.color7}
            onClick={onClickAllApplicants}
            containerStyle={` width: 160px; `}
          />
        </div>
      ) : (
        <div className="buttonContainer">
          <Button
            text="Import Users"
            buttonStyle={buttonImport}
            backgroundColor={Colors.color14}
            onClick={() => setDialogImport(true)}
          />
          <div className="buttonSeparator" />
          <Button
            text="+ Create New Account"
            buttonStyle={buttonCreateNew}
            onClick={() => setDialogAccount(true)}
          />
        </div>
      )}
    </StyledUserTopButton>
  );
}

UserTopButton.propTypes = {
  listBy: PropTypes.func,
  onClickAllAccounts: PropTypes.func,
  onClickAllApplicants: PropTypes.func,
};

UserTopButton.defaultProps = {
  listBy: ``,
  onClickAllAccounts: () => null,
  onClickAllApplicants: () => null,
};

const mapStateToProps = (state) => {
  return {
    account: state.auth.account,
  };
};

export default connect(mapStateToProps)(UserTopButton);

// Array.prototype.contains = function ( needle ) {
//   for (i in this) {
//      if (this[i] == needle) return true;
//   }
//   return false;
// }

// if (x.contains('searchedString')) {

// }

// function removeDuplicates(originalArray, prop) {
//   let newArray = [];
//   let lookupObject  = {};
//   let allProp = []

//   for(let i in originalArray) {
//     lookupObject[originalArray[i][prop]] = originalArray[i];
//     allProp.push(originalArray[i][prop])
//   }

//   for(let i in lookupObject) {
//     newArray.push(lookupObject[i]);
//   }

//   return newArray;
// }
