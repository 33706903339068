import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import deviceSize from '../../constants/deviceSize'

export const StyledDialogTransfer = styled.div`
  padding: 0 14px;
  
  .searchTitle {
    font-size: 16px;
    font-weight: 800;
    margin-top: 15px;
    color: ${Colors.color11};
  }

  .card-container {
    display: grid;
    grid-template: auto / 1fr 60px 1fr;
    margin-top: 20px;
  }

  .card-container2 {
    display: grid;
    grid-template: auto / 1fr 1fr 1fr;
    margin-top: 20px;
  }

  .card-name {
    font-weight: 800;
    font-size: 16px;
    font-weight: bold;
    color: ${Colors.color11};
    margin-bottom: 20px;
  }

  .card-text {
    font-size: 14px;
    color: ${Colors.color11};
    margin-bottom: 10px;
  }
`

export const SearchBarStyle = `
  margin-right: 16px;
  .input-field {
    background: ${Colors.color3} !important;
    border: none !important;
  }
`

export const Card = styled.div`
  background: ${props => props.backgroundColor};
  border: ${props => props.borderColor};
  border-radius: 10px;
  padding: 31px;
  height: 157px;
  margin-bottom: 20px;
  margin-left: 10px;
  cursor: pointer;
`

export const CardDash = styled.div`
  background: ${Colors.color3};
  border: ${Colors.color40};
  border-radius: 10px;
  padding: 20px 31px;
  height: 157px;
  margin-bottom: 20px;
  cursor: pointer;
  border-style: dashed;
`

export const ButtonSearch = css`
  width: 120px;
  height: 40px;
  .button-text {
    ${Fonts.size.size16}
  }
`