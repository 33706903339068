import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledFormEditCategory = styled.div`
  background-color: ${Colors.color3};
  padding: ${props => props.action === 'EDIT' ? '48px 35px' : '50px 15px 15px 15px'};

  .icon-back {
    width: 10px;
    height: 20px;
    margin-right: 16px;
    cursor: pointer;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
  }

  h1 {
    ${Fonts.FontRegular}
    ${Fonts.size.size32}
    color: ${Colors.color2};
    font-weight: 800;
  }

  .image-container {
    background-color: ${Colors.color6};
    width: 100%;
    display: flex;
    padding: 48px 0 30px 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
  }

  .category-image {
    width: ${props => props.image ? '154px' : '48px'};
    height: ${props => props.image ? '154px' : '35px'};
    object-fit: contain;
  }

  .button-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
    margin-top: 50px;
  }
`

export const Label = styled.label`
  ${Fonts.FontRegular}
  ${Fonts.size.size16}
  color: ${Colors.color19};
  font-weight: bold;
`

export const InputStyle = css` 
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin: 10px 0 25px 0; 
  .input-field {  
    width: 100%;
    padding: 16px 14px 16px 18px;
    outline: none;
  }
`

export const InputReadOnlyStyle = css`
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin: 10px 0 25px 0; 
  .input-field { cursor: pointer; } 
`