import React, { Component, createRef } from "react";
import { connect } from "react-redux";
import {
  Tr,
  Th,
  Td,
  Card,
  Spin,
  Title,
  Table,
  Checkbox,
  Container,
  CardHeader,
  CardFooter,
  SearchField,
  CardSection,
  DangerButton,
  PrimaryButton,
  TableContainer,
  SecondaryButton,
  FontAwesomeText,
  CardSectionForm,
  ButtonsContainer,
  PaginationButton,
  GTPLocationTitle,
  ActionDangerButton,
  DangerOrangeButton,
  ActionWarningButton,
  GTPLocationSubtitle,
  PaginationButtonContainer,
} from "./styles/GoToPoints";

import Modal from "../components/modal";

import { Colors } from "../themes";
import { api } from "../sagas";

export class GoToPoints extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Get data stuff
      isGetGTPBusy: false,
      gtps: [],
      totalPage: 1,
      currentPage: 1,
      search: "",
      debouncedSearch: "",

      // Import excel stuff
      excelFile: null,
      showModalImportError: false,
      modalImportErrorMessages: "",
      isImportBusy: false,

      // Deletion stuff
      isDeleteBusy: false,
      tempDeleteIds: [],
      showDeleteModal: false,
    };

    this.uploadFileRef = createRef();
  }

  handleGetAllGTP = async (page, search) => {
    this.setState({
      isGetGTPBusy: true,
    });

    await api
      .findAllGoToPoints({
        search,
        page: page || 1,
      })
      .then((res) => {
        this.setState({
          totalPage: res.data?.data?.pagination?.totalPage || 1,
          currentPage: res.data?.data?.pagination?.currentPage || 1,
          gtps: res.data?.data?.data || [],
        });
      })
      .finally(() => {
        this.setState({
          isGetGTPBusy: false,
        });
      });
  };

  handleFileUpload = async (file) => {
    this.setState({
      isImportBusy: true,
    });
    const data = new FormData();
    data.append("file", file);

    await api
      .importGoToPoints(data)
      .then((res) => {
        if (res.data.type === "ERROR") {
          let errors = {};

          try {
            errors = JSON.parse(
              res.data.data.message
                ?.split("")
                .filter((el, i, arr) => i > 0 && i < arr.length - 1)
                .join("")
            );
          } catch (e) {
            errors =
              "There is an error while importing Go-To-Points from spreadsheet";
          }
          this.setState({
            showModalImportError: true,
            modalImportErrorMessages: errors,
            search: "",
          });
        }

        this.handleGetAllGTP(1, "");
      })
      .finally(() => {
        this.setState({
          isImportBusy: false,
        });
      });
  };

  handleDelete = async () => {
    this.setState({
      isDeleteBusy: true,
      showDeleteModal: false,
    });

    await api
      .deleteGoToPoints(this.state.tempDeleteIds)
      .then(() => {
        this.setState({
          search: "",
        });

        this.handleGetAllGTP(1, this.state.search);
      })
      .finally(() =>
        this.setState({
          isDeleteBusy: false,
        })
      );
  };

  handlePageChange = (to) =>
    this.setState({ currentPage: to, tempDeleteIds: [] });

  renderPageButtons = () => {
    const buttons = [],
      maxVisibleButtons = 5, // Adjust as needed
      currentPage = this.state.currentPage,
      totalPage = this.state.totalPage;

    // Button for the first page (if not current)
    if (currentPage > Math.ceil(maxVisibleButtons / 2)) {
      buttons.push(
        <PaginationButton key="first" onClick={() => this.handlePageChange(1)}>
          1
        </PaginationButton>
      );
    }

    // Ellipsis for hidden pages before current page (if applicable)
    if (currentPage > Math.ceil(maxVisibleButtons / 2) + 1) {
      buttons.push(<p key="ellipsis-before">...</p>);
    }

    // Buttons for surrounding pages
    for (
      let i = Math.max(currentPage - Math.floor(maxVisibleButtons / 2), 1);
      i <= Math.min(currentPage + Math.floor(maxVisibleButtons / 2), totalPage);
      i++
    ) {
      const isActive = i === currentPage;
      buttons.push(
        <PaginationButton
          key={i}
          active={isActive}
          onClick={() => this.handlePageChange(i)}
        >
          {i}
        </PaginationButton>
      );
    }

    // Ellipsis for hidden pages after current page (if applicable)
    if (currentPage < totalPage - Math.floor(maxVisibleButtons / 2) - 1) {
      buttons.push(<p key="ellipsis-after">...</p>);
    }

    // Button for the last page (if not current)
    if (currentPage < totalPage - Math.floor(maxVisibleButtons / 2)) {
      buttons.push(
        <PaginationButton
          key="last"
          onClick={() => this.handlePageChange(totalPage)}
        >
          {totalPage}
        </PaginationButton>
      );
    }

    return buttons;
  };

  componentDidUpdate(_prevProps, prevState) {
    if (prevState.currentPage !== this.state.currentPage)
      this.handleGetAllGTP(this.state.currentPage, this.state.search);
  }

  componentDidMount() {
    this.handleGetAllGTP();
  }

  render() {
    return (
      <Container>
        <input
          onChange={(e) => {
            if (e.target?.files?.[0]) this.handleFileUpload(e.target.files[0]);
            e.target.value = null;
          }}
          type="file"
          style={{
            display: "none",
          }}
          ref={this.uploadFileRef}
          accept=".csv, application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
        />
        <Title>Go-To-Point Management</Title>

        <Card>
          <CardHeader>
            <CardSectionForm
              style={{
                flex: 1,
              }}
              onSubmit={(e) => {
                e.preventDefault();
                this.handleGetAllGTP(1, e.target[0].value);
              }}
            >
              <SearchField
                placeholder="Search &#xF002;"
                value={this.state.search}
                onChange={(e) =>
                  this.setState({
                    search: e.target.value,
                  })
                }
              />
              <PrimaryButton type="submit">Search</PrimaryButton>
            </CardSectionForm>
            <CardSection>
              {this.state.isImportBusy ? (
                <Spin>
                  <FontAwesomeText
                    style={{
                      color: Colors.color11,
                    }}
                  >
                    &#xf110;
                  </FontAwesomeText>
                </Spin>
              ) : (
                <SecondaryButton
                  onClick={() => this.uploadFileRef.current?.click?.()}
                >
                  Upload via csv
                </SecondaryButton>
              )}
              <PrimaryButton
                onClick={() => this.props.history.push("/go-to-points/create")}
              >
                Create
              </PrimaryButton>

              {(this.state.tempDeleteIds.length && (
                <DangerButton
                  disabled={this.state.isDeleteBusy}
                  onClick={() =>
                    this.setState({
                      showDeleteModal: true,
                    })
                  }
                >
                  {this.state.isDeleteBusy ? (
                    <Spin>
                      <FontAwesomeText
                        style={{
                          color: Colors.color3,
                        }}
                      >
                        &#xf110;
                      </FontAwesomeText>
                    </Spin>
                  ) : (
                    "Delete"
                  )}
                </DangerButton>
              )) || <></>}
            </CardSection>
          </CardHeader>

          <TableContainer>
            <Table>
              <Tr>
                <Th>
                  <Checkbox
                    checked={
                      this.state.gtps.map((gtp) => gtp.id).length ===
                      this.state.tempDeleteIds.length
                    }
                    onChange={(e) => {
                      this.setState({
                        tempDeleteIds: e.target.checked
                          ? this.state.gtps.map((el) => el.id)
                          : [],
                      });
                    }}
                  />
                </Th>
                <Th>Location</Th>
                <Th>Action</Th>
              </Tr>

              {this.state.isGetGTPBusy ? (
                <Tr>
                  <Td colSpan="3">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 14,
                      }}
                    >
                      <Spin>
                        <FontAwesomeText
                          style={{
                            fontSize: 30,
                            color: Colors.color11,
                          }}
                        >
                          &#xf110;
                        </FontAwesomeText>
                      </Spin>
                      <h3
                        style={{
                          fontSize: 24,
                          fontWeight: 600,
                          color: Colors.color11,
                        }}
                      >
                        Please wait
                      </h3>
                    </div>
                  </Td>
                </Tr>
              ) : (
                this.state.gtps.map((gtp, i) => (
                  <Tr key={`gtp-${i}`}>
                    <Td>
                      <Checkbox
                        checked={
                          !!this.state.tempDeleteIds.filter(
                            (tempDeletedId) => tempDeletedId === gtp.id
                          ).length
                        }
                        onChange={(e) => {
                          this.setState({
                            tempDeleteIds: e.target.checked
                              ? [...this.state.tempDeleteIds, gtp.id]
                              : this.state.tempDeleteIds.filter(
                                  (el) => el !== gtp.id
                                ),
                          });
                        }}
                      />
                    </Td>
                    <Td>
                      <GTPLocationTitle>{gtp.name}</GTPLocationTitle>
                      <GTPLocationSubtitle>{gtp.address}</GTPLocationSubtitle>
                    </Td>
                    <Td>
                      <ButtonsContainer>
                        <ActionWarningButton
                          onClick={() =>
                            this.props.history.push(
                              `/go-to-points/update/${gtp.id}`
                            )
                          }
                        >
                          Edit
                        </ActionWarningButton>
                        <ActionDangerButton
                          disabled={this.state.isDeleteBusy}
                          onClick={() =>
                            this.setState({
                              tempDeleteIds: [gtp.id],
                              showDeleteModal: true,
                            })
                          }
                        >
                          {this.state.isDeleteBusy ? (
                            <Spin>
                              <FontAwesomeText
                                style={{
                                  color: Colors.color13,
                                }}
                              >
                                &#xf110;
                              </FontAwesomeText>
                            </Spin>
                          ) : (
                            "Delete"
                          )}
                        </ActionDangerButton>
                      </ButtonsContainer>
                    </Td>
                  </Tr>
                ))
              )}
            </Table>
          </TableContainer>

          <CardFooter>
            <div />
            <PaginationButtonContainer>
              <PaginationButton
                onClick={() =>
                  this.handlePageChange(
                    this.state.currentPage - 1 >= 1
                      ? this.state.currentPage - 1
                      : this.state.currentPage
                  )
                }
              >
                <FontAwesomeText>&#xf053;</FontAwesomeText>
              </PaginationButton>

              {this.renderPageButtons()}

              <PaginationButton
                onClick={() =>
                  this.handlePageChange(
                    this.state.currentPage + 1 <= this.state.totalPage
                      ? this.state.currentPage + 1
                      : this.state.currentPage
                  )
                }
              >
                <FontAwesomeText>&#xf054;</FontAwesomeText>
              </PaginationButton>
            </PaginationButtonContainer>
          </CardFooter>
        </Card>

        <Modal
          isVisible={this.state.showModalImportError}
          width={"400px"}
          isClose={() =>
            this.setState({
              showModalImportError: false,
              modalImportErrorMessages: "",
            })
          }
        >
          <h1
            style={{
              fontWeight: 800,
              fontSize: 20,
            }}
          >
            Some errors occurred on the line
          </h1>

          <div
            style={{
              paddingTop: 25,
              display: "flex",
              flexDirection: "column",
              gap: 12,
            }}
          >
            {Object.keys(this.state?.modalImportErrorMessages).length ? (
              Object.keys(this.state?.modalImportErrorMessages).map(
                (key, i) => (
                  <div
                    key={`fields-error-${i}`}
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: 8,
                    }}
                  >
                    <h3>{key}</h3>

                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        gap: 4,
                      }}
                    >
                      {this.state.modalImportErrorMessages[key].includes(
                        "existInDB"
                      ) ? (
                        <p>
                          This column already exist. Please try another name /
                          postal code
                        </p>
                      ) : this.state.modalImportErrorMessages[key].includes(
                          "existInSheet"
                        ) ? (
                        <p>This column already exist on another column</p>
                      ) : (
                        this.state.modalImportErrorMessages?.[
                          key
                        ]?.map?.((el) =>
                          el.includes("notIn(") ? (
                            <p>
                              Field Region must be one of{" "}
                              {el?.replace("notIn(", "")?.replace(")", "")}
                            </p>
                          ) : (
                            <p>Field {el} is empty or not compatible</p>
                          )
                        )
                      )}
                    </div>
                  </div>
                )
              )
            ) : (
              <p>{this.state.modalImportErrorMessages}</p>
            )}
          </div>
        </Modal>

        <Modal
          isVisible={this.state.showDeleteModal}
          height={"auto"}
          showXClose={false}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "35px",
              marginBottom: "-22px",
              padding: "14px",
            }}
          >
            <h1
              style={{
                fontSize: "24px",
                fontWeight: 800,
                textAlign: "center",
              }}
            >
              Are you sure want to delete this location?
            </h1>

            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: 14,
              }}
            >
              <SecondaryButton
                onClick={() =>
                  this.setState({
                    showDeleteModal: false,
                  })
                }
                style={{ flex: 1 }}
              >
                No
              </SecondaryButton>
              <DangerOrangeButton
                onClick={this.handleDelete}
                style={{ flex: 1 }}
              >
                Yes
              </DangerOrangeButton>
            </div>
          </div>
        </Modal>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GoToPoints);
