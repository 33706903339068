import styled, { css } from 'styled-components';
import { Colors, Fonts, Scale, Images } from '../../themes';

const color = {
  purple: '#502773'
}

export const StyledDashboardPartner = styled.div`
  padding: 0 0 2rem 0;
  background-color: ${Colors.primaryBgColor};
  min-height: 100vh;

  .title {
    font-size: 32px;
    color: ${color.purple};
    font-weight: bold;
    background-color: white;
    padding: 2rem;
  }
`