import { API } from "../lib/Request";
import AppConfig from "../config.js";

const notificationsUrl = AppConfig.NOTIFICATIONS_URL;

export const getNotifications = async (data) => {
  return new Promise((resolve, reject) => {
    API({
      url: `${notificationsUrl}/notifications/admin?page=${data.page}&limit=${data.pageSize}`,
      method: "GET",
      data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getNotificationById = async (id) => {
  return new Promise((resolve, reject) => {
    API({
      url: `${notificationsUrl}/notifications/${id}`,
      method: "GET",
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addNotificationByAdmin = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${notificationsUrl}/notifications/admin`, method: "POST", data })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const addNotification = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${notificationsUrl}/notifications`, method: "POST", data })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteNotification = async (data) => {
  return new Promise((resolve, reject) => {
    API({
      url: `${notificationsUrl}/notifications/${data.id}`,
      method: "DELETE",
      data,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const deleteNotificationByQuery = async (params) => {
  return new Promise((resolve, reject) => {
    API({
      url: `${notificationsUrl}/delete-notification`,
      method: "DELETE",
      params,
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const getUnreadNotification = async (params) => {
  return new Promise((resolve, reject) => {
    API({
      url: `${notificationsUrl}/unread-notification${params}`,
      method: "GET",
    })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};

export const updateUnreadNotification = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${notificationsUrl}/unread-notification`, method: "PUT", data })
      .then((res) => {
        resolve(res);
      })
      .catch((err) => {
        reject(err);
      });
  });
};
