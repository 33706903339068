import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Container, Row, Col, Hidden } from 'react-grid-system';
import moment from 'moment'
import {
  StyledCreateNotification,
  Label,
  InputStyle,
} from './styles/StyledCreateNotification';
import DialogInfo from '../components/dialog-info'
import { Images, Colors } from '../themes';
import Input from '../components/input';
import { USER_TYPE } from '../constants';
import { getNotificationById } from '../api/NotificationAPI';
import NotificationsActions from '../redux/NotificationsRedux';

const InputReadOnlyStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin: 10px 0 25px 0; 
  .input-field { cursor: pointer; }
`;

const InputTypeStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin-top: 10px; 
  .input-field { cursor: pointer; }
`;

const InputDateStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin-top: 10px; 

  input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
`

const dropdownTargetData = [
  {
    id: 1,
    name: 'Person with Dementia',
    value: USER_TYPE.DEMENTIA,
  },
  {
    id: 2,
    name: 'Caregiver',
    value: USER_TYPE.CAREGIVER,
  },
  {
    id: 3,
    name: 'Member of Public',
    value: USER_TYPE.MOP,
  },
];

const ageData = [
  {
    id: 1,
    name: 'Above age of 50',
    value: '50,above',
  },
  {
    id: 2,
    name: 'Below age of 50',
    value: '50,below',
  },
];

const typeOfDementiaData = [
  {
    id: 1,
    name: `Alzheimer's Disease`,
  },
  {
    id: 2,
    name: 'Vascular Dementia',
  },
  {
    id: 3,
    name: 'Frontotemporal Dementia (FTD)',
  },
  {
    id: 4,
    name: 'Lewy Body Dementia (LBD)',
  },
  {
    id: 5,
    name: 'Mild Cognitive Impairment (MCI)',
  },
];

const languageData = [
  {
    id: 1,
    name: 'English',
  },
  {
    id: 2,
    name: 'Mandarin',
  },
  {
    id: 3,
    name: 'Malay',
  },
  {
    id: 4,
    name: 'Tamil',
  },
  {
    id: 5,
    name: 'Hokkien',
  },
];

export class CreateNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownTarget: false,
      title: '',
      text: '',
      image: '',
      name: '',
      url: '',
      targetUser: {
        id: 1,
        name: 'Person with Dementia',
        value: USER_TYPE.DEMENTIA,
      },
      targetFiltered: false,
      ageDropdown: false,
      age: {
        id: 1,
        name: 'Above age of 50',
        value: '50,above',
      },
      ageFiltered: false,
      typeOfDementiaDropdown: false,
      typeOfDementia: {
        id: 1,
        name: `Alzheimer's Disease`,
      },
      typeOfDementiaFiltered: false,
      languageDropdown: false,
      language: {
        id: 1,
        name: 'English',
      },
      languageFiltered: false,
      accountStatus: '',
      delivery: '',
      frequency: undefined,
      gender: '',
      genderFiltered: false,
      stageOfDementia: '',
      stageOfDementiaFiltered: false,
      loading: false,
      dateSend: moment().format('YYYY-MM-DD'),
      timeSend: moment().format('HH:mm'),
      infoDialogShow: false,
      infoDescription: ''
    };

    this.renderInputCheckbox = this.renderInputCheckbox.bind(this);
    this.renderInputDropdown = this.renderInputDropdown.bind(this)
  }

  componentDidMount() {
    const { id } = this.props.match.params;

    this.setState({ loading: true });

    getNotificationById(id)
      .then((res) => {
        console.log("res",res);
        const data = res.result;
        const target = data?.NotificationTarget;
        const schedule = data?.NotificationSchedule;

        if (data) {
          this.setState({
            title: data?.title,
            text: data?.text,
            image: data?.image,
            name: data?.name,
            url: data?.url,
            targetFiltered:
              target?.accountType !== 'ALL' ||
              target?.age !== 'ALL' ||
              target?.typeDementia !== 'ALL' ||
              target?.language !== 'ALL' ||
              target?.gender !== 'ALL' ||
              target?.stageDementia !== 'ALL',
            targetUser:
              target?.accountType !== 'ALL'
                ? dropdownTargetData.findIndex(
                    (item) => item.value === target?.accountType
                  )
                : {},
            ageFiltered: target?.age !== 'ALL',
            age:
              target?.age !== 'ALL'
                ? ageData.findIndex((item) => item.value === target?.age)
                : {},
            typeOfDementiaFiltered: target?.typeDementia !== 'ALL',
            typeOfDementia:
              target?.typeDementia !== 'ALL'
                ? typeOfDementiaData.findIndex(
                    (item) => item.value === target?.typeDementia
                  )
                : {},
            languageFiltered: target?.language !== 'ALL',
            language:
              target?.language !== 'ALL'
                ? languageData.findIndex((item) => item.value === target?.language)
                : {},
            genderFiltered: target?.gender !== 'ALL',
            gender: target?.gender !== 'ALL' ? target?.gender : null,
            stageOfDementiaFiltered: target?.stageDementia !== 'ALL',
            stageOfDementia: target?.stageDementia,
            delivery:
              schedule?.delivery === 'NOW'
                ? 'Now'
                : schedule?.delivery === 'SCHEDULE'
                ? 'Schedule'
                : '',
            frequency: schedule?.frequency,
            dateSend: moment(schedule?.dateSend).format('YYYY-MM-DD'),
            timeSend: moment(schedule?.dateSend).format('HH:mm'),
            loading: false,
          });
        }
      })
      .catch((err) => {
        console.error(err);
        this.setState({ loading: false });
      });
  }

  renderInputCheckbox({
    value,
    stateName = 'typeOfDementia',
    md = 3.8,
    filtered = true,
  }) {
    const {
      typeOfDementia,
      accountStatus,
      delivery,
      frequency,
      gender,
      stageOfDementia,
    } = this.state;
    return (
      <Col md={md}>
        <Input
          inputProps={{
            value,
            readOnly: true
          }}
          containerStyle={`
            ${InputTypeStyle}
            .input-field {
              cursor: ${filtered ? 'pointer' : 'not-allowed'}
            }
            input {
              color: ${filtered ? Colors.color2 : Colors.color34} !important;
            }
          `}
          iconRight={
            typeOfDementia === value ||
            accountStatus === value ||
            delivery === value ||
            frequency === value ||
            (gender === value && filtered) ||
            (stageOfDementia === value && filtered)
              ? Images.checkActive
              : Images.checkInactive
          }
          iconRightStyle={{ width: '15px' }}
        />
      </Col>
    );
  }

  renderInputDropdown(props) {
    const { value, onClick, filtered } = props;
    return (
      <Input
        inputProps={{
          readOnly: true,
          onClick: filtered ? onClick : null,
          value,
        }}
        containerStyle={`
          ${InputReadOnlyStyle}
          .input-field {
            cursor: ${filtered ? 'pointer' : 'not-allowed'}
          }
          input {
            color: ${filtered ? Colors.color2 : Colors.color34} !important;
          }
          `}
        iconRight={Images.polygonYellow}
        {...props}
        dropdownWidth={'100%'}
      />
    );
  }

  render() {
    const { history } = this.props;
    const {
      title,
      text,
      image,
      name,
      url,
      dropdownTarget,
      targetUser,
      delivery,
      targetFiltered,
      ageDropdown,
      age,
      ageFiltered,
      typeOfDementiaDropdown,
      typeOfDementia,
      typeOfDementiaFiltered,
      languageDropdown,
      language,
      languageFiltered,
      genderFiltered,
      stageOfDementiaFiltered,
      dateSend,
      timeSend,
      infoDialogShow, 
      infoDescription,
    } = this.state;

    return (
      <StyledCreateNotification>
        {/* Dialog info start */}
        <DialogInfo
          isVisible={infoDialogShow}
          textInfo={infoDescription}
          onOkButton={()=> this.setState({infoDialogShow: false})}
        />
        {/* Dialog info end  */}
        <Container className="wrapper">
          <Row align="center">
            <Col lg={0.6} xs={1}>
              <img
                src={Images.back}
                className="icon  -back"
                onClick={() => history.goBack()}
              />
            </Col>
            <Col lg={11.4} xs={7}>
              <Row align="center" justify="between">
                <Col
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <div className="title-wrapper">
                    <h1 className="title-name">Notification Detail</h1>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: '59px',
              borderBottomWidth: `1px`,
              borderBottomColor: Colors.color27,
              borderBottomStyle: 'solid',
              paddingBottom: '59px',
              marginBottom: '21px',
            }}
          >
            <Col offset={{ lg: 0.6, xs: 1 }} xs={12}>
              <Row>
                <Col
                  md={0.6}
                  style={{ display: 'flex', justifyContent: 'flex-end' }}
                >
                  <Hidden sm xs>
                    <div className="line" />
                  </Hidden>
                </Col>
                <Col xs={11} offset={{ md: 0.1 }}>
                  <div
                    className="circle"
                    style={{ left: '-38px', background: Colors.color14 }}
                  >
                    1
                  </div>
                  <h1 className="section-title">Notification</h1>
                  <Row style={{ marginTop: '27px' }}>
                    <Col xs={6}>
                      <Label htmlFor="title">Notification Title</Label>
                      <Input
                        inputProps={{
                          id: 'title',
                          value: title
                        }}
                        containerStyle={InputStyle}
                      />
                      <Label htmlFor="text">Notification Text</Label>
                      <Input
                        inputProps={{
                          id: 'text',
                          value: text,
                        }}
                        containerStyle={InputStyle}
                        multiline
                      />
                      <Label htmlFor="image">
                        Notification Image (optional)
                      </Label>
                      {
                        image ?      
                          <img 
                            src={image} 
                            style={{
                              height: '200px',
                              margin: '15px 10px 25px 10px'
                            }}
                          />
                        : 
                        <p style={{margin: '15px 10px 25px 10px', fontSize: '14px'}}>-No image</p>
                      }
                      <Label htmlFor="name">Notification Name (optional)</Label>
                      <Input
                        inputProps={{
                          id: 'name',
                          value: name,
                        }}
                        containerStyle={InputStyle}
                      />
                      <Label htmlFor="url">Link URL</Label>
                      <Input
                        inputProps={{
                          id: 'url',
                          value: url,
                        }}
                        containerStyle={InputStyle}
                      />
                    </Col>
                    <Col md={5} style={{ marginTop: '10px' }}>
                      <img src={Images.notifExample} />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '75px' }}>
                    <Col>
                      <div className="circle" style={{ left: '-38px' }}>
                        2
                      </div>
                      <h1 className="section-title">Target</h1>
                      <Row style={{ marginTop: '27px' }}>
                        <Col xs={6}>
                          <Row justify="between">
                            <Col md={10} xs={8}>
                              <Label>Role Type</Label>
                            </Col>
                            <Col
                              md={2}
                              xs={4}
                              style={{ cursor: 'pointer' }}
                            >
                              <img
                                src={
                                  targetFiltered
                                    ? Images.switchOn
                                    : Images.switchOff
                                }
                              />
                            </Col>
                          </Row>
                          {/* <p>{JSON.stringify(dropdownTargetData[targetUser]?.name)}</p>
                          <p>{JSON.stringify(dropdownTargetData)}</p> */}
                          {this.renderInputDropdown({
                            showDropdown: dropdownTarget,
                            dropdownData: dropdownTargetData,
                            dropdownPropertyName: 'name',
                            value: dropdownTargetData[targetUser]?.name,
                            filtered: targetFiltered,
                          })}
                        </Col>
                        <Col xs={6} />
                        <Col xs={6}>
                          <Row justify="between">
                            <Col md={10} xs={8}>
                              <Label>Age</Label>
                            </Col>
                            <Col
                              md={2}
                              xs={4}
                              style={{ cursor: 'pointer' }}
                            >
                              <img
                                src={
                                  ageFiltered
                                    ? Images.switchOn
                                    : Images.switchOff
                                }
                              />
                            </Col>
                          </Row>
                          {this.renderInputDropdown({
                            showDropdown: ageDropdown,
                            dropdownData: ageData,
                            dropdownPropertyName: 'name',
                            value: age.name,
                            filtered: ageFiltered,
                          })}
                        </Col>
                        <Col xs={6} />
                        <Col xs={6}>
                          <Row justify="between">
                            <Col md={10} xs={8}>
                              <Label>Type Of Dementia</Label>
                            </Col>
                            <Col
                              md={2}
                              xs={4}
                              style={{ cursor: 'pointer' }}
                            >
                              <img
                                src={
                                  typeOfDementiaFiltered
                                    ? Images.switchOn
                                    : Images.switchOff
                                }
                              />
                            </Col>
                          </Row>
                          {this.renderInputDropdown({
                            showDropdown: typeOfDementiaDropdown,
                            dropdownData: typeOfDementiaData,
                            dropdownPropertyName: 'name',
                            value: typeOfDementia.name,
                            filtered: typeOfDementiaFiltered,
                          })}
                        </Col>
                        <Col xs={6} />
                        <Col xs={6}>
                          <Row justify="between">
                            <Col md={10} xs={8}>
                              <Label>Language</Label>
                            </Col>
                            <Col
                              md={2}
                              xs={4}
                              style={{ cursor: 'pointer' }}
                            >
                              <img
                                src={
                                  languageFiltered
                                    ? Images.switchOn
                                    : Images.switchOff
                                }
                              />
                            </Col>
                          </Row>
                          {this.renderInputDropdown({
                            showDropdown: languageDropdown,
                            dropdownData: languageData,
                            dropdownPropertyName: 'name',
                            value: language.name,
                            filtered: languageFiltered,
                          })}
                        </Col>
                        <Col xs={6} />
                        <Col xs={6}>
                          <Row justify="between">
                            <Col md={10} xs={8}>
                              <Label>Gender</Label>
                            </Col>
                            <Col
                              md={2}
                              xs={4}
                              style={{ cursor: 'pointer' }}
                            >
                              <img
                                src={
                                  genderFiltered
                                    ? Images.switchOn
                                    : Images.switchOff
                                }
                              />
                            </Col>
                          </Row>
                          <Row>
                            {[
                              this.renderInputCheckbox({
                                value: 'male',
                                stateName: 'gender',
                                md: 6,
                                filtered: genderFiltered,
                              }),
                              this.renderInputCheckbox({
                                value: 'female',
                                stateName: 'gender',
                                md: 6,
                                filtered: genderFiltered,
                              }),
                            ]}
                          </Row>
                        </Col>
                        <Col xs={6} />
                        <Col xs={6} style={{ marginTop: '10px' }}>
                          <Row justify="between">
                            <Col md={10} xs={8}>
                              <Label>Stage Of Dementia</Label>
                            </Col>
                            <Col
                              md={2}
                              xs={4}
                              style={{ cursor: 'pointer' }}
                            >
                              <img
                                src={
                                  stageOfDementiaFiltered
                                    ? Images.switchOn
                                    : Images.switchOff
                                }
                              />
                            </Col>
                          </Row>
                          <Row>
                            {[
                              ,
                              this.renderInputCheckbox({
                                value: 'Mild',
                                stateName: 'stageOfDementia',
                                md: 6,
                                filtered: stageOfDementiaFiltered,
                              }),
                              this.renderInputCheckbox({
                                value: 'Moderate',
                                stateName: 'stageOfDementia',
                                md: 6,
                                filtered: stageOfDementiaFiltered,
                              }),
                              this.renderInputCheckbox({
                                value: 'Severe',
                                stateName: 'stageOfDementia',
                                md: 6,
                                filtered: stageOfDementiaFiltered,
                              }),
                            ]}
                          </Row>
                        </Col>
                        {/* <Col xs={11} style={{ marginTop: '10px', }}>
                          <Label>Account Status</Label>
                          <Row>
                            {
                              [
                                this.renderInputCheckbox({ value: "Completed", stateName: 'accountStatus' }),
                                this.renderInputCheckbox({ value: "Incomplete", stateName: 'accountStatus' }),
                                this.renderInputCheckbox({ value: "Pending approval", stateName: 'accountStatus' }),
                                this.renderInputCheckbox({ value: "Pending cards", stateName: 'accountStatus' }),
                              ]
                            }
                          </Row>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: '75px' }}>
                    <Col>
                      <div className="circle" style={{ left: '-38px' }}>
                        3
                      </div>
                      <div className="line2" />
                      <h1 className="section-title">Schedulling</h1>
                      <Row style={{ marginTop: '27px' }}>
                        <Col xs={11}>
                          <Label>Delivery</Label>
                          <Row>
                            {[
                              this.renderInputCheckbox({
                                value: 'Now',
                                stateName: 'delivery',
                              }),
                              this.renderInputCheckbox({
                                value: 'Schedule',
                                stateName: 'delivery',
                              }),
                            ]}
                          </Row>
                        </Col>
                        <Col xs={11} style={{ marginTop: '10px' }}>
                          <Label>Frequency</Label>
                          <Row>
                            {[
                              this.renderInputCheckbox({
                                value: 'Daily',
                                stateName: 'frequency',
                                filtered: delivery !== 'Now' && delivery.length > 1,
                              }),
                              this.renderInputCheckbox({
                                value: 'Weekly',
                                stateName: 'frequency',
                                filtered: delivery !== 'Now' && delivery.length > 1,
                              }),
                              this.renderInputCheckbox({
                                value: 'Monthly',
                                stateName: 'frequency',
                                filtered: delivery !== 'Now' && delivery.length > 1,
                              }),
                            ]}
                          </Row>
                        </Col>
                        <Col xs={11} style={{ marginTop: '10px' }}>
                          <Label>Date</Label>
                          <Row>
                            <Col md={3.8}>
                              <Input
                                inputProps={{
                                  placeholder: 'Date',
                                  type: 'text',
                                  readOnly: delivery === 'Now' || delivery.length < 1,
                                  value: delivery === 'NOW' || delivery === 'Now' ? '' : dateSend,
                                }}
                                containerStyle={`
                                  ${InputDateStyle}
                                  .input-field {
                                    cursor: ${delivery !== 'Now' && delivery.length > 1 ? 'default' : 'not-allowed'}
                                  }
                                  input {
                                    color: ${delivery !== 'Now' && delivery.length > 1 ? Colors.color2 : Colors.color34} !important;
                                  }
                                `}
                              />
                            </Col>
                            <Col md={3.8}>
                              <Input
                                inputProps={{
                                  placeholder: 'Time',
                                  type: 'text',
                                  readOnly: delivery === 'Now' || delivery.length < 1,
                                  value: delivery === 'NOW' || delivery === 'Now' ? '' : timeSend,
                                }}
                                containerStyle={`
                                  ${InputDateStyle}
                                  .input-field {
                                    cursor: ${delivery !== 'Now' && delivery.length > 1 ? 'default' : 'not-allowed'}
                                  }
                                  input {
                                    color: ${delivery !== 'Now' && delivery.length > 1 ? Colors.color2 : Colors.color34} !important;
                                  }
                                `}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="button-container">
            <div style={{ width: '446px', display: 'flex' }}>
              <div style={{ flex: 1 }}>
                {/* <Button
                  text="Save as draft"
                  bordered
                  textColor={Colors.color11}
                  textStyle={`font-weight: bold;`}
                /> */}
              </div>
              <div style={{ width: '20px' }} />
            </div>
          </div>
        </Container>
      </StyledCreateNotification>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: (params) =>
      dispatch(NotificationsActions.getNotificationsRequest(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNotification);
