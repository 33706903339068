import React, { useState, } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Hidden, } from 'react-grid-system';
import _ from 'lodash'

import { StyledDialogTag, InputStyle, InputReadOnlyStyle, } from './StyledDialogTag'

import Dialog from '../modal'
import Input from '../input'
import Button from '../button'

import { Colors, Images, } from '../../themes'

import { relationshipList } from '../../data/RelationshipList'

import { getUsers, tagAccount, } from '../../api/UserAPI'

import ProccessData from '../../lib/ProccessData'

function TagCaregiver({
  dialogProps,
  otherAccount,
  onTagAccount,
  loadingTag,
}) {
  const [searchBy, setSearchBy] = useState('name')
  const [inputValue, setInputValue] = useState('')
  const [users, setUsers] = useState({
    loading: false,
    data: [],
  })
  const [dropdowns,setDropdowns] = useState({
    relationship: {
      showDropdown: false,
      value: '',
    },
  });
  const [loading, setLoading] = useState(false)
  const [searched, setSearched] = useState(false)
  const [userSelected, setUserSelected] = useState({})

  const { relationship } = dropdowns

  const renderInputCheckbox = ({
    value,
    stateName = 'accountStatus',
    md = 6,
    data,
    onClick,
  }) => {
    
    return (
      <Col md={md}>
        <Input
          inputProps={{
            value,
            readOnly: true,
            onClick,
          }}
          containerStyle={`
            ${InputStyle}
            cursor: pointer;
            .input-field {
              cursor: pointer;
            }
          `}
          iconLeft={
            searchBy === data.value ?
              Images.checkActive
            : 
              Images.checkInactive
          }
          iconLeftStyle={{ width: '15px' }}
        />
      </Col>
    );
  }
  
  const renderInputDropdown = (props) => {
    const { value, onClick, } = props;
    let filtered = true
  
    return (
      <Col md={12}>
        <Input
          inputProps={{
            readOnly: true,
            onClick: filtered ? onClick : null,
            value,
          }}
          containerStyle={`
            ${InputReadOnlyStyle}
            .input-field {
              cursor: ${filtered ? 'pointer' : 'not-allowed'}
            }
            input {
              color: ${filtered ? Colors.color2 : Colors.color34} !important;
            }
          `}
          iconRight={Images.polygonYellow}
          {...props}
          dropdownWidth={'100%'}
        />
      </Col>
    );
  }

  const onPressDropdown = (stateName) => {
    setDropdowns(state => {
      return { ...state, [stateName]: { ...dropdowns[stateName], showDropdown: !dropdowns[stateName].showDropdown, }}
    })
  };

  const onSelectDataDropdown = (stateName, value) => {
    setDropdowns(state => {
      return { ...state, [stateName]: { ...dropdowns[stateName], value, showDropdown: !dropdowns[stateName].showDropdown, }}
    })
  }

  const searchUser = async () => {
    setUsers(state => ({ ...state, loading: true, }) )

    const users = await getUsers({ 
      [searchBy]: inputValue.trim(),
      type: 'CAREGIVER',
    })

    try {
      let resultGet = {}
      resultGet.fetching = false
      resultGet.payload = users
      ProccessData(resultGet, 'get user')
        .then(({ data }) => {
          setUsers(state => ({ data, loading: false, }) )
        })
        .catch(err => {
          alert(err ? err : 'ERROR')
        })
    } catch (e) {
      alert('CONNECTION ERROR ' + e)
    }

    setUsers(state => ({ ...state, loading: false, }) )
    setSearched(true)
  }

  return (
    <Dialog
      {...dialogProps}
      width="36%"
      height={searched ? undefined : "auto"}
      title={`Tag new Caregiver / Next-of-Kin`}
      additionalStyle={{
        titleStyle: ` color: ${Colors.color19}; `,
      }}
    >
      <StyledDialogTag>
        <p className="text">Search by</p>
        <Row>
          {
            [
              renderInputCheckbox({
                value: `Name`,
                onClick: () => setSearchBy('name'),
                data: {
                  value: 'name',
                }
              }),
              renderInputCheckbox({
                value: 'Number',
                onClick: () => setSearchBy('phone'),
                data: {
                  value: 'phone',
                }
              })
            ]
          }
          <Col>
            <Input
              inputProps={{
                value: inputValue,
                onChange: (e) => setInputValue(e.target.value)
              }}
              containerStyle={`
                ${InputStyle}
              `}
            />
          </Col>
        </Row>
        {
          searched &&
            <p className="result-text">Showing {users.data.length} results for “{inputValue}”</p>
        }
        {
          users.data.length > 0 && searched && 
          [
            users.data.map(item => {
              return (
                <div className={`card ${userSelected.uid === item.uid && `bordered`}`} onClick={() => {
                    let isTagged = otherAccount.some(data => (data.uidTagged === item.uid && data.status === `ACCEPTED`) || (data.uidTags === item.uid && data.status === `ACCEPTED`))
                    if (isTagged) alert(`This user has already been tagged`) 
                    else {
                      setUserSelected(item)
                      setDropdowns(state => {
                        return { ...state, ['relationship']: { ...dropdowns['relationship'], value: '', }}
                      })
                    }
                  }}>
                  <div className={`card-content`}>
                    <div className="photo-profile-container">
                      <img className="photo-profile" src={item.photo ? item.photo : Images.defaultPhoto} />
                    </div>
                    <div className="text-container">
                      <p className="name-text">{item.firstname} {item.lastname}</p>
                      <p className="phone-text">{item.prefixCountryNum} {item.phone}</p>
                    </div>
                  </div>
                  <div>
                    <img className="check-image" src={userSelected.uid === item.uid ? Images.checkActive : Images.checkInactive} />
                  </div>
                </div>
              )
            }),
            <>
              <p className="text">The Caregiver / Next-of-Kin is my</p>
              {
                renderInputDropdown({
                  showDropdown: relationship.showDropdown,
                  dropdownData: relationshipList('DEMENTIA'),
                  dropdownPropertyName: 'name',
                  onClick: () => onPressDropdown('relationship'),
                  onSelectDropdown: ({ name, }) => { 
                    name === `Don't Filter` ? onSelectDataDropdown(`relationship`, ``) : onSelectDataDropdown(`relationship`, name)
                  },
                  value: relationship.value,
                  placeholder: relationship.placeholder,
                })
              }
            </>
          ]
        }
        <Row justify='end' style={{ marginTop: '30px' }}>
          <Col md={6}>
            <Button
              text="Discard"     
              backgroundColor={Colors.primaryBgColor}    
              textColor={Colors.color11}
              bordered
              buttonStyle={`border-radius: 15px`} 
              onClick={() => {
                dialogProps.isClose()
                setSearchBy('name')
                setInputValue('')
                setUsers({
                  loading: false,
                  data: [],
                })
                setDropdowns({
                  relationship: {
                    showDropdown: false,
                    value: '',
                  },
                })
                setSearched(false)
                setUserSelected({})
                setInputValue('')
              }}
            />
          </Col>
          <Col md={6}>
            <Button
              text={users.loading && !searched ? "Searching..." : !users.loading && searched ? "Add" : "Search"}
              disabled={
                users.loading || 
                inputValue === '' || 
                ( 
                  searched &&
                  (
                    ((_.isEmpty(userSelected))) || 
                    (dropdowns.relationship.value === '')
                  ) 
                ) || 
                loadingTag}
              backgroundColor={Colors.color10}   
              buttonStyle={`border-radius: 15px`} 
              onClick={() => (searched && !_.isEmpty(userSelected)) ? onTagAccount({ user: userSelected, successTagMessage: `Your Caregiver / Next-of-Kin has been successfully added!`, relationship: dropdowns.relationship.value}) : searchUser()}
            />
          </Col>
        </Row>
      </StyledDialogTag>
    </Dialog>
  )
}

TagCaregiver.propTypes = {
  dialogProps: PropTypes.any,
  otherAccount: PropTypes.array,
  onTagAccount: PropTypes.func,
  loadingTag: PropTypes.bool,
}

TagCaregiver.defaultProps = {
  dialogProps: () => null,
  otherAccount: [],
  onTagAccount: () => null,
  loadingTag: false,
}

export default TagCaregiver

