import styled, { css } from 'styled-components';
import { Colors, Fonts, Scale, Images } from '../../themes';

const color = {
    purple: '#502773'
}

const StyledLongCard = styled.div`
    height: 299px;
    width: 90%;
    background-color: ${props => props.purple ? '#502773' : 'white'};
    margin: 2rem auto 0;
    border-radius: 15px;
    position: relative;

    .calendar-comp {
        padding: 2rem 1.5rem;
        display: flex;
    }

    .xax {
        margin: 0.5rem 0 0 3rem;
        font-weight: bold;
        font-size: 24px;
        color: ${color.purple}
    }

    .date-range {
        cursor: pointer;
        border-radius: 10px;
        padding: 0.6rem 1rem;
        border: 1px solid ${color.purple};
        width: 160px
    }
`

export default StyledLongCard