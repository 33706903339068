import React from 'react';
import Modal from 'react-awesome-modal';
import DatePicker from "react-datepicker";
import styled from "styled-components";
import moment from 'moment'

import Input from '../input';
import Button from '../button';
import {StyledFilter} from './StyledFilter';
import close from '../../assets/images/close.png';
import { Row, Col } from 'react-grid-system';

import {Colors, Fonts} from '../../themes'
import "react-datepicker/dist/react-datepicker.css";



const CustomInput = React.forwardRef((props, ref) => {
  return (
    <CustomDatePickDiv>
      <Row>
        <label onClick={props.onClick} ref={ref}>
          <Col>
          {props.value || props.placeholder}
          </Col> 
        </label>
      </Row>
    </CustomDatePickDiv>
  );
});

const MyContainer = ({ className, children }) => {
  return (
    <div style={{ marginLeft: "180px"}}>
      <CustomDatePickDiv className={className}>
        <div style={{ position: "relative" }}>{children}</div>
      </CustomDatePickDiv>
    </div>
  );
};

const MyContainer2 = ({ className, children }) => {
  return (
    <div style={{ marginLeft: "400px"}}>
      <CustomDatePickDiv className={className}>
        <div style={{ position: "relative" }}>{children}</div>
      </CustomDatePickDiv>
    </div>
  );
};

const CustomDatePickDiv = styled.div`
  background-color: white;
  border-radius: 10px;
  label{
    padding: 1px 35px 5px 5px;
    font-family: ${Fonts.FontRegular};
    font-weight: bold;
    color: ${Colors.color2};
    resize: none;
    ${Fonts.size.size20}}
  }
`;

export default class DialogFilterTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDate: "",
      endDate: "",
      date: "",
      dateNow: "",
    }
  }

  componentDidUpdate(prevState) {
    const {startDate, endDate} = this.props.data
    const {startDate: sd, endDate: ed} = this.state;

    if(startDate !== prevState.startDate) {
      this.setState({
        startDate: startDate
      })
    }

    if(endDate !== prevState.endDate) {
      this.setState({
        endDate: endDate
      })
    }

    if (new Date(sd) > new Date(ed)) {
      this.setState({endDate: sd})
    }

    if (sd === '' && ed) {
      this.setState({startDate: ed})
    }

    if (sd && ed === '') {
      this.setState({endDate: sd})
    }

  }

  _cekCallback() {
    if (this._flag) return

    this._flag = setTimeout(() => {
      const {startDate, endDate} = this.state
      this.props.form({ startDate, endDate})
      this._flag = null
    }, 500)
  }

  applyDate = async () => {
    const {startDate, endDate} = this.state
    await this.props.form({startDate, endDate})
    this.props.isClose()
    this.props.onClickApply()
  }

  render() {
    const {haveDate, onClickApply} = this.props
    const {startDate, endDate} = this.state
    // this._cekCallback()

    console.log(this.state.startDate, this.state.endDate)

    return (
      <StyledFilter>
        <Modal 
          visible={this.props.isVisible} 
          width="400px"
          effect="fadeInUp" 
          onClonClickAway={this.props.isClose}
        >
          <div className="" style={{overflow: 'auto', padding: "20px 25px 20px 25px"}}>
            <div style={{display:'flex', justifyContent: 'space-between'}}>
              <p className="title" style={{padding: 0}} >Choose Date</p>
              <div style={{width: "20px", height: "20px", borderRadius: "10px", backgroundColor: "#c4b2cf" }} onClick={this.props.isClose}>
                <p style={{cursor: 'pointer'}} className="close">X</p>
              </div>
            </div>

            <div style={{display: "flex", justifyContent: "space-between"}}>
              <p className="labelTitle">Last Updated Date</p>
              <div style={{display: "flex"}} onClick={() => this.setState({startDate:"", endDate: ""}) }>
                <p className="allTime">All Time</p>
                <div style={{width: "15px", height: "15px", borderRadius: '7.5px', borderWidth: 1, borderColor: "#502773", backgroundColor: "#502773", opacity: startDate !== "" || endDate !== "" ? 0.3 : 0.9, marginTop: "20px"}}>
                </div>
              </div>
            </div>

            <div style={{display: "flex", justifyContent: "space-between", marginBottom: "25px"}}>
              <div>
                <p className="label">Start Date</p>
                <Row className="input" style={{margin:'auto', border: '1px solid #B1C2D8', boxSizing: 'border-box', borderRadius: '7px'}}>
                  <DatePicker showMonthDropdown showYearDropdown customInput={<CustomInput />} filterDate = {(date) => {return moment() > date;}} placeholderText="dd-mm-yyyy"  dateFormat="dd-MM-yyyy" selected={this.state.startDate ? new Date(this.state.startDate) : null} 
                        onChange={date => { this.setState({ startDate:date }) }} showPopperArrow={false} dropdownMode="select" popperPlacement="bottom-end" calendarContainer={MyContainer}/>
                </Row>
              </div>
              <div>
                <p className="label">End Date</p>
                <Row className="input" style={{margin:'auto', border: '1px solid #B1C2D8', boxSizing: 'border-box', borderRadius: '7px'}}>
                  <DatePicker required showMonthDropdown showYearDropdown customInput={<CustomInput />} filterDate = {(date) => {return moment() > date;}} placeholderText="dd-mm-yyyy"  dateFormat="dd-MM-yyyy" selected={this.state.endDate ? new Date(this.state.endDate) : null} 
                        minDate={ startDate ? new Date(startDate): null}
                        onChange={date => { this.setState({endDate: date })}} showPopperArrow={false} dropdownMode="select" popperPlacement="bottom-end" calendarContainer={MyContainer2} />
                </Row>
              </div>
            </div>

            <Button
              text="Apply"
              textColor={Colors.color3}
              buttonStyle={` font-weight: bold; height: 40px; display: flex; justify-content: space-around; `}
              onClick={this.applyDate}
            />
          </div>
        </Modal>
      </StyledFilter>
    )
  }
}