import Swal from 'sweetalert2'
import styled from "styled-components"; 

import { Colors, Fonts, Scale } from '../../themes';

const styledTitle = styled.span`
  ${Fonts.FontRegular}
  font-size: 20px;
`

export  const alertError = ({ text, cb, }) => {
  Swal.fire({
    title: 'Error!',
    text,
    icon: 'error',
    confirmButtonText: 'OK',
  }).then(() => {
    cb && cb()
  })
}

export const alert = ({ icon, title, preConfirm = () => null, text, exit = false }) => {
  Swal.fire({
    title,
    text,
    icon,
    confirmButtonText: 'OK',
    allowOutsideClick: false,
    allowEscapeKey: false,
    allowEnterKey: false,
    preConfirm,
  })
}