import React, { useState, useEffect, } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Hidden, } from 'react-grid-system';
import DatePicker from "react-datepicker";
import styled from "styled-components";
import moment from 'moment'

// import {  } from './StyledDialogFilterUser'
import { StyledFilterUser } from './filterUserStyle'

import Input from '../input'
import Button from '../button'
import Dialog from '../modal'

import { Colors, Images, Fonts } from '../../themes'
import { filter } from 'ramda';

const InputTypeStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  max-width: 100%;
  .input-field { cursor: pointer; }
`;

const checkboxDefaultData = [
  {
    name: 'First Name',
    params: 'firstname',
  },
  { 
    name: 'Last Name',
    params: 'lastname',
  },
  { 
    name: 'Full Name',
    params: 'fullname'
  },
  {
    name: 'NRIC',
    params: 'lastNric',
  },
  {
    name: 'Phone Number',
    params: 'phone',
  },
  {
    name: 'Email',
    params: 'email',
  },
  {
    name: 'Membership ID',
    params: 'membershipId',
  },
  {
    name: 'Address',
    params: 'address',
  },
]

const typeOfDementiaData = [
  {
    id: 6,
    name: `Don't Filter`,
  },
  {
    id: 1,
    name: `Alzheimer's Disease`,
  },
  {
    id: 2,
    name:  'Vascular Dementia',
  },
  {
    id: 3,
    name:  'Frontotemporal Dementia (FTD)',
  },
  {
    id: 4,
    name:  'Lewy Body Dementia (LBD)',
  },
  {
    id: 5,
    name:  'Mild Cognitive Impairment (MCI)',
  },
]

const languageData = [
  {
    id: 6,
    name: `Don't Filter`
  },
  {
    id: 1,
    name: 'English',
  },
  {
    id: 2,
    name:  'Mandarin',
  },
  {
    id: 3,
    name:  'Malay',
  },
  {
    id: 4,
    name:  'Tamil',
  },
  {
    id: 5,
    name:  'Hokkien',
  },
]

const genderData = [
  {
    id: 6,
    name: `Don't Filter`
  },
  {
    id: 1,
    name: 'Male',
  },
  {
    id: 2,
    name:  'Female',
  },
]

const accountStatusData = [
  {
    id: 8,
    name: `Don't Filter`
  },
  {
    id: 1,
    name: `INCOMPLETE`
  },
  {
    id: 2,
    name: `COMPLETED`
  },
  {
    id: 3,
    name: `PENDING_APPROVAL`
  },
  {
    id: 4,
    name: `PENDING_CARDS`
  },
  {
    id: 5,
    name: `REJECTED`
  },
  {
    id: 6,
    name: `EXPIRED`
  },
  {
    id: 7,
    name: `TO_FOLLOW_UP`
  }
]

const allApplicantStatus = [
  {
    id: 6,
    name: `Don't Filter`,
    label: `Don't Filter`
  },
  {
    id: 1,
    name: `INCOMPLETE`,
    label: 'New User'
  },
  {
    id: 2,
    name: `PENDING_APPROVAL`,
    label: 'Pending approval'
  },
  {
    id: 3,
    name: `REJECTED`,
    label: 'Rejected'
  },
  {
    id: 4,
    name: `EXPIRED`,
    label: 'Expired'
  },
  {
    id: 5,
    name: `TO_FOLLOW_UP`,
    label: 'To Follow-up'
  }
]

const allAccountStatus = [
  {
    id: 3,
    name: `Don't Filter`,
    label: `Don't Filter`
  },
  {
    id: 1,
    name: `COMPLETED`,
    label: 'Completed'
  },
  {
    id: 2,
    name: `PENDING_CARDS`,
    label: 'Pending cards'
  }
]

const InputReadOnlyStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin: 10px 0 25px 0; 
  .input-field { cursor: pointer; }
`;

function DialogFilterUser(props) {
  const { 
    dialogProps, 
    checkboxData, 
    onSelectCheckbox, 
    onSelectDropdown, 
    settedFilterBy, 
    onApply, 
    additionalFilter,
    onCheckFilter, 
    onSetAdditionalFilter,
    showDropdownFilter,
    listBy,
    dateFilter,
    onChangeStartDate,
    onChangeEndDate
  } = props
  
  const [dropdowns,setDropdowns] = useState({
    typeDementia: {
      showDropdown: false,
      value: '',
      text: 'Type of Dementia'
    },
    gender: {
      showDropdown: false,
      value: '',
      text: 'Gender'
    },
    language: {
      showDropdown: false,
      value: '',
      text: 'Preferred Language'
    },
    accountStatus: {
      showDropdown: false,
      value: '',
      text: 'Status'
    },
    signupdate: {
      showDropdown: false,
      value: '',
      text: 'Sign Up Date'
    },
  });

  const [filterBy, setFilterBy] = useState([])
  const [showDate, setShowDate] = useState(false)
  const [dateFilterTemp, setDateFilterTemp] = useState({
    startDate: null,
    endDate: null
  })

  const [settedDropdown, setSettedDropdown] = useState(false)

  const [inCheckbox, setInCheckbox] = useState(checkboxData)

  const onCheck = (value) => {
    onCheckFilter(value)
    setFilterBy(state => {
      const currentFilterBy = [...state]
      
      if (!currentFilterBy.includes(value.params)) {          //checking weather array contain the id
        currentFilterBy.push(value.params);               //adding to array because value doesnt exists
      } else {
        currentFilterBy.splice(currentFilterBy.indexOf(value.params), 1);  //deleting
      }
      
      return currentFilterBy
    })
  }  

  const renderInputCheckbox = (value) => {
    const { name, params } = value
    const checkFilter = filterBy.filter(el=> el === params)
    
    return (
      <Button
        text={name}    
        textColor={Colors.color2}
        borderColor={ checkFilter.length > 0 ? Colors.color36 : Colors.color20 }
        bordered
        iconRight={true}
        iconRightStyle={`width: 15px; height: 15px; margin: 0 0 0 15px`}
        buttonStyle={` font-weight: bold; max-width: 170px; height: 40px; display: flex; margin: 0 10px 0 0; padding: 0 10px `}
        backgroundColor={'transparent'}
        iconRightImage={ checkFilter.length > 0 ? Images.checkActive : Images.checkInactive}
        onClick={() => onCheck(value)}
      />
    );
  }

  const CustomInput = React.forwardRef((props, ref) => {
    return (
      <CustomDatePickDiv>
        <Row>
          <label onClick={props.onClick} ref={ref}>
            <Col>
            {props.value || props.placeholder}
            </Col> 
          </label>
        </Row>
      </CustomDatePickDiv>
    );
  });

  const MyContainer = ({ className, children }) => {
    return (
      <div style={{ marginLeft: "180px"}}>
        <CustomDatePickDiv className={className}>
          <div style={{ position: "relative" }}>{children}</div>
        </CustomDatePickDiv>
      </div>
    );
  };

  const CustomDatePickDiv = styled.div`
    background-color: white;
    border-radius: 10px;
    label{
      padding: 5px;
      font-family: ${Fonts.FontRegular};
      font-weight: bold;
      color: ${Colors.color2};
      resize: none;
      ${Fonts.size.size20}}
    }
  `;

  const renderInputDropdown = (props) => {
    const { value, onClick, placeholder, dropdownData} = props;
    let filtered = true
    
    let isTextValid 

    if(dropdownData[0].label) {
      isTextValid = dropdownData.map(item => item.label).includes(props.text);
    } else {
      isTextValid = dropdownData.map(item => item.name).includes(props.text);
    }

    if(isTextValid === false) {
      props.borderColor = Colors.color20

      const statusValue = ['COMPLETED', 'INCOMPLETE', 'PENDING_APPROVAL', 'PENDING_CARDS', 'REJECTED', 'EXPIRED', 'TO_FOLLOW_UP']
      if(statusValue.includes(props.text)) props.text = 'Status'
    }  

    return (
      <Button
        {...props}   
        sortPropertyName={dropdownData[0]?.label ? 'label' : 'name'}
        textColor={Colors.color2}
        bordered
        iconRight
        iconRightStyle={`width: 15px; height: 15px; margin: 0 0 0 15px`}
        buttonStyle={` font-weight: bold; max-width: 200px; height: 40px; display: flex; margin: 10px 10px 10px 0; padding: 0 10px `}
        backgroundColor={'transparent'}
      />
    );
  }

  const applyDateFilter = async () => {
    await onChangeStartDate(dateFilterTemp?.startDate ? dateFilterTemp?.startDate : dateFilter?.startDate)
    await onChangeEndDate(dateFilterTemp?.endDate ? dateFilterTemp?.endDate : dateFilter?.endDate)
    setDateFilterTemp({
     startDate: null,
     endDate: null
    })
    setShowDate(false)
  } 

  const renderDateDropdown = (props) => {
    const { value, onClick, placeholder, dropdownData} = props;

    return (
      <div style={{position: 'relative'}}>
          <Button
            {...props}   
            text={dateFilter.startDate && dateFilter.endDate ? `${moment(dateFilter.startDate).format('DD/MM/YYYY')} - ${moment(dateFilter.endDate).format('DD/MM/YYYY')}` : props.text}
            textColor={Colors.color2}
            bordered
            borderColor={dateFilter.startDate && dateFilter.endDate ? Colors.color36 : Colors.color20}
            iconRight
            iconRightStyle={`width: 15px; height: 15px; margin: 0 0 0 15px`}
            buttonStyle={` font-weight: bold; max-width: auto; height: 40px; display: flex; margin: 10px 10px 10px 0; padding: 0 10px `}
            backgroundColor={'transparent'}
            onClick={() => setShowDate(!showDate)}
          />
          {
            showDate && (
              <div style={{
                width: '300px',
                height: '210px',
                backgroundColor: '#f7f7f7',
                position: 'absolute',
                zIndex: '999',
                borderRadius: '15px',
                padding: '20px',
                border: '1px solid lightgrey'
              }}>
                <div style={{display: 'flex', justifyContent: 'space-between', marginBottom: '20px'}}>
                  <div>
                    <p style={{color: Colors.color11, fontSize: '20px', fontWeight: '800'}}>Choose Date</p>
                  </div>
                  <div 
                    style={{cursor: 'pointer'}}
                    onClick={emptyDateFilter}
                  >
                    <img 
                      src={Images.iconClose2}
                      style={{
                        width: '20px',
                        height: '20px'
                      }}
                    />
                  </div>
                </div>
                <div style={{marginBottom: '20px'}}>
                  <p style={{color: Colors.color2, fontSize: '14px', fontWeight: '800'}}>Sign Up Date</p>
                </div>
                <div style={{display: 'flex', justifyContent: 'space-between'}}>
                  <div>
                    <p style={{color: Colors.color19, fontSize: '14px', fontWeight: '700', marginBottom: '10px'}}>Start Date</p>
                    <div style={{backgroundColor: 'white', width:'120px', height: '30px', border: '1px solid lightgrey', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <DatePicker 
                        showMonthDropdown 
                        showYearDropdown 
                        customInput={<CustomInput />} 
                        filterDate = {(date) => {return moment() > date;}} 
                        placeholderText="dd-mm-yyyy"  
                        dateFormat="dd-MM-yyyy" 
                        selected={dateFilterTemp?.startDate ? new Date(dateFilterTemp.startDate) : dateFilter?.startDate ? new Date(dateFilter?.startDate) : null} 
                        onChange={date => { setDateFilterTemp({ ...dateFilterTemp, startDate: moment(date).format('YYYY-MM-DD') }) }} showPopperArrow={false} dropdownMode="select" popperPlacement="bottom-end" calendarContainer={MyContainer}
                      />
                    </div>
                  </div>
                  <div>
                    <p style={{color: Colors.color19, fontSize: '14px', fontWeight: '700', marginBottom: '10px'}}>End Date</p>
                    <div style={{backgroundColor: 'white', width:'120px', height: '30px', border: '1px solid lightgrey', borderRadius: '10px', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                      <DatePicker 
                        showMonthDropdown 
                        showYearDropdown 
                        customInput={<CustomInput />} 
                        filterDate = {(date) => {return moment() > date;}} 
                        minDate={dateFilterTemp?.startDate ? moment(dateFilterTemp.startDate).add(1, 'days').toDate() : dateFilter?.startDate ? moment(dateFilter.startDate).add(1, 'days').toDate() : null}
                        placeholderText="dd-mm-yyyy"  
                        dateFormat="dd-MM-yyyy" 
                        selected={dateFilterTemp?.endDate ? new Date(dateFilterTemp.endDate) : dateFilter?.endDate ? new Date(dateFilter?.endDate) : null} 
                        onChange={date => { setDateFilterTemp({ ...dateFilterTemp, endDate: moment(date).format('YYYY-MM-DD') }) }} showPopperArrow={false} dropdownMode="select" popperPlacement="bottom-end" calendarContainer={MyContainer}
                      />
                    </div>
                  </div>
                </div>
                <div style={{marginTop: '20px'}}>
                  <Button
                   text="Apply"
                   buttonStyle={{height: '20px'}}
                   disabled={(dateFilterTemp?.startDate === null || dateFilterTemp?.endDate === null) && (dateFilter?.startDate === null || dateFilter?.endDate === null)}
                   onClick={applyDateFilter}
                  />
                </div>
              </div>
            )
          } 
      </div>
    );
  }

  const onPressDropdown = (stateName) => {
    setDropdowns(state => {
      return { ...state, [stateName]: { ...dropdowns[stateName], showDropdown: !dropdowns[stateName].showDropdown, }}
    })
  };

  const onSelectDataDropdown = (stateName, value, label) => {
    setDropdowns(state => {
      onSetAdditionalFilter({ [stateName]: value })
      return { ...state, [stateName]: { ...dropdowns[stateName], value: label ? label : value, showDropdown: !dropdowns[stateName].showDropdown, }}
    })
    
    setSettedDropdown(true)
  }

  const emptyDateFilter = async () => {
    setDateFilterTemp({
      startDate: null,
      endDate: null
    })
    await onChangeStartDate(null)
    await onChangeEndDate(null)

    setShowDate(false)
  }

  const { typeDementia, gender, language, accountStatus, signupdate } = dropdowns
  const show = true
  return (
      <StyledFilterUser>
          <div style={{display: 'flex', marginLeft: '-15px'}}>
            {
              checkboxData.map(item => {
              const { name, data } = item
              return (
                  renderInputCheckbox(item)
              )
              })
            }
          </div>
          <div style={{display: 'flex', marginLeft: '-15px'}}>
          {
            showDropdownFilter.typeDementia &&
              renderInputDropdown({
                showDropdown: typeDementia.showDropdown,
                dropdownData: typeOfDementiaData,
                styleHoverDropdown: `background-color: ${Colors.color14}; color: ${Colors.color3}`,
                text: typeDementia.value === ''? 'Type of Dementia': typeDementia.value,
                borderColor: typeDementia.value === ''? Colors.color20: Colors.color36,
                dropdownWidth: `180px`,
                onClick: () => onPressDropdown('typeDementia'),
                onSelectSort: ({ name, }) => { 
                  name === `Don't Filter` ? onSelectDataDropdown(`typeDementia`, ``) : onSelectDataDropdown(`typeDementia`, name)
                },
                value: typeDementia.value,
                placeholder: typeDementia.placeholder,
              })
          }
          {
            showDropdownFilter.gender &&
              renderInputDropdown({
                showDropdown: gender.showDropdown,
                dropdownData: genderData,
                styleHoverDropdown: `background-color: ${Colors.color14}; color: ${Colors.color3}`,
                text: gender.value === ''? 'Gender': gender.value,
                borderColor: gender.value === ''? Colors.color20: Colors.color36,
                dropdownWidth: `108px`,
                onClick: () => onPressDropdown('gender'),
                onSelectSort: ({ name, }) => { 
                  name === `Don't Filter` ? onSelectDataDropdown(`gender`, ``) : onSelectDataDropdown(`gender`, name)
                },
                value: gender.value,
                placeholder: gender.placeholder,
              })
          }
          { 
            showDropdownFilter.language &&
              renderInputDropdown({
                showDropdown: language.showDropdown,
                dropdownData: languageData,
                styleHoverDropdown: `background-color: ${Colors.color14}; color: ${Colors.color3}`,
                borderColor: language.value === ''? Colors.color20: Colors.color36,
                text: language.value === ''? 'Preferred Language': language.value,
                dropdownWidth: `120px`,
                onClick: () => onPressDropdown('language')
                ,
                onSelectSort: ({ name, }) => { 
                  name === `Don't Filter` ? onSelectDataDropdown(`language`, ``) : onSelectDataDropdown(`language`, name)
                },
                value: language.value,
                placeholder: language.placeholder,
              })     
          }
          { 
            showDropdownFilter.accountStatus &&
              renderInputDropdown({
                showDropdown: accountStatus.showDropdown,
                dropdownData: props.listBy === 'ALL_ACCOUNTS' ? allAccountStatus : props.listBy === 'ALL_APPLICANTS' ? allApplicantStatus : accountStatusData,
                styleHoverDropdown: `background-color: ${Colors.color14}; color: ${Colors.color3}`,
                borderColor: accountStatus.value === ''? Colors.color: Colors.color36,
                text: accountStatus.value === ''? 'Status': accountStatus.value,
                dropdownWidth: `200px`,
                onClick: () => onPressDropdown('accountStatus')
                ,
                onSelectSort: ({ name, label }) => { 
                  name === `Don't Filter` ? onSelectDataDropdown(`accountStatus`, ``) : onSelectDataDropdown(`accountStatus`, name, label)
                }
              })     
          }
          { 
            showDropdownFilter.signupdate &&
              renderDateDropdown({
                styleHoverDropdown: `background-color: ${Colors.color14}; color: ${Colors.color3}`,
                borderColor: signupdate.value === ''? Colors.color20: Colors.color36,
                text: signupdate.value === ''? 'Sign Up Date': signupdate.value,
                dropdownWidth: `300px`,
                onClick: () => onPressDropdown('signupdate')
                ,
                onSelectSort: ({ name, }) => { 
                  name === `Don't Filter` ? onSelectDataDropdown(`language`, ``) : onSelectDataDropdown(`language`, name)
                }
              })     
          }
          </div>
      </StyledFilterUser>
  )
}

DialogFilterUser.propTypes = {
  dialogProps: PropTypes.any,
  checkboxData: PropTypes.any,
  onSelectCheckbox: PropTypes.func,
  onSelectDropdown: PropTypes.func,
  onApply: PropTypes.func,
  settedFilterBy: PropTypes.any,
  additionalFilter: PropTypes.any,
  onCheckFilter: PropTypes.func,
  onSetAdditionalFilter: PropTypes.func,
  listBy: PropTypes.string,
  showDropdownFilter: {
    typeDementia: true,
    gender: true,
    language: true,
    accountStatus: true,
    signupdate: true
  },
  dateFilter: PropTypes.any,
  onChangeStartDate: PropTypes.func,
  onChangeEndDate: PropTypes.func
}

DialogFilterUser.defaultProps = {
  dialogProps: () => null,
  checkboxData: checkboxDefaultData,
  onSelectCheckbox: () => null,
  onSelectDropdown: () => null,
  onApply: () => null,
  settedFilterBy: [],
  additionalFilter: {},
  onCheckFilter: () => null,
  onSetAdditionalFilter: () => null,
  listBy: '',
  showDropdownFilter: {
    typeDementia: true,
    gender: true,
    language: true,
    accountStatus: true,
    signupdate: true
  },
  dateFilter: {
    startDate: null,
    endDate: null
  },
  onChangeStartDate: () => null,
  onChangeEndDate: () => null
}

export default DialogFilterUser

// inputProps={{
//     placeholder: name,
//     readOnly: true,
//     onClick: () => {
//         console.log(value)
//         valFilter = filterBy.filter(el=> el.name === value.name).length
//         // console.log(filterBy)
//         console.log(valFilter)
//         if (valFilter.length <= 0){
//             setFilterBy([...filterBy,{ name: value.name, value: value.data.value }])
//             onApply(filterBy, { typeDementia: dropdowns.typeDementia.value, gender: dropdowns.gender.value, language: dropdowns.language.value })
//             console.log(filterBy)
//         }
//         if (valFilter > 0 ) {
//             let newFilter = filterBy.filter(el=> {
//                 el.name != value.name
//             })
//             setFilterBy([...filterBy, ...newFilter])
//             onApply(filterBy, { typeDementia: dropdowns.typeDementia.value, gender: dropdowns.gender.value, language: dropdowns.language.value })
//         }
//     }
//   }}
//   containerStyle={`
//     ${InputTypeStyle}
//   `}
//   iconRight={onCheck}
//   iconRightStyle={{ width: '15px' }}




{/* <Input
          inputProps={{
            readOnly: true,
            onClick: filtered ? onClick : null,
            value,
            placeholder,
          }}
          containerStyle={`
            ${InputReadOnlyStyle}
            .input-field {
              cursor: ${filtered ? 'pointer' : 'not-allowed'}
            }
            input {
              color: ${filtered ? Colors.color2 : Colors.color34} !important;
            }
          `}
          iconRight={Images.polygonYellow}
          {...props}
          dropdownWidth={'100%'}
        /> */}
