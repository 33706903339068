import React, { useEffect, useState } from "react";
import { connect } from "react-redux";

import ProccessData from "../lib/ProccessData";

import Loading from "../components/loading";

import AuthActions from "../redux/AuthRedux";
import StartupActions from "../redux/StartupRedux";

let verified = false;

export const CheckToken = ({
  account,
  verifyToken,
  initToken,
  history,
  match,
  signout,
}) => {
  useEffect(() => {
    if (!verified && !account.payload) {
      const { token } = match.params;
      verifyToken({ partner: true, token: `bearer ${token}` });
      verified = true;
    }

    ProccessData(account, "check partner token")
      .then((res) => {
        if (res.type === "SUCCESS" && res.data.account) {
          initToken();
        } else {
          signout();
          alert(res);
        }
        history.replace("/");
      })
      .catch((err) => {
        signout();
        alert(err);
        history.replace("/");
      });
  }, [account]);

  return (
    <div>
      <Loading visible />
    </div>
  );
};

const mapStateToProps = (state) => ({
  account: state.auth.account,
});

const mapDispatchToProps = (dispatch) => {
  return {
    verifyToken: (params) => dispatch(AuthActions.verifyTokenRequest(params)),
    initToken: () => dispatch(StartupActions.initToken()),
    signout: () => dispatch(AuthActions.signout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CheckToken);
