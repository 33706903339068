import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getSafeReturnRequest: ['data'],
  getSafeReturnSuccess: ['payload'],
  getSafeReturnFailure: ['error'],
})

export const SafeReturnTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: { fetching: null, error: null, payload: null, data: null },
})

/* ------------- Selectors ------------- */

export const SafeReturnSelectors = {
  SafeReturn: state => state.SafeReturn.list,
}

/* ------------- Reducers ------------- */

export const getSafeReturnRequest = (state, { data }) => {
  return state.merge({ ...state, list: { ...state.list, fetching: true, data, }})
}

export const getSafeReturnSuccess = (state, { payload }) => {
  return state.merge({ ...state, list: {  ...state.list, fetching: false, error: false, payload, }})
}

export const getSafeReturnFailure = (state, { error }) => {
  return state.merge({ ...state, list: { ...state.list, fetching: false, error, }})
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_SAFE_RETURN_REQUEST]: getSafeReturnRequest,
  [Types.GET_SAFE_RETURN_SUCCESS]: getSafeReturnSuccess,
  [Types.GET_SAFE_RETURN_FAILURE]: getSafeReturnFailure,
})
