import React, { useState, } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Hidden, } from 'react-grid-system';

import { StyledDialogFilterUser } from './StyledDialogFilterUser'

import Dialog from '../modal'
import Input from '../input'
import Button from '../button'

import { Colors, Images, } from '../../themes'

const InputTypeStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  .input-field { cursor: pointer; }
`;

const checkboxDefaultData = [
  {
    name: 'First Name',
    data: { name: '', value: 'firstname' }
  },
  { 
    name: 'Last Name',
    data: { name: '', value: 'lastname' }
  },
  { 
    name: 'Full Name',
    data: { name: '', value: 'fullname' }
  },
  {
    name: 'NRIC',
    data: { name: '', value: 'lastNric' }
  },
  {
    name: 'Phone Number',
    data: { name: '', value: 'phone' }
  },
  {
    name: 'Email',
    data: { name: '', value: 'email' }
  },
  {
    name: 'Membership ID',
    data: { name: '', value: 'membershipId' }
  },
  {
    name: 'Address',
    data: { name: '', value: 'address' }
  },
]

const typeOfDementiaData = [
  {
    id: 6,
    name: `Don't Filter`,
  },
  {
    id: 1,
    name: `Alzheimer's Disease`,
  },
  {
    id: 2,
    name:  'Vascular Dementia',
  },
  {
    id: 3,
    name:  'Frontotemporal Dementia (FTD)',
  },
  {
    id: 4,
    name:  'Lewy Body Dementia (LBD)',
  },
  {
    id: 5,
    name:  'Mild Cognitive Impairment (MCI)',
  },
]

const languageData = [
  {
    id: 6,
    name: `Don't Filter`
  },
  {
    id: 1,
    name: 'English',
  },
  {
    id: 2,
    name:  'Mandarin',
  },
  {
    id: 3,
    name:  'Malay',
  },
  {
    id: 4,
    name:  'Tamil',
  },
  {
    id: 5,
    name:  'Hokkien',
  },
]

const genderData = [
  {
    id: 6,
    name: `Don't Filter`
  },
  {
    id: 1,
    name: 'Male',
  },
  {
    id: 2,
    name:  'Female',
  },
]

const InputReadOnlyStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin: 10px 0 25px 0; 
  .input-field { cursor: pointer; }
`;

const defaultFilter = { name: 'First Name', value: 'firstname', }
function DialogFilterUser(props) {
  const { dialogProps, checkboxData, onSelectCheckbox, onSelectDropdown, settedFilterBy, onApply, additionalFilter, } = props
  
  const [dropdowns,setDropdowns] = useState({
    typeDementia: {
      showDropdown: false,
      value: '',
      placeholder: 'Type of Dementia'
    },
    gender: {
      showDropdown: false,
      value: '',
      placeholder: 'Gender'
    },
    language: {
      showDropdown: false,
      value: '',
      placeholder: 'Preferred Language'
    },
  });

  const [filterBy, setFilterBy] = useState({ ...defaultFilter })

  const [settedDropdown, setSettedDropdown] = useState(false)

  const renderInputCheckbox = ({
    value,
    stateName = 'accountStatus',
    md = 6,
    filtered = true,
    data,
  }) => {
    // const {
    //   accountStatus,
    // } = this.state;
    return (
      <Col md={md}>
        <Input
          inputProps={{
            value,
            readOnly: true,
            onClick: () => {
              setFilterBy({ name: value, value: data.value })
            }
          }}
          containerStyle={`
            ${InputTypeStyle}
          `}
          iconRight={
            filterBy.name === value ?
              Images.checkActive
            : 
              Images.checkInactive
          }
          iconRightStyle={{ width: '15px' }}
        />
      </Col>
    );
  }

  const renderInputDropdown = (props) => {
    const { value, onClick, placeholder, } = props;
    let filtered = true

    return (
      <Col md={6}>
        <Input
          inputProps={{
            readOnly: true,
            onClick: filtered ? onClick : null,
            value,
            placeholder,
          }}
          containerStyle={`
            ${InputReadOnlyStyle}
            .input-field {
              cursor: ${filtered ? 'pointer' : 'not-allowed'}
            }
            input {
              color: ${filtered ? Colors.color2 : Colors.color34} !important;
            }
          `}
          iconRight={Images.polygonYellow}
          {...props}
          dropdownWidth={'100%'}
        />
      </Col>
    );
  }

  const onPressDropdown = (stateName) => {
    setDropdowns(state => {
      return { ...state, [stateName]: { ...dropdowns[stateName], showDropdown: !dropdowns[stateName].showDropdown, }}
    })
  };

  const onSelectDataDropdown = (stateName, value) => {
    setDropdowns(state => {
      return { ...state, [stateName]: { ...dropdowns[stateName], value, showDropdown: !dropdowns[stateName].showDropdown, }}
    })
    setSettedDropdown(true)
  }

  const { typeDementia, gender, language, } = dropdowns
  
  return (
    <Dialog
      {...dialogProps}
      isClose={() => {
        setFilterBy(settedFilterBy ? { ...settedFilterBy, } : { ...defaultFilter })
        settedDropdown && setDropdowns(state => {
          return { 
            ...state, 
            typeDementia: { ...dropdowns['typeDementia'], value: additionalFilter.typeDementia, },
            gender: { ...dropdowns['gender'], value: additionalFilter.gender, },
            language: { ...dropdowns['language'], value: additionalFilter.language, }
          }
        })
        setSettedDropdown(false)
        dialogProps.isClose()
      }}
      width="50%"
    >
      <StyledDialogFilterUser>
        <Row>
            {
              [
                checkboxData.map(item => {
                  const { name, data } = item
                  return (
                    renderInputCheckbox({
                      value: name,
                      data,
                    })
                  )
                }),
                renderInputDropdown({
                  showDropdown: typeDementia.showDropdown,
                  dropdownData: typeOfDementiaData,
                  dropdownPropertyName: 'name',
                  onClick: () => onPressDropdown('typeDementia'),
                  onSelectDropdown: ({ name, }) => { 
                    name === `Don't Filter` ? onSelectDataDropdown(`typeDementia`, ``) : onSelectDataDropdown(`typeDementia`, name)
                  },
                  value: typeDementia.value,
                  placeholder: typeDementia.placeholder,
                }),
                renderInputDropdown({
                  showDropdown: gender.showDropdown,
                  dropdownData: genderData,
                  dropdownPropertyName: 'name',
                  onClick: () => onPressDropdown('gender'),
                  onSelectDropdown: ({ name, }) => { 
                    name === `Don't Filter` ? onSelectDataDropdown(`gender`, ``) : onSelectDataDropdown(`gender`, name)
                  },
                  value: gender.value,
                  placeholder: gender.placeholder,
                }),
                renderInputDropdown({
                  showDropdown: language.showDropdown,
                  dropdownData: languageData,
                  dropdownPropertyName: 'name',
                  onClick: () => onPressDropdown('language'),
                  onSelectDropdown: ({ name, }) => { 
                    name === `Don't Filter` ? onSelectDataDropdown(`language`, ``) : onSelectDataDropdown(`language`, name)
                  },
                  value: language.value,
                  placeholder: language.placeholder,
                })
              ]
            }
        </Row>
        <Row justify='end' style={{ marginTop: '30px' }}>
          <Col md={4}>
            <Button
              text="Cancel"     
              backgroundColor={Colors.color22}    
              textColor={Colors.color21}
              buttonStyle={`box-shadow: -3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color23};`} 
              onClick={() => {
                setFilterBy(settedFilterBy ? { ...settedFilterBy, } : { ...defaultFilter })
                settedDropdown && setDropdowns(state => {
                  return { 
                    ...state, 
                    typeDementia: { ...dropdowns['typeDementia'], value: additionalFilter.typeDementia, },
                    gender: { ...dropdowns['gender'], value: additionalFilter.gender, },
                    language: { ...dropdowns['language'], value: additionalFilter.language, }
                  }
                })
                setSettedDropdown(false)
                dialogProps.isClose()
              }}  
            />
          </Col>
          <Col md={4}>
            <Button
              text="Apply"
              onClick={() => {
                onApply(filterBy, { typeDementia: dropdowns.typeDementia.value, gender: dropdowns.gender.value, language: dropdowns.language.value })
                setSettedDropdown(false)
                dialogProps.isClose()
              }}
            />
          </Col>
        </Row>
      </StyledDialogFilterUser>
    </Dialog>
  )
}

DialogFilterUser.propTypes = {
  dialogProps: PropTypes.any,
  checkboxData: PropTypes.any,
  onSelectCheckbox: PropTypes.func,
  onSelectDropdown: PropTypes.func,
  onApply: PropTypes.func,
  settedFilterBy: PropTypes.any,
  additionalFilter: PropTypes.any,
}

DialogFilterUser.defaultProps = {
  dialogProps: () => null,
  checkboxData: checkboxDefaultData,
  onSelectCheckbox: () => null,
  onSelectDropdown: () => null,
  onApply: () => null,
  settedFilterBy: null,
  additionalFilter: {},
}

export default DialogFilterUser

