import React from 'react'
import { Container, Row, Col, Hidden, } from 'react-grid-system';
import PropTypes from 'prop-types'

import { StyledFormEditCategory, Label, InputStyle, } from './StyledFormEditCategory'
import { Images, Fonts, Colors, } from '../../themes/'

import Input from '../input/'
import Button from '../button/'

function FormEditCategory({ action, history, title, titleChinese, image, imageChinese, onChangeText, onChangeImage, onChangeImageChinese, loading, onSave, fixedTitle, stateTitle, stateTitleChinese, onDiscard, }) {

  let imageInput = null
  let imageInputChinese = null

  let buttonDisabled = loading || (title === '' && titleChinese === '') || (!image && !imageChinese)
  
  return (
    <StyledFormEditCategory action={action} image={image}>
      {
        action === 'EDIT' && 
          <Row style={{ marginBottom: '50px', }}>
            <Col className="title-wrapper">
              <img src={Images.back} className="icon-back" onClick={() => history.goBack()} />
              <h1>{fixedTitle}</h1>
            </Col>
          </Row>
      }
      <p style={{ color: Colors.color19, marginTop: '-30px', marginBottom: '20px', fontSize: '26px', fontWeight: 'bold' }}>English</p>
      <Container>
        <Row>
          <Label for="name">Category Name</Label>
          <Input 
            inputProps={{ id: "name", value: title, onChange: (e) => onChangeText(stateTitle, e.target.value), maxLength: 50, }}
            containerStyle={InputStyle}
            iconRight={action === 'EDIT' ? Images.editBlue : ''} 
          />
        </Row>
      </Container>
      <div>
        <Label for="name">Category Image</Label>
        <div className="image-container">
          <img src={image ? image : Images.thumbnail} alt="category" className="category-image" />
          <Button
            text="Change images"
            buttonStyle={` margin-top: 35px; `}
            textStyle={` font-size: 13px; margin: 0 30px; font-weight: 800; `}
            onClick={() => {
              imageInput.click()
            }}
          />
          <input
            type="file"
            style={{ display: 'none' }}
            ref={input => {
              imageInput = input;
            }}
            onChange={(e) => onChangeImage(e)}
            accept="image/*"
            required
          />
        </div>
      </div>
      <hr style={{marginTop: '40px', marginBottom: '40px'}}/>
      <p style={{ color: Colors.color19, marginTop: '30px', marginBottom: '20px', fontSize: '26px', fontWeight: 'bold' }}>Mandarin</p>
      <Container>
        <Row>
          <Label for="name-chinese">Category Name</Label>
          <Input 
            inputProps={{ id: "name-chinese", value: titleChinese, onChange: (e) => onChangeText(stateTitleChinese, e.target.value), maxLength: 50, }}
            containerStyle={InputStyle}
            iconRight={action === 'EDIT' ? Images.editBlue : ''} 
          />
        </Row>
      </Container>
      <div>
        <Label for="name">Category Image</Label>
        <div className="image-container">
          <img src={imageChinese ? imageChinese : Images.thumbnail} alt="category" className="category-image" />
          <Button
            text="Change images"
            buttonStyle={` margin-top: 35px; `}
            textStyle={` font-size: 13px; margin: 0 30px; font-weight: 800; `}
            onClick={() => {
              imageInputChinese.click()
            }}
          />
          <input
            type="file"
            className="image-container"
            style={{ display: 'none' }}
            ref={input => {
              imageInputChinese = input;
            }}
            onChange={(e) => onChangeImageChinese(e)}
            accept="image/*"
            required
          />
        </div>
      </div>
      <div className="button-wrapper">
        <div style={{ width: '48%' }}>
          <Button
            text="Discard"
            textColor={Colors.color21}
            buttonStyle={` background: ${Colors.color22}; box-shadow: 3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color23}; `}
            onClick={onDiscard}
          />
        </div>
        <div style={{ width: '48%' }}> 
          <Button
            text={loading ? "Loading..." : "Save" }
            buttonStyle={` box-shadow: -3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color23}; `}
            onClick={onSave}
            disabled={buttonDisabled}
          />
        </div>
      </div>
    </StyledFormEditCategory>
  )
}

FormEditCategory.propTypes = {
  action: PropTypes.oneOf(['ADD', 'EDIT']),
  title: PropTypes.string,
  titleChinese: PropTypes.string,
  image: PropTypes.any,
  imageChinese: PropTypes.any,
  onChangeText: PropTypes.func,
  onChangeImage: PropTypes.func,
  onChangeImageChinese: PropTypes.func,
  loading: PropTypes.bool,
  onSave: PropTypes.func,
  fixedTitle: PropTypes.string,
  stateTitle: PropTypes.string,
  onDiscard: PropTypes.func,
}

FormEditCategory.defaultProps = {
  action: 'EDIT',
  title: '',
  titleChinese: '',
  image: null,
  imageChinese: null,
  onChangeText: () => null,
  onChangeImage: () => null,
  onChangeImageChinese: () => null,
  loading: false,
  onSave: () => null,
  fixedTitle: '',
  stateTitle: 'title',
  onDiscard: () => null,
}


export default FormEditCategory
