import React from 'react'

import StyledLongCard from './StyledLongCard'

function LongCard(props) {
    return (
        <StyledLongCard {...props}>
            {props.children}
        </StyledLongCard>
    )
}

export default LongCard
