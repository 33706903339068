import styled from "styled-components";
import { Col, } from 'react-grid-system';
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledDialogContent = styled.div`
  margin: 30px 14px 0;

  .image-container {
    background-color: ${Colors.color6};
    width: 100%;
    display: flex;
    padding: 48px 0 30px 0;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 10px;
    margin-bottom: 25px;
  }

  .banner-image {
    width: ${props => props.image ? '154px' : '48px'};
    height: ${props => props.image ? '154px' : '35px'};
    object-fit: contain;
  }

  .button-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }
`