import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledInput = styled.div`
  display: flex;
  width: 100%;
  background-color: ${Colors.color4};
  border-radius: 10px;
  margin: 15px 0;
  
  .input-field {  
    width: 100%;
    padding: 16px 24px;
    outline: none;
    cursor: ${props => props.dropdownData && props.dropdownData.length > 0 && 'pointer'};
  }

  input, textarea {
    background: rgba(0, 0, 0, 0);
    border: none;
    outline: none;
    font-family: NunitoSans;
    font-weight: bold;
    color: ${Colors.color};
    resize: none;
    ${Fonts.size.size16}
  }
  
  ::placeholder {
    font-family: NunitoSans;
    color: ${Colors.color5};
    ${Fonts.size.size14}
  }

  .icon-right {
    width: 11px;
    object-fit: contain;
    margin-right: 14px;
    cursor: pointer;
    ${props => props.iconRightStyle}
  }

  .icon-left {
    width: 11px;
    object-fit: contain;
    margin-left: 14px;
    cursor: pointer;
    ${props => props.iconLeftStyle}
  }

  ${props => props.containerStyle}
`

export const StyledDropdown = styled.div`  
  ul {
    background-color: ${Colors.color3};
    width: ${props => props.dropdownWidth};
    z-index: 2;
    position: absolute;
    height: ${props => props.showDropdown ? 'auto' : '0px'};
    padding: ${props => props.showDropdown ? '10px' : '0px'};
    overflow: hidden;
    -webkit-transition: all 0.5s ease-in-out;
    -moz-transition: all 0.5s ease-in-out;
    -o-transition: all 0.5s ease-in-out;
    transition: all 0.5s ease-in-out;
  }

  ul li {
    ${Fonts.FontRegular}
    ${Fonts.size.size16}
    font-weight: 800;
    color: ${Colors.color2};
    margin-bottom: 10px;
  }

  ul li:hover {
    cursor: pointer;
  }
`

export const StyledInputTag = styled.div` 

  .ReactTags__tags {
    /* background-color: red; */
  }

  .ReactTags__tagInput {
    /* background: green; */
  }

  .ReactTags__tagInputField {
    /* background-color: ${Colors.color3};  */
    width: 100%;
    padding: 16px 14px 16px 18px;
    outline: none;
    border-radius: 10px;
    margin: 10px 0;
    border: 1px solid ${Colors.color20};
    font-family: NunitoSans;
    font-weight: bold;
    color: ${Colors.color};
    resize: none;
    ${Fonts.size.size16}
  }

  .ReactTags__selected {
    /* background: yellow; */
  }

  div.ReactTags__selected span.ReactTags__tag {
    border: 1px solid #ddd;
    background: #eee;
    font-size: 12px;
    display: inline-block;
    padding: 10px;
    margin: 10px 5px;
    cursor: move;
    border-radius: 2px;
    font-family: NunitoSans;
    font-weight: bold;
    color: ${Colors.color};
    resize: none;
    ${Fonts.size.size14}
  }

  .ReactTags__remove {
    color: #aaa;
    margin-left: 10px;
    cursor: pointer;
    outline: none;
    border: 0;
    ${Fonts.size.size16}
  }

`

// export const StyledInputTag = styled.div` 
//   div.ReactTags__tags {
//     position: relative;
//     margin: 10px;
//   }

//   /* Styles for the input */
//   div.ReactTags__tagInput {
//       width: 200px;
//       border-radius: 2px;
//       display: inline-block;
//   }
//   div.ReactTags__tagInput input.ReactTags__tagInputField,
//   div.ReactTags__tagInput input.ReactTags__tagInputField:focus {
//       height: 31px;
//       margin: 0;
//       font-size: 12px;
//       width: 100%;
//       border: 1px solid #eee;
//       padding: 0 4px;
//   }

//   /* Styles for selected tags */
//   div.ReactTags__selected span.ReactTags__tag {
//       border: 1px solid #ddd;
//       background: #eee;
//       font-size: 12px;
//       display: inline-block;
//       padding: 5px;
//       margin: 10px 5px;
//       cursor: move;
//       border-radius: 2px;
//   }
//   .ReactTags__remove {
//       color: #aaa;
//       background: red;
//       margin-left: 15px !important;
//       display: inline-block;
//       cursor: pointer;
//   }

//   /* Styles for suggestions */
//   div.ReactTags__suggestions {
//       position: absolute;
//   }
//   div.ReactTags__suggestions ul {
//       list-style-type: none;
//       box-shadow: .05em .01em .5em rgba(0,0,0,.2);
//       background: white;
//       width: 200px;
//   }
//   div.ReactTags__suggestions li {
//       border-bottom: 1px solid #ddd;
//       padding: 5px 10px;
//       margin: 0;
//   }
//   div.ReactTags__suggestions li mark {
//       text-decoration: underline;
//       background: none;
//       font-weight: 600;
//   }
//   div.ReactTags__suggestions ul li.ReactTags__activeSuggestion {
//       background: #b7cfe0;
//       cursor: pointer;
//   }
// `