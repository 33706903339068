import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledUserQRCode = styled.div`
  .title {
    ${Fonts.FontRegular}
    ${Fonts.size.size32}
    margin-bottom: 20px;
  }
  .qrcode-container {
    height: 100vh;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }
`