
const colors = {
  primaryBgColor: '#EEF1F5',
  primaryBgColor2: '#E5E5E5',
  sidebarBackground: 'rgba(255, 255, 255, 0.75)',
  color: '#000000',
  color2: '#424242',
  color3: '#FFFFFF',
  color4: '#EEEEEE',
  color5: '#939393',
  color6: '#DDE4ED',
  color7: '#B1C2D8',
  color8: 'linear-gradient(132.58deg, #62318C 26.06%, #512874 80.3%)',
  color9: '#DCE3ED',
  color10: 'linear-gradient(120.94deg, #FF8655 31.26%, #FC7445 98.46%)',
  color11: '#502773',
  color12: '#4B4B4B',
  color13: '#FF5757',
  color14: '#EFC900',
  color15: '#FF6666',
  color16: 'linear-gradient(129.88deg, #E2E7EF 27.24%, #DCE3ED 74.4%)',
  color17: '#4CC876',
  color18: '#FAA808',
  color19: '#758BA9',
  color20: '#CCD5E1',
  color21: '#F45A5A',
  color22: 'linear-gradient(326.32deg, #E5E9EE 28.34%, #EFF2F6 75.67%)',
  color23: '#C1CAD7',
  color24: '#F7F9FC',
  color25: '#FF8655',
  color26: '#1F8AAC',
  color27: '#C5C5C5',
  color28: 'linear-gradient(129.88deg, #F1F4F8 27.24%, #EEF1F5 74.4%)',
  color29: '#F1F4F8',
  color30: '#F7F8FA',
  color31: '#DEE4EB',
  color32: '#F0F3F8',
  color33: '#BBCADB',
  color34: '#C4C4C4',
  color35: '#EB5757', 
  color36: `#7F59A0`,
  color37: `#29D09E`,
  color38: '#333333',
  color39: '#D2DDEA',
  color40: '#E6D9F2',
  color41: '#62318C',
  orange: '#FF9900',
  yellow: '#FFE699',
  color42: '#FD7D4D',
  orange2: '#FD7A4A',
  navy: '#165BAA',
}

export default colors
