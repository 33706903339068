import { call, put, select, actionChannel} from 'redux-saga/effects'
import SafeReturnActions from '../redux/SafeReturnRedux'

import { AuthSelectors } from '../redux/AuthRedux'

export function* getSafeReturn(api, action) {
  let { data } = action
  
  const userInfo = yield select(AuthSelectors.account);
  if (userInfo.payload) {
    const { type } = userInfo.payload.data.account
    
    const response = yield call(api.getSafeReturn, {
      ...data,
      type,
    })
  
    if (response.ok) {    
      yield put(SafeReturnActions.getSafeReturnSuccess(response.data))   
    } else {
      yield put(SafeReturnActions.getSafeReturnFailure(response))
    }
  }
}
