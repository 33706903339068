import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */
const { Types, Creators } = createActions({
  getReportMissingRequest: ['data'],
  getReportMissingSuccess: ['payload'],
  getReportMissingFailure: ['error'],

  editReportMissingRequest: ['data'],
  editReportMissingSuccess: ['payload'],
  editReportMissingFailure: ['error'],
})

export const ReportMissingTypes = Types
export default Creators

/* ------------- Initial State ------------- */
export const INITIAL_STATE = Immutable({
  reportMissingList: {fetching: null, error: null, payload: null, data: null},
  editReport: {fetching: null, error: null, payload: null, data: null},
})

/* ------------- Selectors ------------- */
export const ReportMissingSelectors = {
  reportMissingList: state => state.reportMissing.reportMissingList,
  editReport: state => state.reportMissing.editReport,
}

/* ------------- Reducers ------------- */
export const getReportMissingRequest = (state, {data}) => {
  return state.merge({ ...state, reportMissingList: {...state.reportMissingList, fetching: true, data}})
}
export const getReportMissingSuccess = (state, {payload}) => {
  return state.merge({ ...state, reportMissingList: {...state.reportMissingList, fetching: false, payload, error: null} })
}
export const getReportMissingFailure = (state, {error}) => {
  return state.merge({ ...state, reportMissingList: {...state.reportMissingList, fetching: false, error}})
}


export const editReportMissingRequest = (state, {data}) => {
  return state.merge({ ...state, editReport: {...state.editReport, fetching: true, data}})
}
export const editReportMissingSuccess = (state, {payload}) => {
  return state.merge({ ...state, editReport: {...state.editReport, fetching: false, payload, error: null}})
}
export const editReportMissingFailure = (state, {error}) => {
  return state.merge({ ...state, editReport: {...state.editReport, fetching: false, error}})
}

/* ------------- Hookup Reducers To Types ------------- */
export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_REPORT_MISSING_REQUEST]: getReportMissingRequest,
  [Types.GET_REPORT_MISSING_SUCCESS]: getReportMissingSuccess,
  [Types.GET_REPORT_MISSING_FAILURE]: getReportMissingFailure,

  [Types.EDIT_REPORT_MISSING_REQUEST]: editReportMissingRequest,
  [Types.EDIT_REPORT_MISSING_SUCCESS]: editReportMissingSuccess,
  [Types.EDIT_REPORT_MISSING_FAILURE]: editReportMissingFailure,
})