import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledExportQRCode = styled.div`
  .title {
    ${Fonts.FontRegular}
    ${Fonts.size.size32}
    margin-bottom: 20px;
  }
  .qrcode-container {
    min-height: 100vh;
    background-color: ${Colors.primaryBgColor};
    display: flex;
    align-items: center;
    flex-direction: column;
  }
`