import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledUserPrintPage = styled.div`
  background-color: ${Colors.primaryBgColor};
  display: flex;
  flex-direction: column;
  width: 100%;

  .wrapper {
    background-color: ${Colors.color3};
    width: 100%;
    border-radius: 15px;
    padding-top: 20px;
    word-break: break-all;
    overflow-y: auto;
    overflow-x: hidden;
    margin: 60px 100px;
  }

  .list-container {
    ${Fonts.FontRegular}
    ${Fonts.size.size14}
    color: ${Colors.color};
    font-weight: bold;
    padding: 12px 0;
  }

  .list-dark {
    background-color: ${Colors.color24};
  }

  .qrcode-container {
    display: flex;
    justify-content: center;
    width: 100%;
    margin: 20px 0;
  }

  @media print {
    -webkit-print-color-adjust: exact;  
    background-color: ${Colors.primaryBgColor} !important;

    .wrapper {
      background-color: ${Colors.color3} !important;
    }
  }
`