import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledLogin = styled.div`
  background-color: ${Colors.color3};
  border-radius: 20px;
  min-width: 375px;

  .buttonContainer {
    width: 100%;
    margin-top: 20px;
    overflow: hidden;
    display: flex;
    flex-direction: row;
  }

  .buttonSeparator {
    width: 15px;
  }
`

export const Wrapper = styled.div`
  margin: 68px 51px 30px;
  
`

export const buttonAllAccounts = css`
  width: 160px;
  height: 40px;
  .button-text {
    ${Fonts.size.size18}
    font-weight: 800;
  }

`

export const buttonAllAplicants = css`
  width: 160px;
  height: 40px;
  .button-text {
    ${Fonts.size.size18}
    font-weight: 800;
    text-align: center;
  }
`