import React, { useState, } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Hidden, } from 'react-grid-system';
import _ from 'lodash'

import { StyledDialogTag, InputStyle, InputReadOnlyStyle, } from './StyledDialogTag'

import Dialog from '../modal'
import Input from '../input'
import Button from '../button'

import { Colors, Images, } from '../../themes'

import { relationshipList } from '../../data/RelationshipList'

import { getUsers } from '../../api/UserAPI'

import ProccessData from '../../lib/ProccessData'

function TagDementia({
  dialogProps,
  otherAccount,
  onTagAccount,
  loadingTag,
  user,
}) {

  const [inputValue, setInputValue] = useState({
    name: '',
    lastNric: ''
  })
  const [users, setUsers] = useState({
    loading: false,
    data: [],
  })
  const [dropdowns,setDropdowns] = useState({
    relationship: {
      showDropdown: false,
      value: '',
    },
  });
  const [loading, setLoading] = useState(false)
  const [searched, setSearched] = useState(false)
  const [userSelected, setUserSelected] = useState({})

  const { relationship } = dropdowns
  const { name, lastNric } = inputValue

  const renderInputDropdown = (props) => {
    const { value, onClick, } = props;
    let filtered = true
  
    return (
      <Col md={12}>
        <Input
          inputProps={{
            readOnly: true,
            onClick: filtered ? onClick : null,
            value,
          }}
          containerStyle={`
            ${InputReadOnlyStyle}
            .input-field {
              cursor: ${filtered ? 'pointer' : 'not-allowed'}
            }
            input {
              color: ${filtered ? Colors.color2 : Colors.color34} !important;
            }
          `}
          iconRight={Images.polygonYellow}
          {...props}
          dropdownWidth={'100%'}
        />
      </Col>
    );
  }

  const onPressDropdown = (stateName) => {
    setDropdowns(state => {
      return { ...state, [stateName]: { ...dropdowns[stateName], showDropdown: !dropdowns[stateName].showDropdown, }}
    })
  };

  const onSelectDataDropdown = (stateName, value) => {
    setDropdowns(state => {
      return { ...state, [stateName]: { ...dropdowns[stateName], value, showDropdown: !dropdowns[stateName].showDropdown, }}
    })
  }

  const searchUser = async () => {
    setUsers(state => ({ ...state, loading: true, }) )

    const users = await getUsers({ 
      name: name.trim(),
      lastNric,
      type: 'DEMENTIA',
    })

    try {
      let resultGet = {}
      resultGet.fetching = false
      resultGet.payload = users
      ProccessData(resultGet, 'get user')
        .then(({ data }) => {
          setUsers(state => ({ data, loading: false, }) )
        })
        .catch(err => {
          alert(err ? err : 'ERROR')
        })
    } catch (e) {
      alert('CONNECTION ERROR ' + e)
    }

    setUsers(state => ({ ...state, loading: false, }) )
    setSearched(true)
  }

  const onChangeText = (stateName, value) => {
    setInputValue(state => {
      return { ...state, [stateName]: value, }
    })
  }

  return (
    <Dialog
      {...dialogProps}
      width="36%"
      height={searched ? undefined : "auto"}
      title="Tag new Person Living with Dementia"
      additionalStyle={{
        titleStyle: ` color: ${Colors.color19}; `,
      }}
    >
      <StyledDialogTag>
      <p className="text">Name</p>
        <Input
          inputProps={{
            value: name,
            onChange: (e) => onChangeText('name', e.target.value)
          }}
          containerStyle={`
            ${InputStyle}
            margin-bottom: 26px;
          `}
        />
        <p className="text">Last 4 characters of NRIC</p>
        <Input
          inputProps={{
            value: lastNric,
            onChange: (e) => onChangeText('lastNric', e.target.value),
            maxLength: 4,
          }}
          containerStyle={`
            ${InputStyle}
          `}
        />
        {
          searched &&
            <p className="result-text">{users.data.length > 0 ? `Matched result` : `User not found`}</p>
        }
        {
          users.data.length > 0 && searched && 
            [
              users.data.map(item => {
                return (
                  <div className={`card ${userSelected.uid === item.uid && `bordered`}`} onClick={() => {
                    let isTagged = otherAccount.some(data => (data.uidTagged === item.uid && data.status === `ACCEPTED`) || (data.uidTags === item.uid && data.status === `ACCEPTED`))
                    isTagged ? alert(`This user has already been tagged`) : setUserSelected(item)
                  }}>
                  <div className={`card-content`}>
                    <div className="photo-profile-container">
                      <img className="photo-profile" src={item.photo ? item.photo : Images.defaultPhoto} />
                    </div>
                    <div className="text-container">
                      <p className="name-text">{item.firstname} {item.lastname}</p>
                      <p className="phone-text">XXXX{item.lastNric}</p>
                    </div>
                  </div>
                  <div>
                    <img className="check-image" src={userSelected.uid === item.uid ? Images.checkActive : Images.checkInactive} />
                  </div>
                </div>
                )
              }),
              <p className="text">The Person Living with Dementia is my</p>,
              renderInputDropdown({
                showDropdown: relationship.showDropdown,
                dropdownData: relationshipList('CAREGIVER'),
                dropdownPropertyName: 'name',
                onClick: () => onPressDropdown('relationship'),
                onSelectDropdown: ({ name, }) => { 
                  name === `Don't Filter` ? onSelectDataDropdown(`relationship`, ``) : onSelectDataDropdown(`relationship`, name)
                },
                value: relationship.value,
                placeholder: relationship.placeholder,
              })  
            ]
        }
        <Row justify='end' style={{ marginTop: '30px' }}>
          <Col md={6}>
            <Button
              text="Discard"     
              backgroundColor={Colors.primaryBgColor}    
              textColor={Colors.color11}
              bordered
              buttonStyle={`border-radius: 15px`} 
              onClick={() => {
                dialogProps.isClose()
                setInputValue({
                  name: '',
                  lastNric: ''
                })
                setUsers({
                  loading: false,
                  data: [],
                })
                setDropdowns({
                  relationship: {
                    showDropdown: false,
                    value: '',
                  },
                })
                setSearched(false)
                setUserSelected({})
                setInputValue({
                  name: '',
                  lastNric: ''
                })
              }}
            />
          </Col>
          <Col md={6}>
            <Button
              text={users.loading && !searched ? "Searching..." : !users.loading && searched ? "Add" : "Search"}
              disabled={
                users.loading || 
                inputValue.name === '' ||
                inputValue.lastNric === ''|| 
                (searched && (_.isEmpty(userSelected) || dropdowns.relationship.value === '')) || 
                loadingTag
              }
              backgroundColor={Colors.color10}   
              buttonStyle={`border-radius: 15px`} 
              onClick={() => (searched && !_.isEmpty(userSelected)) ? onTagAccount({ user: userSelected, successTagMessage: `Your Loved One has been successfully added!`, relationship: dropdowns.relationship.value, }) : searchUser()}
            />
          </Col>
        </Row>
      </StyledDialogTag>
    </Dialog>
  )
}

TagDementia.propTypes = {
  dialogProps: PropTypes.any,
  otherAccount: PropTypes.array,
  onTagAccount: PropTypes.func,
  loadingTag: PropTypes.bool,
  user: PropTypes.any,
}

TagDementia.defaultProps = {
  dialogProps: () => null,
  otherAccount: [],
  onTagAccount: () => null,
  loadingTag: false,
  user: {},
}

export default TagDementia

