export const relationshipList = (account) => {
  // for caregiver list
  if (account === 'CAREGIVER' || account === 'Caregiver') {
    return [
      {
        id: 1,
        name: `Uncle / Aunt`,
      },
      {
        id: 2,
        name:  'Spouse',
      },
      {
        id: 3,
        name:  'Parent',
      },
      {
        id: 4,
        name:  'Grandparent',
      },
      {
        id: 5,
        name:  'Child',
      },
      {
        id: 6,
        name: 'Grandchild',
      },
      {
        id: 7,
        name: `Nephew / Niece`,
      },
      {
        id: 8,
        name: `Care Professional`,
      },
      {
        id: 9,
        name:  'Volunteer',
      },
    ]
  }

  else if (account === 'ORGANISATION_STAFF' || account === 'Organisation Staff' || account === "Care Professional") {
    // for dementia tagged to organisation staff list
    return [
      {
        id: 1,
        name: `Care Professional`,
      },
      {
        id: 2,
        name:  'Volunteer',
      },
    ]
  }

  // for dementia list
  return [
    {
      id: 1,
      name: `Nephew / Niece`,
    },
    {
      id: 2,
      name:  'Child',
    },
    {
      id: 3,
      name:  'Grandchild',
    },
    {
      id: 4,
      name:  'Spouse',
    },
    {
      id: 5,
      name:  'Parent',
    },
    {
      id: 6,
      name: 'Grandparent',
    },
    {
      id: 7,
      name: `Uncle / Aunt`,
    },
    {
      id: 8,
      name: `Care Professional`,
    },
    {
      id: 9,
      name:  'Volunteer',
    },
  ]
}