import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';

import { StyledCard } from './StyledCard';
import { Colors, Fonts, Scale, Images } from '../../themes';
import SwitchButton from '../switch-button'

import edit from '../../assets/images/edit.png'
import rubbish from '../../assets/images/rubbish.png'
import { Link } from 'react-router-dom';

import { USER_TYPE } from '../../constants';
import SolutionsActions from '../../redux/SolutionsRedux'
import BenefitsActions from '../../redux/BenefitsRedux'

class Card extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      isEnable: true
    }
  }

  componentDidMount() {
    const {enable} = this.props
    this.setState({isEnable: enable})
  }

  componentDidUpdate(prevProps) {
    if (this.props.tabActive !== prevProps.tabActive) {
      if(this.props.enable !== this.state.isEnable)
      this.setState({isEnable: this.props.enable})
    } else  {
      if (this.props.enable  !== prevProps.enable) {
        this.setState({isEnable: this.props.enable})
      }
    }
  }

  onSwitch() {
    const {id} = this.props
    if(this.state.isEnable) {
      if(this.props.tabActive === 'solutions') {
        this.props.updateSolution({
          id,
          data: {
            enable: false
          }
        })
      } else if (this.props.tabActive === 'categorySolutions') {
        this.props.updateCategorySolution({
          id,
          data: {
            enable: false
          }
        })
      } else if (this.props.tabActive === 'benefits') {
        this.props.updateBenefit({
          id,
          data: {
            enable: false
          }
        })
      } else if (this.props.tabActive === 'categoryBenefits') {
        this.props.updateCategoryBenefit({
          id,
          data: {
            enable: false
          }
        })
      }
      this.setState({isEnable: false})
    } else {
      if(this.props.tabActive === 'solutions') {
        this.props.updateSolution({
          id,
          data: {
            enable: true
          }
        })
      } else if (this.props.tabActive === 'categorySolutions') {
        this.props.updateCategorySolution({
          id,
          data: {
            enable: true
          }
        })
      } else if (this.props.tabActive === 'benefits') {
        this.props.updateBenefit({
          id,
          data: {
            enable: true
          }
        })
      } else if (this.props.tabActive === 'categoryBenefits') {
        this.props.updateCategoryBenefit({
          id,
          data: {
            enable: true
          }
        })
      }
      this.setState({isEnable: true})
    }

  }

  render() {
    const { column, image, content, imageStyle, titleStyle, key, transparent, additionalStyle, isBenefits, isSolutions } = this.props
    const { isEnable } = this.state
    
    return (
      <StyledCard imageStyle={imageStyle} titleStyle={titleStyle} md={column} sm={12} isHide={isEnable} transparent={transparent} additionalStyle={additionalStyle}>
        <div className="card" key={key}>
          {
            (isBenefits || isSolutions) ?
            (this.props.account.payload.data.account.type === USER_TYPE.ADMIN || this.props.account.payload.data.account.type === USER_TYPE.SUPERADMIN) && (
              <div className="position">
                  <SwitchButton label={`${this.props.title}_${key}`} isOn={!isEnable ? false : true} handleToggle={() => this.onSwitch()} />
              </div>
            ) : (
              <div className="position">
                  <SwitchButton label={`${this.props.title}_${key}`} isOn={!isEnable ? false : true} handleToggle={() => this.onSwitch()} />
              </div>
            )
          }
          <img className="image" src={image} alt="content" />
          <div className="bottom-wrapper">
            <p className="title">{this.props.title ? this.props.title : this.props.titleChinese ? this.props.titleChinese : ''}</p>
            <div dangerouslySetInnerHTML={{ __html: content }} className="description" />
            {/* <p className="description">{content}</p> */}
            <div className="action">
              {
                (this.props.account && this.props.account.payload && this.props.account.payload.data && this.props.account.payload.data.account && this.props.account.payload.data.account.type && this.props.account.payload.data.account.type !== USER_TYPE.VIEWER) && (
                  <div onClick={this.props.onEdit}>
                    <div className="edit-wrapper">
                      <img src={Images.edit} className="edit" />
                    </div>
                  </div>
                )
              }
              {
                (this.props.account && this.props.account.payload && this.props.account.payload.data && this.props.account.payload.data.account && this.props.account.payload.data.account.type && this.props.account.payload.data.account.type === USER_TYPE.SUPERADMIN) && (
                  <div onClick={this.props.onDelete}>
                    <div className="trash-wrapper">
                      <img src={Images.trash} className="delete" />
                    </div>
                  </div>
                )
              }
            </div>
          </div>
          
        </div>
      </StyledCard>
    )
  }
}

Card.propTypes = {
  title: PropTypes.string,
  image: PropTypes.string,
  description: PropTypes.node,
  onEdit: PropTypes.func,
  onDelete: PropTypes.func,
  column: PropTypes.string,
  image: PropTypes.string,
  content: PropTypes.string,
  imageStyle: PropTypes.string,
  titleStyle: PropTypes.string,
  key: PropTypes.any,
  transparent: PropTypes.bool,
  additionalStyle: PropTypes.string,
}

Card.defaultProps = {
  title: '',
  image: '',
  description: '',
  onEdit: () => null,
  onDelete: null,
  column: 4,
  image: '',
  content: '',
  imageStyle: '',
  titleStyle: '',
  key: 0,
  transparent: false,
  additionalStyle: ``,
  isBenefits: false,
  isSolutions: false
}

const mapStateToProps = state => {
  return {
    account: state.auth.account     
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    updateSolution: (params) => dispatch(SolutionsActions.updateSolutionRequest(params)),
    updateCategorySolution: (params) => dispatch(SolutionsActions.updateSolutionCategoryRequest(params)),
    updateBenefit: (params) => dispatch(BenefitsActions.updateBenefitRequest(params)),
    updateCategoryBenefit: (params) => dispatch(BenefitsActions.updateCategoryRequest(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps) (Card)