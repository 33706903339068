import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
    getDashboardRequest: ['data'],
    getDashboardSuccess: ['payload'],
    getDashboardFailure: ['error'],
    getDailySignUpRequest: ['data'],
    getDailySignUpSuccess: ['payload'],
    getDailySignUpFailure: ['error'],
  })


export const DashboardTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  dashboard: { fetching: null, error: null, payload: null, data: null, daily: null },
})

/* ------------- Selectors ------------- */

export const DashboardSelectors = {
  dashboard: state => state.dashboard.dashboard,
}

/* ------------- Reducers ------------- */

export const getDashboardRequest = (state, {data}) => {
  return state.merge({ ...state, dashboard: { ...state.dashboard, fetching: true, data}})
}

export const getDashboardSuccess = (state, {payload}) => {
  return state.merge({ ...state, dashboard: { ...state.dashboard, fetching: false, error: false, payload}})
}

export const getDashboardFailure = (state, {error}) => {
  return state.merge({ ...state, dashboard: { ...state.dashboard, fetching: false, error}})
}

export const getDailySignUpRequest = (state, {data}) => {
  return state.merge({ ...state, dashboard: { ...state.dashboard, fetching: true, data}})
}

export const getDailySignUpSuccess = (state, {payload}) => {
  return state.merge({ ...state, dashboard: { ...state.dashboard, fetching: false, error: false}, daily: payload.data})
}

export const getDailySignUpFailure = (state, {error}) => {
  return state.merge({ ...state, dashboard: { ...state.dashboard, fetching: false, error}})
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_DASHBOARD_REQUEST]: getDashboardRequest,
  [Types.GET_DASHBOARD_SUCCESS]: getDashboardSuccess,
  [Types.GET_DASHBOARD_FAILURE]: getDashboardFailure,
  [Types.GET_DAILY_SIGN_UP_REQUEST] : getDailySignUpRequest,
  [Types.GET_DAILY_SIGN_UP_SUCCESS] : getDailySignUpSuccess,
  [Types.GET_DAILY_SIGN_UP_FAILURE] : getDailySignUpFailure
})