import { API } from '../lib/Request'
// import AppConfig from '../config/AppConfig'
import AppConfig from '../config.js'

const accountUrl = AppConfig.ACCOUNT_URL

export const deleteUser = async (params) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/account`, method: 'DELETE', params })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const deleteUser2 = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/account/loggedin/${data.uid}`, method: 'DELETE', data })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getAddress = async (params) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/account/address`, method: 'GET', params })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const editUser = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/account/${data.uid}`, method: 'PATCH', data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const updateAccountStatus = async (data) => {
  return new Promise((resolve, reject) => {
    let params = ``

    if (data.uid) params = `?uid=${data.uid}`
    if (data.uidList) params = `?uidList=${data.uidList}`
    
    API({ url: `${accountUrl}/admin/status${params}`, method: 'PATCH', data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const exportUser = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/export?uid=${data.uid}&exportType=${data.exportType}`, method: 'GET', data, responseType: 'blob' })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const exportUserSelected = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/export?uidList=${data.uidList}&exportType=${data.exportType}`, method: 'GET', data, responseType: 'blob' })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const exportUserFiltered = async (params) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/export`, method: 'GET', params, responseType: 'blob' })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getUserById = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/accounts/users/${data.uid}`, method: 'GET', data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getUsersOld = async (params) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/accounts/users`, method: 'GET', params, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getUsers = async (params, token) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/accounts/users/v2`, method: 'GET', params})
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getCountUsers = async ({ params, type }) => {
  let url = type === "ADMIN" ? '/admin/users' : '/partner/users'
  let filter = params
  
  if (type !== "ADMIN") {
    delete filter.searchFrom
    delete filter.showBy
  }
  
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}${url}`, method: 'GET', params: filter, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getOtherAccount = async (uid, params) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/otheraccount/${uid}`, method: 'GET', params, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const tagAccount = async ({ data }) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/accounts/tagMyOtherAccount`, method: 'POST', data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const deleteOtherAccount = async (params) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/tag-account`, method: 'DELETE', params, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const deleteSafeReturn = async (params) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/tag-account`, method: 'DELETE', params, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const updatePartnerAccountStatus = async (data) => {
  return new Promise((resolve, reject) => {
    let params = ``

    if (data.uid) params = `?uid=${data.uid}`
    if (data.uidList) params = `?uidList=${data.uidList}`
    
    API({ url: `${accountUrl}/admin/partner-status${params}`, method: 'PATCH', data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const bulkCreateAccount = async ({ data }) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/partner/users`, method: 'POST', data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getCountSignupPlatform = async (params) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/account-signup-platform`, method: 'GET', params, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const validateCSVData = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/upload`, method: 'POST', data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getPartners = async (params) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/partners`, method: 'GET', params, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const editPartner = async ({ partnerUid, data }) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/partner/${partnerUid}`, method: 'PATCH', data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const updateAccountMetadata = async (params, { data }) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/user-metadata`, method: 'PATCH', params, data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getSingleAccount = async ({ data }) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/public/credential/getSingleAccount`, method: 'POST', data, })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const reversingAccountStatus = async (data) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/users/account-status/reverse`, method: 'PATCH', data })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const getFeatureAnalyticUser = async(uid) => {
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}/admin/feature-analytic/${uid}`, method: 'GET'})
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}