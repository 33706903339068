import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledUserManagement = styled.div`
  padding-top: 62px;
  padding-left: 40px;
  background-color: ${Colors.primaryBgColor};
  min-height: 100vh;
  padding-right: 40px;
  overflow: hidden;

  .header-container {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  .title {
    ${Fonts.FontRegular};
    ${Fonts.size.size32};
    font-weight: 800;
    color: ${Colors.color2};
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
  }

  .buttonSeparator {
    width: 15px;
  }

  a {
    text-decoration: none;
    color: ${Colors.color11};
  }

  .updated-text {
    ${Fonts.FontRegular}
    font-weight: bold;
    color: ${Colors.color2};
    margin: 30px 0 16px 0;
  }
`


export const buttonAllAccounts = css`
  width: 160px;
  height: 40px;
  .button-text {
    ${Fonts.size.size18}
    font-weight: 800;
  }

`

export const buttonAllAplicants = css`
  width: 160px;
  height: 40px;
  .button-text {
    ${Fonts.size.size18}
    font-weight: 800;
    text-align: center;
  }
`

export const buttonImport = css`
  width: 134px;
  height: 40px;
  .button-text {
    ${Fonts.size.size18}
    font-weight: 800;
    text-align: center;
  }
`

export const buttonCreateNew = css`
  width: 225px;
  height: 40px;
  .button-text {
    ${Fonts.size.size18}
    font-weight: 800;
    text-align: center;
  }
`

export const Label = styled.label`
  ${Fonts.FontRegular}
  ${Fonts.size.size16}
  color: ${Colors.color19};
  font-weight: bold;

  ${props => props.additionalStyle}
`

export const InputStyle = ` 
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin: 20px 0 27px 0;
  .input-field {  
    width: 100%;
    padding: 16px 14px 16px 18px;
    outline: none;
  }
` 