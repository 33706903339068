import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledSidebar = styled.div`
  background: ${Colors.sidebarBackground};
  padding-top: 55px;
  height: 100%;
  position: fixed;
  width: 100%;
  overflow: auto;

  .itemBox:hover, .itemBox:active {
    background-color: ${Colors.color6};
    cursor: pointer;

    .text {
      color: ${Colors.color2};
      font-weight: 800;
    }
  }

  .activeBox {
    background-color: ${Colors.color6};
    .text {
      color: ${Colors.color2};
      font-weight: 800;
    }
  }

  .itemBox {
    display: block;
    padding: 20px;
  }

  .text, a {
    ${Fonts.FontRegular}
    ${Fonts.size.size16}
    color: ${Colors.color7};
    font-weight: bold;
    text-decoration: none;
  }
`