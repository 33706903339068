import React from 'react';
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from 'chart.js';
import { Pie } from 'react-chartjs-2';

ChartJS.register(ArcElement, Tooltip, Legend);

const options = { 
    plugins: {
      tooltip: {
        titleFont: {
          size: 12
        },
        bodyFont: {
          size: 12
        },
     },
    legend: {
      display: true,
      responsive: true,
      position: "right",
      align: "left",
      borderRadius: true
    },
  }
}

export default function PieChart({label, data, color}) {
  return <Pie 
            data={{
                labels: label,
                datasets: [
                {
                    label: '# of Votes',
                    data: data,
                    backgroundColor: color,
                },
                ],
            }} 
            options={options}
         />;
}
