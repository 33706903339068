import {call, put, select} from 'redux-saga/effects';
import ReportActions from '../redux/ReportMissingRedux';
import { AuthSelectors } from '../redux/AuthRedux';


export function * getReport(api, action) {
  let {data} = action
  const userInfo = yield select(AuthSelectors.account);
  const { type } = userInfo.payload.data.account
 
  const response = yield call(api.getReportMissing, {
    ...data,
    type,
  })

  if (response.ok) {    
    yield put(ReportActions.getReportMissingSuccess(response.data))   
  } else {
    if (response.status === 400 && response.data.error.name ==="TokenExpiredError"){
      yield put(AuthActions.signout())
      localStorage.clear()
    } else {
        yield put(ReportActions.getReportMissingFailure(response))
    }
  }

}

export function * editReport(api, action) {
  let {data} = action
  const userInfo = yield select(AuthSelectors.account);
  const { type } = userInfo.payload.data.account
 
  const response = yield call(api.editReportMissing, {
    ...data,
    type,
  })

  if (response.ok) {    
    yield put(ReportActions.editReportMissingSuccess(response.data))   
  } else {
    if (response.status === 400 && response.data.error.name ==="TokenExpiredError"){
      yield put(AuthActions.signout())
      localStorage.clear()
    } else {
        yield put(ReportActions.editReportMissingFailure(response))
    }
  }
}

