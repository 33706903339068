import React, { Component } from 'react'
import { connect } from 'react-redux'

import QRCode from 'qrcode.react'

import { StyledUserQRCode} from './styles/StyledUserQRCode'

// import AppConfig from '../config/AppConfig'
import AppConfig from '../config.js'

export class UserQRCode extends Component {
  constructor(props) {
    super(props)

    this.state = { width: screen.width, height: screen.height };
  }
  
  componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  render() {
    const { width } = this.state
    const { match } = this.props
    
    return (
      <StyledUserQRCode>
        <div className="qrcode-container">
          <h1 className="title">Scan user here</h1>
          <QRCode value={`${AppConfig.HOSTNAME}/user-detail/${match.params.uid}`} size={width > 440 ? 400 : 200} />
        </div>
      </StyledUserQRCode>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(UserQRCode)
