import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Container, Row, Col, Hidden, } from 'react-grid-system';

import Button from "../components/button";
import DialogContent from "../components/dialog-content"
import DialogInfo from '../components/dialog-info'

import EditContent from "./EditContent"

import { StyledContentManagement } from './styles/StyledContentManagement'

import { Typography, Colors, } from '../themes'

import axios from 'axios'

// import AppConfig from '../config/AppConfig'
import AppConfig from '../config.js'
import Example from '../components/grid/index'
import { USER_TYPE } from '../constants';

const benefitsUrl = AppConfig.BENEFITS_URL
const { Title } = Typography

const data = [
  { 
    id:1,
    title: 'Banner 1'
  },
  {
    id:2,
    title: 'Banner 2'
  },
  {
    id:3,
    title: 'Banner 3'
  }
]

export class ContentManagement extends Component {
  constructor(props) {
    super(props)

    this.state = {
      dialogAddBanner: false,
      dialogEditBanner: false,
      title: '',
      description: '',
      id: '',
      text: '',
      url: '',
      image: '',
      buttonText: '',
      base64Image:'',
      contentData:[],
      show: 'CONTENT',
      infoDialogShow: false,
      infoDescription: ''
    }
  }

  componentDidMount(){
    axios.get(`${benefitsUrl}/banners`)
    .then(res=> {
      this.setState({contentData:res.data.payload.data})
    })
  }
  
  onChangeText = (name, value) => {
    this.setState({ [name]: value })
  }

  show_item_after_edit= () => {
    setTimeout(() => {
      axios.get(`${benefitsUrl}/banners`)
      .then(res=> {
        this.setState({contentData:res.data.payload.data})

      })
    }, 500)
  }

  onClickCreate = () => {
    axios.post(`${benefitsUrl}/banner`,{
      title: this.state.title,
      description: this.state.description,
      text: this.state.text,
      url: this.state.url,
      base64Image:this.state.base64Image,
      buttonText: this.state.buttonText,
    }).then(res => {
      if (res.data.type === 'ERROR') {
        const desc = res.data.error ? res.data.error : 'Error'
        this.onDialogInfo(desc)
      } else {
        this.setState({ 
          dialogAddBanner: false, 
          title: '',
          description: '',
          id:'',
          text:'',
          url:'',
          image:'',
          base64Image:'',
          buttonText: '',
        })
      }
    })
    .catch(err => {
      this.onDialogInfo(err)
    })
      
    this.show_item_after_edit()
  }

  onClick = () => {
    axios.patch(`${benefitsUrl}/banner/${this.state.id}`,{
      title: this.state.title,
      description: this.state.description,
      text: this.state.text,
      url: this.state.url,
      base64Image:this.state.base64Image,
      buttonText: this.state.buttonText,
    }).then( res => {
      if (res.data.type === 'ERROR') {
        const desc = res.data.error ? res.data.error : 'Error'
        this.onDialogInfo(desc)
      } else {
        this.setState({ 
          dialogAddBanner: false, 
        })
      }
    })
    axios.get(`${benefitsUrl}/banners`)
    .then(res=> {
      this.setState({contentData:res.data.payload.data, dialogEditBanner: false, })
    })
    
  this.show_item_after_edit()
  }
  
  handleChangeI = (e) => {
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
    this.setState({
      image: URL.createObjectURL(e.target.files[0]),
      
        base64Image: reader.result
      });
    }
  }

  show_item_after_delete= () => {
    setTimeout(()=>{
      axios.get(`${benefitsUrl}/banners`)
      .then(res=> {
        this.setState({contentData:res.data.payload.data})
      })
    },500)
  }

  onDelete(id) {
    //function props to delete
    const confirmation = confirm('Are you sure want to delete this banner?')
    
    if (confirmation) {
      axios.delete(`${benefitsUrl}/banner/${id}`)
      .then(res=>{
          
        console.log(res)
      }) 
      this.show_item_after_delete()
    }    
  }
 
  render() {
    const { 
      dialogAddBanner, 
      contentData,
      dialogEditBanner,
      title,
      id,
      text,
      url,
      image,
      description,
      buttonText,
      infoDialogShow, 
      infoDescription,
    } = this.state

    const { history } = this.props

    let imageInput = null
    return (
      <StyledContentManagement>
        <DialogContent 
          titleCreate={title}  
          idCreate={id} 
          urlCreate={url} 
          textCreate={text} 
          imageCreate={image} 
          description={description}
          buttonText={buttonText}
          onClickCreate={this.onClickCreate}    
          onChangeImageCreate={this.handleChangeI} 
          onChangeText={this.onChangeText}
          dialogProps={{
            isVisible: dialogAddBanner,
            isClose: () => this.setState({ dialogAddBanner: false, })
          }}
        />
        {/* Dialog info start */}
        <DialogInfo
          isVisible={infoDialogShow}
          textInfo={infoDescription}
          onOkButton={()=> this.setState({infoDialogShow: false})}
        />
        {/* Dialog info end  */}
        <div className="title-container">
          <Title customStyle={` color: black; padding:  `}>Content Management</Title>
          {
            (this.props.account && this.props.account.payload && this.props.account.payload.data && this.props.account.payload.data.account && this.props.account.payload.data.account.type && this.props.account.payload.data.account.type !== USER_TYPE.VIEWER) && (
              <Button
                text="Create New Banner"
                buttonStyle={` width: 315px; zIndex: 100`}
                textStyle={` font-weight: 800; `}
                onClick={() => this.setState({ dialogAddBanner: true, })}
              />
            )
          }
        </div>
        <Container>
          {/* <Row>
          <EditContent 
            title={title}  
            id={id} 
            url={url} 
            text={text} 
            image={image} 
            description={description}
            buttonText={buttonText}
            onClick={this.onClick} 
            onChangeImage={this.handleChangeI} 
            onChangeText={this.onChangeText}
            dialogProps={{
              isVisible: dialogEditBanner,  
              isClose: () => this.setState({ dialogEditBanner: false, })
            }}
        />
        {
              contentData.map((item,i) => {
                return (
                  <Card
                    {...item}
                    onEdit={() => this.setState({ 
                        dialogEditBanner: true, 
                        title:item.title, 
                        id:item.id,text:item.text,
                        url:item.url,
                        image:item.imageUrl,
                        description: item.description,
                        buttonText: item.buttonText, 
                      })
                    }
                    onDelete={()=> this.onDelete(item.id)}
                    content={item && item.text}
                    image={item.imageUrl}
                    key={i.toString()}
                    transparent
                    imageStyle={`
                      height: 160px;
                      border-radius: 15px;
                    `}
                    additionalStyle={`
                      .bottom-wrapper {
                        margin: 0 13px 13px 0;
                      }
                      .action {
                        justify-content: flex-start;
                      }
                    `}
                  />
                )
              })
            }
            
          </Row> */}
          {/* <div style={{position:'absolute', zIndex: 99, top: 0, right: 0, left: 0}}> */}
            <Example bannerData={contentData}/>
          {/* </div> */}
        </Container>
      </StyledContentManagement>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.auth.account
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(ContentManagement)
