import { call, put, all, actionChannel} from 'redux-saga/effects'
import NotificationsActions from '../redux/NotificationsRedux'

export function* getNotifications(api, action) {
  let { data } = action
  
  const response = yield call(api.getNotifications, data)
  if (response.ok) {    
    yield put(NotificationsActions.getNotificationsSuccess(response.data))   
  } else {
    yield put(NotificationsActions.getNotificationsFailure(response))
  }
}