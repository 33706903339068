import {call, put, select} from 'redux-saga/effects';
import UserActions from '../redux/UserRedux'
import AuthActions from '../redux/AuthRedux'

import { AuthSelectors } from '../redux/AuthRedux'

export function* getUsers(api, action) {
  let { data } = action

  const userInfo = yield select(AuthSelectors.account);
  const { type } = userInfo.payload.data.account

  const response = yield call(api.getUsers, {
    ...data,
    type,
  })

  if (response.ok) {    
    yield put(UserActions.getUsersSuccess(response.data))   
  } else {
    if (response.status === 400 && response.data.error.name ==="TokenExpiredError"){
      yield put(AuthActions.signout())
      localStorage.clear()
    } else {
      yield put(UserActions.getUsersFailure(response))
    }
  }
}

export function* getAdmin(api, action) {
  let { data } = action

  const userInfo = yield select(AuthSelectors.account);
  const { type } = userInfo.payload.data.account
  
  const response = yield call(api.getAdmin, {
    ...data,
    type,
  })

  if (response.ok) {    
    yield put(UserActions.getAdminSuccess(response.data))   
  } else {
    if (response.status === 400 && response.data.error.name ==="TokenExpiredError"){
      yield put(AuthActions.signout())
      localStorage.clear()
    } else {
      yield put(UserActions.getAdminFailure(response))
    }
  }
}