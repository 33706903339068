import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getUsersRequest: ['data'],
  getUsersSuccess: ['payload'],
  getUsersFailure: ['error'],
  resetUsers: ['payload'],

  getAdminRequest: ['data'],
  getAdminSuccess: ['payload'],
  getAdminFailure: ['error'],
})

export const UserTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  users: { fetching: null, error: null, payload: null, data: null },
  admin: { fetching: null, error: null, payload: null, data: null },
})

/* ------------- Selectors ------------- */

export const UserSelectors = {
  users: state => state.user.users,
  admin: state => state.user.admin,
}

/* ------------- Reducers ------------- */

export const getUsersRequest = (state, { data }) => {
  return state.merge({ ...state, users: { ...state.users, fetching: true, data, }})
}

export const getUsersSuccess = (state, { payload }) => {
  return state.merge({ ...state, users: {  ...state.users, fetching: false, error: false, payload, }})
}

export const getUsersFailure = (state, { error }) => {
  return state.merge({ ...state, users: { ...state.users, fetching: false, error, }})
}

export const resetUsers = (state) => {
  return state.merge({ ...state, users: { ...state.users, fetching: false, payload: null, data: null }})
}

export const getAdminRequest = (state, { data }) => {
  return state.merge({ ...state, admin: { ...state.admin, fetching: true, data, }})
}

export const getAdminSuccess = (state, { payload }) => {
  return state.merge({ ...state, admin: {  ...state.admin, fetching: false, error: false, payload, }})
}

export const getAdminFailure = (state, { error }) => {
  return state.merge({ ...state, admin: { ...state.admin, fetching: false, error, }})
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_USERS_REQUEST]: getUsersRequest,
  [Types.GET_USERS_SUCCESS]: getUsersSuccess,
  [Types.GET_USERS_FAILURE]: getUsersFailure,
  [Types.RESET_USERS]: resetUsers,

  [Types.GET_ADMIN_REQUEST]: getAdminRequest,
  [Types.GET_ADMIN_SUCCESS]: getAdminSuccess,
  [Types.GET_ADMIN_FAILURE]: getAdminFailure,
})
