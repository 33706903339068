import styled from "styled-components";
import { Colors } from "../../themes";

export const Container = styled.div`
  gap: 35px;
  display: flex;
  overflow: auto;
  min-height: 100vh;
  padding: 62px 40px;
  flex-direction: column;
  background-color: ${Colors.primaryBgColor};
`;

export const Spin = styled.div`
  -webkit-animation: spin 2s linear infinite;
  animation: spin 2s linear infinite;
  @-webkit-keyframes spin {
    0% {
      -webkit-transform: rotate(0deg);
    }
    100% {
      -webkit-transform: rotate(360deg);
    }
  }

  @keyframes spin {
    0% {
      transform: rotate(0deg);
    }
    100% {
      transform: rotate(360deg);
    }
  }
`;

export const SearchField = styled.input`
  flex: 1;
  height: 43px;
  font-weight: 700;
  font-style: normal;
  padding-left: 18px;
  border-style: solid;
  border-radius: 10px;
  padding-right: 18px;
  text-decoration: inherit;
  border-color: ${Colors.color39};
  background-color: ${Colors.color24};
  font-family: NunitoSans, FontAwesome;

  ::placeholder,
  ::-webkit-input-placeholder {
    color: ${Colors.color7};
  }
  :-ms-input-placeholder {
    color: ${Colors.color7};
  }
`;

// Typography's
export const Title = styled.h1`
    font-size: 32px;
    font-weight: 800;
    color: ${Colors.color2};
  `,
  Subtitle = styled.h2`
    font-size: 26px;
    font-weight: 800;
    color: ${Colors.color2};
  `,
  FormTitle = styled.h3`
    font-size: 18px;
  `,
  GTPLocationTitle = styled.h1`
    font-size: 14px;
    font-weight: 700;
    line-height: 20px;
    color: ${Colors.color12};
  `,
  GTPLocationSubtitle = styled.p`
    font-size: 14px;
    font-weight: 400;
    line-height: 20px;
    color: ${Colors.color19};
  `,
  FontAwesomeText = styled.p`
    font-family: FontAwesome;
    font-weight: 100;
  `,
  InputLabel = styled.h4`
    color: ${(props) => (props.isError ? Colors.color13 : Colors.color19)};
  `;

// Card's stuff
export const Card = styled.div`
    height: 100%;
    border-radius: 15px;
    background-color: ${Colors.color3};
  `,
  CardHeader = styled.div`
    gap: 20px;
    padding: 16px;
    display: flex;
    align-items: center;
    flex-direction: column;
    justify-content: space-between;
    @media screen and (min-width: 768px) {
      flex-direction: row;
      gap: 55px;
    }
  `,
  CardSection = styled.div`
    gap: 25px;
    display: flex;
    align-items: center;
    flex-direction: row;
  `,
  CardSectionForm = styled.form`
    gap: 25px;
    display: flex;
    align-items: center;
    flex-direction: row;
  `,
  CardInputSectionContainer = styled.div`
    row-gap: 34px;
    display: flex;
    font-weight: 800;
    flex-direction: column;
    color: ${Colors.color2};
  `,
  CardFooter = styled.div`
    padding: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  `,
  FormSection = styled.form`
    gap: 35px;
    display: flex;
    flex-direction: row;
  `,
  InputSection = styled.div`
    flex: 1;
    row-gap: 25px;
    display: flex;
    flex-direction: column;
  `,
  InputContainer = styled.div`
    gap: 12px;
    display: flex;
    flex-direction: column;
  `,
  InputRow = styled.div`
    display: flex;
    flex-direction: row;
    gap: 25px;

    div:not(.select-input *) {
      flex: 1;
    }
  `,
  InputField = styled.input`
    border-width: 1px;
    padding: 16px 13px;
    border-style: solid;
    border-radius: 10px;
    border-color: ${(props) =>
      props.isError ? Colors.color13 : Colors.color20};
    font-weight: 700;
    color: ${Colors.color2};
  `,
  TextareaField = styled.textarea`
    border-width: 1px;
    padding: 16px 13px;
    border-style: solid;
    border-radius: 10px;
    border-color: ${(props) =>
      props.isError ? Colors.color13 : Colors.color20};
    font-weight: 700;
    resize: vertical;
    color: ${Colors.color2};
  `,
  Checkbox = styled.input.attrs({ type: "checkbox" })`
    accent-color: ${Colors.color36};
  `,
  ErrorField = styled.small`
    font-size: 12px;
    color: ${Colors.color13};
  `;

// Pagination
export const PaginationButtonContainer = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 8px;
  `,
  PaginationButton = styled.button`
    height: 100%;
    cursor: pointer;
    min-height: 24px;
    font-weight: 500;
    aspect-ratio: 1/1;
    border-radius: 4px;
    border-style: solid;

    border-color: ${(props) => (props.active ? Colors.color36 : "#f5f5f5")};
    background-color: ${(props) => (props.active ? Colors.color36 : "#f5f5f5")};
    color: ${(props) => (props.active ? Colors.color3 : Colors.color12)};
  `;

// Buttons
export const ButtonsContainer = styled.div`
    gap: 15px;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
  `,
  PrimaryButton = styled.button`
    height: 100%;
    cursor: pointer;
    font-size: 16px;
    font-weight: 800;
    padding: 10px 30px;
    border-width: 1.5px;
    border-radius: 10px;
    border-style: solid;
    color: ${Colors.color3};
    border-color: ${Colors.color36};
    background-color: ${Colors.color36};
  `,
  DangerButton = styled.button`
    height: 100%;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    font-size: 16px;
    font-weight: 800;
    padding: 10px 30px;
    border-width: 1.5px;
    border-radius: 10px;
    border-style: solid;
    color: ${Colors.color3};
    border-color: ${(props) =>
      props.disabled ? Colors.color35 + "70" : Colors.color35};
    background-color: ${(props) =>
      props.disabled ? Colors.color35 + "70" : Colors.color35};
  `,
  DangerOrangeButton = styled.button`
    height: 100%;
    cursor: pointer;
    font-size: 16px;
    font-weight: 800;
    padding: 10px 30px;
    border-width: 1.5px;
    border-radius: 10px;
    border-style: solid;
    color: ${Colors.color3};
    border-color: ${Colors.color42};
    background-color: ${Colors.color42};
  `,
  SecondaryButton = styled.button`
    height: 100%;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    font-size: 16px;
    font-weight: 800;
    padding: 10px 30px;
    border-width: 1.5px;
    border-style: solid;
    border-radius: 10px;
    color: ${Colors.color11};
    background-color: ${(props) =>
      props.disabled ? Colors.primaryBgColor2 : "transparent"};
    border-color: ${Colors.color11};
  `,
  ActionWarningButton = styled.button`
    font-size: 13px;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    font-weight: 800;
    padding: 5px 20px;
    border-style: solid;
    border-radius: 10px;
    border-width: 1.5px;
    color: ${Colors.color3};
    border-color: ${Colors.color14};
    background-color: ${Colors.color14};
  `,
  ActionDangerButton = styled.button`
    font-size: 13px;
    cursor: ${(props) => (props.disabled ? "not-allowed" : "pointer")};
    font-weight: 800;
    padding: 5px 20px;
    border-style: solid;
    border-radius: 10px;
    border-width: 1.5px;
    color: ${(props) =>
      props.disabled ? Colors.color13 + "70" : Colors.color13};
    background-color: transparent;
    border-color: ${(props) =>
      props.disabled ? Colors.color13 + "70" : Colors.color13};
  `;

// Table's stuff
export const TableContainer = styled.div`
    overflow-x: auto;
    width: 100% !important;
  `,
  Table = styled.table`
    margin: 0;
    width: 100%;
    min-width: 576px;

    th:nth-child(2),
    td:nth-child(2) {
      text-align: left;
    }

    th:nth-child(1),
    td:nth-child(1) {
      padding: 10px;
    }

    td:not(tr:nth-child(1), td:nth-child(2)) {
      text-align: center;
    }

    tr:nth-child(1) th {
      font-weight: 800;
      color: ${Colors.color12};
      background-color: ${Colors.color6};
    }

    tr:not(tr:nth-child(1)) td {
      padding: 15px 0;
    }

    tr:nth-child(odd) td {
      background-color: ${Colors.color24};
    }
  `,
  Tr = styled.tr``,
  Th = styled.th`
    vertical-align: middle;
  `,
  Td = styled.td`
    vertical-align: middle;
  `;
