import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Dialog from '../modal'
import Button from '../button'

import { StyledDialogSelectAccount } from './StyledDialogSelectAccount'

import { Images } from '../../themes'

function DialogSelectAccount({
  dialogProps,
  onClickBox,
  dialogTitle,
  listAccountType,
  okText,
  dialogDownload,
}) {
  const [accountType, setAccountType] = useState('')


  const box = ({ text, src, iconCheck, onClick }) => {
    return (
      <div className="box" onClick={onClick}>
        <div style={{ width: '100%', padding: '20px' }}>
          <div>
            <img src={src} alt="carasg" className="icon" />
          </div>
          <p className="text">{text}</p>
        </div>
        <div className="icon-check-container">
          <img src={iconCheck} alt="check" />
        </div>
      </div>
    )
  }
  
  return (
    <Dialog
      {...dialogProps}
      isClose={() => {
        setAccountType('')
        dialogProps.isClose()
      }}
      title={dialogTitle}
      width="59%"
      height="auto"
    >
      <StyledDialogSelectAccount>
        <div className="box-container">
          {
            box({ 
              text: listAccountType.pwd,
              src: Images.iconPWD,
              iconCheck: accountType === 'DEMENTIA' ? Images.checkActive : Images.checkInactive,
              onClick: () => {
                setAccountType('DEMENTIA')
                onClickBox('DEMENTIA')
              }
            })
          }
          <div style={{ width: '25px', }} />
          {dialogDownload &&
            box({ 
              text: listAccountType.cg,
              src: Images.iconCG,
              iconCheck: accountType === 'CAREGIVER' ? Images.checkActive : Images.checkInactive,
              onClick: () => {
                setAccountType('CAREGIVER')
                onClickBox('CAREGIVER')
              } 
            })
          }
        </div>
        <div className="button-container">
          <Button
            text="Cancel"
            buttonStyle={
              `
                width: 165px;
              `
            }
            onClick={() => {
              setAccountType('')
              dialogProps.isClose()
            }}
          />
           <div style={{ width: '18px', }} />
          <Button
            text={okText}
            buttonStyle={
              `
                width: 165px;
              `
            }
            onClick={() => {
              setAccountType('')
              dialogProps.onOk(accountType)
            }}
          />
        </div>
      </StyledDialogSelectAccount>
    </Dialog>
  )
}

DialogSelectAccount.propTypes = {
  dialogProps: PropTypes.any,
  onClickBox: PropTypes.func,
  dialogTitle: PropTypes.string,
  listAccountType: PropTypes.any,
  okText: PropTypes.string,
}

DialogSelectAccount.defaultProps = {
  dialogProps: {},
  onClickBox: () => null,
  dialogTitle: "Create new account",
  listAccountType: { cg: 'Care Professional', pwd: 'Person living with dementia (PLWD)' },
  okText: `Next`,
}

export default DialogSelectAccount

