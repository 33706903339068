import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import ReactTable from "react-table-v6";
import { connect } from "react-redux";
import AppConfig from "../../config";

const caraWebUrl = AppConfig.HOSTNAME;

import { Colors, Fonts, Images } from "../../themes";
import {
  StyledTable,
  ButtonDementia,
  ButtonCaregiver,
  buttonAllAccounts,
  buttonAllAplicants,
  SearchBarStyle,
  filterButton,
  ButtonFilter,
} from "./StyledTable";

import FilterUser from "../filter-user";

import { USER_TYPE } from "../../constants";

import Button from "../button";
import Input from "../input";
import RadioButton from "../radio-button/RadioButton";
import colors from "../../themes/Colors";

function Table(props) {
  const [sortDropdown, setSortDropdown] = useState(false);
  const [showFilter, setShowFilter] = useState(true);
  const {
    showTableHeader,
    columns,
    data,
    show,
    setShow,
    loading,
    tableProps,
    onSortBy,
    onlyCreatedByPartner,
    onOnlyCreatedByPartner,
    selectedData,
    onExportSelectedData,
    onExportByLastUpdated,
    dataLimit,
    rowLimit,
    page,
    handleUpdate,
    resetPage,
    pages,
    pageSize,
    onClickBulkActions,
    onClickRow,
    onClickFilterUser,
    onSearch,
    onChangeSearchText,
    onCheckFilter,
    onSendFilter,
    onSearchKey,
    onSetAdditionalFilter,
    account,
    tableHeader,
    queryType,
    isDeleted,
    listBy,
    dateFilter,
    onChangeStartDate,
    onChangeEndDate,
    manualPagination
  } = props;
  const [sortBy, setSortBy] = useState({});

  const onPressEnterSearch = (event) => {
    if (event.key === "Enter") {
      return onSearch();
    }
  };

  useEffect(() => {
    console.log("test data", data);
  }, [data]);

  // console.log("data usernya", data)
  // console.log("data show user type", show)
  // var newData = []
  // if(show === USER_TYPE.CAREGIVER) {
  //   newData= data
  //   // newData = data.filter(o => ((o.metadata && o.metadata.MoP && o.metadata.MoP === true ) && (o.otherAccount && o.otherAccount.length > 0)) || (o.metadata && o.metadata === null ) || (o.metadata && o.metadata.MoP === undefined))
  // } else if (show === USER_TYPE.MOP) {
  //   // newData = data.filter(o => ((o.metadata && o.metadata.MoP && o.metadata.MoP === true) && (o.otherAccount && o.otherAccount.length === 0)) )
  //   newData = data
  // } else {
  //   newData = data
  // }

  // console.log("new datanya ", newData)
  return (
    <StyledTable>
      {showTableHeader && (
        <div>
          <div
            style={{
              display: "flex",
              backgroundColor: Colors.color3,
              borderRadius: "10px 10px",
              marginBottom: "15px",
            }}
          >
            <div
              style={{
                paddingRight: 0,
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "100%",
              }}
            >
              <div className="buttonTableContainer" style={{ paddingRight: 0 }}>
                <Button
                  text="PLWD"
                  buttonStyle={ButtonDementia}
                  backgroundColor={
                    String(queryType).toUpperCase() === USER_TYPE.DEMENTIA
                      ? undefined
                      : Colors.color9
                  }
                  textColor={
                    String(queryType).toUpperCase() === USER_TYPE.DEMENTIA
                      ? undefined
                      : Colors.color7
                  }
                  onClick={() => setShow(USER_TYPE.DEMENTIA)}
                />
                <div className="buttonSeparator" />
                <Button
                  text={
                    account.payload.data.account.type === USER_TYPE.PARTNER
                      ? "Care Professional"
                      : "Caregiver"
                  }
                  buttonStyle={ButtonCaregiver}
                  backgroundColor={
                    String(queryType).toUpperCase() === USER_TYPE.CAREGIVER
                      ? Colors.color10
                      : Colors.color9
                  }
                  textColor={
                    String(queryType).toUpperCase() === USER_TYPE.CAREGIVER
                      ? undefined
                      : Colors.color7
                  }
                  onClick={() => setShow(USER_TYPE.CAREGIVER)}
                />
                {account.payload.data.account.type !== USER_TYPE.PARTNER && (
                  <>
                    <div className="buttonSeparator" />
                    <Button
                      text="Partner"
                      buttonStyle={ButtonCaregiver}
                      backgroundColor={
                        String(queryType).toUpperCase() === USER_TYPE.PARTNER
                          ? Colors.color14
                          : Colors.color9
                      }
                      textColor={
                        String(queryType).toUpperCase() === USER_TYPE.PARTNER
                          ? undefined
                          : Colors.color7
                      }
                      onClick={() => setShow(USER_TYPE.PARTNER)}
                    />
                    <div className="buttonSeparator" />
                    <Button
                      text={"MoP"}
                      buttonStyle={ButtonDementia}
                      backgroundColor={
                        String(queryType) === USER_TYPE.MOP
                          ? Colors.color10
                          : Colors.color9
                      }
                      textColor={
                        String(queryType) === USER_TYPE.MOP
                          ? undefined
                          : Colors.color7
                      }
                      onClick={() => setShow(USER_TYPE.MOP)}
                    />
                    <div className="buttonSeparator" />
                    <Button
                      text={"Care Professional"}
                      buttonStyle={ButtonCaregiver}
                      backgroundColor={
                        String(queryType).toUpperCase() === USER_TYPE.CARE
                          ? Colors.color10
                          : Colors.color9
                      }
                      textColor={
                        String(queryType).toUpperCase() === USER_TYPE.CARE
                          ? undefined
                          : Colors.color7
                      }
                      onClick={() => setShow(USER_TYPE.CARE)}
                    />
                    {props.queryType === "dementia" && (
                      <>
                        <div
                          style={{
                            fontSize: "37px",
                            color: Colors.color20,
                            marginLeft: "10px",
                            marginRight: "10px",
                          }}
                        >
                          |
                        </div>
                        <div
                          style={{
                            display: "flex",
                            alignItems: "center",
                            border: `2px solid ${colors.color20}`,
                            borderRadius: "10px",
                            padding: "0 5px",
                          }}
                        >
                          <p
                            style={{
                              color: Colors.color2,
                              fontSize: "14px",
                              fontWeight: "700",
                            }}
                          >
                            Created By Partner
                          </p>
                          <div style={{ marginTop: "-5px", marginLeft: "3px" }}>
                            <RadioButton
                              buttonStyle={ButtonCaregiver}
                              checked={onlyCreatedByPartner ? true : false}
                              onChange={() =>
                                onOnlyCreatedByPartner(!onlyCreatedByPartner)
                              }
                            />
                          </div>
                        </div>
                      </>
                    )}
                  </>
                )}
                {String(queryType).toUpperCase() !== USER_TYPE.PARTNER && (
                  <>
                    <div className="buttonSeparator" />
                    {/* <Button 
                        text="Sort By"
                        bordered
                        textColor={Colors.color12}
                        buttonStyle={` font-weight: bold; width: 106px; height: 40px; display: flex; justify-content: space-around; `}
                        iconRight
                        onClick={() => setSortDropdown(!sortDropdown)}
                        showDropdown={sortDropdown}
                        isPlwd={ show === 'dementia' ? true : false }
                        onSelectSort={(data) => {
                          setSortBy(data)
                          onSortBy(data)
                          setSortDropdown(!sortDropdown)
                        }}
                        selectedData={sortBy.name}
                      /> */}
                  </>
                )}
              </div>
              <div
                style={{
                  display: "flex",
                  justifyContent: "flex-end",
                  padding: "0 15px 0 0",
                }}
              >
                {/* <div style={{ padding: '15px 15px 0 0' }}> */}
                {account.payload.data.account.type !== USER_TYPE.PARTNER &&
                  account.payload.data.account.type !== USER_TYPE.VIEWER && (
                    <>
                      <div className="buttonSeparator" />
                      <Button
                        text="Bulk Actions"
                        textColor={Colors.color11}
                        bordered
                        buttonStyle={` font-weight: bold; display: flex; justify-content: space-around; width: 120px; height: 40px; `}
                        textStyle={` margin: 0 10px; `}
                        onClick={
                          selectedData.length < 1
                            ? () => alert("Please select an account.")
                            : onClickBulkActions
                        }
                        // disabled={isDeleted}
                      />
                      <div className="buttonSeparator" />
                      <Button
                        text="Add New Member"
                        textColor={Colors.color3}
                        buttonStyle={` font-weight: bold; display: flex; justify-content: space-around; width: 172px; height: 40px; background-color: ${Colors.color36} `}
                        textStyle={` margin: 0 10px; `}
                        onClick={() =>
                          window.open(
                            `${caraWebUrl}/registration?token=${account.payload.data.account.token}`
                          )
                        }
                        // disabled={isDeleted}
                      />
                      <div className="buttonSeparator" />
                      {account &&
                        account.payload &&
                        account.payload.data &&
                        account.payload.data.account &&
                        account.payload.data.account.type &&
                        account.payload.data.account.type ===
                          USER_TYPE.SUPERADMIN && (
                          <Button
                            text="Export"
                            backgroundColor={Colors.color14}
                            textColor={Colors.color3}
                            buttonStyle={` font-weight: bold; display: flex; justify-content: space-around; width: 120px; height: 40px; `}
                            textStyle={` margin: 0 10px; `}
                            onClick={onExportByLastUpdated}
                          />
                        )}
                    </>
                  )}
                {/* </div> */}
              </div>
            </div>
            {/* <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: '17px',  }}>
                <div className="filter-button" onClick={onClickFilterUser}>
                  <img src={Images.filter} />  
                  <span className="filter-text">Search Filter</span>
                </div>
              </div> */}
            {/* <div style={{ display: 'flex', alignItems: 'flex-end', marginBottom: '17px', }}>
                <Button 
                    text="Search Filter"
                    bordered
                    textColor={Colors.color12}
                    buttonStyle={filterButton}
                    iconRight
                    onClick={() => setSortDropdown(!sortDropdown)}
                    showDropdown={sortDropdown}
                    onSelectSort={(data) => {
                      setSortBy(data)
                      onSortBy(data)
                      setSortDropdown(!sortDropdown)
                    }}
                    selectedData={sortBy.name}
                  />
              </div> */}
          </div>
          <div
            style={{
              backgroundColor: Colors.color3,
              borderRadius: "10px 10px 0 0",
              marginTop: "35px",
            }}
          >
            <div
              className="searchbar-container"
              style={{
                paddingRight: 0,
                display: "flex",
                alignItems: "center",
                padding: "0 15px 0 0",
              }}
            >
              <Input
                containerStyle={SearchBarStyle}
                inputProps={{
                  placeholder: "Search",
                  onKeyPress: onSearchKey,
                  onChange: (e) => onChangeSearchText(e.target.value),
                }}
                onKeyDown={(e) => onPressEnterSearch(e)}
              />
              <div className="buttonSeparator" />
              <Button
                text={`Set Filter`}
                buttonStyle={ButtonFilter}
                backgroundColor={Colors.color3}
                textColor={Colors.color36}
                bordered
                borderColor={Colors.color36}
                onClick={() => setShowFilter(!showFilter)}
                iconRight
                iconRightImage={showFilter ? Images.drowpup : Images.drowpdown}
                iconRightStyle={{
                  marginLeft: "5px",
                  width: "14px",
                  height: "14px",
                }}
              />
              <div className="buttonSeparator" />
              <Button
                text="Search"
                buttonStyle={ButtonDementia}
                backgroundColor={Colors.color8}
                textColor={Colors.color3}
                onClick={() => onSearch()}
              />
            </div>
            {showFilter && (
              <FilterUser
                listBy={props.listBy}
                onCheckFilter={onCheckFilter}
                onSetAdditionalFilter={onSetAdditionalFilter}
                onApply={(filterBy, { typeDementia, gender, language }) =>
                  onSendFilter(filterBy, { typeDementia, gender, language })
                }
                dateFilter={props.dateFilter}
                onChangeStartDate={(data) => props.onChangeStartDate(data)}
                onChangeEndDate={(data) => props.onChangeEndDate(data)}
              />
            )}
            {/* <div className="buttonTableContainer">
                {
                  selectedData.length > 0 &&
                    <>
                      <Button 
                        text="Export Selected Data"
                        backgroundColor={Colors.color17}
                        textColor={Colors.color3}
                        buttonStyle={` font-weight: bold; display: flex; justify-content: space-around; width: 172px; height: 40px; `}
                        textStyle={` margin: 0 10px; `}
                        onClick={onExportSelectedData}
                      />
                      <div className="buttonSeparator" />
                    </>
                }
              </div> */}
          </div>
        </div>
      )}
      {tableHeader && <div>{tableHeader}</div>}
      <div style={{ backgroundColor: Colors.color3 }}>
        <ReactTable
          responsive
          manual={manualPagination}
          data={data}
          columns={columns}
          defaultPageSize={dataLimit}
          pageSizeOptions={rowLimit}
          pages={pages}
          pageSize={pageSize}
          show={false}
          page={page}
          onPageChange={handleUpdate}
          showPagination={true}
          showPageJump={true}
          className="-striped -highlight"
          getTdProps={(state, rowInfo, column, instance) => {
            return {
              onClick: (e, handleOriginal) => {
                // console.log('A Td Element was clicked!')
                // console.log('it produced this event:', e)
                // console.log('It was in this column:', column)
                // console.log('It was in this row:', rowInfo)
                // console.log('It was in this table instance:', instance)
                // console.log('state:', state)

                // IMPORTANT! React-Table uses onClick internally to trigger
                // events like expanding SubComponents and pivots.
                // By default a custom 'onClick' handler will override this functionality.
                // If you want to fire the original onClick handler, call the
                // 'handleOriginal' function.
                {
                  /* if (onClickRow) {
                  onClickRow(rowInfo)
                } */
                }
              },
            };
          }}
          loading={loading}
          {...tableProps}
        />
      </div>
    </StyledTable>
  );
}

Table.propTypes = {
  showTableHeader: PropTypes.bool,
  columns: PropTypes.array,
  data: PropTypes.array,
  show: PropTypes.oneOf([USER_TYPE.CAREGIVER, USER_TYPE.DEMENTIA]),
  setShow: PropTypes.func,
  loading: PropTypes.bool,
  tableProps: PropTypes.any,
  onSortBy: PropTypes.func,
  selectedData: PropTypes.array,
  onExportSelectedData: PropTypes.func,
  onExportByLastUpdated: PropTypes.func,
  dataLimit: PropTypes.number,
  rowLimit: PropTypes.array,
  pages: PropTypes.number,
  onClickBulkActions: PropTypes.func,
  onClickRow: PropTypes.func,
  onClickFilterUser: PropTypes.func,
  onSearch: PropTypes.func,
  onChangeSearchText: PropTypes.func,
  onCheckFilter: PropTypes.func,
  onSendFilter: PropTypes.func,
  onSearchKey: PropTypes.func,
  onSetAdditionalFilter: PropTypes.func,
  tableHeader: PropTypes.node,
  listBy: PropTypes.string,
  dateFilter: PropTypes.any,
  onChangeStartDate: PropTypes.func,
  onChangeEndDate: PropTypes.func,
};

Table.defaultProps = {
  showTableHeader: false,
  queryType: "dementia",
  columns: [],
  data: [],
  show: "dementia",
  setShow: () => null,
  loading: false,
  tableProps: {},
  onSortBy: () => null,
  selectedData: [],
  onExportSelectedData: () => null,
  onExportByLastUpdated: () => null,
  onClickBulkActions: () => null,
  onClickRow: () => null,
  onClickFilterUser: () => null,
  onSearch: () => null,
  onChangeSearchText: () => null,
  onCheckFilter: () => null,
  onSendFilter: () => null,
  onSearchKey: () => null,
  onSetAdditionalFilter: () => null,
  tableHeader: null,
  listBy: "",
  dateFilter: {
    startDate: null,
    endDate: null,
  },
  onChangeStartDate: () => null,
  onChangeEndDate: () => null,
};

const mapStateToProps = (state) => {
  return {
    account: state.auth.account,
  };
};

export default connect(mapStateToProps)(Table);
