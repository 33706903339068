import React, { Component } from "react";
import moment from "moment";
import { connect } from "react-redux";
import { StyledNotifications } from "./styles/StyledNotifications";
import Button from "../components/button";
import Table from "../components/table";
import Dialog from "../components/modal";
import DialogInfo from "../components/dialog-info";
import { Colors, Images } from "../themes";
import {
  deleteNotification,
  getNotifications as getNotificationsAPI,
} from "../api/NotificationAPI";
import { USER_TYPE } from "../constants";

class Notifications extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: false,
      notificationData: [],
      dialogConfirmation: false,
      loadingDelete: false,
      notificationId: "",
      page: 1,
      pageSize: 5,
      infoDescription: "",
      infoDialogShow: false,
      pages: 0,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getNotifications();
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevState.page !== this.state.page) {
      this.getNotifications();
    }
  }

  getNotifications = async () => {
    const { page, pageSize } = this.state;
    try {
      const response = await getNotificationsAPI({ page, pageSize });
      this.setState({
        notificationData: response.result,
        page: response.page,
        pages: response.totalPage,
        rows: response.totalRows,
        loading: false,
      });
    } catch (error) {
      this.setState({ loading: false, msg: "Failed to load notifications" });
    }
  };

  handleUpdate = (pageIndex) => {
    this.setState({ loading: true }, () => {
      this.setState({ page: pageIndex });
    });
  };

  deleteNotification = async (id) => {
    this.setState({ loadingDelete: true });
    try {
      await deleteNotification({ id });
      this.setState({
        loadingDelete: false,
        dialogConfirmation: false,
        loading: true,
      });
      this.onDialogInfo("Notification Deleted");
      this.getNotifications();
    } catch (error) {
      console.error("Error deleting notification:", error);
      this.setState({
        loadingDelete: false,
        dialogConfirmation: false,
        msg: "Failed to delete notification",
      });
      this.onDialogInfo(error.message);
    }
  };

  onDialogInfo = (val) => {
    this.setState({ infoDescription: val, infoDialogShow: true });
  };

  render() {
    const {
      notificationData,
      loading,
      dialogConfirmation,
      infoDescription,
      infoDialogShow,
      loadingDelete,
      notificationId,
      page,
      pages,
      pageSize,
    } = this.state;

    const columns = [
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>No</span>
        ),
        Cell: (props) => (
          <span
            className="column-text"
            onClick={() =>
              this.props.history.push(`/notifications/${props.original.id}`)
            }
          >
            {(page - 1) * pageSize + props.index + 1}
          </span>
        ),
        headerClassName: "table-header header-no",
        className: "column text-no",
        width: 70,
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            Notification
          </span>
        ),
        Cell: (props) => (
          <span
            className="column-text"
            onClick={() =>
              this.props.history.push(`/notifications/${props.original.id}`)
            }
          >
            {props.original.title}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            Status
          </span>
        ),
        Cell: (props) => (
          <span
            className="column-text"
            onClick={() =>
              this.props.history.push(`/notifications/${props.original.id}`)
            }
          >
            {props.original.status}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            Platform
          </span>
        ),
        Cell: (props) => (
          <span
            className="column-text"
            onClick={() =>
              this.props.history.push(`/notifications/${props.original.id}`)
            }
          >
            {props.original.platform}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
        width: 120,
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            Start/Send
          </span>
        ),
        Cell: (props) => (
          <span
            className="column-text"
            onClick={() =>
              this.props.history.push(`/notifications/${props.original.id}`)
            }
          >
            {props.original.NotificationSchedule
              ? moment(props.original.NotificationSchedule.dateSend).format(
                  "DD/MM/YYYY, h:mm A"
                )
              : "-"}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
        width: 180,
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>End</span>
        ),
        Cell: (props) => (
          <span
            className="column-text"
            onClick={() =>
              this.props.history.push(`/notifications/${props.original.id}`)
            }
          >
            {"-"}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            Push Notifications
          </span>
        ),
        Cell: (props) => (
          <span
            className="column-text"
            onClick={() =>
              this.props.history.push(`/notifications/${props.original.id}`)
            }
          >
            {parseInt(props.original.total) < 1000 ? `<1000` : `>1000`}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
        width: 150,
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            Total sent
          </span>
        ),
        Cell: (props) => (
          <span
            className="column-text"
            onClick={() =>
              this.props.history.push(`/notifications/${props.original.id}`)
            }
          >
            {props.original.sentTo > 0 ? props.original.sentTo : 0}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
        width: 150,
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            Opened By
          </span>
        ),
        Cell: (props) => (
          <span
            className="column-text"
            onClick={() =>
              this.props.history.push(`/notifications/${props.original.id}`)
            }
          >
            {props.original.opened > 0 ? props.original.opened : 0}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
        width: 150,
      },
    ];

    if (
      this.props.account &&
      this.props.account.payload &&
      this.props.account.payload.data &&
      this.props.account.payload.data.account &&
      this.props.account.payload.data.account.type &&
      this.props.account.payload.data.account.type === USER_TYPE.SUPERADMIN
    ) {
      columns.push({
        headerClassName: "table-header",
        className: "actions-column",
        width: 40,
        Cell: (row) => (
          <div className="icon-container">
            <div
              className="trash-wrapper"
              onClick={() =>
                this.setState({
                  dialogConfirmation: true,
                  notificationId: row.original.id,
                })
              }
            >
              <img src={Images.trash} className="delete" alt="delete" />
            </div>
          </div>
        ),
      });
    }

    return (
      <StyledNotifications>
        <Dialog
          isVisible={dialogConfirmation}
          isClose={() => this.setState({ dialogConfirmation: false })}
          title="Delete Notification"
          showXClose={false}
          additionalStyle={{
            titleStyle: `color: ${Colors.color13} !important;`,
          }}
          description={`Are you sure you want to delete this notification?`}
          width={`37%`}
          height={`auto`}
          cancelText={`Decline`}
          okText={loadingDelete ? `Loading...` : `Delete`}
          okBorderColor={Colors.color13}
          okTextColor={Colors.color13}
          onOk={() => this.deleteNotification(notificationId)}
        />

        {/* Dialog info start */}
        <DialogInfo
          textInfo={infoDescription}
          isVisible={infoDialogShow}
          onOkButton={() => this.setState({ infoDialogShow: false })}
        />
        {/* Dialog info end  */}

        <div className="title-container">
          <h1 className="title">Notifications</h1>
          {this.props.account &&
            this.props.account.payload &&
            this.props.account.payload.data &&
            this.props.account.payload.data.account &&
            this.props.account.payload.data.account.type &&
            this.props.account.payload.data.account.type !==
              USER_TYPE.VIEWER && (
              <Button
                text="Create New Notification"
                buttonStyle={`width: 315px;`}
                textStyle={`font-weight: 800;`}
                onClick={() => this.props.history.push("/create-notification")}
              />
            )}
        </div>
        <Table
          columns={columns}
          responsive
          data={notificationData}
          manualPagination={true}
          loading={loading}
          page={page - 1}
          pages={pages}
          pageSize={pageSize}
          handleUpdate={(pageIndex) => this.handleUpdate(pageIndex + 1)}
          rowLimit={[pageSize]}
          dataLimit={pageSize}
        />
      </StyledNotifications>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.auth.account,
  };
};

export default connect(mapStateToProps)(Notifications);
