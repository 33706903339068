const USER_TYPE = {
  ADMIN: 'ADMIN',
  SUPERADMIN: 'SUPER_ADMIN',
  VIEWER: 'VIEWER',
  CAREGIVER: 'CAREGIVER',
  DEMENTIA: 'DEMENTIA',
  PARTNER: 'PARTNER',
  MOP: 'MoP',
  CARE: 'CARE'
};

export default USER_TYPE;
