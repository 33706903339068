import { combineReducers } from 'redux';
import { persistStore, persistReducer } from 'redux-persist'
import ReduxPersist from '../config/ReduxPersist'
import configureStore from './CreateStore';
import rootSaga from '../sagas';

/* ------------- Assemble The Reducers ------------- */
export const appReducer = combineReducers({
  auth: require('./AuthRedux').reducer,
  startup: require('./StartupRedux').reducer,
  user: require('./UserRedux').reducer,
  benefits: require('./BenefitsRedux').reducer,
  solutions: require('./SolutionsRedux').reducer,
  notifications: require('./NotificationsRedux').reducer,
  safeReturn: require('./SafeReturnRedux').reducer,
  dashboard: require('./DashboardPartner').reducer,
  reportMissing: require('./ReportMissingRedux').reducer,
});

export const reducers = (state, action) => {
  if(action.type === 'SIGNOUT') {
    return appReducer(undefined, action)
  }
  return appReducer(state, action)
}

export default () => {
  let finalReducers = reducers;

  if (ReduxPersist.active) {
    const persistConfig = ReduxPersist.storeConfig
    finalReducers = persistReducer(persistConfig, reducers)
  }

  let { history, store, sagasManager, sagaMiddleware } = configureStore(
    finalReducers,
    rootSaga
  );

  const persistor = persistStore(store)

  if (module.hot) {
    module.hot.accept(() => {
      const nextRootReducer = require('.').reducers;
      store.replaceReducer(nextRootReducer);

      const newYieldedSagas = require('../sagas').default;
      sagasManager.cancel();
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware.run(newYieldedSagas);
      });
    });
  }

  return {
    history,
    store,
    persistor,
  };
};
