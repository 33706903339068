// Deps
import axios from "axios";
import Select from "react-select";
import { connect } from "react-redux";
import React, { Component } from "react";
import ReactMapGL, { AttributionControl, Marker } from "react-map-gl/maplibre";

// Components
import {
  Card,
  Spin,
  Title,
  Subtitle,
  InputRow,
  Container,
  FormTitle,
  InputLabel,
  InputField,
  FormSection,
  InputSection,
  PrimaryButton,
  TextareaField,
  InputContainer,
  SecondaryButton,
  FontAwesomeText,
  CardInputSectionContainer,
  ErrorField,
} from "./styles/GoToPoints";

// Common
import debounce from "../lib/debounce";

// External styling
import "maplibre-gl/dist/maplibre-gl.css";

// Saga
import { api } from "../sagas";

// Constants
import { Colors } from "../themes";

export class GoToPoints extends Component {
  constructor(props) {
    super(props);

    this.state = {
      // Form stuff
      generatedFrom: "self",
      regionOptions: [
        { value: "CENTRAL", label: "Central" },
        { value: "NORTH", label: "North" },
        { value: "WEST", label: "West" },
        { value: "EAST", label: "East" },
      ],
      form: {
        region: "",
        name: "",
        contactNumber: "",
        postalCode: "",
        address: "",
        openingHours: "",
        specialNote: "",
        lat: 1.350117,
        lng: 103.8497632,
      },

      formErrors: [],

      // Get single stuff
      isFindOneBusy: false,

      // Submit stuff
      isSubmitBusy: false,
    };
  }

  componentDidUpdate(_, prevState) {
    if (
      (prevState.form.address !== this.state.form.address ||
        this.state.form.postalCode !== prevState.form.postalCode) &&
      this.state.generatedFrom === "self"
    )
      debounce(
        () =>
          axios
            .get("https://www.onemap.gov.sg/api/common/elastic/search", {
              params: {
                searchVal:
                  prevState.form.address !== this.state.form.address
                    ? this.state.form.address
                    : this.state.form.postalCode,
                returnGeom: "Y",
                getAddrDetails: "Y",
                pageNum: 1,
              },
            })
            .then((res) => {
              if (
                res.data?.results?.[0]?.LATITUDE &&
                res.data?.results?.[0]?.LONGITUDE
              )
                this.setState({
                  generatedFrom: "self",
                  form: {
                    ...this.state.form,
                    lat: res.data.results[0].LATITUDE,
                    lng: res.data.results[0].LONGITUDE,
                  },
                });
            }),
        500
      );
  }

  componentDidMount() {
    if (this.props.match.params.id) this.handleFindOneGTP();
  }

  handleFindOneGTP = async () => {
    this.setState({
      isFindOneBusy: true,
    });
    await api
      .findOneGoToPoints(this.props.match.params.id)
      .then((res) => {
        if (res.data.data) {
          const {
            lat,
            lng,
            name,
            region,
            address,
            postalCode,
            specialNote,
            openingHours,
            contactNumber,
          } = res.data.data;

          return this.setState({
            generatedFrom: "system",
            form: {
              ...(this.state.form || {}),
              lat,
              lng,
              name,
              region,
              address,
              postalCode,
              specialNote,
              openingHours,
              contactNumber,
            },
          });
        }

        throw new Error();
      })
      .catch(() => this.props.history.goBack())
      .finally(() => {
        this.setState({
          isFindOneBusy: false,
        });
      });
  };

  handleSubmit = async () => {
    let formErrors = [];

    ["name", "region", "postalCode", "address", "openingHours"].forEach(
      (el) => {
        if (!this.state.form[el])
          formErrors.push({ key: el, message: "This field cannot be empty" });
      }
    );

    if (formErrors.length)
      return this.setState({
        formErrors,
      });

    try {
      this.setState({
        isSubmitBusy: true,
      });

      if (this.props.match.params.id)
        await api.updateGoToPoints(this.props.match.params.id, this.state.form);
      else {
        const data = await api.createGoToPoints(this.state.form);
        if (data?.data?.data === "This location already exist")
          return this.setState({
            formErrors: [
              {
                key: "all",
                message:
                  "This location already exist. Try another postal code / name",
              },
            ],
          });
      }
      this.props.history.goBack();
    } catch (e) {
    } finally {
      this.setState({
        isSubmitBusy: false,
      });
    }
  };

  render() {
    return (
      <Container>
        <Title>Go-To-Point Management</Title>

        <Card
          style={{
            padding: "30px",
          }}
        >
          <CardInputSectionContainer>
            <Subtitle>
              {this.props.match.url?.split?.("/")?.filter?.((el) => el)?.[1] ===
                "update" && this.props.match.params.id
                ? "Edit"
                : "Create"}{" "}
              Location
            </Subtitle>

            {!this.state.isFindOneBusy ? (
              <FormSection
                onSubmit={(e) => {
                  e.preventDefault();
                  this.handleSubmit();
                }}
              >
                <InputSection>
                  <FormTitle>Location Detail</FormTitle>

                  <InputRow>
                    <InputContainer>
                      <InputLabel
                        isError={
                          !!this.state.formErrors.filter(
                            (el) => el.key === "region"
                          ).length
                        }
                      >
                        Region
                      </InputLabel>
                      <Select
                        className="select-input"
                        styles={{
                          control: (base) => ({
                            ...base,
                            borderColor: !!this.state.formErrors.filter(
                              (el) => el.key === "region"
                            ).length
                              ? Colors.color13
                              : Colors.color20,
                            minHeight: "49.5px",
                            borderRadius: "10px",
                          }),
                          indicatorSeparator: () => ({
                            borderWidth: 0,
                          }),
                        }}
                        placeholder=""
                        isSearchable
                        onChange={(e) =>
                          this.setState({
                            formErrors: this.state.formErrors.filter(
                              (el) => el.key !== "region"
                            ),
                            generatedFrom: "self",
                            form: {
                              ...this.state.form,
                              region: e.value,
                            },
                          })
                        }
                        value={
                          this.state.form.region &&
                          this.state.regionOptions.filter(
                            (el) => el.value === this.state.form.region
                          )
                        }
                        options={this.state.regionOptions}
                      />

                      {this.state.formErrors
                        .filter((el) => el.key === "region")
                        .map((el) => (
                          <ErrorField>{el.message || ""}</ErrorField>
                        ))}
                    </InputContainer>
                    <InputContainer>
                      <InputLabel
                        isError={
                          !!this.state.formErrors.filter(
                            (el) => el.key === "name"
                          ).length
                        }
                      >
                        GTP Name
                      </InputLabel>
                      <InputField
                        isError={
                          !!this.state.formErrors.filter(
                            (el) => el.key === "name"
                          ).length
                        }
                        value={this.state.form.name}
                        onChange={(e) =>
                          this.setState({
                            formErrors: this.state.formErrors.filter(
                              (el) => el.key !== "name"
                            ),
                            generatedFrom: "self",
                            form: {
                              ...this.state.form,
                              name: e.target.value,
                            },
                          })
                        }
                      />

                      {this.state.formErrors
                        .filter((el) => el.key === "name")
                        .map((el) => (
                          <ErrorField>{el.message || ""}</ErrorField>
                        ))}
                    </InputContainer>
                  </InputRow>

                  <InputRow>
                    <InputContainer>
                      <InputLabel
                        isError={
                          !!this.state.formErrors.filter(
                            (el) => el.key === "contactNumber"
                          ).length
                        }
                      >
                        Contact Number
                      </InputLabel>
                      <InputField
                        isError={
                          !!this.state.formErrors.filter(
                            (el) => el.key === "contactNumber"
                          ).length
                        }
                        value={this.state.form.contactNumber}
                        onChange={(e) =>
                          this.setState({
                            formErrors: this.state.formErrors.filter(
                              (el) => el.key !== "contactNumber"
                            ),
                            generatedFrom: "self",
                            form: {
                              ...this.state.form,
                              contactNumber: e.target.value,
                            },
                          })
                        }
                      />

                      {this.state.formErrors
                        .filter((el) => el.key === "contactNumber")
                        .map((el) => (
                          <ErrorField>{el.message || ""}</ErrorField>
                        ))}
                    </InputContainer>
                    <InputContainer>
                      <InputLabel
                        isError={
                          !!this.state.formErrors.filter(
                            (el) => el.key === "postalCode"
                          ).length
                        }
                      >
                        Postal Code
                      </InputLabel>
                      <InputField
                        isError={
                          !!this.state.formErrors.filter(
                            (el) => el.key === "postalCode"
                          ).length
                        }
                        value={this.state.form.postalCode}
                        onChange={(e) =>
                          this.setState({
                            formErrors: this.state.formErrors.filter(
                              (el) => el.key !== "postalCode"
                            ),
                            generatedFrom: "self",
                            form: {
                              ...this.state.form,
                              postalCode: isNaN(Number(e.target.value))
                                ? this.state.form.postalCode
                                : e.target.value,
                            },
                          })
                        }
                      />

                      {this.state.formErrors
                        .filter((el) => el.key === "postalCode")
                        .map((el) => (
                          <ErrorField>{el.message || ""}</ErrorField>
                        ))}
                    </InputContainer>
                  </InputRow>

                  <InputContainer>
                    <InputLabel
                      isError={
                        !!this.state.formErrors.filter(
                          (el) => el.key === "address"
                        ).length
                      }
                    >
                      Address
                    </InputLabel>
                    <InputField
                      isError={
                        !!this.state.formErrors.filter(
                          (el) => el.key === "address"
                        ).length
                      }
                      value={this.state.form.address}
                      onChange={(e) =>
                        this.setState({
                          formErrors: this.state.formErrors.filter(
                            (el) => el.key !== "address"
                          ),
                          generatedFrom: "self",
                          form: {
                            ...this.state.form,
                            address: e.target.value,
                          },
                        })
                      }
                    />

                    {this.state.formErrors
                      .filter((el) => el.key === "address")
                      .map((el) => (
                        <ErrorField>{el.message || ""}</ErrorField>
                      ))}
                  </InputContainer>

                  <InputContainer>
                    <InputLabel
                      isError={
                        !!this.state.formErrors.filter(
                          (el) => el.key === "openingHours"
                        ).length
                      }
                    >
                      Opening hours
                    </InputLabel>
                    <InputField
                      isError={
                        !!this.state.formErrors.filter(
                          (el) => el.key === "openingHours"
                        ).length
                      }
                      value={this.state.form.openingHours}
                      onChange={(e) =>
                        this.setState({
                          formErrors: this.state.formErrors.filter(
                            (el) => el.key !== "openingHours"
                          ),
                          generatedFrom: "self",
                          form: {
                            ...this.state.form,
                            openingHours: e.target.value,
                          },
                        })
                      }
                    />

                    {this.state.formErrors
                      .filter((el) => el.key === "openingHours")
                      .map((el) => (
                        <ErrorField>{el.message || ""}</ErrorField>
                      ))}
                  </InputContainer>

                  <InputContainer>
                    <InputLabel
                      isError={
                        !!this.state.formErrors.filter(
                          (el) => el.key === "specialNote"
                        ).length
                      }
                    >
                      Special Note
                    </InputLabel>
                    <TextareaField
                      isError={
                        !!this.state.formErrors.filter(
                          (el) => el.key === "specialNote"
                        ).length
                      }
                      value={this.state.form.specialNote}
                      onChange={(e) =>
                        this.setState({
                          formErrors: this.state.formErrors.filter(
                            (el) => el.key !== "specialNote"
                          ),
                          generatedFrom: "self",
                          form: {
                            ...this.state.form,
                            specialNote: e.target.value,
                          },
                        })
                      }
                    />

                    {this.state.formErrors
                      .filter((el) => el.key === "specialNote")
                      .map((el) => (
                        <ErrorField>{el.message || ""}</ErrorField>
                      ))}
                  </InputContainer>
                  {this.state.formErrors
                    .filter((el) => el.key === "all")
                    .map((el, i) => (
                      <ErrorField key={`error-all-${i}`}>
                        {el.message || ""}
                      </ErrorField>
                    ))}
                </InputSection>

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: "50px",
                  }}
                >
                  <div>
                    <ReactMapGL
                      style={{
                        width: "500px",
                        aspectRatio: "1/1",
                      }}
                      attributionControl={false}
                      maxBounds={[103.596, 1.1443, 104.1, 1.4835]}
                      mapStyle="https://www.onemap.gov.sg/maps/json/raster/mbstyle/Default.json"
                    >
                      <Marker
                        draggable
                        key={"gtp-exact"}
                        onDragEnd={(e) => {
                          this.setState({
                            generatedFrom: "self",
                            form: {
                              ...this.state.form,
                              lat: e.lngLat.lat,
                              lng: e.lngLat.lng,
                            },
                          });
                        }}
                        latitude={this.state.form.lat}
                        longitude={this.state.form.lng}
                      />

                      <AttributionControl
                        customAttribution={
                          '<img src="https://www.onemap.gov.sg/web-assets/images/logo/om_logo.png" style="height:20px;width:20px;"/>&nbsp;<a href="https://www.onemap.gov.sg/" target="_blank" rel="noopener noreferrer">OneMap</a>&nbsp;&copy;&nbsp;contributors&nbsp;&#124;&nbsp;<a href="https://www.sla.gov.sg/" target="_blank" rel="noopener noreferrer">Singapore Land Authority</a>'
                        }
                      />
                    </ReactMapGL>
                  </div>

                  <div
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      gap: "15px",
                      justifyContent: "end",
                    }}
                  >
                    <SecondaryButton
                      type="button"
                      style={{
                        flex: 1,
                      }}
                      onClick={() => this.props.history.push("/go-to-points")}
                    >
                      Discard
                    </SecondaryButton>
                    <PrimaryButton
                      type="submit"
                      style={{
                        flex: 1,
                      }}
                    >
                      Save
                    </PrimaryButton>
                  </div>
                </div>
              </FormSection>
            ) : (
              <div
                style={{
                  gap: 14,
                  display: "flex",
                  flexDirection: "row",
                  alignItems: "center",
                }}
              >
                <Spin>
                  <FontAwesomeText
                    style={{
                      fontSize: 28,
                      color: Colors.color11,
                    }}
                  >
                    &#xf110;
                  </FontAwesomeText>
                </Spin>
                <h1
                  style={{
                    color: Colors.color11,
                  }}
                >
                  Please wait
                </h1>
              </div>
            )}
          </CardInputSectionContainer>
        </Card>
      </Container>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {};
};

export default connect(mapStateToProps, mapDispatchToProps)(GoToPoints);
