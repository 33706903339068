import { call, put, all, actionChannel} from 'redux-saga/effects'
import SolutionsActions from '../redux/SolutionsRedux'

export function* getSolutions(api, action) {
  let { data } = action
  
  const response = yield call(api.getSolutions, data)
  if (response.ok) {    
    yield put(SolutionsActions.getSolutionsSuccess(response.data))   
  } else {
    yield put(SolutionsActions.getSolutionsFailure(response))
  }
}

export function* addSolution(api, action) {
  let { data } = action
  
  const response = yield call(api.addSolution, data)
  if (response.ok) {    
    yield put(SolutionsActions.addSolutionSuccess(response.data))   
  } else {
    yield put(SolutionsActions.addSolutionFailure(response))
  }
}

export function* deleteSolution(api, action) {
  let { data } = action
  
  const response = yield call(api.deleteSolution, data)
  if (response.ok) {    
    yield put(SolutionsActions.deleteSolutionSuccess(response.data))   
  } else {
    yield put(SolutionsActions.deleteSolutionFailure(response))
  }
}

export function* updateSolution(api, action) {
  let { data } = action
  
  const response = yield call(api.updateSolution, data)
  if (response.ok) {    
    yield put(SolutionsActions.updateSolutionSuccess(response.data))   
  } else {
    yield put(SolutionsActions.updateSolutionFailure(response))
  }
}

export function* getSolutionCategories(api, action) {
  let { data } = action
  
  const response = yield call(api.getSolutionCategories, data)
  if (response.ok) {    
    yield put(SolutionsActions.getSolutionCategoriesSuccess(response.data))   
  } else {
    yield put(SolutionsActions.getSolutionCategoriesFailure(response))
  }
}

export function* addSolutionCategory(api, action) {
  let { data } = action
  
  const response = yield call(api.addSolutionCategory, data)
  if (response.ok) {    
    yield put(SolutionsActions.addSolutionCategorySuccess(response.data))   
  } else {
    yield put(SolutionsActions.addSolutionCategoryFailure(response))
  }
}

export function* deleteSolutionCategory(api, action) {
  let { data } = action
  
  const response = yield call(api.deleteSolutionCategory, data)
  if (response.ok) {    
    yield put(SolutionsActions.deleteSolutionCategorySuccess(response.data))   
  } else {
    yield put(SolutionsActions.deleteSolutionCategoryFailure(response))
  }
}

export function* updateSolutionCategory(api, action) {
  let { data } = action
  
  const response = yield call(api.updateSolutionCategory, data)
  if (response.ok) {    
    yield put(SolutionsActions.updateSolutionCategorySuccess(response.data))   
  } else {
    yield put(SolutionsActions.updateSolutionCategoryFailure(response))
  }
}
