import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';

export const StyledDialogDetailUser = styled.div`
  overflow: auto !important;

  .container-modal {
    padding: 30px;
    padding-bottom: 22px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${props => props.height};
  }
  .title {
    font-family: NunitoSans;
    font-weight: bold;
    color: ${Colors.color11};
    font-size: 25px;
    padding-top: 10px;
  }
  .title2 {
    font-family: NunitoSans;
    font-weight: bold;
    color: ${Colors.color13};
    font-size: 25px;
    padding-top: 10px;
  }
  .symbol {
    font-family: NunitoSans;
    font-weight: bold;
    color: ${Colors.color};
    font-size: 25px;
    padding: 15px 15px 10px 15px;
  }
  .close {
    width: 30px;
    height: 30px;
    object-fit: contain;
    margin-top: 10px;
  }
  .card-container {
    margin-top: 20px;
  }
  .textDetail {
    font-family: NunitoSans;
    font-weight: bold;
    font-size: 14px;
  }
  .textDetail2 {
    font-family: NunitoSans;
    font-weight: bold;
    font-size: 14px;
    padding-right: 20px;
  }
  .paddingDetail {
    padding: 10px;
  }
`