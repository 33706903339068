import styled from 'styled-components'
import { Fonts, Colors } from './'
import { device } from '../constants/deviceSize'

const Title = styled.h1`
  ${Fonts.FontRegular}
  ${Fonts.size.size32}
  font-weight: 800;
  color: ${Colors.color2};
  ${props => props.customStyle}
`

const SubTitle = styled.h1`
  ${Fonts.FontNexaLight}
  font-size: 14px;
  font-weight: 800;
  color: ${Colors.lightblue};
  ${props => props.customStyles}
`

const DialogTitle = styled.h1`
  ${Fonts.FontAvenir}
  ${Fonts.size.dialogTitleSize}
  font-weight: bold;
  text-align: center;
  color: ${Colors.lightblue};
  margin: 1em 0;
  ${props => props.customStyles}
`

const Paragraph = styled.p`
  ${Fonts.FontAvenir}
  color: ${Colors.paragraph};
  ${props => props.customStyles}
`

const ErrorText = styled.p`
  ${Fonts.FontRegular}
  ${Fonts.size.size16}
  font-weight: bold;
  color: ${Colors.color15};
  text-align: center;
  ${props => props.customStyles}
`

const FooterText = styled.p`
  ${Fonts.FontNexaLight}
  color: ${Colors.white};
  ${props => props.customStyles}
`

export default {
  Title,
  SubTitle,
  DialogTitle,
  Paragraph,
  ErrorText,
  FooterText
}