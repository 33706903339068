import qs from "querystring";
import axios from "axios";
// import $ from "../config/AppConfig";

export const API = (props) => {
  const { url = '', method = '', data = {}, headers = {}, timeout = 60000, responseType = 'json', params = {}, } = props
  return new Promise((resolve, reject) => {
    axios({
      url,
      method,
      data,
      headers,
      timeout,
      responseType,
      params,
    })
    .then(resp => {
      resolve(resp.data);
    })
    .catch(err => {
      reject(err);
    });
  });
}