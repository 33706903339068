import React, {createRef} from "react";
import Pdf from "react-to-pdf";
import moment from "moment";

import { Colors, Container, Images, } from '../../themes'
import {StyledPdfFile} from './StyledPdfFile';
import Button from "../button";

function PDFFile({detailReports}) {
  const detailReport = detailReports ? detailReports : {}
  const ref = React.createRef();
  const today = new Date()
  const birthDate = detailReport.detailPlwd && new Date(detailReport.detailPlwd.birth);
  const lastSeenDate = detailReport.lastSeenDatetime !== null ? new Date(detailReport.lastSeenDatetime) : false
  const age = birthDate === null ? '-' : today.getFullYear() - birthDate.getFullYear();
  const dayname = lastSeenDate ? moment(lastSeenDate).format('dddd') : '-';

  return (
    <div>
      <Pdf targetRef={ref} scale={1} filename="ReportMissing.pdf">
        {({ toPdf }) => <Button 
                text="+ Download as PDF"
                buttonStyle={{width: '165px', height: '40px'}}
                bordered={true}
                borderColor={Colors.color11}
                textColor={Colors.color11}
                onClick={toPdf}
              />}
      </Pdf>
      <StyledPdfFile ref={ref}>
        <div className="container-header">
          <div className="title">HELP LOOK OUT FOR</div>
        </div>
        <div className="name">{detailReport.detailPlwd && detailReport.detailPlwd.displayName ? detailReport.detailPlwd.displayName.toUpperCase() : '-'}</div>
        <div className="label" style={{textAlign: 'center'}}>{`${detailReport.detailPlwd.gender.toUpperCase()} | AGE: ${age} | HEIGHT: ${detailReport.height} CM`}</div>
        <div className="label" style={{color: Colors.color13, textAlign: 'center'}}>{`Last Seen: ${!lastSeenDate ? '-' : `${dayname} ${moment(lastSeenDate).format("YYYY-MM-DD HH:mm")}`}`}</div>
        <div className="container-detail">
          <hr />
          <div className="container-row">
            <div>
              <div className="container-place">
                <div className="label" style={{color: Colors.color13}}>Last Seen Location</div>
                <div className="label">{detailReport.lastSeenLocation ? detailReport.lastSeenLocation : '-'}</div>
                <div className="label" style={{color: Colors.color13, marginTop: 5}}>Last Known Attire</div>
                <div className="label">{detailReport.lastSeenAttire ? detailReport.lastSeenAttire : '-'}</div>
                <div className="label" style={{color: Colors.color13, marginTop: 5}}>Places Frequented</div>
                <div className="label">{detailReport.placesFrequented ? detailReport.placesFrequented : '-'}</div>
              </div>
              <div className="container-traits">
                <div className="label">Other Physical or Behavioral Traits :</div>
                <div className="container-traitsText">
                  <div className="label">{detailReport.physicalTrait ? detailReport.physicalTrait : '-'}</div>
                </div>
              </div>
            </div>
            {detailReport && detailReport.images && detailReport.images.length > 0  &&
            <div className="container-image">
              <div className="row-image">
                <img className="image" src={detailReport.images && detailReport.images.length > 0 && detailReport.images[0].imageLink} style={{borderTopLeftRadius: 10}}/>
                <img className="image" src={detailReport.images && detailReport.images.length > 1 && detailReport.images[1].imageLink} style={{borderTopRightRadius: 10}}/>
              </div>
              <div className="row-image" style={{marginTop: '20px'}}>
                <img className="image" src={detailReport.images && detailReport.images.length > 2 && detailReport.images[2].imageLink} style={{borderBottomLeftRadius: 10}} />
                <img className="image" src={detailReport.images && detailReport.images.length > 3 && detailReport.images[3].imageLink} style={{borderBottomRightRadius: 10}} />
              </div>
            </div>
            }
          </div>
          <div className="container-contact">
            <div className="label" style={{color: Colors.color13, textAlign: 'center', fontSize: "16px"}}>{`IF YOU HAVE ANY INFORMATION ABOUT ${detailReport.detailPlwd.displayName ? detailReport.detailPlwd.displayName.toUpperCase() : '-'}, PLEASE INFORM CONTACT BELOW`}</div>
            <div className="label" style={{fontSize: '20px', textAlign: 'center', paddingTop: 5}}>{`${detailReport.detailCg.displayName ? detailReport.detailCg.displayName.toUpperCase(): '-'} (${detailReport.detailCg.prefixCountryNum} ${detailReport.detailCg.phone ? detailReport.detailCg.phone : ''})`}</div>
          </div>
        </div>
      </StyledPdfFile>
    </div>
  )
}

export default PDFFile;