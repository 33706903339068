import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect } from "react-router-dom";
import memoize from "memoize-one";
import moment from "moment";
import { USER_TYPE } from "../constants";
import AppConfig from "../config.js";

import {
  StyledAdminManagement,
  InputStyle,
  Label,
} from "./styles/StyledAdminManagement";

import Button from "../components/button";
import Table from "../components/table";
import DialogAdmin from "../components/dialogAdmin";
import Dialog from "../components/modal";
import DialogInfo from "../components/dialog-info";
import Pagination from "../components/pagination";

import { Colors, Images } from "../themes";

import UserActions from "../redux/UserRedux";
import AuthActions from "../redux/AuthRedux";

import ProccessData from "../lib/ProccessData";

import { createAdmin } from "../api/AdminAPI";
import { deleteUser } from "../api/UserAPI";

class AdminManagement extends Component {
  constructor(props) {
    super(props);

    this.state = {
      adminList: [],
      loading: false,
      dialogAdd: false,
      formInput: {
        firstname: "",
        username: "",
        phone: "",
        password: "",
        type: "",
        // page: 1,
      },
      error: "",
      dialogConfirmation: false,
      uidDelete: null,
      loadingDelete: false,
      infoDescription: "",
      infoDialogShow: false,
      page: 1,
      totalPage: 1,
    };
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.getAdmin();
  }

  setData = memoize((data) => {
    ProccessData(data, "Get admin list data")
      .then((res) => {
        this.setState({
          loading: false,
          adminList: res.data.listData,
          totalPage: res.data.totalPages,
        });
      })
      .catch((err) => {
        alert(err ? err : "ERROR");
        this.setState({
          loading: false,
        });
      });
  });

  closeModal() {
    this.setState({
      dialogAdd: false,
    });
  }

  onCreateAdmin = async () => {
    const {
      firstname,
      username,
      email,
      phone,
      password,
      type,
    } = this.state.formInput;
    const createAdminParams = {
      credential: {
        credentialType: "ADMIN",
        credentialData: {
          username,
          email,
          password,
          phone,
        },
        accountData: {
          username,
          email,
          firstname,
          lastname: "",
          gender: "male",
          birth: moment().format("YYYY-MM-DD"),
          phone,
          type,
        },
      },
    };
    this.setState({ loading: true });
    try {
      let resultAdd = {};
      resultAdd.fetching = false;
      resultAdd.payload = await createAdmin(createAdminParams);
      ProccessData(resultAdd, "add new admin")
        .then((res) => {
          this.setState({
            dialogAdd: false,
          });
          this.getAdmin();
        })
        .catch((err) => {
          this.setState({
            loading: false,
            dialogAdd: false,
            error: err,
          });
          this.onDialogInfo(err);
        });
    } catch (err) {
      this.setState({
        loading: false,
        dialogAdd: false,
        error: err,
      });
      this.onDialogInfo(err);
    }
  };

  onChangeText = (name, value) => {
    let { formInput } = this.state;
    formInput[name] = value;
    this.setState({
      formInput,
    });
  };

  handleUpdate = (pageIndex) => {
    this.setState({ page: pageIndex });
  };

  deleteUser = async (uid) => {
    this.setState({ loadingDelete: true });

    try {
      let resultDelete = {};
      resultDelete.fetching = false;
      resultDelete.payload = await deleteUser({
        uidList: this.state.uidDelete,
      });
      ProccessData(resultDelete, "delete user")
        .then((res) => {
          this.getAdmin();
          this.setState({
            loadingDelete: false,
            dialogConfirmation: false,
          });
          this.onDialogInfo("Delete Admin Success");
        })
        .catch((err) => {
          this.onDialogInfo(err);
        });
    } catch (err) {
      this.onDialogInfo(err);
      this.setState({
        loadingDelete: false,
        dialogConfirmation: false,
      });
    }
  };

  onDialogInfo = (val) => {
    this.setState({ infoDescription: val, infoDialogShow: true });
  };

  getAdmin = async (params) => {
    this.props.getAdmin({
      data: {
        type: ["ADMIN", "VIEWER", "SUPER_ADMIN"],
        searchPage: params && params.searchPage ? params.searchPage : 1,
        searchMax: 10,
      },
    });
  };

  handlePagination = async (Index) => {
    this.setState({ page: Index, loading: true });

    this.getAdmin({
      searchPage: Index,
    });
  };

  render() {
    const {
      loading,
      adminList,
      dialogAdd,
      formInput,
      page,
      dialogConfirmation,
      loadingDelete,
      infoDescription,
      infoDialogShow,
      totalPage,
    } = this.state;
    const { admin } = this.props;
    this.setData(admin);
    let AllPage = [];
    for (let i = 1; i <= totalPage; i++) {
      AllPage.push(i);
    }
    const currentPage = page ? page : 1;

    const onClickColumn = (props, action) =>
      this.props.history.push({
        pathname: `/admin/${props.original.uid}`,
        state: {
          action,
        },
      });

    const columns = [
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>No</span>
        ),
        Cell: (props) => (
          <span className="column-text">
            {(currentPage - 1) * 10 + (props.index + 1)}
          </span>
        ),
        headerClassName: "table-header header-no",
        className: "column text-no",
        width: 70,
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            Username
          </span>
        ),
        Cell: (props) => (
          <span className="column-text">
            {props.original.username ? props.original.username : "-"}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            Name
          </span>
        ),
        Cell: (props) => (
          <span className="column-text">
            {props.original.firstname} {props.original.lastname}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            Account Type
          </span>
        ),
        Cell: (props) => {
          if (props.original.type === USER_TYPE.ADMIN) {
            return <span className="column-text">Regular Admin</span>;
          } else if (props.original.type == USER_TYPE.SUPERADMIN) {
            return <span className="column-text">Super Admin</span>;
          } else if (props.original.type === USER_TYPE.VIEWER) {
            return <span className="column-text">Viewer</span>;
          } else {
            <span className="column-text">-</span>;
          }
        },
        headerClassName: "table-header",
        className: "column",
      },
      {
        id: "friendName",
        Header: (props) => (
          <span
            style={{ display: "inline-block", margin: "10px 0" }}
          >{`Created Date`}</span>
        ),
        // Cell: props => <span className="column-text">{props.original.username ? moment(props.original.createdAt).format('DD/MM/YYYY') : '-'}</span>,
        Cell: (props) => (
          <span className="column-text">
            {moment(props.original.createdAt).format("DD/MM/YYYY")}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
      },
    ];

    if (
      this.props.account &&
      this.props.account.payload &&
      this.props.account.payload.data &&
      this.props.account.payload.data.account &&
      this.props.account.payload.data.account.type &&
      this.props.account.payload.data.account.type === USER_TYPE.SUPERADMIN
    ) {
      const superAdminAct = [
        {
          Header: (props) => (
            <span style={{ display: "inline-block", margin: "10px 0" }}>
              Actions
            </span>
          ),
          headerClassName: "table-header",
          className: "actions-column",
          width: 100,
          Cell: (row) => {
            return (
              <div className="icon-container">
                <div
                  className="edit-wrapper"
                  onClick={() => onClickColumn(row, "EDIT")}
                >
                  <img src={Images.edit} className="edit" />
                </div>
                <div
                  className="trash-wrapper"
                  onClick={() => {
                    this.setState({
                      dialogConfirmation: true,
                      uidDelete: row.original.uid,
                    });
                  }}
                >
                  <img src={Images.trash} className="delete" />
                </div>
              </div>
            );
          },
        },
        {
          headerClassName: "table-header",
          className: "last-column",
          Cell: (row) => {
            return (
              <div
                className="button-view"
                onClick={() => onClickColumn(row, "DETAIL")}
              >
                <p className="view-text">View</p>
              </div>
            );
          },
          width: 100,
        },
      ];

      columns.push(...superAdminAct);
    }

    return (
      <StyledAdminManagement>
        <DialogAdmin
          {...formInput}
          isVisible={dialogAdd}
          isClose={() => this.closeModal()}
          title="Create New Admin"
          inputStyle={InputStyle}
          Label={Label}
          onCreateAdmin={this.onCreateAdmin}
          onChangeText={this.onChangeText}
        />
        {/* Dialog for confirmation start  */}
        <Dialog
          isVisible={dialogConfirmation}
          isClose={() => this.setState({ dialogConfirmation: false })}
          title="Delete Account"
          showXClose={false}
          additionalStyle={{ titleStyle: ` color: ${Colors.color13}; ` }}
          description={`Are you sure you want to delete this account?`}
          width={`37%`}
          height={`auto`}
          cancelText={`Decline`}
          okText={loadingDelete ? `Loading...` : `Delete`}
          okBorderColor={Colors.color13}
          okTextColor={Colors.color13}
          onOk={() => this.deleteUser()}
        />
        {/* Dialog for confirmation end */}

        {/* Dialog info start */}
        <DialogInfo
          isVisible={infoDialogShow}
          textInfo={infoDescription}
          onOkButton={() => {
            if (
              this.props.account &&
              this.props.account.payload &&
              this.props.account.payload.data &&
              this.props.account.payload.data.account &&
              this.props.account.payload.data.account.uid &&
              Number(this.props.account.payload.data.account.uid) ===
                Number(this.state.uidDelete)
            ) {
              this.setState({ infoDialogShow: false });
              this.props.signOut();
              this.props.history.replace("/");
            } else {
              this.setState({ infoDialogShow: false });
            }
          }}
        />
        {/* Dialog info end  */}

        <div className="title-container">
          <h1 className="title">Admin Management</h1>
          {this.props.account &&
            this.props.account.payload &&
            this.props.account.payload.data &&
            this.props.account.payload.data.account &&
            this.props.account.payload.data.account.type &&
            this.props.account.payload.data.account.type ===
              USER_TYPE.SUPERADMIN && (
              <Button
                text="Create New Admin Account"
                buttonStyle={` width: 315px; `}
                textStyle={` font-weight: 800; `}
                onClick={() => this.setState({ dialogAdd: true })}
              />
            )}
        </div>
        <Table
          loading={loading}
          columns={columns}
          data={adminList}
          pageSize={10}
          dataLimit={AppConfig.DEFAULT_LIMIT_USER}
          tableProps={{ showPagination: false }}
        />
        <Pagination
          page={currentPage}
          onClickPrevious={() =>
            this.handlePagination(parseInt(currentPage) - 1)
          }
          onClickNext={() => this.handlePagination(parseInt(currentPage) + 1)}
          onClickPage={(number) => this.handlePagination(number)}
          totalPage={AllPage}
        />
      </StyledAdminManagement>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    admin: state.user.admin,
    account: state.auth.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAdmin: (params) => dispatch(UserActions.getAdminRequest(params)),
    signOut: () => dispatch(AuthActions.signout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AdminManagement);
