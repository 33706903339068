import { takeLatest, all } from 'redux-saga/effects';
import AppConfig from '../config.js'
import API from '../services/Api'

/* ------------- Types ------------- */

import { AuthTypes } from '../redux/AuthRedux';
import { StartupTypes } from '../redux/StartupRedux';
import { UserTypes } from '../redux/UserRedux';
import { BenefitsTypes } from '../redux/BenefitsRedux';
import { SolutionsTypes } from '../redux/SolutionsRedux';
import { NotificationsTypes } from '../redux/NotificationsRedux';
import { SafeReturnTypes } from '../redux/SafeReturnRedux';
import { DashboardTypes } from '../redux/DashboardPartner';
import { ReportMissingTypes } from '../redux/ReportMissingRedux';

/* ------------- Sagas ------------- */

import { signin, signup, verifyToken, signin2FA, verifyQR, signinSSO } from './AuthSagas';
import { startup } from './StartupSagas'
import { getUsers, getAdmin } from './UserSagas';
import { getBenefits, addBenefit, deleteBenefit, updateBenefit, getBenefitCategories, addBenefitCategory, deleteBenefitCategory, updateBenefitCategory } from './BenefitsSagas';
import { getSolutions, addSolution, deleteSolution, updateSolution, getSolutionCategories, addSolutionCategory, deleteSolutionCategory, updateSolutionCategory } from './SolutionsSagas';
import { getNotifications, } from './NotificationsSagas';
import { getSafeReturn, } from './SafeReturnSagas';
import { getDashboard, getDailySignUp} from './DashboardPartnerSagas';
import { getReport, editReport } from './ReportMissingSagas';

/* ------------- API ------------- */

// The API we use is only used from Sagas, so we create it here and pass along
// to the sagas which need it.
// const api = DebugConfig.useFixtures ? FixtureAPI : API.create()

/* ------------- Connect Types To Sagas ------------- */

export const api = API.create(AppConfig.ACCOUNT_URL, AppConfig.BENEFITS_URL, AppConfig.SOLUTIONS_URL, AppConfig.NOTIFICATIONS_URL)

export default function* root() {
  yield all([
    // AUTH ACTIONS
    takeLatest(AuthTypes.SIGNIN_REQUEST, signin, api),
    takeLatest(AuthTypes.SIGNUP_REQUEST, signup, api),
    takeLatest(AuthTypes.VERIFY_TOKEN_REQUEST, verifyToken, api),
    takeLatest(AuthTypes.GET_QR_CODE_REQUEST, signin2FA, api),
    takeLatest(AuthTypes.VERIFY_QR_REQUEST, verifyQR, api),
    takeLatest(AuthTypes.CHECK_ADMIN_ACCOUNT_REQUEST, signinSSO, api),

    takeLatest(StartupTypes.INIT_TOKEN, startup, api),

    takeLatest(UserTypes.GET_USERS_REQUEST, getUsers, api),
    takeLatest(UserTypes.GET_ADMIN_REQUEST, getAdmin, api),

    takeLatest(BenefitsTypes.GET_BENEFITS_REQUEST, getBenefits, api),
    takeLatest(BenefitsTypes.ADD_BENEFIT_REQUEST, addBenefit, api),
    takeLatest(BenefitsTypes.DELETE_BENEFIT_REQUEST, deleteBenefit, api),
    takeLatest(BenefitsTypes.UPDATE_BENEFIT_REQUEST, updateBenefit, api),
    takeLatest(BenefitsTypes.GET_CATEGORIES_REQUEST, getBenefitCategories, api),
    takeLatest(BenefitsTypes.ADD_CATEGORY_REQUEST, addBenefitCategory, api),
    takeLatest(BenefitsTypes.DELETE_CATEGORY_REQUEST, deleteBenefitCategory, api),
    takeLatest(BenefitsTypes.UPDATE_CATEGORY_REQUEST, updateBenefitCategory, api),

    takeLatest(SolutionsTypes.GET_SOLUTIONS_REQUEST, getSolutions, api),
    takeLatest(SolutionsTypes.ADD_SOLUTION_REQUEST, addSolution, api),
    takeLatest(SolutionsTypes.DELETE_SOLUTION_REQUEST, deleteSolution, api),
    takeLatest(SolutionsTypes.UPDATE_SOLUTION_REQUEST, updateSolution, api),
    takeLatest(SolutionsTypes.GET_SOLUTION_CATEGORIES_REQUEST, getSolutionCategories, api),
    takeLatest(SolutionsTypes.ADD_SOLUTION_CATEGORY_REQUEST, addSolutionCategory, api),
    takeLatest(SolutionsTypes.DELETE_SOLUTION_CATEGORY_REQUEST, deleteSolutionCategory, api),
    takeLatest(SolutionsTypes.UPDATE_SOLUTION_CATEGORY_REQUEST, updateSolutionCategory, api),

    takeLatest(NotificationsTypes.GET_NOTIFICATIONS_REQUEST, getNotifications, api),

    takeLatest(SafeReturnTypes.GET_SAFE_RETURN_REQUEST, getSafeReturn, api),

    takeLatest(DashboardTypes.GET_DASHBOARD_REQUEST, getDashboard, api),
    takeLatest(DashboardTypes.GET_DAILY_SIGN_UP_REQUEST, getDailySignUp, api),

    takeLatest(ReportMissingTypes.GET_REPORT_MISSING_REQUEST, getReport, api),
    takeLatest(ReportMissingTypes.EDIT_REPORT_MISSING_REQUEST, editReport, api),
  ]);
}
