import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledPagination = styled.div`
  display: flex;
  justify-content: ${props => props.showLimit ? `space-between` : `center`};
  align-items: center;
  flex: 1;
  
  .pagination {
    display: inline-block;
    margin: 20px 0; 
  }

  .pagination a, .pagination p {
    color: ${Colors.color};
    float: left;
    width: 45px;
    height: 35px;
    margin: auto;
    text-align: center;
    padding-top: 9px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid ${Colors.color19};
    margin: 0 4px;
    cursor: pointer;
  }

  .pagination a.active {
    background-color: ${Colors.color11};
    color: ${Colors.color3};
    border: 1px solid ${Colors.color11};
  }

  .pagination a:hover:not(.active), .pagination p:hover:not(.cursor-blocked) {
    background-color: ${Colors.color5}; 
    color: ${Colors.color3};
  }

  .cursor-blocked {
    cursor: not-allowed !important;
  }
`