import React, { useState } from 'react'
import PropTypes from 'prop-types'

import Dialog from '../modal'
import Button from '../button'
import Input from '../input'

import { StyledDialogTransfer, SearchBarStyle, ButtonSearch, Card, CardDash } from './StyledDialogTransfer'

import { Images, Colors, } from '../../themes'

import { getCountUsers, getUsers } from '../../api/UserAPI'

import ProccessData from '../../lib/ProccessData'

function DialogTransfer({
  dialogProps,
  searchTitle,
  user,
  otherAccount,
  onTagAccount,
  loadingTag,
  selectedUser,
}) {
  // const [searchValue, setSearchValue] = useState('')
  const [searchBy, setSearchBy] = useState('name')
  const [inputValue, setInputValue] = useState('')
  const [searched, setSearched] = useState(false)
  const [userSelected, setUserSelected] = useState({})
  const [users, setUsers] = useState({
    loading: false,
    data: [],
  })

  const searchUser = async () => {
    setUsers(state => ({ ...state, loading: true, }) )
    let filter = {
      params: {
        [searchBy]: inputValue.trim(),
        type: dialogProps.type,
        searchBy: "PARTNER",
      },
      type: "PARTNER",
    }

    const users = await getCountUsers(filter)

    try {
      let resultGet = {}
      resultGet.fetching = false
      resultGet.payload = users
      ProccessData(resultGet, 'get user')
        .then(({ data }) => {
          setUsers(state => ({ data, loading: false, }) )
        })
        .catch(err => {
          alert(err ? err : 'ERROR')
        })
    } catch (e) {
      alert('CONNECTION ERROR ' + e)
    }

    setUsers(state => ({ ...state, loading: false, }) )
    setSearched(true)
  }

  const _transfer = () => {
    selectedUser(userSelected)

    // let isTagged = otherAccount.some(data => (data.uidTagged === item.uid && data.status === `ACCEPTED`) || (data.uidTags === item.uid && data.status === `ACCEPTED`))
    // isTagged ? alert(`This user has already been tagged`) :  setUserSelected(item)
  }

  const selected = (item) => {
    setUserSelected(item)
  }
  return (
    <Dialog
      {...dialogProps}
      isClose={() => {
        dialogProps.isClose()
      }}
      width="70%"
      title={dialogProps.title}
    >
      <StyledDialogTransfer>
      {
        user &&
        <div style={{paddingTop: "45px"}}>
          <div className="card-container">
            <Card borderColor={`1px solid ${Colors.color40}`} backgroundColor={Colors.color40}>
              <p className="card-name">{user.firstname} {user.lastname ? user.lastname : ''}</p>
              <p className="card-text">{user.membershipId && user.membershipId}</p>
              <p className="card-text">{user.email && user.email}</p>
              <p className="card-text">{ user.phone ? `(${user.prefixCountryNum}) ${user.phone}`: ""}</p>
            </Card>
            <div style={{ margin: '70px auto 0' }}>
              <img src={Images.arrowRight3} alt="arrow" style={{ width: '35px', height: '35px', objectFit: 'contain' }} />
            </div>
            <div>
              {!_.isEmpty(userSelected) ? 
                <Card borderColor={`1px solid ${Colors.color40}`} backgroundColor={Colors.color40}>
                  <p className="card-name">{userSelected.firstname} {userSelected.lastname ? userSelected.lastname : ''}</p>
                  <p className="card-text">{userSelected.membershipId}</p>
                  <p className="card-text">{userSelected.email}</p>
                  <p className="card-text">{ userSelected.phone ? `(${userSelected.prefixCountryNum}) ${userSelected.phone}`: ""}</p>
                </Card>
              :
              <CardDash borderColor={Colors.color40}>
                {
                  <div style={{ display: 'flex', height: '100%', justifyContent: 'center', alignItems: 'center', fontSize: '16px', color: Colors.color11, fontWeight: 'bold' }}>
                    {dialogProps.searchLabel}
                  </div>
                }
              </CardDash>
              }
            </div>
          </div>
          <div style={{display: "flex", flexDirection:"row", width: "100%", alignItems: "center", justifyContent: "space-between"}}>
            <div style={{display: "flex", flexDirection:"row", borderBottom: `1px solid ${Colors.color39}`, alignItems: "center"}}>
              <img src={Images.iconSearch} alt="search" style={{ width: '20px', height: '20px', objectFit: 'contain', marginRight: "10px" }} />
              <Input
                containerStyle={SearchBarStyle}
                inputProps={{ placeholder: "Search", onChange: (e) => setInputValue(e.target.value), value: inputValue }}
              />
            </div>
            <Button 
              text={!_.isEmpty(userSelected) ? "Transfer" : "Search"}
              buttonStyle={ButtonSearch}
              backgroundColor={Colors.color8}
              textColor = {Colors.color3}
              onClick={()=> !_.isEmpty(userSelected) ? _transfer() : searchUser()}
            />
          </div>

          <div className="card-container2">
          {users.data.length > 0 && 
            users.data.map((item, index) => {
              return (
                <Card borderColor={`1px solid ${userSelected.uid === item.uid ? Colors.color36 : Colors.color40}`} onClick={() => selected(item)}>
                    <p className="card-name">{item.firstname} {item.lastname ? item.lastname : ''}</p>
                    <p className="card-text">{item.membershipId}</p>
                    <p className="card-text">{item.email}</p>
                    <p className="card-text">{ item.phone ? `(${item.prefixCountryNum}) ${item.phone}`: ""}</p>
                </Card>
              )
            })
          }
          </div>
        </div>
      }
      </StyledDialogTransfer>
    </Dialog>
  )
}

DialogTransfer.propTypes = {
  dialogProps: PropTypes.any,
  searchTitle: PropTypes.string,
  user: PropTypes.any,
}

DialogTransfer.defaultProps = {
  dialogProps: {},
  searchTitle: `Search Partner Account`,
  user: {},
}

export default DialogTransfer

