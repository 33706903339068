import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledSafeReturn = styled.div`
  padding-top: 62px;
  padding-left: 40px;
  background-color: ${Colors.primaryBgColor};
  min-height: 100vh;
  padding-right: 40px;

  .title-container {
    display: flex;
    justify-content: space-between;
  }

  .title {
    ${Fonts.FontRegular};
    ${Fonts.size.size32};
    font-weight: 800;
    color: ${Colors.color2};
  }

  .table-header-container {
    background-color: ${Colors.color3};
    border-radius: 10px 10px;
    margin: 0;
    padding: 20px 15px 10px;
  }

  .submitted-text {
    ${Fonts.FontRegular}
    font-size: 16px;
    color: ${Colors.color19};
  }

  .submitted-text2 {
    ${Fonts.FontRegular}
    font-size: 16px;
    font-weight: bold;
    color: ${Colors.color11};
    margin-left: 10px;
  }

  .buttonSeparator {
    width: 15px;
  }
`