// ========== Radio Button
// import all modules
import React, { Fragment } from 'react';
import PropTypes from 'prop-types'
import Checked from '../../assets/images/radio-button-on.png';
import UnChecked from '../../assets/images/radio-button-off.png';
import Input from '../input';
import { prop } from 'ramda';

function RadioButton(props) {
	return (
		<Fragment>
			<div>
				<label htmlFor={props.value} style={{
					display: 'flex',
					alignItems: 'center',
					marginTop: '10px'
				}}>
					<Input containerStyle={{marginRight: '5px', display: 'none'}} inputProps={{ type: "radio", checked: props.checked, name: props.name, id: props.value, value: props.value, onClick: props.onChange, }} />
					<div style={{ marginRight: '5px' }}>
						<img src={props.checked ? Checked : UnChecked} style={{ width: '20px', height: '20px', display: 'inline-block' }} />
					</div>
					{props.placeholder}
				</label>
			</div>
		</Fragment>
	);
}

RadioButton.propTypes = {
    name: PropTypes.string,
		checked: PropTypes.bool,
    value: PropTypes.any,
		onChange: PropTypes.func,
		placeholder: PropTypes.string
}

RadioButton.defaultProps = {
	checked: false,
	onChange: () => null
}

export default RadioButton;