const ACCOUNT_STATUS = {
  INCOMPLETE: "INCOMPLETE",
  COMPLETED: "COMPLETED",
  PENDING_APPROVAL: "PENDING_APPROVAL",
  PENDING_CARDS: "PENDING_CARDS",
  REJECTED: "REJECTED",
  EXPIRED: "EXPIRED",
  ARCHIVED: "ARCHIVED",
  TO_FOLLOW_UP: "TO_FOLLOW_UP",
};

export default ACCOUNT_STATUS;
