import React from 'react'

import {Images} from './../../themes'

function NumericInfo(props) {
    return (
        <div style={{height: '3.5rem'}}>
            <div style={{display: 'flex', gap: "20px"}}>
                <img style={{padding: '13px',borderRadius: '2rem', backgroundColor: props.bgColor }} src={props.image} />
                <div style={{textAlign: 'left', display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center'}} >
                    <p style={{color: props.color,fontWeight: 'bold', alignSelf: 'start', fontSize: "32px"}}>{props.numeric}</p>
                    <p style={{margin: '0.3rem 0 0 0',color: '#AFAFAF'}}>{props.description}</p>
                </div>
            </div>
        </div>
    )
}

export default NumericInfo
