import React from 'react';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import colors from '../../themes/Colors';

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'bottom',
      align: 'left'
    },
    title: {
      display: false
    },
  },
};

// example data
const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

export const exampleData = {
  labels: ['Oct 2023', 'Dec 2023', 'Jan 2024'],
  datasets: [
    {
      label: 'Clicks',
      data: [2344, 3422, 2138, 2149, 9283, 4284, 2389],
      borderColor: colors.navy,
      backgroundColor: colors.navy,
    }
  ],
};

export default function LineChart({label, data}) {
  const datas = {
    labels: label,
    datasets: data,
  }

  return <Line options={options} data={datas} />;
}
