import { call, put, all, actionChannel} from 'redux-saga/effects'
import BenefitsActions from '../redux/BenefitsRedux'

export function* getBenefits(api, action) {
  let { data } = action
  
  const response = yield call(api.getBenefits, data)
  if (response.ok) {    
    yield put(BenefitsActions.getBenefitsSuccess(response.data))   
  } else {
    yield put(BenefitsActions.getBenefitsFailure(response))
  }
}

export function* addBenefit(api, action) {
  let { data } = action
  const response = yield call(api.addBenefit, data)
  if (response.ok) {    
    yield put(BenefitsActions.addBenefitSuccess(response.data))   
  } else {
    yield put(BenefitsActions.addBenefitFailure(response))
  }
}

export function* deleteBenefit(api, action) {
  let { data } = action
  
  const response = yield call(api.deleteBenefit, data)
  if (response.ok) {    
    yield put(BenefitsActions.deleteBenefitSuccess(response.data))   
  } else {
    yield put(BenefitsActions.deleteBenefitFailure(response))
  }
}

export function* updateBenefit(api, action) {
  let { data } = action
  const response = yield call(api.updateBenefit, data)
  if (response.ok) {    
    yield put(BenefitsActions.updateBenefitSuccess(response.data))   
  } else {
    yield put(BenefitsActions.updateBenefitFailure(response))
  }
}

export function* getBenefitCategories(api, action) {
  let { data } = action
  
  const response = yield call(api.getBenefitCategories, data)
  if (response.ok) {    
    yield put(BenefitsActions.getCategoriesSuccess(response.data))   
  } else {
    yield put(BenefitsActions.getCategoriesFailure(response))
  }
}

export function* addBenefitCategory(api, action) {
  let { data } = action
  
  const response = yield call(api.addBenefitCategory, data)
  if (response.ok) {    
    yield put(BenefitsActions.addCategorySuccess(response.data))   
  } else {
    yield put(BenefitsActions.addCategoryFailure(response))
  }
}

export function* deleteBenefitCategory(api, action) {
  let { data } = action
  
  const response = yield call(api.deleteBenefitCategory, data)
  if (response.ok) {    
    yield put(BenefitsActions.deleteCategorySuccess(response.data))   
  } else {
    yield put(BenefitsActions.deleteCategoryFailure(response))
  }
}

export function* updateBenefitCategory(api, action) {
  let { data } = action
  
  const response = yield call(api.updateBenefitCategory, data)
  if (response.ok) {    
    yield put(BenefitsActions.updateCategorySuccess(response.data))   
  } else {
    yield put(BenefitsActions.updateCategoryFailure(response))
  }
}
