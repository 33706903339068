import React from 'react'
import PropTypes from 'prop-types'

import Dialog from '../modal'
import Button from '../button'

import { ACCOUNT_STATUS, EXPORT_TYPE, USER_TYPE, } from '../../constants/'

import { Colors, Images, } from '../../themes'

import { StyledDialogBulkActions } from './StyledDialogBulkActions'

function DialogBulkActions(props) {
  
  const { 
    dialogProps,
    onClickButton,
    type,
    isRecentlyDeleted
  } = props
  
  const renderButton = ({ textColor = Colors.color11, borderColor = Colors.color11, ...props }) => {
    return (
      <Button
        {...props}
        bordered
        borderColor={borderColor}
        textColor={textColor}
        buttonStyle={` margin-bottom: 20px; `}
      />
    )
  }

  return (
    <Dialog
      {...dialogProps}
    >
      <StyledDialogBulkActions>
        { (type && type === USER_TYPE.ADMIN || type === USER_TYPE.SUPERADMIN && !isRecentlyDeleted) && renderButton({ text: `Approve`, onClick: () => onClickButton({ accountStatus: ACCOUNT_STATUS.PENDING_CARDS }) })}
        {(type && type === USER_TYPE.ADMIN || type === USER_TYPE.SUPERADMIN && !isRecentlyDeleted) && renderButton({ text: `Reject`, onClick: () => onClickButton({ accountStatus: ACCOUNT_STATUS.REJECTED }) })}
        {
          (type && (type === USER_TYPE.ADMIN || type === USER_TYPE.SUPERADMIN || type === USER_TYPE.VIEWER) && !isRecentlyDeleted) && renderButton({ text: `Complete`, onClick: () => onClickButton({ accountStatus: ACCOUNT_STATUS.COMPLETED }) })
        }
        {(type && (type === USER_TYPE.ADMIN || type === USER_TYPE.SUPERADMIN || type === USER_TYPE.VIEWER) && !isRecentlyDeleted) && renderButton({ text: `Enable Care Circle`, onClick: () => onClickButton({ metadata: { caraPro: true } }) })}
        {(type && (type === USER_TYPE.ADMIN || type === USER_TYPE.SUPERADMIN || type === USER_TYPE.VIEWER) && !isRecentlyDeleted) && renderButton({ text: `Disable Care Circle`, onClick: () => onClickButton({ metadata: { caraPro: false } }) })}
        {(!isRecentlyDeleted) && renderButton({ text: `Archive`, onClick: () => onClickButton({ isArchived: 1 }) })}
        {(!isRecentlyDeleted) && renderButton({ text: `To Follow-up`, onClick: () => onClickButton({ accountStatus: ACCOUNT_STATUS.TO_FOLLOW_UP }) })}
        {(type && type === USER_TYPE.SUPERADMIN && !isRecentlyDeleted) && renderButton({ text: `Export Card Information`, onClick: () => onClickButton({ exportType: EXPORT_TYPE.INFO }) })}
        {(type && type === USER_TYPE.SUPERADMIN && !isRecentlyDeleted) && renderButton({ text: `Export Salesforce`, onClick: () => onClickButton({ exportType: EXPORT_TYPE.SALESFORCE }) })}
        {(type && type === USER_TYPE.SUPERADMIN && !isRecentlyDeleted) && renderButton({ text: `Export`, onClick: () => onClickButton({ exportType: EXPORT_TYPE.ALL_FIELD }) })}
        {(type && type === USER_TYPE.SUPERADMIN && !isRecentlyDeleted) && renderButton({ text: `Revert`, onClick: () => onClickButton({ action: 'REVERSING_ACCOUNT_STATUS' }) })}
        {/* below is code for delete with reason */}
        {/* {(type && type === USER_TYPE.SUPERADMIN) && renderButton({ text: `Delete 2`, textColor: Colors.color35, borderColor: Colors.color35, onClick: () => onClickButton({ action: `Delete 2` }) })} */}
        {(type && type === USER_TYPE.SUPERADMIN) && renderButton({ text: `Delete`, textColor: Colors.color35, borderColor: Colors.color35, onClick: () => onClickButton({ action: `Delete` }) })}
      </StyledDialogBulkActions>
    </Dialog>
  )
}

DialogBulkActions.propTypes = {
  dialogProps: PropTypes.any,
  onClickButton: PropTypes.func,
  type: PropTypes.any
}

DialogBulkActions.defaultProps = {
  dialogProps: {},
  onClickButton: () => null,
  type: null
}

export default DialogBulkActions

