import { AllCheckerCheckbox, Checkbox, } from '@createnl/grouped-checkboxes';
import moment from 'moment';

import { Images, Colors, } from '../themes'

import { SAFE_RETURN_STATUS, } from '../constants/'

export const partnerColumns = ({ onClickColumn, sortBy, onSort }) => {
  return [
    {
      Header: props => <div style={{ display: 'flex', margin: '10px 0', justifyContent: 'center', alignItems: 'center', }}> <AllCheckerCheckbox style={{ cursor: 'pointer', }} /></div>,
      Cell: props => <div style={{ display: 'flex', margin: '10px 0', justifyContent: 'center', alignItems: 'center', }}>  <Checkbox style={{ cursor: 'pointer', }} value={props.original}/></div>, 
      headerClassName: 'table-header',
      className: 'column-checkbox',
      width: 40,
      sortable: false,    
      resizable: false,
    },
    {
      Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Reported PLWD</span>,
      Cell: props => <span className="column-text">{props && props.original && props.original.dementia ? `${props.original.dementia.firstname} ${props.original.dementia.lastname}` : ''}</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,      
      resizable: false,
    }, 
    {
      Header: props => 
      <div 
        style={{ display: 'flex', margin: '10px 0', justifyContent: 'space-between' }}
        onClick={() => onSort(sortBy === 'name,asc' ? 'name,desc' : sortBy === 'name,desc' ? 'name,asc' : 'name,asc')}
      >
          <span>Reporter Name</span>
          {
            <img
              src={sortBy === 'name,asc' ? Images.ascending : sortBy === 'name,desc' ? Images.descending : Images.sortInactive}
              style={{height: '25px', marginLeft: '7px', marginTop: '-5px'}}
            />
          }
      </div>,
      Cell: props => <span className="column-text">{props.original.ReturnCard && props.original.ReturnCard.name}</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,      
      resizable: false,
    },
    {
      Header: props => 
      <div 
        style={{ display: 'flex', margin: '10px 0', justifyContent: 'space-between' }}
        onClick={() => onSort(sortBy === 'phone,asc' ? 'phone,desc' : sortBy === 'phone,desc' ? 'phone,asc' : 'phone,asc')}
      >
        <span>Phone No.</span>
        {
          <img
            src={sortBy === 'phone,asc' ? Images.ascending : sortBy === 'phone,desc' ? Images.descending : Images.sortInactive}
            style={{height: '25px', marginLeft: '7px', marginTop: '-5px'}}
          />
        }
      </div>,
      Cell: props => <span className="column-text">{props.original.ReturnCard && props.original.ReturnCard.phone}</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,      
      resizable: false,
    },  
    {
      Header: props => 
      <div 
        style={{ display: 'flex', margin: '10px 0', justifyContent: 'space-between' }}
        onClick={() => onSort(sortBy === 'location,asc' ? 'location,desc' : sortBy === 'location,desc' ? 'location,asc' : 'location,asc')}
      >
        <span>Location</span>
        {
          <img
            src={sortBy === 'location,asc' ? Images.ascending : sortBy === 'location,desc' ? Images.descending : Images.sortInactive}
            style={{height: '25px', marginLeft: '7px', marginTop: '-5px'}}
          />
        }
      </div>,
      Cell: props => <span className="column-text">{props.original.ReturnCard && props.original.ReturnCard.location}</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,      
      resizable: false,
    }, 
    {
      Header: props => 
      <div 
        style={{ display: 'flex', margin: '10px 0', justifyContent: 'space-between' }}
        onClick={() => onSort(sortBy === 'createdAt,asc' ? 'createdAt,desc' : sortBy === 'createdAt,desc' ? 'createdAt,asc' : 'createdAt,asc')}
      >
        <span>Report Date</span>
        {
          <img
            src={sortBy === 'createdAt,asc' ? Images.ascending : sortBy === 'createdAt,desc' ? Images.descending : Images.sortInactive}
            style={{height: '25px', marginLeft: '7px', marginTop: '-5px'}}
          />
        }
      </div>,
      Cell: props => <span className="column-text">{moment(props.original.ReturnCard && props.original.ReturnCard.createdAt).format(('DD-MM-YY h:mm a'))}</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,      
      resizable: false,
    }, 
    {
      Header: props => 
      <div 
        style={{ display: 'flex', margin: '10px 0', justifyContent: 'space-between' }}
        onClick={() => onSort(sortBy === 'status,asc' ? 'status,desc' : sortBy === 'status,desc' ? 'status,asc' : 'status,asc')}
      >
        <span>Status</span>
        {
          <img
            src={sortBy === 'status,asc' ? Images.ascending : sortBy === 'status,desc' ? Images.descending : Images.sortInactive}
            style={{height: '25px', marginLeft: '7px', marginTop: '-5px'}}
          />
        }
      </div>,
      Cell: props => {
        let status = 
          props.original.ReturnCard && props.original.ReturnCard.status === SAFE_RETURN_STATUS.PENDING ? { text: `Pending`, color: Colors.color14 }
          : { text: `Action Taken`, color: Colors.color17 }
        return <span style={{ color: status.color }} className="column-text">{status.text}</span>
      }, 
      headerClassName: 'table-header',
      width: 100,
      className: 'column',
      sortable: false,      
      resizable: false,
    }, 
    {
      headerClassName: 'table-header',
      className: 'actions-column',
      width: 40,
      Cell: row => {
        return (
           <div className="icon-container">
            <div className="dots-wrapper" onClick={() => onClickColumn(row)}>
              <img src={Images.threeDots} className="delete" />
            </div>
           </div>
        )
      },
      sortable: false,      
      resizable: false,
    }]
}


export const adminColumns = ({ onClickColumn, sortBy, onSort }) => {
  return (  
    [
      {
        Header: props => <div style={{ display: 'flex', margin: '10px 0', justifyContent: 'center', alignItems: 'center', }}> <AllCheckerCheckbox style={{ cursor: 'pointer', }} /></div>,
        Cell: props => <div style={{ display: 'flex', margin: '10px 0', justifyContent: 'center', alignItems: 'center', }}>  <Checkbox style={{ cursor: 'pointer', }} value={props.original}/></div>, 
        headerClassName: 'table-header',
        className: 'column-checkbox',
        width: 40,
        sortable: false,    
        resizable: false,
      },
      {
        Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>Reported PLWD</span>,
        Cell: props => <span className="column-text">{ props && props.original && props.original.dementia ? `${props.original.dementia.firstname} ${props.original.dementia.lastname}` : ''}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        sortable: false,      
        resizable: false,
      }, 
      {
        Header: props => 
        <div 
          style={{ display: 'flex', margin: '10px 0', justifyContent: 'space-between' }}
          onClick={() => onSort(sortBy === 'name,asc' ? 'name,desc' : sortBy === 'name,desc' ? 'name,asc' : 'name,asc')}
        >
            <span>Reporter Name</span>
            {
              <img
                src={sortBy === 'name,asc' ? Images.ascending : sortBy === 'name,desc' ? Images.descending : Images.sortInactive}
                style={{height: '25px', marginLeft: '7px', marginTop: '-5px'}}
              />
            }
        </div>,
        Cell: props => <span className="column-text">{props.original.name}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        sortable: false,      
        resizable: false,
      },
      {
        Header: props => 
        <div 
          style={{ display: 'flex', margin: '10px 0', justifyContent: 'space-between' }}
          onClick={() => onSort(sortBy === 'phone,asc' ? 'phone,desc' : sortBy === 'phone,desc' ? 'phone,asc' : 'phone,asc')}
        >
          <span>Phone No.</span>
          {
            <img
              src={sortBy === 'phone,asc' ? Images.ascending : sortBy === 'phone,desc' ? Images.descending : Images.sortInactive}
              style={{height: '25px', marginLeft: '7px', marginTop: '-5px'}}
            />
          }
        </div>,
        Cell: props => <span className="column-text">{props.original.phone}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        sortable: false,      
        resizable: false,
      },  
      {
        Header: props => 
        <div 
          style={{ display: 'flex', margin: '10px 0', justifyContent: 'space-between' }}
          onClick={() => onSort(sortBy === 'location,asc' ? 'location,desc' : sortBy === 'location,desc' ? 'location,asc' : 'location,asc')}
        >
          <span>Location</span>
          {
            <img
              src={sortBy === 'location,asc' ? Images.ascending : sortBy === 'location,desc' ? Images.descending : Images.sortInactive}
              style={{height: '25px', marginLeft: '7px', marginTop: '-5px'}}
            />
          }
        </div>,
        Cell: props => <span className="column-text">{props.original.location}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        sortable: false,      
        resizable: false,
      }, 
      {
        Header: props => 
        <div 
          style={{ display: 'flex', margin: '10px 0', justifyContent: 'space-between' }}
          onClick={() => onSort(sortBy === 'createdAt,asc' ? 'createdAt,desc' : sortBy === 'createdAt,desc' ? 'createdAt,asc' : 'createdAt,asc')}
        >
          <span>Report Date</span>
          {
            <img
              src={sortBy === 'createdAt,asc' ? Images.ascending : sortBy === 'createdAt,desc' ? Images.descending : Images.sortInactive}
              style={{height: '25px', marginLeft: '7px', marginTop: '-5px'}}
            />
          }
        </div>,
        Cell: props => <span className="column-text">{moment(props.original.createdAt).format(('DD-MM-YY h:mm a'))}</span>, 
        headerClassName: 'table-header',
        className: 'column',
        sortable: false,      
        resizable: false,
      }, 
      {
        Header: props => 
        <div 
          style={{ display: 'flex', margin: '10px 0', justifyContent: 'space-between' }}
          onClick={() => onSort(sortBy === 'status,asc' ? 'status,desc' : sortBy === 'status,desc' ? 'status,asc' : 'status,asc')}
        >
          <span>Status</span>
          {
            <img
              src={sortBy === 'status,asc' ? Images.ascending : sortBy === 'status,desc' ? Images.descending : Images.sortInactive}
              style={{height: '25px', marginLeft: '7px', marginTop: '-5px'}}
            />
          }
        </div>,
        Cell: props => {
          let status = 
            props.original.status === SAFE_RETURN_STATUS.PENDING ? { text: `Pending`, color: Colors.color14 }
            : { text: `Action Taken`, color: Colors.color17 }
          return <span style={{ color: status.color }} className="column-text">{status.text}</span>
        }, 
        headerClassName: 'table-header',
        width: 100,
        className: 'column',
        sortable: false,      
        resizable: false,
      }, 
      {
        headerClassName: 'table-header',
        className: 'actions-column',
        width: 40,
        Cell: row => {
          return (
              <div className="icon-container">
              <div className="dots-wrapper" onClick={() => onClickColumn(row)}>
                <img src={Images.threeDots} className="delete" />
              </div>
              </div>
          )
        },
        sortable: false,      
        resizable: false,
      }
    ]
  )
} 