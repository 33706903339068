import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledUserDetail = styled.div`
  background-color: ${Colors.primaryBgColor};
  padding: 55px 40px 73px;

  ${props => props.customStyles}

  .wrapper {
    background-color: ${Colors.color3};
    width: 100%;
    border-radius: 15px;
    padding: 28px 350px 23px;
    word-break: break-all;
    overflow-y: auto;
    overflow-x: hidden;
  }

  .icon-back {
    width: 10px;
    margin: 0 0 0 14px;
    cursor: pointer;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
  }

  .title-name {
    ${Fonts.FontRegular}
    ${Fonts.size.size32};
    font-weight: 800;
    color: ${Colors.color2};
    margin-right: 20px;
    width: 350px;
  }

  .title-partner-name {
    ${Fonts.FontRegular}
    ${Fonts.size.size32};
    font-weight: 800;
    color: ${Colors.color2};
    margin-right: 20px;
    width: 300px;
  }

  .title-status {
    ${Fonts.FontRegular}
    ${Fonts.size.size16};
    font-weight: 800;
  }

  .action {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin-right: 23px;
  }

  .edit {
    width: 11px;
    height: 11px;
  }

  .delete {
    width: 11px;
    height: 11px;
  }

  .trash-wrapper {
    background-color: ${Colors.color13};
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .edit-wrapper {
    background-color: ${Colors.color14};
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    cursor: pointer;
  }

  .transfer-wrapper {
    background-color: ${Colors.color36};
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    cursor: pointer;
  }

  .transfer {
    width: 11px;
    height: 11px;
  }

  .subtitle {
    ${Fonts.FontRegular}
    ${Fonts.size.size24}
    color: ${Colors.color19};
    font-weight: bold;
    margin: 45px 0 26px 0;
  }

  .list-container {
    ${Fonts.FontRegular}
    ${Fonts.size.size14}
    color: ${Colors.color};
    font-weight: bold;
    padding: 12px 0;
  }

  .list-dark {
    background-color: ${Colors.color24};
  }

  .input-field {
    background-color: ${Colors.color3};
    border: 1px solid ${Colors.color7};
    padding: 5px 15px !important;
    border-radius: 4px;
  }

  .button-wrapper {
    width: 100%;
    margin-top: 24px;
    display: flex;
    justify-content: flex-end;
  }

  .buttonContainer {
    display: flex;
    justify-content: space-between;
    width: 250px;
    margin-right: 30px;
  }

  .buttonSelfContainer {
    display: flex;
    justify-content: flex-end;
    width: 160px;
  }

  .textTitlePlwd {
    ${Fonts.FontRegular}
    ${Fonts.size.size14}
    color:#4B4B4B;
    font-weight: bold;
  }

  .textPlwdd {
    ${Fonts.FontRegular}
    ${Fonts.size.size14}
    color:#4B4B4B;
    margin-Top: 10px;
  }

  .titlePLWD {
    ${Fonts.FontRegular}
    ${Fonts.size.size24}
    color:#502773;
    font-weight: bold;
  }

  .checkbox-container {
      display: flex;
      margin-top: 20px;
      align-items: center;
  }

  .checkbox-text {
    font-weight: bold;
    font-size: 16px;
    color: #758BA9;
    margin-left: 8px;
  }

  /* Styling Checkbox Starts */
  .checkbox-label {
    display: block;
    position: relative;
    cursor: pointer;
    height: 24px;
    width: 24px;
    clear: both;
    color: white;
  }

  .checkbox-label input {
      position: absolute;
      opacity: 0;
      cursor: pointer;
      color: white;
  }

  .checkbox-label .checkbox-custom {
      position: absolute;
      top: 0px;
      left: 0px;
      height: 24px;
      width: 24px;
      background-color: #758BA9;
      color: white;
      border-radius: 5px;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      border: 2px solid #FFFFFF;
  }


  .checkbox-label input:checked ~ .checkbox-custom {
      background: #EFC900;
      border-radius: 5px;
      -webkit-transform: rotate(0deg) scale(1);
      -ms-transform: rotate(0deg) scale(1);
      transform: rotate(0deg) scale(1);
      opacity:1;
      color: white;
      border: 2px solid #FFFFFF;
  }


  .checkbox-label .checkbox-custom::after {
      position: absolute;
      content: "";
      left: 12px;
      top: 12px;
      height: 0px;
      width: 0px;
      border-radius: 5px;
      border: solid #009BFF;
      border-width: 0 3px 3px 0;
      -webkit-transform: rotate(0deg) scale(0);
      -ms-transform: rotate(0deg) scale(0);
      transform: rotate(0deg) scale(0);
      opacity:1;
      transition: all 0.3s ease-out;
      -webkit-transition: all 0.3s ease-out;
      -moz-transition: all 0.3s ease-out;
      -ms-transition: all 0.3s ease-out;
      -o-transition: all 0.3s ease-out;
      color: white;
  }


  .checkbox-label input:checked ~ .checkbox-custom::after {
    -webkit-transform: rotate(45deg) scale(1);
    -ms-transform: rotate(45deg) scale(1);
    transform: rotate(45deg) scale(1);
    opacity:1;
    left: 8px;
    top: 3px;
    width: 6px;
    height: 12px;
    border: solid white;
    border-width: 0 2px 2px 0;
    background-color: transparent;
    border-radius: 0;
    color: white;
  }

  /* For Ripple Effect */
  .checkbox-label .checkbox-custom::before {
    position: absolute;
    content: "";
    left: 10px;
    top: 10px;
    width: 0px;
    height: 0px;
    border-radius: 5px;
    border: 2px solid #FFFFFF;
    -webkit-transform: scale(0);
    -ms-transform: scale(0);
    transform: scale(0);  
    color: white;
  }

  .checkbox-label input:checked ~ .checkbox-custom::before {
    left: -3px;
    top: -3px;
    width: 24px;
    height: 24px;
    border-radius: 5px;
    -webkit-transform: scale(3);
    -ms-transform: scale(3);
    transform: scale(3);
    opacity:0;
    z-index: 999;
    transition: all 0.3s ease-out;
    -webkit-transition: all 0.3s ease-out;
    -moz-transition: all 0.3s ease-out;
    -ms-transition: all 0.3s ease-out;
    -o-transition: all 0.3s ease-out;
    color: white;
  }
`

export const Container = styled.div`
  background-color: ${Colors.color3};
  width: 100%;
  border-radius: 15px;
`

export const ContainerPLWD = styled.div`
  background-color:#F7F9FC;
  height: 130px;
  width: 48%;
  border-radius: 15px;
  margin-Bottom: 20px;
`