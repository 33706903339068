import React, { Fragment } from 'react';

import Dialog from '../modal';
import Input from '../input';
import Button from '../button';
import RadioButton from '../radio-button/RadioButton';

import { Colors} from '../../themes'

export default class DialogAdmin extends React.Component {

  state = {
    checkedsuperadmin: false,
    checkedadmin: false,
    checkedviewer: false,
    roleType: false,
    roleTypes: [
      {
        value: 'SUPER_ADMIN',
        name: 'Super Admin'
      },
      {
        value: 'ADMIN',
        name: 'Regular Admin'
      },
      {
        value: 'VIEWER',
        name: 'Viewer'
      }
    ]
  }

  handleRadioButton = (e) => {
    this.setState(state => ({
      roleType: e.target.value,
      checkedadmin: false,
      checkedsuperadmin: false,
      checkedviewer: false,
      [`checked${e.target.value.toLowerCase().split('_').join('')}`]: !state[`checked${e.target.value.toLowerCase().split('_').join('')}`]
    }))
  }

  render() {
    const { Label, inputStyle, loading, onCreateAdmin, firstname, username, phone, password, email, onChangeText } = this.props


    return (
      <div>
        <Dialog {...this.props} title={"Create New Admin"} width="504" height="auto" >
          <div style={{ paddingTop: '39px', margin: '0 14px', }}>
            <Label>Account Type</Label>
            <div style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'space-between'
            }}>
              {
                this.state.roleTypes.map((item, index) => (
                  <Fragment key={String(index)}>
                    <RadioButton name={`role`} value={item.value} onChange={(e) => {
                      this.handleRadioButton(e)
                      onChangeText('type', e.target.value)
                    }} placeholder={item.name}  checked={this.state[`checked${item.value.toLowerCase().split('_').join('')}`]} />
                  </Fragment>
                ))
              }
            </div>
          </div>
          <div style={{ paddingTop: '19px', margin: '0 14px', }}>
            {/* <Label>Username</Label>
            <Input containerStyle={inputStyle} inputProps={{ value: username, onChange: (e) => onChangeText('username', e.target.value), }} /> */}
            <Label>Admin Name</Label>
            <Input containerStyle={inputStyle} inputProps={{ value: firstname, onChange: (e) => onChangeText('firstname', e.target.value), }} />
            <Label>Email</Label>
            <Input containerStyle={inputStyle} inputProps={{ value: email, onChange: (e) => onChangeText('email', e.target.value), }} />
            <Label>Phone Number</Label>
            <Input containerStyle={inputStyle} inputProps={{ type: 'number', value: phone, onChange: (e) => onChangeText('phone', e.target.value), }} />
            {/* <Label>Password</Label>
            <Input containerStyle={inputStyle} inputProps={{ type: 'password', value: password, onChange: (e) => onChangeText('password', e.target.value), }} /> */}

            <div style={{display: "flex", justifyContent: 'space-between', paddingTop: 20 }}>
              <div style={{ width: '48%' }}>
                <Button
                  text="Discard"
                  textColor={Colors.color21}
                  buttonStyle={` background: ${Colors.color22}; box-shadow: 3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color23}; `}
                  onClick={this.props.isClose}
                />
              </div>
              <div style={{ width: '48%' }}> 
                <Button
                  text={loading ? "Loading..." : "Save" }
                  buttonStyle={` box-shadow: -3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color23}; `}
                  onClick={onCreateAdmin}
                  disabled={!email || !firstname  || !this.state.roleType}
                />
              </div>
            </div>
          </div>
        </Dialog>
      </div>
    )
  }
}