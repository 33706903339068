import React from 'react';

import Dialog from '../modal';
import Button from '../button';

import { Colors} from '../../themes'

export default class DialogInfo extends React.Component {

  constructor (props) {
    super(props)

    this.state = {
      textInfo: '',
      showXClose:false,
      width:`37%`,
      height: `auto`,
      buttonText: `OK`,
      buttonColor: Colors.color8,
      buttonTextColor: Colors.color3,
    }
  }

  render() {

    const {isVisible, onOkButton} = this.props

    const style = {
      description: {
        fontFamily: `NunitoSans`,
        fontWeight: `bold`,
        color: Colors.color2,
        fontSize: `20px`,
        marginTop: `30px`,
        marginLeft: `14px`,
        textAlign: 'center',
        marginBottom: '15px'
      },
    }
    return (
      <div>
          <Dialog {...this.state} isVisible={isVisible}>
            <div  style={style.description}>
              <p>{this.props.textInfo}</p>
            </div>
            <div >
              <Button
              text={this.state.buttonText}
              textColor={this.state.buttonTextColor}
              backgroundColor={this.state.buttonColor}
              onClick={onOkButton}
              buttonStyle={`display: flex; justify-content: center; margin-left: 40%; margin-right: 35%; height: 30px`}
              />
            </div>
          </Dialog>
      </div>
    )
  }
}