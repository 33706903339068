import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledUserTopButton = styled.div`
  overflow: auto;
  .users-title {
    ${Fonts.FontRegular}
    font-weight: bold;
    font-size: 24px;
    color: ${Colors.color11};
  }

  .button-import-container {
    position: fixed;
    bottom: 24px;
    right: 40px;
    width: 195px;
  }

  .text {
    ${Fonts.FontRegular}
    font-weight: bold;
    font-size: 16px;
    color: ${Colors.color11};
    margin-top: 20px;
  }
`