import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

const windowHeight = window.innerHeight

export const StyledFilter = styled.div`
  overflow: auto !important;
  .container-modal {
    padding: 30px;
    padding-bottom: 22px;
    overflow: auto;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: ${props => props.height};
  }
  .image {
    width: 10px;
    height: 10px;
    margin-Left: 5px;
    justify-Content: center;
    align-Items: center;
  }
  .title {
    font-family: NunitoSans;
    font-weight: bold;
    color: #502773;
    font-size: 25px;
    padding-top: 10px;
    ${props => props.titleStyle}
  }

  .labelTitle {
    font-family: NunitoSans;
    font-weight: bold;
    font-size: 16px;
    padding-top: 20px;
  }

  .allTime {
    font-family: NunitoSans;
    font-weight: bold;
    font-size: 16px;
    padding-top: 20px;
    padding-Right: 10px;
  }

  .label {
    font-family: NunitoSans;
    font-size: 14px;
    padding-top: 20px;
    color: #502773;
    padding-Bottom: 10px;
  }

  .input {
      height: 35px;
      border-Radius: 5px;
      width: auto;
      padding: 5px;
      border-Color: #dddddd;
  }

  .close {
    font-weight: bold;
    font-size: 10px;
    padding-Top: 5px;
    padding-Left: 7px;
    color: #502773;
  }
`