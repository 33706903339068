import React, { Component } from "react";
import { connect } from "react-redux";
import Swal from "sweetalert2";
import axios from "axios";
import DatePicker from "react-datepicker";
import styled from "styled-components";
import { Row, Col } from "react-grid-system";
import moment from "moment";

// Styles
import { StyledAnalytics } from "./styles/StyledAnalytics";
import { Typography, Images, Colors, Fonts } from "../themes";

// Componentes
import Card2 from "../components/card2";
import PieChart from "../components/pie-chart";
import LineChart from "../components/line-chart";
import VerticalBarChart from "../components/vertical-bar-chart/index.js";
import ButtonSlider from "../components/button-slider";
import Button from "../components/button";
import Table from "../components/table";
import Pagination from "../components/pagination";

// APIs
import { getCountSignupPlatform } from "../api/UserAPI";
import DashboardAction from "../redux/DashboardPartner";

// Libraries / Helpers
import ProccessData from "../lib/ProccessData";
import AppConfig from "../config.js";

const { Title } = Typography;

const ITEMS = [
  {
    id: 1,
    platform: "Web",
    total: 0,
  },
  {
    id: 1,
    platform: "Mobile",
    total: 0,
  },
];

export class Analytics extends Component {
  constructor(props) {
    super(props);

    this.state = {
      totalUsers: 0,
      legendData: ITEMS,
      activeFeatureClick: {
        key: "BENEFIT",
        display: "Benefit",
      },
      lineChartLabel: [],
      lineChartData: [],
      lineChartTotal: null,
      newAndRecurringUserBarChart: [],
      phoneOsChart: {
        android: 0,
        ios: 0,
        undefined: 0,
      },
      activeUsersFilter: "DAILY",
      dailyUsersFilter: moment().format("YYYY/MM"),
      monthlyUsersFilter: moment().format("YYYY"),
      dailyUsersFilterTemp: "",
      monthlyUsersFilterTemp: "",
      showDateFilter: false,
      activeUsersLabel: [],
      activeUsersDate: {},
      totalActiveUsers: null,
      pageBenefit: 1,
      pageSolution: 1,
      paginationBenefit: {},
      paginationSolution: {},
      benefitData: [],
      solutionData: [],
    };
  }

  componentDidMount() {
    this.getApi({});
    this.props.getDailySignUp();
    this.getFeatureAnalytic("BENEFIT");
    this.getNewAndRecurringUsers();
    this.getFeatureAnalyticOs();
    this.getActiveUsers();
    this.getFeatureAnalyticBenefit();
    this.getFeatureAnalyticSolution();
  }

  componentDidUpdate(prevProps, prevState) {
    const {
      dailyUsersFilter,
      monthlyUsersFilter,
      activeUsersFilter,
    } = this.state;

    if (
      dailyUsersFilter !== prevState.dailyUsersFilter ||
      monthlyUsersFilter !== prevState.monthlyUsersFilter ||
      activeUsersFilter !== prevState.activeUsersFilter
    ) {
      this.getActiveUsers();
    }
  }

  getApi = async ({ params = {} }) => {
    this.setState({ loading: true });
    try {
      let resultGet = {};
      resultGet.fetching = false;
      resultGet.payload = await getCountSignupPlatform(params);
      ProccessData(resultGet, "get total user")
        .then((res) => {
          const {
            mobile,
            mobilePercentage,
            website,
            webPercentage,
            totalUsers,
          } = res.data;
          const chartData = [];

          if (website > 0)
            chartData.push({
              angle: website,
              label: webPercentage,
              className: "mobile-value",
              color: Colors.color36,
            });

          if (mobile > 0)
            chartData.push({
              color: Colors.color25,
              angle: mobile,
              label: mobilePercentage,
              className: "web-value",
            });

          this.setState({
            loading: false,
            legendData: [
              {
                ...ITEMS[0],
                total: website,
              },
              {
                ...ITEMS[1],
                total: mobile,
              },
            ],
            chartData,
            totalUsers,
          });
        })
        .catch((err) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: err,
          });
          this.setState({ loading: false });
        });
    } catch (e) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: e,
      });
      this.setState({ loading: false });
    }
  };

  getFeatureAnalytic = async (param) => {
    axios
      .get(`${AppConfig.ACCOUNT_URL}/admin/feature-analytic?type=${param}`)
      .then((res) => {
        const result = res.data.data;

        // Extract the labels and counts arrays
        const labels = result.map((item) => item.month);
        const counts = result.map((item) => item.count);

        // Calculate the total count
        const total = counts.reduce((sum, count) => sum + count, 0);

        this.setState({
          lineChartLabel: labels,
          lineChartData: counts,
          lineChartTotal: total,
        });
      });
  };

  getFeatureAnalyticOs = async (param) => {
    axios
      .get(`${AppConfig.ACCOUNT_URL}/admin/feature-analytic-os`)
      .then((res) => {
        const result = res.data.data;

        this.setState({
          phoneOsChart: {
            android:
              result.find((item) => item.label === "android")?.count || 0,
            ios: result.find((item) => item.label === "ios")?.count || 0,
            undefined:
              result.find((item) => item.label === "undefined")?.count || 0,
          },
        });
      });
  };

  getNewAndRecurringUsers = async () => {
    axios
      .get(
        `${AppConfig.ACCOUNT_URL}/admin/feature-analytic?type=LOGIN_REGISTER`
      )
      .then((res) => {
        const data = res.data.data;

        const label = data.map((item) => item.month);
        const login = data.map((item) => item.login);
        const register = data.map((item) => item.register);

        const total =
          login.reduce((sum, count) => sum + count, 0) +
          register.reduce((sum, count) => sum + count, 0);

        const result = {
          label,
          login,
          register,
          total,
        };

        this.setState({
          newAndRecurringUserBarChart: result,
        });
      });
  };

  getActiveUsers = async () => {
    axios
      .get(
        `${AppConfig.ACCOUNT_URL}/admin/feature-analytic-active-users?type=${
          this.state.activeUsersFilter
        }_ACTIVE&endDate=${
          this.state.activeUsersFilter === "DAILY"
            ? this.state.dailyUsersFilter
            : this.state.monthlyUsersFilter
        } `
      )
      .then((res) => {
        const data = res.data.data;
        let totalCount = 0;

        const usersActive = {
          MoP: data.MoP,
          Caregiver: data.Caregiver,
          PLWD: data.PLWD,
        };

        for (const key in usersActive) {
          totalCount += usersActive[key].reduce((acc, curr) => acc + curr, 0);
        }

        this.setState({
          activeUsersDate: {
            MoP: data.MoP,
            Caregiver: data.Caregiver,
            PLWD: data.PLWD,
          },
          activeUsersLabel: data.label,
          totalActiveUsers: totalCount,
        });
      });
  };

  getFeatureAnalyticBenefit = async (param) => {
    axios
      .get(
        `${AppConfig.ACCOUNT_URL}/admin/feature-analytic-benefit?page=${
          param ? param : this.state.pageBenefit
        }`
      )
      .then((res) => {
        const result = res.data.data.data;
        const pagination = res.data.data.pagination;

        // follow the weird logic of pagination component, need to fix later
        let totalPage = [];
        for (let i = 1; i <= pagination.totalPage; i++) {
          totalPage.push(i);
        }

        pagination.totalPage = totalPage;

        this.setState({
          benefitData: result,
          paginationBenefit: pagination,
        });
      });
  };

  getFeatureAnalyticSolution = async (param) => {
    axios
      .get(
        `${AppConfig.ACCOUNT_URL}/admin/feature-analytic-solution?page=${
          param ? param : this.state.pageSolution
        }`
      )
      .then((res) => {
        const result = res.data.data.data;
        const pagination = res.data.data.pagination;

        // follow the weird logic of pagination component, need to fix later
        let totalPage = [];
        for (let i = 1; i <= pagination.totalPage; i++) {
          totalPage.push(i);
        }

        pagination.totalPage = totalPage;

        this.setState({
          solutionData: result,
          paginationSolution: pagination,
        });
      });
  };

  applyDateFilter = async () => {
    const {
      activeUsersFilter,
      dailyUsersFilterTemp,
      monthlyUsersFilterTemp,
    } = this.state;

    if (activeUsersFilter === "DAILY") {
      this.setState({
        dailyUsersFilter: dailyUsersFilterTemp,
        showDateFilter: false,
      });
    } else if (activeUsersFilter === "MONTHLY") {
      this.setState({
        monthlyUsersFilter: monthlyUsersFilterTemp,
        showDateFilter: false,
      });
    }
  };

  handlePagination = async ({ page, type }) => {
    if (type === "BENEFIT") {
      this.setState({ pageBenefit: page });
      this.getFeatureAnalyticBenefit(page);
    } else if (type === "SOLUTION") {
      this.setState({ pageSolution: page });
      this.getFeatureAnalyticSolution(page);
    }
  };

  render() {
    moment.locale("en");

    const {
      legendData,
      totalUsers,
      lineChartData,
      lineChartLabel,
      lineChartTotal,
      activeFeatureClick,
      newAndRecurringUserBarChart,
      phoneOsChart,
      activeUsersFilter,
      dailyUsersFilter,
      monthlyUsersFilter,
      showDateFilter,
      dailyUsersFilterTemp,
      monthlyUsersFilterTemp,
      activeUsersLabel,
      activeUsersDate,
      totalActiveUsers,
      benefitData,
      solutionData,
      paginationBenefit,
      paginationSolution,
      pageBenefit,
      pageSolution,
    } = this.state;

    const sortOrder = ["DEMENTIA", "CAREGIVER", "MOP"];

    const userRoleDistributionData = sortOrder.map((type) => {
      const found = this.props.daily?.daily?.find((item) => item.type === type);
      return found ? found.count : null;
    });

    const userRegistrationPlatformData = legendData?.map((item) => item.total);

    const allUsersCount = this.props.daily?.daily?.find(
      (item) => item.type === "ALL"
    ).count;

    const containerStyle = {
      display: "flex",
      flexDirection: "column",
      gap: "20px", // Adjust the gap between rows
    };

    const rowStyle = {
      display: "flex",
      justifyContent: "space-between",
    };

    // Get the current month and year using Moment.js
    const currentDate = moment();
    const currentYear = currentDate.year();
    const currentMonth = currentDate.month();

    // Calculate the maximum date
    const maxDate = moment([currentYear, currentMonth]).endOf("month");

    const CustomInput = React.forwardRef((props, ref) => {
      return (
        <CustomDatePickDiv>
          <Row>
            <label onClick={props.onClick} ref={ref}>
              <Col>{props.value || props.placeholder}</Col>
            </label>
          </Row>
        </CustomDatePickDiv>
      );
    });

    const CustomDatePickDiv = styled.div`
      background-color: white;
      border-radius: 10px;
      width: 220px;
      label{
        padding: 5px;
        font-family: ${Fonts.FontRegular};
        color: ${Colors.color2};
        resize: none;
        text-align: center;
        ${Fonts.size.size16}}
      }
    `;

    const MyContainer = ({ className, children }) => {
      return (
        <div style={{ marginLeft: "180px" }}>
          <CustomDatePickDiv className={className}>
            <div style={{ position: "relative" }}>{children}</div>
          </CustomDatePickDiv>
        </div>
      );
    };

    const { history } = this.props;

    const columnBenefit = [
      {
        Header: (props) => (
          <span
            style={{
              display: "inline-block",
              margin: "10px 0",
              whiteSpace: "pre-line !important",
              wordWrap: "break-word",
            }}
          >
            No
          </span>
        ),
        Cell: (props) => (
          <span
            className="column-text"
            onClick={() => history.push(`/benefit/${props.original.benefitId}`)}
          >
            {props.row._index +
              1 +
              (pageBenefit > 1 ? (pageBenefit - 1) * 10 : 0)}
          </span>
        ),
        headerClassName: "table-header header-no",
        className: "column text-no",
        width: 90,
        sortable: false,
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            Title
          </span>
        ),
        Cell: (props) => (
          <span
            onClick={() => history.push(`/benefit/${props.original.benefitId}`)}
          >
            {props.original.title
              ? props.original.title
              : props.original.titleChinese
              ? props.original.titleChinese
              : "-"}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
        width: 350,
        sortable: false,
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            Category
          </span>
        ),
        Cell: (props) => (
          <span
            className="column-text"
            onClick={() => history.push(`/benefit/${props.original.benefitId}`)}
          >
            {props.original.category}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
        width: 350,
        sortable: false,
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            Created Date
          </span>
        ),
        Cell: (props) => (
          <span
            className="column-text"
            onClick={() => history.push(`/benefit/${props.original.benefitId}`)}
          >
            {moment(props.original.createdAt).format("DD MMMM YYYY")}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
        width: 250,
        sortable: false,
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            Number of Views
          </span>
        ),
        Cell: (props) => (
          <span
            className="column-text"
            onClick={() => history.push(`/benefit/${props.original.benefitId}`)}
          >
            {props.original.viewCount}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
        sortable: false,
      },
    ];

    const columnSolution = [
      {
        Header: (props) => (
          <span
            style={{
              display: "inline-block",
              margin: "10px 0",
              whiteSpace: "pre-line !important",
              wordWrap: "break-word",
            }}
          >
            No
          </span>
        ),
        Cell: (props) => (
          <span
            className="column-text"
            onClick={() =>
              history.push(`/solution/${props.original.solutionId}`)
            }
          >
            {props.row._index +
              1 +
              (pageSolution > 1 ? (pageSolution - 1) * 10 : 0)}
          </span>
        ),
        headerClassName: "table-header header-no",
        className: "column text-no",
        width: 90,
        sortable: false,
      },
      {
        Header: (props) => (
          <span
            style={{
              display: "inline-block",
              margin: "10px 0",
              textAlign: "left",
            }}
          >
            Title
          </span>
        ),
        Cell: (props) => (
          <span
            className="column-text"
            onClick={() =>
              history.push(`/solution/${props.original.solutionId}`)
            }
          >
            {props.original.title
              ? props.original.title
              : props.original.titleChinese
              ? props.original.titleChinese
              : "-"}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
        width: 350,
        sortable: false,
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            Category
          </span>
        ),
        Cell: (props) => (
          <span
            className="column-text"
            onClick={() =>
              history.push(`/solution/${props.original.solutionId}`)
            }
          >
            {props.original.category}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
        width: 350,
        sortable: false,
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            Created Date
          </span>
        ),
        Cell: (props) => (
          <span
            className="column-text"
            onClick={() =>
              history.push(`/solution/${props.original.solutionId}`)
            }
          >
            {moment(props.original.createdAt).format("DD MMMM YYYY")}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
        width: 250,
        sortable: false,
      },
      {
        Header: (props) => (
          <span style={{ display: "inline-block", margin: "10px 0" }}>
            Number of Views
          </span>
        ),
        Cell: (props) => (
          <span
            className="column-text"
            onClick={() =>
              history.push(`/solution/${props.original.solutionId}`)
            }
          >
            {props.original.viewCount}
          </span>
        ),
        headerClassName: "table-header",
        className: "column",
        sortable: false,
      },
    ];

    return (
      <StyledAnalytics>
        <div className="container">
          <div className="header">
            <Title customStyle={` color: black; `}>Analytics</Title>
          </div>
          <div>
            <>
              <p
                style={{
                  textAlign: "left",
                  fontWeight: 800,
                  fontSize: "24px",
                  color: Colors.color2,
                  marginBottom: "30px",
                  marginTop: "30px",
                }}
              >
                User Analytics
              </p>
              <div style={containerStyle}>
                <div style={rowStyle}>
                  {/* card 1 */}
                  <Card2>
                    <div style={{ padding: "0px 30px" }}>
                      <p
                        className="coming-soon-text"
                        style={{
                          marginTop: 0,
                          marginBottom: "20px",
                          marginTop: "30px",
                          textAlign: "left",
                        }}
                      >
                        User Registration Platform
                      </p>
                      <p
                        style={{
                          fontSize: "40px",
                          color: Colors.color11,
                          fontWeight: 800,
                          textAlign: "left",
                          marginBottom: "5px",
                        }}
                      >
                        {totalUsers}{" "}
                      </p>
                      <p className="total-text" style={{ textAlign: "left" }}>
                        Total users
                      </p>
                    </div>
                    <hr
                      style={{
                        border: `solid 1px ${Colors.color9}`,
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    />
                    <div
                      style={{
                        width: "85%",
                        padding: "40px 50px",
                      }}
                    >
                      <PieChart
                        label={["Web", "Mobile"]}
                        data={userRegistrationPlatformData}
                        color={[Colors.color36, Colors.color25]}
                      />
                    </div>
                  </Card2>

                  {/* card 2 */}
                  <Card2>
                    <div style={{ padding: "0px 30px" }}>
                      <p
                        className="coming-soon-text"
                        style={{
                          marginTop: 0,
                          marginBottom: "20px",
                          marginTop: "30px",
                          textAlign: "left",
                        }}
                      >
                        User Role Distribution
                      </p>
                      <p
                        style={{
                          fontSize: "40px",
                          color: Colors.color11,
                          fontWeight: 800,
                          textAlign: "left",
                          marginBottom: "5px",
                        }}
                      >
                        {allUsersCount}{" "}
                      </p>
                      <p className="total-text" style={{ textAlign: "left" }}>
                        Total users
                      </p>
                    </div>
                    <hr
                      style={{
                        border: `solid 1px ${Colors.color9}`,
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    />
                    <div
                      style={{
                        width: "100%",
                        padding: "0px 50px",
                      }}
                    >
                      <PieChart
                        label={[
                          "Person Living With Dementia",
                          "Caregiver",
                          "Member of Public",
                        ]}
                        data={userRoleDistributionData}
                        color={[Colors.color11, Colors.color14, Colors.orange2]}
                      />
                    </div>
                  </Card2>
                </div>
                <div style={rowStyle}>
                  {/* card 3 */}
                  <Card2>
                    <div style={{ padding: "0px 30px", marginBottom: "65px" }}>
                      <p
                        className="coming-soon-text"
                        style={{
                          marginTop: 0,
                          marginBottom: "20px",
                          marginTop: "30px",
                          textAlign: "left",
                        }}
                      >
                        OS Platform Usage
                      </p>
                      <p className="total-text" style={{ textAlign: "left" }}>
                        User distribution
                      </p>
                    </div>
                    <hr
                      style={{
                        border: `solid 1px ${Colors.color9}`,
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    />
                    <div style={{ width: "auto", padding: "0px 110px" }}>
                      <PieChart
                        label={["iOS", "Android", "Undefined"]}
                        data={[
                          phoneOsChart?.ios,
                          phoneOsChart?.android,
                          phoneOsChart?.undefined,
                        ]}
                        color={["#758BA9", "#29D09E", "#f0f0f0"]}
                      />
                    </div>
                  </Card2>
                  {/* card 4 */}
                  <Card2>
                    <div style={{ padding: "0px 30px" }}>
                      <p
                        className="coming-soon-text"
                        style={{
                          marginTop: 0,
                          marginBottom: "20px",
                          marginTop: "30px",
                          textAlign: "left",
                        }}
                      >
                        New and Recurring Users
                      </p>
                      <p
                        style={{
                          fontSize: "40px",
                          color: Colors.color11,
                          fontWeight: 800,
                          textAlign: "left",
                          marginBottom: "5px",
                        }}
                      >
                        {newAndRecurringUserBarChart?.total}{" "}
                      </p>
                      <p className="total-text" style={{ textAlign: "left" }}>
                        Total users
                      </p>
                    </div>
                    <hr
                      style={{
                        border: `solid 1px ${Colors.color9}`,
                        marginTop: "20px",
                        marginBottom: "20px",
                      }}
                    />
                    <div style={{ width: "auto", padding: "0px 50px" }}>
                      <VerticalBarChart
                        label={newAndRecurringUserBarChart?.label}
                        data={[
                          {
                            label: "Register",
                            data: newAndRecurringUserBarChart?.register,
                            backgroundColor: "#63ABFD",
                            borderColor: "#165BAA",
                            borderWidth: 2,
                            borderRadius: "5",
                          },
                          {
                            label: "Login",
                            data: newAndRecurringUserBarChart?.login,
                            backgroundColor: "#E697FF",
                            borderColor: "#A155B9",
                            borderWidth: 2,
                            borderRadius: "5",
                          },
                        ]}
                      />
                    </div>
                  </Card2>
                </div>
              </div>

              {/* Active users */}
              <p
                style={{
                  textAlign: "left",
                  fontWeight: 800,
                  fontSize: "24px",
                  color: Colors.color2,
                  marginBottom: "30px",
                  marginTop: "30px",
                }}
              >
                Active Users
              </p>
              <div style={{ marginBottom: "20px" }}>
                <div style={{ display: "flex" }}>
                  <Button
                    text={"Daily"}
                    buttonStyle={{
                      borderRadius: "10px",
                      width: "240px",
                      height: "50px",
                    }}
                    backgroundColor={
                      activeUsersFilter === "DAILY"
                        ? Colors.color25
                        : Colors.color9
                    }
                    textColor={
                      activeUsersFilter === "DAILY"
                        ? Colors.color3
                        : Colors.color7
                    }
                    onClick={() =>
                      this.setState({ activeUsersFilter: "DAILY" })
                    }
                  />
                  <Button
                    text={"Monthly"}
                    buttonStyle={{
                      borderRadius: "10px",
                      width: "240px",
                      height: "50px",
                      marginLeft: "20px",
                    }}
                    backgroundColor={
                      activeUsersFilter === "MONTHLY"
                        ? Colors.color25
                        : Colors.color9
                    }
                    textColor={
                      activeUsersFilter === "MONTHLY"
                        ? Colors.color3
                        : Colors.color7
                    }
                    onClick={() =>
                      this.setState({ activeUsersFilter: "MONTHLY" })
                    }
                  />
                </div>
              </div>
              <Card2>
                <div
                  style={{
                    display: "flex",
                    padding: "10px 30px 0px",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div style={{ width: "50%" }}>
                    <p
                      className="coming-soon-text"
                      style={{
                        marginTop: 0,
                        marginBottom: "20px",
                        marginTop: "30px",
                        textAlign: "left",
                      }}
                    >
                      Active{" "}
                      {activeUsersFilter === "DAILY" ? "Daily" : "Monthly"}{" "}
                      Users
                    </p>
                    <p
                      style={{
                        fontSize: "40px",
                        color: Colors.color11,
                        fontWeight: 800,
                        textAlign: "left",
                        marginBottom: "5px",
                      }}
                    >
                      {totalActiveUsers}{" "}
                    </p>
                    <p style={{ textAlign: "left", fontWeight: 800 }}>
                      Total active users in
                      {activeUsersFilter === "DAILY" && dailyUsersFilter !== ""
                        ? ` ${moment(dailyUsersFilter, "YYYY/MM").format(
                            "MMMM YYYY"
                          )}`
                        : ""}
                      {activeUsersFilter === "MONTHLY" &&
                      monthlyUsersFilter !== ""
                        ? ` ${monthlyUsersFilter}`
                        : ""}
                    </p>
                  </div>
                  <div>
                    <Button
                      text={"Filter By Date"}
                      buttonStyle={{
                        padding: "10px 20px",
                        marginTop: "-30px",
                      }}
                      backgroundColor={"white"}
                      textColor={Colors.color36}
                      borderColor={Colors.color36}
                      bordered
                      onClick={() =>
                        this.setState({ showDateFilter: !showDateFilter })
                      }
                      iconRight
                      iconRightImage={
                        showDateFilter ? Images.drowpdown : Images.drowpup
                      }
                      iconRightStyle={`
                        width: 16px;
                        margin-left: 10px;
                      `}
                    />

                    {showDateFilter && (
                      <div
                        style={{
                          width: "260px",
                          height: "180px",
                          backgroundColor: "#f7f7f7",
                          position: "absolute",
                          zIndex: "999",
                          borderRadius: "15px",
                          padding: "20px",
                          border: "1px solid lightgrey",
                          marginLeft: "-95px",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                            marginBottom: "20px",
                          }}
                        >
                          <div>
                            <p
                              style={{
                                color: Colors.color11,
                                fontSize: "20px",
                                fontWeight: "800",
                              }}
                            >
                              Filter by Date
                            </p>
                          </div>
                          <div
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              this.setState({
                                dailyUsersFilter: "",
                                monthlyUsersFilter: "",
                                showDateFilter: false,
                              });
                            }}
                          >
                            <img
                              src={Images.iconClose2}
                              style={{
                                width: "20px",
                                height: "20px",
                              }}
                            />
                          </div>
                        </div>
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "space-between",
                          }}
                        >
                          {activeUsersFilter === "DAILY" ? (
                            <div style={{ marginRight: "5px" }}>
                              <p
                                style={{
                                  color: Colors.color19,
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  marginBottom: "10px",
                                  textAlign: "left",
                                }}
                              >
                                Month
                              </p>
                              <div
                                style={{
                                  backgroundColor: "white",
                                  width: "",
                                  height: "30px",
                                  border: "1px solid lightgrey",
                                  borderRadius: "10px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <DatePicker
                                  showMonthDropdown
                                  showYearDropdown
                                  customInput={<CustomInput />}
                                  filterDate={(date) => {
                                    return moment() > date;
                                  }}
                                  placeholderText="Select month"
                                  dateFormat="MM/yyyy"
                                  showMonthYearPicker
                                  dateFormatCalendar="MM/yyyy"
                                  selected={
                                    dailyUsersFilterTemp !== ""
                                      ? new Date(dailyUsersFilterTemp)
                                      : null
                                  }
                                  onChange={(date) =>
                                    this.setState({
                                      dailyUsersFilterTemp: moment(date).format(
                                        "YYYY/MM"
                                      ),
                                    })
                                  }
                                  showPopperArrow={false}
                                  dropdownMode="select"
                                  popperPlacement="bottom-end"
                                  calendarContainer={MyContainer}
                                  maxDate={maxDate.toDate()}
                                />
                              </div>
                            </div>
                          ) : (
                            <div>
                              <p
                                style={{
                                  color: Colors.color19,
                                  fontSize: "14px",
                                  fontWeight: "700",
                                  marginBottom: "10px",
                                  textAlign: "left",
                                }}
                              >
                                Year
                              </p>
                              <div
                                style={{
                                  backgroundColor: "white",
                                  width: "",
                                  height: "30px",
                                  border: "1px solid lightgrey",
                                  borderRadius: "10px",
                                  display: "flex",
                                  justifyContent: "center",
                                  alignItems: "center",
                                }}
                              >
                                <DatePicker
                                  showMonthDropdown
                                  showYearDropdown
                                  customInput={<CustomInput />}
                                  filterDate={(date) => {
                                    return moment() > date;
                                  }}
                                  placeholderText="Select year"
                                  dateFormat="yyyy"
                                  showYearPicker
                                  dateFormatCalendar="yyyy"
                                  selected={
                                    monthlyUsersFilterTemp !== ""
                                      ? new Date(monthlyUsersFilterTemp)
                                      : null
                                  }
                                  onChange={(date) => {
                                    this.setState({
                                      monthlyUsersFilterTemp: moment(
                                        date
                                      ).format("YYYY"),
                                    });
                                  }}
                                  showPopperArrow={false}
                                  dropdownMode="select"
                                  popperPlacement="bottom-end"
                                  calendarContainer={MyContainer}
                                  maxDate={maxDate.toDate()}
                                />
                              </div>
                            </div>
                          )}
                        </div>
                        <div style={{ marginTop: "20px" }}>
                          <Button
                            text="Apply"
                            buttonStyle={{ height: "20px" }}
                            disabled={
                              activeUsersFilter === "DAILY"
                                ? dailyUsersFilterTemp === ""
                                : activeUsersFilter === "MONTHLY"
                                ? monthlyUsersFilterTemp === ""
                                : true
                            }
                            onClick={() => this.applyDateFilter()}
                          />
                        </div>
                      </div>
                    )}
                  </div>
                </div>
                <hr
                  style={{
                    border: `solid 1px ${Colors.color9}`,
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                />
                <div style={{ padding: "0px 30px 20px" }}>
                  <LineChart
                    label={activeUsersLabel}
                    data={[
                      {
                        label: "PLWD",
                        data: activeUsersDate?.PLWD,
                        borderColor: Colors.navy,
                        backgroundColor: Colors.navy,
                      },
                      {
                        label: "Caregiver",
                        data: activeUsersDate?.Caregiver,
                        borderColor: "#A155B9",
                        backgroundColor: "#A155B9",
                      },
                      {
                        label: "MoP",
                        data: activeUsersDate?.MoP,
                        borderColor: "#F765A3",
                        backgroundColor: "#F765A3",
                      },
                    ]}
                  />
                </div>
              </Card2>

              <p
                style={{
                  textAlign: "left",
                  fontWeight: 800,
                  fontSize: "24px",
                  color: Colors.color2,
                  marginBottom: "30px",
                  marginTop: "30px",
                }}
              >
                Feature Clicks
              </p>
              <div style={{ marginBottom: "20px" }}>
                <ButtonSlider
                  onClick={(e) => {
                    this.getFeatureAnalytic(e.key);
                    this.setState({ activeFeatureClick: e });
                  }}
                  active={this.state.activeFeatureClick}
                  data={[
                    {
                      key: "BENEFIT",
                      display: "Benefit",
                    },
                    {
                      key: "SOLUTION",
                      display: "Solution",
                    },
                    {
                      key: "MY_RADIO",
                      display: "My Radio",
                    },
                    {
                      key: "REPORT_MISSING_PERSON",
                      display: "Report Missing Person",
                    },
                    {
                      key: "BANNER_CONTENT",
                      display: "Banner Content",
                    },
                  ]}
                />
              </div>
              <Card2>
                <div style={{ padding: "10px 30px 0px" }}>
                  <p
                    className="coming-soon-text"
                    style={{
                      marginTop: 0,
                      marginBottom: "20px",
                      marginTop: "30px",
                      textAlign: "left",
                    }}
                  >
                    {activeFeatureClick.display} Menu Clicks
                  </p>
                  <p
                    style={{
                      fontSize: "40px",
                      color: Colors.color11,
                      fontWeight: 800,
                      textAlign: "left",
                      marginBottom: "5px",
                    }}
                  >
                    {lineChartTotal}{" "}
                  </p>
                  <p className="total-text" style={{ textAlign: "left" }}>
                    Total clicks
                  </p>
                </div>
                <hr
                  style={{
                    border: `solid 1px ${Colors.color9}`,
                    marginTop: "20px",
                    marginBottom: "20px",
                  }}
                />
                <div style={{ padding: "0px 30px 20px" }}>
                  <LineChart
                    label={lineChartLabel}
                    data={[
                      {
                        label: "Clicks",
                        data: lineChartData,
                        borderColor: Colors.navy,
                        backgroundColor: Colors.navy,
                      },
                    ]}
                  />
                </div>
              </Card2>
            </>

            <p
              style={{
                textAlign: "left",
                fontWeight: 800,
                fontSize: "24px",
                color: Colors.color2,
                marginBottom: "30px",
                marginTop: "30px",
              }}
            >
              Benefits
            </p>
            <div style={{ textAlign: "left" }}>
              <Table
                columns={columnBenefit}
                data={benefitData}
                pageSize={10}
                dataLimit={10}
                tableProps={{
                  showPageSizeOptions: false,
                  showPagination: false,
                }}
              />
              <Pagination
                page={pageBenefit}
                onClickPrevious={() =>
                  this.handlePagination({
                    page: parseInt(pageBenefit) - 1,
                    type: "BENEFIT",
                  })
                }
                onClickNext={() =>
                  this.handlePagination({
                    page: parseInt(pageBenefit) + 1,
                    type: "BENEFIT",
                  })
                }
                onClickPage={(number) =>
                  this.handlePagination({ page: number, type: "BENEFIT" })
                }
                totalPage={paginationBenefit?.totalPage}
              />
            </div>

            <p
              style={{
                textAlign: "left",
                fontWeight: 800,
                fontSize: "24px",
                color: Colors.color2,
                marginBottom: "30px",
                marginTop: "30px",
              }}
            >
              Solutions
            </p>
            <div style={{ textAlign: "left" }}>
              <Table
                columns={columnSolution}
                data={solutionData}
                pageSize={10}
                dataLimit={10}
                tableProps={{
                  showPageSizeOptions: false,
                  showPagination: false,
                }}
              />
              <Pagination
                page={pageSolution}
                onClickPrevious={() =>
                  this.handlePagination({
                    page: parseInt(pageSolution) - 1,
                    type: "SOLUTION",
                  })
                }
                onClickNext={() =>
                  this.handlePagination({
                    page: parseInt(pageSolution) + 1,
                    type: "SOLUTION",
                  })
                }
                onClickPage={(number) =>
                  this.handlePagination({ page: number, type: "SOLUTION" })
                }
                totalPage={paginationSolution?.totalPage}
              />
            </div>
            {/* <div className="coming-soon-container">
              <img src={Images.analyticsComingSoon} style={{ width: '350px', height: '330px', objectFit: 'contain' }} />
              <p className="coming-soon-text">Coming Soon-Phase 2</p>
            </div> */}
          </div>
        </div>
      </StyledAnalytics>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    daily: state.dashboard,
  };
};

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getDailySignUp: (params) =>
      dispatch(DashboardAction.getDailySignUpRequest(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Analytics);
