import React, { Component } from 'react'
import { Container, Row, Col, Hidden, } from 'react-grid-system';
import QRCode from 'qrcode.react'
import moment from 'moment'

import { StyledUserPrintPage } from './styles/StyledUserPrintPage'

import DialogInfo from '../components/dialog-info'
import { Colors } from '../themes'

// import AppConfig from '../config/AppConfig'
import AppConfig from '../config.js'

import { getUserById, getUsers, } from '../api/UserAPI'

import ProccessData from '../lib/ProccessData'

export class UserPrintPage extends Component {
  constructor(props) {
    super(props)

    this.state = {
      users: [],
      loading: true,
      infoDialogShow: false,
      infoDescription: ''
    }
  }


  async componentDidMount() {
    const { location, } = this.props
    let users = []
    this.setState({ loading: true })
    let params = {}
    
    if (location.state.uid) {
      params.uid = location.state.uid
    } else if (location.state.uidList) {
      params.uidList = location.state.uidList
    } else {
      params.updatedAt = `${location.state.startDate},${location.state.endDate}`
    }

    try {
      let resultGet = {}
      resultGet.fetching = false
      resultGet.payload = location.state.uid ? await getUserById({ uid: params.uid }) : await getUsers({ ...params, })
      ProccessData(resultGet, 'get user')
      .then(res => {
        if (res.data.length < 1) {
          this.onDialogInfo('Data Not Found')
          this.props.history.goBack()
          return false
        }
        this.setState({ 
          users: res.data,
          loading: false,
        })
        window.print()
      })
      .catch(err => {
        this.onDialogInfo(err)
        this.setState({
          loading: false,
        })
      })
    } catch (e) {
      this.onDialogInfo(e.message)
      this.setState({ loading: false })
    }
  }

   renderList(stateName, field, value, className = '', dropdown = {}, inputProps = { type: 'text' }) {

    const isInput = Object.keys(dropdown).length === 0
    const type = inputProps
    return (
      <Row style={{ width: '100%', marginLeft: '.1px', }} className={`list-container ${className}`}> 
        <Col xs={5}>
          <p>{field}</p>
        </Col>
        <Col xs={7}>
          <div style={{ display: 'flex', }}>
            <p style={{ marginRight: '22px', }}>: </p>
            <p>{value ? value : '-'}</p>
          </div>
        </Col>   
      </Row>
    )
  }
  
  render() {
    const { loading, users, infoDialogShow, infoDescription, } = this.state
    return (
      <StyledUserPrintPage>        
        {/* Dialog info start */}
        <DialogInfo
          isVisible={infoDialogShow}
          textInfo={infoDescription}
          onOkButton={()=> this.setState({infoDialogShow: false})}
        />
        {/* Dialog info end  */}
        {
          loading ?
            <h1>Loading...</h1>
          : 
          Array.isArray(users) ?
            users.map(data => {
              const { uid, firstname, lastname, membershipId, approvalDate, } = data
              return (
                <Container className="wrapper">
                  {
                    <div>
                      <Row>    
                        {
                          [
                            this.renderList('firstname', 'First Name', firstname, 'list-dark'),
                            this.renderList('lastname', 'Last Name', lastname),
                            this.renderList('gender', 'Membership Card Number', membershipId, 'list-dark'),
                            this.renderList('lastNric', 'Member Since', approvalDate, ''),
                          ]
                        }
                        <div className="qrcode-container">
                          <QRCode value={`${AppConfig.HOSTNAME}/user-detail/${uid}`} size={200} />
                        </div>
                      </Row>
                    </div>
                  }
                </Container>
              )
            }) 
          :
          <Container className="wrapper">
            {
              <div>
                <Row>    
                  {
                    [
                      this.renderList('firstname', 'First Name', users.firstname, 'list-dark'),
                      this.renderList('lastname', 'Last Name', users.lastname),
                      this.renderList('gender', 'Membership Card Number', users.membershipId, 'list-dark'),
                      this.renderList('lastNric', 'Member Since', users.approvalDate, ''),
                    ]
                  }
                  <div className="qrcode-container">
                    <QRCode value={`${AppConfig.HOSTNAME}/user-detail/${users.uid}`} size={200} />
                  </div>
                </Row>
              </div>
            }
          </Container>
        }
      </StyledUserPrintPage>
    )
  }
}

export default UserPrintPage
