import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { Bar } from 'react-chartjs-2';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);

export const options = {
  responsive: true,
  plugins: {
    legend: {
        position: "bottom",
        align: "left",
        border: true,
        borderColor: '#A155B9'
    },
    title: {
      display: true,
    },
  },
};

const labels = ['January', 'February', 'March', 'April', 'May', 'June', 'July'];

const example = {
    labels: labels,
    datasets: [{
      label: 'Register',
      data: [65, 59, 80, 81, 56, 55, 40],
      backgroundColor: '#63ABFD',
      borderColor: '#165BAA',
      borderWidth: 2,
      borderRadius: '5'
    },
    {
        label: 'Login',
        data: [53, 89, 83, 98, 87, 190, 230],
        backgroundColor: '#E697FF',
        borderColor: '#A155B9',
        borderWidth: 2,
        borderRadius: '5'
      }]
  };

export default function VerticalBarChart({label, data}) {
  return <Bar options={options}   
            data={{
                labels: label,
                datasets: data,
            }}  
        />;
}
