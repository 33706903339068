import {call, put, select} from 'redux-saga/effects';
import DashboardAction from '../redux/DashboardPartner';
import { AuthSelectors } from '../redux/AuthRedux';

export function* getDashboard(api, action) {
    let { data } = action;

    const response = yield call(api.getDashboardPartner, data)
    if(response.ok) {
        yield put(DashboardAction.getDashboardSuccess(response.data))
    } else {
        if (response.status === 400 && response.data.error.name ==="TokenExpiredError"){
            yield put(AuthActions.signout())
            localStorage.clear()
        } else {
            yield put(DashboardAction.getDashboardFailure(response))
        }
    }
}

export function* getDailySignUp(api, action) {
    let { data } = action

    const response = yield call(api.getDailySignUp, data)
    if(response.ok) {
        yield put(DashboardAction.getDailySignUpSuccess(response.data))
    } else {
        if (response.status === 400 && response.data.error.name ==="TokenExpiredError"){
            yield put(AuthActions.signout())
            localStorage.clear()
        } else {
            yield put(DashboardAction.getDailySignUpFailure(response))
        }
    }
}