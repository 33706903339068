import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getSolutionsRequest: ['data'],
  getSolutionsSuccess: ['payload'],
  getSolutionsFailure: ['error'],

  addSolutionRequest: ['data'],
  addSolutionSuccess: ['payload'],
  addSolutionFailure: ['error'],

  deleteSolutionRequest: ['data'],
  deleteSolutionSuccess: ['payload'],
  deleteSolutionFailure: ['error'],
  
  updateSolutionRequest: ['data'],
  updateSolutionSuccess: ['payload'],
  updateSolutionFailure: ['error'],

  getSolutionCategoriesRequest: ['data'],
  getSolutionCategoriesSuccess: ['payload'],
  getSolutionCategoriesFailure: ['error'],

  addSolutionCategoryRequest: ['data'],
  addSolutionCategorySuccess: ['payload'],
  addSolutionCategoryFailure: ['error'],

  deleteSolutionCategoryRequest: ['data'],
  deleteSolutionCategorySuccess: ['payload'],
  deleteSolutionCategoryFailure: ['error'],

  updateSolutionCategoryRequest: ['data'],
  updateSolutionCategorySuccess: ['payload'],
  updateSolutionCategoryFailure: ['error'],
})

export const SolutionsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: { fetching: null, error: null, payload: null, data: null },
  addSolution:  { fetching: null, error: null, payload: null, data: null },
  deleteSolution:  { fetching: null, error: null, payload: null, data: null },
  updateSolution:  { fetching: null, error: null, payload: null, data: null },
  solutionCategories: { fetching: null, error: null,  payload: null, data: null },
  addCategory: { fetching: null, error: null,  payload: null, data: null },
  deleteCategory: { fetching: null, error: null,  payload: null, data: null },
  updateCategory: { fetching: null, error: null,  payload: null, data: null }
})

/* ------------- Selectors ------------- */

export const SolutionsSelectors = {
  solutions: state => state.Solutions.list,
}

/* ------------- Reducers ------------- */

export const getSolutionsRequest = (state, { data }) => {
  return state.merge({ ...state, list: { ...state.list, fetching: true, data, }})
}

export const getSolutionsSuccess = (state, { payload }) => {
  return state.merge({ ...state, list: {  ...state.list, fetching: false, error: false, payload, }})
}

export const getSolutionsFailure = (state, { error }) => {
  return state.merge({ ...state, list: { ...state.list, fetching: false, error, }})
}

export const addSolutionRequest = (state, { data }) => {
  return state.merge({ ...state, addSolution: { ...state.addSolution, fetching: true, data, }})
}

export const addSolutionSuccess = (state, { payload }) => {
  return state.merge({ ...state, addSolution: {  ...state.addSolution, fetching: false, error: false, payload, }})
}

export const addSolutionFailure = (state, { error }) => {
  return state.merge({ ...state, addSolution: { ...state.addSolution, fetching: false, error, }})
}

export const deleteSolutionRequest = (state, { data }) => {
  return state.merge({ ...state, deleteSolution: { ...state.deleteSolution, fetching: true, data, }})
}

export const deleteSolutionSuccess = (state, { payload }) => {
  return state.merge({ ...state, deleteSolution: {  ...state.deleteSolution, fetching: false, error: false, payload, }})
}

export const deleteSolutionFailure = (state, { error }) => {
  return state.merge({ ...state, deleteSolution: { ...state.deleteSolution, fetching: false, error, }})
}

export const updateSolutionRequest = (state, { data }) => {
  return state.merge({ ...state, updateSolution: { ...state.updateSolution, fetching: true, data, }})
}

export const updateSolutionSuccess = (state, { payload }) => {
  return state.merge({ ...state, updateSolution: {  ...state.updateSolution, fetching: false, error: false, payload, }})
}

export const updateSolutionFailure = (state, { error }) => {
  return state.merge({ ...state, updateSolution: { ...state.updateSolution, fetching: false, error, }})
}

export const getSolutionCategoriesRequest = (state, { data }) => {
  return state.merge({ ...state, solutionCategories: { ...state.solutionCategories, fetching: true, data, }})
}

export const getSolutionCategoriesSuccess = (state, { payload }) => {
  return state.merge({ ...state, solutionCategories: {  ...state.sSolutionCategories, fetching: false, error: false, payload, }})
}

export const getSolutionCategoriesFailure = (state, { error }) => {
  return state.merge({ ...state, solutionCategories: { ...state.solutionCategories, fetching: false, error, }})
}

export const addSolutionCategoryRequest = (state, { data }) => {
  return state.merge({ ...state, addCategory: { ...state.addCategory, fetching: true, data, }})
}

export const addSolutionCategorySuccess = (state, { payload }) => {
  return state.merge({ ...state, addCategory: {  ...state.addCategory, fetching: false, error: false, payload, }})
}

export const addSolutionCategoryFailure = (state, { error }) => {
  return state.merge({ ...state, addCategory: { ...state.addCategory, fetching: false, error, }})
}

export const deleteSolutionCategoryRequest = (state, { data }) => {
  return state.merge({ ...state, deleteCategory: { ...state.deleteCategory, fetching: true, data, }})
}

export const deleteSolutionCategorySuccess = (state, { payload }) => {
  return state.merge({ ...state, deleteCategory: {  ...state.deleteCategory, fetching: false, error: false, payload, }})
}

export const deleteSolutionCategoryFailure = (state, { error }) => {
  return state.merge({ ...state, deleteCategory: { ...state.deleteCategory, fetching: false, error, }})
}

export const updateSolutionCategoryRequest = (state, { data }) => {
  return state.merge({ ...state, updateCategory: { ...state.updateCategory, fetching: true, data, }})
}

export const updateSolutionCategorySuccess = (state, { payload }) => {
  return state.merge({ ...state, updateCategory: {  ...state.updateCategory, fetching: false, error: false, payload, }})
}

export const updateSolutionCategoryFailure = (state, { error }) => {
  return state.merge({ ...state, updateCategory: { ...state.updateCategory, fetching: false, error, }})
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_SOLUTIONS_REQUEST]: getSolutionsRequest,
  [Types.GET_SOLUTIONS_SUCCESS]: getSolutionsSuccess,
  [Types.GET_SOLUTIONS_FAILURE]: getSolutionsFailure,

  [Types.ADD_SOLUTION_REQUEST]: addSolutionRequest,
  [Types.ADD_SOLUTION_SUCCESS]: addSolutionSuccess,
  [Types.ADD_SOLUTION_FAILURE]: addSolutionFailure,

  [Types.DELETE_SOLUTION_REQUEST]: deleteSolutionRequest,
  [Types.DELETE_SOLUTION_SUCCESS]: deleteSolutionSuccess,
  [Types.DELETE_SOLUTION_FAILURE]: deleteSolutionFailure,

  [Types.UPDATE_SOLUTION_REQUEST]: updateSolutionRequest,
  [Types.UPDATE_SOLUTION_SUCCESS]: updateSolutionSuccess,
  [Types.UPDATE_SOLUTION_FAILURE]: updateSolutionFailure,

  [Types.GET_SOLUTION_CATEGORIES_REQUEST]: getSolutionCategoriesRequest,
  [Types.GET_SOLUTION_CATEGORIES_SUCCESS]: getSolutionCategoriesSuccess,
  [Types.GET_SOLUTION_CATEGORIES_FAILURE]: getSolutionCategoriesFailure,

  [Types.ADD_SOLUTION_CATEGORY_REQUEST]: addSolutionCategoryRequest,
  [Types.ADD_SOLUTION_CATEGORY_SUCCESS]: addSolutionCategorySuccess,
  [Types.ADD_SOLUTION_CATEGORY_FAILURE]: addSolutionCategoryFailure,

  [Types.DELETE_SOLUTION_CATEGORY_REQUEST]: deleteSolutionCategoryRequest,
  [Types.DELETE_SOLUTION_CATEGORY_SUCCESS]: deleteSolutionCategorySuccess,
  [Types.DELETE_SOLUTION_CATEGORY_FAILURE]: deleteSolutionCategoryFailure,

  [Types.UPDATE_SOLUTION_CATEGORY_REQUEST]: updateSolutionCategoryRequest,
  [Types.UPDATE_SOLUTION_CATEGORY_SUCCESS]: updateSolutionCategorySuccess,
  [Types.UPDATE_SOLUTION_CATEGORY_FAILURE]: updateSolutionCategoryFailure,
})
