import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Hidden } from "react-grid-system";
import moment from "moment";

import {
  StyledCreateNotification,
  Label,
  InputStyle,
} from "./styles/StyledCreateNotification";

import DialogInfo from "../components/dialog-info";

import { Images, Colors } from "../themes";

import Input from "../components/input";
import Button from "../components/button";

import { USER_TYPE } from "../constants";

import { addNotificationByAdmin as addNotification } from "../api/NotificationAPI";

import ProccessData from "../lib/ProccessData";

import NotificationsActions from "../redux/NotificationsRedux";

const InputReadOnlyStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin: 10px 0 25px 0; 
  .input-field { cursor: pointer; }
`;

const InputTypeStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin-top: 10px; 
  .input-field { cursor: pointer; }
`;

const InputDateStyle = `
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin-top: 10px; 

  input[type="date"]::-webkit-calendar-picker-indicator, input[type="time"]::-webkit-calendar-picker-indicator {
    cursor: pointer;
  }
`;

const dropdownTargetData = [
  {
    id: 1,
    name: "Person with Dementia",
    value: USER_TYPE.DEMENTIA,
  },
  {
    id: 2,
    name: "Caregiver",
    value: USER_TYPE.CAREGIVER,
  },
  {
    id: 3,
    name: "Member of Public",
    value: USER_TYPE.MOP,
  },
];

const ageData = [
  {
    id: 1,
    name: "Above age of 50",
    value: "50,above",
  },
  {
    id: 2,
    name: "Below age of 50",
    value: "50,below",
  },
];

const typeOfDementiaData = [
  {
    id: 1,
    name: `Alzheimer's Disease`,
  },
  {
    id: 2,
    name: "Vascular Dementia",
  },
  {
    id: 3,
    name: "Frontotemporal Dementia (FTD)",
  },
  {
    id: 4,
    name: "Lewy Body Dementia (LBD)",
  },
  {
    id: 5,
    name: "Mild Cognitive Impairment (MCI)",
  },
];

const languageData = [
  {
    id: 1,
    name: "English",
  },
  {
    id: 2,
    name: "Mandarin",
  },
  {
    id: 3,
    name: "Malay",
  },
  {
    id: 4,
    name: "Tamil",
  },
  {
    id: 5,
    name: "Hokkien",
  },
];

export class CreateNotification extends Component {
  constructor(props) {
    super(props);

    this.state = {
      dropdownTarget: false,
      title: "",
      text: "",
      image: "",
      name: "",
      url: "",
      targetUser: {
        id: 1,
        name: "Person with Dementia",
        value: USER_TYPE.DEMENTIA,
      },
      targetFiltered: false,
      ageDropdown: false,
      age: {
        id: 1,
        name: "Above age of 50",
        value: "50,above",
      },
      ageFiltered: false,
      typeOfDementiaDropdown: false,
      typeOfDementia: {
        id: 1,
        name: `Alzheimer's Disease`,
      },
      typeOfDementiaFiltered: false,
      languageDropdown: false,
      language: {
        id: 1,
        name: "English",
      },
      languageFiltered: false,
      accountStatus: "",
      delivery: "",
      frequency: undefined,
      gender: "",
      genderFiltered: false,
      stageOfDementia: "",
      stageOfDementiaFiltered: false,
      loading: false,
      dateSend: moment().format("YYYY-MM-DD"),
      timeSend: moment().format("HH:mm"),
      infoDialogShow: false,
      infoDescription: "",
    };

    this.renderInputCheckbox = this.renderInputCheckbox.bind(this);
    this.renderInputDropdown = this.renderInputDropdown.bind(this);
  }

  renderInputCheckbox({
    value,
    stateName = "typeOfDementia",
    md = 3.8,
    filtered = true,
  }) {
    const {
      typeOfDementia,
      accountStatus,
      delivery,
      frequency,
      gender,
      stageOfDementia,
    } = this.state;

    // console.log("renderInputCheckbox - value:", value);
    // console.log("renderInputCheckbox - stateName:", stateName);
    // console.log("renderInputCheckbox - currentState:", this.state);

    return (
      <Col md={md}>
        <Input
          inputProps={{
            value,
            readOnly: true,
            onClick: () =>
              {
                filtered ? this.setState({ [stateName]: value }) : null}
          }}
          containerStyle={`
            ${InputTypeStyle}
            .input-field {
              cursor: ${filtered ? "pointer" : "not-allowed"}
            }
            input {
              color: ${filtered ? Colors.color2 : Colors.color34} !important;
            }
          `}
          iconRight={
            typeOfDementia === value ||
            accountStatus === value ||
            delivery === value ||
            frequency === value ||
            (gender === value && filtered) ||
            (stageOfDementia === value && filtered)
              ? Images.checkActive
              : Images.checkInactive
          }
          iconRightStyle={{ width: "15px" }}
        />
      </Col>
    );
  }

  renderInputDropdown(props) {
    const { value, onClick, filtered } = props;
    return (
      <Input
        inputProps={{
          readOnly: true,
          onClick: filtered ? onClick : null,
          value,
        }}
        containerStyle={`
          ${InputReadOnlyStyle}
          .input-field {
            cursor: ${filtered ? "pointer" : "not-allowed"}
          }
          input {
            color: ${filtered ? Colors.color2 : Colors.color34} !important;
          }
          `}
        iconRight={Images.polygonYellow}
        {...props}
        dropdownWidth={"100%"}
      />
    );
  }

  onPressDropdown = (stateName, state) => {
    this.setState({ [stateName]: !state });
  };

  onChangeText = (stateName, value) => {
    this.setState({ [stateName]: value });
  };

  onAddNotification = async () => {
    const {
      title,
      text,
      image,
      name,
      dropdownTarget,
      targetUser,
      delivery,
      frequency,
      targetFiltered,
      ageDropdown,
      age,
      ageFiltered,
      typeOfDementiaDropdown,
      typeOfDementia,
      typeOfDementiaFiltered,
      languageDropdown,
      language,
      languageFiltered,
      genderFiltered,
      stageOfDementia,
      stageOfDementiaFiltered,
      gender,
      dateSend,
      timeSend,
      url,
    } = this.state;

    console.log('onAddNotification - currentState:', this.state);


    if (title.length > 1000) {
      this.onDialogInfo("The title cannot exceed 1000 characters");
      return false;
    }

    if (
      delivery === "Schedule" &&
      new Date(`${dateSend} ${timeSend}`) < new Date()
    ) {
      this.onDialogInfo("Schedule cannot be in the past");
      return false;
    }

    const data = {
      title,
      text,
      image,
      name,
      platform: "Android, iOS",
      accountType: targetFiltered ? targetUser.value : "ALL",
      age: ageFiltered ? age.value : "ALL",
      typeDementia: typeOfDementiaFiltered ? typeOfDementia.name : "ALL",
      language: languageFiltered ? language.name : "ALL",
      gender: genderFiltered ? gender.toLowerCase() : "ALL",
      stageDementia: stageOfDementiaFiltered ? stageOfDementia : "ALL",
      delivery: delivery.toUpperCase(),
      frequency,
      dateSend: moment(`${dateSend} ${timeSend}`),
      url,
    };

    this.setState({ loading: true });

    try {
      let resultAdd = {};
      resultAdd.fetching = false;
      resultAdd.payload = await addNotification(data);
      ProccessData(resultAdd, "add notification")
        .then((res) => {
          this.props.getNotifications({});
          this.onDialogInfo("Notification Added");
          this.props.history.goBack();
          this.setState({ loading: false });
        })
        .catch((err) => {
          this.onDialogInfo(err);
          this.setState({ loading: false });
        });
    } catch (e) {
      this.onDialogInfo(e);
      this.setState({ loading: false });
    }
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState !== this.state) {
      console.log('State updated:', this.state);
    }
  }
  

  onChangeImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({ image: reader.result });
    };

    reader.readAsDataURL(file);
  };

  onDialogInfo = (val) => {
    this.setState({ infoDescription: val, infoDialogShow: true });
  };

  render() {
    const { history } = this.props;
    const {
      title,
      text,
      image,
      name,
      url,
      dropdownTarget,
      targetUser,
      delivery,
      targetFiltered,
      ageDropdown,
      age,
      ageFiltered,
      typeOfDementiaDropdown,
      typeOfDementia,
      typeOfDementiaFiltered,
      languageDropdown,
      language,
      languageFiltered,
      genderFiltered,
      gender,
      stageOfDementia,
      stageOfDementiaFiltered,
      loading,
      dateSend,
      timeSend,
      infoDialogShow,
      infoDescription,
    } = this.state;

    let buttonDisabled =
      title === "" ||
      text === "" ||
      delivery === "" ||
      (genderFiltered && gender === "") ||
      loading;

    return (
      <StyledCreateNotification>
        {/* Dialog info start */}
        <DialogInfo
          isVisible={infoDialogShow}
          textInfo={infoDescription}
          onOkButton={() => this.setState({ infoDialogShow: false })}
        />
        {/* Dialog info end  */}
        <Container className="wrapper">
          <Row align="center">
            <Col lg={0.6} xs={1}>
              <img
                src={Images.back}
                className="icon  -back"
                onClick={() => history.goBack()}
              />
            </Col>
            <Col lg={11.4} xs={7}>
              <Row align="center" justify="between">
                <Col
                  style={{
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "space-between",
                  }}
                >
                  <div className="title-wrapper">
                    <h1 className="title-name">Create New Notification</h1>
                  </div>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row
            style={{
              marginTop: "59px",
              borderBottomWidth: `1px`,
              borderBottomColor: Colors.color27,
              borderBottomStyle: "solid",
              paddingBottom: "59px",
              marginBottom: "21px",
            }}
          >
            <Col offset={{ lg: 0.6, xs: 1 }} xs={12}>
              <Row>
                <Col
                  md={0.6}
                  style={{ display: "flex", justifyContent: "flex-end" }}
                >
                  <Hidden sm xs>
                    <div className="line" />
                  </Hidden>
                </Col>
                <Col xs={11} offset={{ md: 0.1 }}>
                  <div
                    className="circle"
                    style={{ left: "-38px", background: Colors.color14 }}
                  >
                    1
                  </div>
                  <h1 className="section-title">Notification</h1>
                  <Row style={{ marginTop: "27px" }}>
                    <Col xs={6}>
                      <Label htmlFor="title">Notification Title</Label>
                      <Input
                        inputProps={{
                          id: "title",
                          value: title,
                          onChange: (e) =>
                            this.onChangeText("title", e.target.value),
                        }}
                        containerStyle={InputStyle}
                      />
                      <Label htmlFor="text">Notification Text</Label>
                      <Input
                        inputProps={{
                          id: "text",
                          value: text,
                          onChange: (e) =>
                            this.onChangeText("text", e.target.value),
                        }}
                        containerStyle={InputStyle}
                        multiline
                      />
                      <Label htmlFor="image">
                        Notification Image (optional)
                      </Label>
                      <Input
                        inputProps={{
                          id: "image",
                          type: "file",
                          accept: "image/*",
                          onChange: (e) => this.onChangeImage(e),
                        }}
                        containerStyle={InputStyle}
                      />
                      <Label htmlFor="name">Notification Name (optional)</Label>
                      <Input
                        inputProps={{
                          id: "name",
                          value: name,
                          onChange: (e) =>
                            this.onChangeText("name", e.target.value),
                        }}
                        containerStyle={InputStyle}
                      />
                      <Label htmlFor="url">Link URL</Label>
                      <Input
                        inputProps={{
                          id: "url",
                          value: url,
                          onChange: (e) =>
                            this.onChangeText("url", e.target.value),
                        }}
                        containerStyle={InputStyle}
                      />
                    </Col>
                    <Col md={5} style={{ marginTop: "10px" }}>
                      <img src={Images.notifExample} />
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "75px" }}>
                    <Col>
                      <div className="circle" style={{ left: "-38px" }}>
                        2
                      </div>
                      <h1 className="section-title">Target</h1>
                      <Row style={{ marginTop: "27px" }}>
                        <Col xs={6}>
                          <Row justify="between">
                            <Col md={10} xs={8}>
                              <Label>Role Type</Label>
                            </Col>
                            <Col
                              md={2}
                              xs={4}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.setState({
                                  targetFiltered: !targetFiltered,
                                })
                              }
                            >
                              <img
                                src={
                                  targetFiltered
                                    ? Images.switchOn
                                    : Images.switchOff
                                }
                              />
                            </Col>
                          </Row>
                          {this.renderInputDropdown({
                            showDropdown: dropdownTarget,
                            dropdownData: dropdownTargetData,
                            dropdownPropertyName: "name",
                            onClick: () =>
                              this.onPressDropdown(
                                "dropdownTarget",
                                dropdownTarget
                              ),
                            onSelectDropdown: (targetUser) => {
                              this.setState({
                                dropdownTarget: false,
                                targetUser,
                              });
                            },
                            value: targetUser.name,
                            filtered: targetFiltered,
                          })}
                        </Col>
                        <Col xs={6} />
                        <Col xs={6}>
                          <Row justify="between">
                            <Col md={10} xs={8}>
                              <Label>Age</Label>
                            </Col>
                            <Col
                              md={2}
                              xs={4}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.setState({ ageFiltered: !ageFiltered })
                              }
                            >
                              <img
                                src={
                                  ageFiltered
                                    ? Images.switchOn
                                    : Images.switchOff
                                }
                              />
                            </Col>
                          </Row>
                          {this.renderInputDropdown({
                            showDropdown: ageDropdown,
                            dropdownData: ageData,
                            dropdownPropertyName: "name",
                            onClick: () =>
                              this.onPressDropdown("ageDropdown", ageDropdown),
                            onSelectDropdown: (age) => {
                              this.setState({ ageDropdown: false, age });
                            },
                            value: age.name,
                            filtered: ageFiltered,
                          })}
                        </Col>
                        <Col xs={6} />
                        <Col xs={6}>
                          <Row justify="between">
                            <Col md={10} xs={8}>
                              <Label>Type Of Dementia</Label>
                            </Col>
                            <Col
                              md={2}
                              xs={4}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.setState({
                                  typeOfDementiaFiltered: !typeOfDementiaFiltered,
                                })
                              }
                            >
                              <img
                                src={
                                  typeOfDementiaFiltered
                                    ? Images.switchOn
                                    : Images.switchOff
                                }
                              />
                            </Col>
                          </Row>
                          {this.renderInputDropdown({
                            showDropdown: typeOfDementiaDropdown,
                            dropdownData: typeOfDementiaData,
                            dropdownPropertyName: "name",
                            onClick: () =>
                              this.onPressDropdown(
                                "typeOfDementiaDropdown",
                                typeOfDementiaDropdown
                              ),
                            onSelectDropdown: (typeOfDementia) => {
                              this.setState({
                                typeOfDementiaDropdown: false,
                                typeOfDementia,
                              });
                            },
                            value: typeOfDementia.name,
                            filtered: typeOfDementiaFiltered,
                          })}
                        </Col>
                        <Col xs={6} />
                        <Col xs={6}>
                          <Row justify="between">
                            <Col md={10} xs={8}>
                              <Label>Language</Label>
                            </Col>
                            <Col
                              md={2}
                              xs={4}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.setState({
                                  languageFiltered: !languageFiltered,
                                })
                              }
                            >
                              <img
                                src={
                                  languageFiltered
                                    ? Images.switchOn
                                    : Images.switchOff
                                }
                              />
                            </Col>
                          </Row>
                          {this.renderInputDropdown({
                            showDropdown: languageDropdown,
                            dropdownData: languageData,
                            dropdownPropertyName: "name",
                            onClick: () =>
                              this.onPressDropdown(
                                "languageDropdown",
                                languageDropdown
                              ),
                            onSelectDropdown: (language) => {
                              this.setState({
                                languageDropdown: false,
                                language,
                              });
                            },
                            value: language.name,
                            filtered: languageFiltered,
                          })}
                        </Col>
                        <Col xs={6} />
                        <Col xs={6}>
                          <Row justify="between">
                            <Col md={10} xs={8}>
                              <Label>Gender</Label>
                            </Col>
                            <Col
                              md={2}
                              xs={4}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.setState({
                                  genderFiltered: !genderFiltered,
                                })
                              }
                            >
                              <img
                                src={
                                  genderFiltered
                                    ? Images.switchOn
                                    : Images.switchOff
                                }
                              />
                            </Col>
                          </Row>
                          <Row>
                            {[
                              this.renderInputCheckbox({
                                value: "Male",
                                stateName: "gender",
                                md: 6,
                                filtered: genderFiltered,
                              }),
                              this.renderInputCheckbox({
                                value: "Female",
                                stateName: "gender",
                                md: 6,
                                filtered: genderFiltered,
                              }),
                            ]}
                          </Row>
                        </Col>
                        <Col xs={6} />
                        <Col xs={6} style={{ marginTop: "10px" }}>
                          <Row justify="between">
                            <Col md={10} xs={8}>
                              <Label>Stage Of Dementia</Label>
                            </Col>
                            <Col
                              md={2}
                              xs={4}
                              style={{ cursor: "pointer" }}
                              onClick={() =>
                                this.setState({
                                  stageOfDementiaFiltered: !stageOfDementiaFiltered,
                                })
                              }
                            >
                              <img
                                src={
                                  stageOfDementiaFiltered
                                    ? Images.switchOn
                                    : Images.switchOff
                                }
                              />
                            </Col>
                          </Row>
                          <Row>
                            {[
                              ,
                              this.renderInputCheckbox({
                                value: "Mild",
                                stateName: "stageOfDementia",
                                md: 6,
                                filtered: stageOfDementiaFiltered,
                              }),
                              this.renderInputCheckbox({
                                value: "Moderate",
                                stateName: "stageOfDementia",
                                md: 6,
                                filtered: stageOfDementiaFiltered,
                              }),
                              this.renderInputCheckbox({
                                value: "Severe",
                                stateName: "stageOfDementia",
                                md: 6,
                                filtered: stageOfDementiaFiltered,
                              }),
                            ]}
                          </Row>
                        </Col>
                        {/* <Col xs={11} style={{ marginTop: '10px', }}>
                          <Label>Account Status</Label>
                          <Row>
                            {
                              [
                                this.renderInputCheckbox({ value: "Completed", stateName: 'accountStatus' }),
                                this.renderInputCheckbox({ value: "Incomplete", stateName: 'accountStatus' }),
                                this.renderInputCheckbox({ value: "Pending approval", stateName: 'accountStatus' }),
                                this.renderInputCheckbox({ value: "Pending cards", stateName: 'accountStatus' }),
                              ]
                            }
                          </Row>
                        </Col> */}
                      </Row>
                    </Col>
                  </Row>
                  <Row style={{ marginTop: "75px" }}>
                    <Col>
                      <div className="circle" style={{ left: "-38px" }}>
                        3
                      </div>
                      <div className="line2" />
                      <h1 className="section-title">Schedulling</h1>
                      <Row style={{ marginTop: "27px" }}>
                        <Col xs={11}>
                          <Label>Delivery</Label>
                          <Row>
                            {[
                              this.renderInputCheckbox({
                                value: "Now",
                                stateName: "delivery",
                              }),
                              this.renderInputCheckbox({
                                value: "Schedule",
                                stateName: "delivery",
                              }),
                            ]}
                          </Row>
                        </Col>
                        <Col xs={11} style={{ marginTop: "10px" }}>
                          <Label>Frequency</Label>
                          <Row>
                            {[
                              this.renderInputCheckbox({
                                value: "Daily",
                                stateName: "frequency",
                                filtered:
                                  delivery !== "Now" && delivery.length > 1,
                              }),
                              this.renderInputCheckbox({
                                value: "Weekly",
                                stateName: "frequency",
                                filtered:
                                  delivery !== "Now" && delivery.length > 1,
                              }),
                              this.renderInputCheckbox({
                                value: "Monthly",
                                stateName: "frequency",
                                filtered:
                                  delivery !== "Now" && delivery.length > 1,
                              }),
                            ]}
                          </Row>
                        </Col>
                        <Col xs={11} style={{ marginTop: "10px" }}>
                          <Label>Date</Label>
                          <Row>
                            <Col md={3.8}>
                              <Input
                                inputProps={{
                                  placeholder: "Date",
                                  type: "date",
                                  readOnly:
                                    delivery === "Now" || delivery.length < 1,
                                  onChange: (e) => {
                                    if (new Date(e.target.value) < new Date()) {
                                      this.onDialogInfo(
                                        "Schedule cannot be in the past!"
                                      );
                                      return false;
                                    }
                                    this.setState({ dateSend: e.target.value });
                                  },
                                  value: dateSend,
                                }}
                                containerStyle={`
                                  ${InputDateStyle}
                                  .input-field {
                                    cursor: ${
                                      delivery !== "Now" && delivery.length > 1
                                        ? "default"
                                        : "not-allowed"
                                    }
                                  }
                                  input {
                                    color: ${
                                      delivery !== "Now" && delivery.length > 1
                                        ? Colors.color2
                                        : Colors.color34
                                    } !important;
                                  }
                                `}
                              />
                            </Col>
                            <Col md={3.8}>
                              <Input
                                inputProps={{
                                  placeholder: "Time",
                                  type: "time",
                                  readOnly:
                                    delivery === "Now" || delivery.length < 1,
                                  onChange: (e) => {
                                    this.setState({ timeSend: e.target.value });
                                  },
                                  value: timeSend,
                                }}
                                containerStyle={`
                                  ${InputDateStyle}
                                  .input-field {
                                    cursor: ${
                                      delivery !== "Now" && delivery.length > 1
                                        ? "default"
                                        : "not-allowed"
                                    }
                                  }
                                  input {
                                    color: ${
                                      delivery !== "Now" && delivery.length > 1
                                        ? Colors.color2
                                        : Colors.color34
                                    } !important;
                                  }
                                `}
                              />
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <div className="button-container">
            <div style={{ width: "446px", display: "flex" }}>
              <div style={{ flex: 1 }}>
                {/* <Button
                  text="Save as draft"
                  bordered
                  textColor={Colors.color11}
                  textStyle={`font-weight: bold;`}
                /> */}
              </div>
              <div style={{ width: "20px" }} />
              <div style={{ flex: 1 }}>
                <Button
                  text={loading ? "Loading..." : "Create Notification"}
                  onClick={this.onAddNotification}
                  disabled={buttonDisabled}
                />
              </div>
            </div>
          </div>
        </Container>
      </StyledCreateNotification>
    );
  }
}

const mapStateToProps = (state) => ({});

const mapDispatchToProps = (dispatch) => {
  return {
    getNotifications: (params) =>
      dispatch(NotificationsActions.getNotificationsRequest(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(CreateNotification);
