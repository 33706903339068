import React, { useState, } from 'react'
import PropTypes from 'prop-types'

import { WithContext as ReactTags } from 'react-tag-input';

import { StyledInputTag, } from "./StyledInput";

const KeyCodes = {
  comma: 188,
  enter: 13,
};


const delimiters = [KeyCodes.comma, KeyCodes.enter];

function InputTag({
  inputProps,
  onAddTag,
  onDeleteTag,
  onDragTag,
  tagsData,
  onClearData,
  id
}) {
  return (
    <StyledInputTag>
      <ReactTags 
        {...inputProps}
        id={id? id : ''}
        tags={tagsData}
        suggestions={[]}
        handleDelete={onDeleteTag}
        handleAddition={onAddTag}
        handleDrag={onDragTag}
        delimiters={delimiters} 
        placeholder=""
        autofocus={false}
        handleInputChange={(e) => {
          if (e.indexOf('--') > -1) {
            alert('Cannot insert -- character')
            onClearData()
          }
        }}
      />
    </StyledInputTag>
  )
}

InputTag.propTypes = {
  inputProps: PropTypes.any,
  onAddTag: PropTypes.func,
  onDeleteTag: PropTypes.func,
  onDragTag: PropTypes.func,
  tagsData: PropTypes.array,
  onClearData: PropTypes.func,
}

InputTag.defaultProps = {
  inputProps: {},
  onAddTag: () => null,
  onDeleteTag: () => null,
  onDragTag: () => null,
  tagsData: [],
  onClearData: () => null,
}

export default InputTag


  // const handleDelete = (i) => {
  //   setState(state => {
  //     return { ...state, tags: state.tags.filter((tag, index) => index !== i), }
  //   })
  // }

  // const handleAddition = (tag) => {
  //   setState(state => {
  //     return { ...state, tags: [...state.tags, tag] }
  //   })
  // }

  // const handleDrag = (tag, currPos, newPos) => {
  //   const currentTags = [...tags];
  //   const newTags = currentTags.slice();

  //   newTags.splice(currPos, 1);
  //   newTags.splice(newPos, 0, tag);

  //   // re-render
  //   setState(state => {
  //     return { ...state, tags: newTags, }
  //   })
  // }

