import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/'

export const StyledCreateNotification = styled.div`
    background-color: ${Colors.primaryBgColor};
    padding: 53px 132px 122px 70px;

    .wrapper {
    background-color: ${Colors.color3};
    width: 100%;
    border-radius: 15px;
    padding: 28px 350px 23px;
    word-break: break-all;
    overflow: hidden;
  }

  .icon-back {
    width: 10px;
    margin: 0 0 0 14px;
    cursor: pointer;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
  }

  .title-name {
    ${Fonts.FontRegular}
    ${Fonts.size.size32};
    font-weight: 800;
    color: ${Colors.color2};
    margin-right: 20px;
    width: 350px;
  }

  .line {
    width: 1px;
    height: auto;
    background-color: ${Colors.color2};
    display: flex;
    justify-content: center;
  }

  .circle {
    width: 30px;
    height: 30px;
    background-color: ${Colors.color26};
    border-radius: 15px;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    ${Fonts.FontRegular}
    ${Fonts.size.size18}
    color: ${Colors.color3};
    font-weight: 800;
    @media ${deviceSize.tabletL} {
      display: none;
    }
  }

  .line2 {
    width: 20px;
    height: 90%;
    background-color: ${Colors.color3};
    display: flex;
    justify-content: center;
    top: 30px;
    position: absolute;
    left: -28px;
    @media ${deviceSize.tabletL} {
      display: none;
    }
  }

  .section-title {
    ${Fonts.FontRegular}
    ${Fonts.size.size24}
    color: ${Colors.color2};
    font-weight: 800;
    margin-top: 6px;
  }

  .button-container {
    display: flex;
    justify-content: flex-end;
  }
`;

export const Label = styled.label`
  ${Fonts.FontRegular}
  ${Fonts.size.size16}
  color: ${Colors.color19};
  font-weight: bold;
`;

export const InputStyle = css` 
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin: 10px 0 25px 0; 
  .input-field {  
    width: 100%;
    padding: 16px 14px 16px 18px;
    outline: none;
  }
`;
