import React, { useState, useEffect, } from 'react'
import PropTypes from 'prop-types'

import Dialog from '../modal'
import Table from '../table'
import Button from '../button'

import { Colors, Images, } from '../../themes'

function DialogUserList({
  dialogProps,
  userData,
  duplicateData,
  incompleteData,
}) {

  const [users, setUsers] = useState([])
  const [duplicated, setDuplicated] = useState([])
  const [incompleted, setIncompleted] = useState([])

  const buttonDisabled = dialogProps.loading ||
  users.length < 1 ||
  incompleted.length > 0 ||
  duplicated.length > 0

  useEffect(() => {
    setUsers(userData)
    setDuplicated(duplicateData)
    setIncompleted(incompleteData)
  }, [userData])

  const deleteRow = ({ data = users, cb = setUsers, indexArr = [] }) => {
    const result = data.filter(item => indexArr.indexOf(item) === -1)
    cb(result)
  }

  const onDeleteRow = (row, { data, cb }) => {
    deleteRow({ indexArr: [row.original], data, cb })
  }
  
  const columns = [
    {
      Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>{`First Name`}</span>,
      Cell: props => <span className="column-text" onClick={() => null}>{props.original.firstname || '-'}</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,       
      resizable: false,
    }, 
    {
      Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>{`Last Name`}</span>,
      Cell: props => <span className="column-text" onClick={() => null}>{props.original.lastname || '-' }</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,       
      resizable: false,
    }, 
    {
      Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>{`Membership ID`}</span>,
      Cell: props => <span className="column-text" onClick={() => null}>{
        props.original.type === 'CAREGIVER' ?
          `C${props.original.lastNric}XXXXX`
        :
          `D${props.original.lastNric}XXXXX`
      }</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,       
      resizable: false,
    }, 
    {
      Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>{`Email`}</span>,
      Cell: props => <span className="column-text" onClick={() => null}>{props.original.email || '-' }</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,       
      resizable: false,
    }, 
    {
      headerClassName: 'table-header',
      className: 'actions-column',
      width: 40,
      Cell: row => {
        return (
          <div className="icon-container">
            <div className="trash-wrapper" onClick={() => onDeleteRow(row, { datqa: users, cb: setUsers })}>
              <img src={Images.trash} className="delete" />
            </div>
          </div>
        )
      },
    },
  ]

  const columnsNoHeader = [
    {
      Cell: props => <span className="column-text" onClick={() => null}>{props.original.firstname || '-'}</span>, 
      
      className: 'column',
      sortable: false,       
      resizable: false,
    }, 
    {
      Cell: props => <span className="column-text" onClick={() => null}>{props.original.lastname || '-' }</span>, 
      
      className: 'column',
      sortable: false,       
      resizable: false,
    }, 
    {
      Cell: props => <span className="column-text" onClick={() => null}>{
        props.original.type === 'CAREGIVER' ?
          `C${props.original.lastNric}XXXXX`
        :
          `D${props.original.lastNric}XXXXX`
      }</span>, 
      
      className: 'column',
      sortable: false,       
      resizable: false,
    }, 
    {
      Cell: props => <span className="column-text" onClick={() => null}>{props.original.email || '-' }</span>, 
      
      className: 'column',
      sortable: false,       
      resizable: false,
    }, 
  ]

  return (
    <Dialog
      {...dialogProps}
    >
      <div className="users-title">Uploaded/Downloaded docs or file name</div>
      <Table
        loading={false}
        columns={columns}
        data={users}
        pageSize={users.length}
        tableProps={{ showPagination: false,  responsive: true, }}
      />
      <div className="text">Duplicated</div>
      <Table
        loading={false}
        columns={columnsNoHeader.concat(
          {
            className: 'actions-column',
            width: 40,
            Cell: row => {
              return (
                <div className="icon-container">
                  <div className="trash-wrapper" onClick={() => onDeleteRow(row, { data: duplicated, cb: setDuplicated })}>
                    <img src={Images.trash} className="delete" />
                  </div>
                </div>
              )
            },
          },)
        }
        data={duplicated}
        pageSize={duplicated.length}
        tableProps={{ showPagination: false,  responsive: true, }}
      />
      <div className="text">Incompleted</div>
      <Table
        loading={false}
        columns={columnsNoHeader.concat(
          {
            className: 'actions-column',
            width: 40,
            Cell: row => {
              return (
                <div className="icon-container">
                  <div className="trash-wrapper" onClick={() => onDeleteRow(row, { data: incompleted, cb: setIncompleted })}>
                    <img src={Images.trash} className="delete" />
                  </div>
                </div>
              )
            },
          },)
        }
        data={incompleted}
        pageSize={incompleted.length}
        tableProps={{ showPagination: false,  responsive: true, }}
      />
      <div className="button-import-container" onClick={() => buttonDisabled ? null : dialogProps.onOk({ data: users })}>
        <Button 
          text={dialogProps.loading ? "Importing User..." :  "Import"}
          disabled={buttonDisabled}
        />
      </div>
    </Dialog>
  )
}

DialogUserList.propTypes = {
  dialogProps: PropTypes.any,
  userData: PropTypes.array,
  duplicateData: PropTypes.array,
  incompleteData: PropTypes.array,
}

DialogUserList.defaultProps = {
  dialogProps: {},
  userData: [],
  duplicateData: [],
  incompleteData: [],
}

export default DialogUserList

