import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import deviceSize from '../../constants/deviceSize'

export const StyledDialogSelectAccount = styled.div`
  .box-container {
    display: flex;
    flex-wrap: wrap;
    padding: 0;

    @media ${deviceSize.tablet} {
      flex-direction: column;
    }
  }

  .box {
    background: ${Colors.color3};
    border: 1px solid ${Colors.color7};
    border-radius: 15px;
    margin-top: 40px;
    flex: 1;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
  }

  .box:first-child {
    width: 100%;
  }

  .box:not(:first-child) {
    flex: 1;
  }

  .icon {
    width: 60px;
    object-fit: contain;
    margin: 0 auto;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    padding-right: 20px;
  }

  .text {
    ${Fonts.FontRegular}
    font-size: 20px;
    color: ${Colors.color2};
    text-align: center;
    margin-top: 30px;
    font-weight: 800;
  }

  .icon-check-container {
    width: 25px;
    height: 25px;
    position: relative;
    top: -40px;
    right: 20px;
  }

  .icon-check {
    width: 25px;
    height: 25px;
    object-fit: contain;
    
    position: absolute;
    right: 20px;
    top: 20px;
  }
`