import { API } from '../lib/Request'
// import AppConfig from '../config/AppConfig'
import AppConfig from '../config.js'

const accountUrl = AppConfig.ACCOUNT_URL

export const update = async ({ params, data, type }) => {
  let url = (type === "ADMIN" || type === "SUPER_ADMIN" || type === "VIEWER") ? '/admin/safe-return' : '/partner/safe-return'
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}${url}`, method: 'PATCH', params, data })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}

export const changeSafeReturnStatus = async ({ params, data, type }) => {
  let url = '/admin/safe-return/status/' + params.id
  return new Promise((resolve, reject) => {
    API({ url: `${accountUrl}${url}`, method: 'PATCH', data })
    .then(res => {
      resolve(res)
    })
    .catch(err => {
      reject(err)
    })
  })
}
