// leave off @2x/@3x
const images = {
   polygon: require('../assets/images/polygon.png').default,
   trash: require('../assets/images/trash.png').default,
   edit: require('../assets/images/edit2.png').default,
   back: require('../assets/images/back.png').default,
   editBlue: require('../assets/images/edit-blue.png').default,
   thumbnail: require('../assets/images/thumbnail.png').default,
   iconX: require('../assets/images/icon-x.png').default,
   notifExample: require('../assets/images/notif-example.png').default,
   polygonYellow: require('../assets/images/polygon-yellow.png').default,
   checkActive: require('../assets/images/check-active.png').default,
   checkInactive: require('../assets/images/check-inactive.png').default,
   defaultPhoto: require('../assets/images/default-photo.png').default,
   export: require('../assets/images/export.png').default,
   switchOff: require('../assets/images/switch-off.png').default,
   switchOn: require('../assets/images/switch-on.png').default,
   filter: require('../assets/images/filter.svg').default,
   analyticsComingSoon: require('../assets/images/analytics.png').default,
   iconCG: require('../assets/images/icon-cg.png').default,
   iconPWD: require('../assets/images/icon-pwd.png').default,
   threeDots: require('../assets/images/three-dots.svg').default,
   arrowLeft: require('../assets/images/arrow-left.png').default,
   arrowRight: require('../assets/images/arrow-right.png').default,
   arrowLeftDisable: require('../assets/images/arrow-left-disable.svg').default,
   arrowRightDisable: require('../assets/images/arrow-right-disable.svg').default,
   arrowRightOutline: require('../assets/images/arrow-right-outline.png').default,
   search: require('../assets/images/search.svg').default,
   gambarnya: require('../assets/images/gambarnya.PNG').default,
   transfer: require('../assets/images/transfer.png').default,
   arrowRight2: require('../assets/images/arrow-right2.png').default,
   iconAccount: require('../assets/images/icon-account.png').default,
   arrowRight3: require('../assets/images/arrow-right3.png').default,
   iconSearch: require('../assets/images/search.png').default,
   iconClose2: require('../assets/images/close2.png').default,
   calendar: require('./../assets/images/Calendar.png').default,
   phone: require('./../assets/images/phone.png').default,
   phone2: require('./../assets/images/Phone-2.png').default,
   qr: require('./../assets/images/qr.png').default,
   form: require('./../assets/images/form.png').default,
   brain: require('./../assets/images/brain.png').default,
   mop: require('./../assets/images/mop.png').default,
   heart: require('./../assets/images/hand 1.png').default,
   kotakKanan: require('./../assets/images/kotak-kanan.png').default,
   kotakKuning: require('./../assets/images/kotak-kuning.png').default,
   microsoftLogo: require('./../assets/images/microsoft.png').default,
   ascending: require('./../assets/images/ascending.png').default,
   descending: require('./../assets/images/descending.png').default,
   sortInactive: require('./../assets/images/sortInactive.png').default,
   checkActive2: require('./../assets/images/check-active2.png').default,
   checkInactive2: require('./../assets/images/check-inactive2.png').default,
   cancelActive: require('./../assets/images/cancel-active.png').default,
   cancelInactive: require('./../assets/images/cancel-inactive.png').default,
   drowpdown: require('./../assets/images/arrow_drop_down.png').default,
   drowpup: require('./../assets/images/arrow_drop_up.png').default,
}

export default images
