import React, { Component } from 'react'
import { connect } from 'react-redux'
import JSZip from 'jszip'
import saveAs from 'file-saver'

import QRCode from 'qrcode.react'

import Button from '../components/button'

import DialogInfo from '../components/dialog-info'
import { Colors } from '../themes'

import { StyledExportQRCode } from './styles/StyledExportQRCode'

// import AppConfig from '../config/AppConfig'
import AppConfig from '../config.js'

import { getUserById, getUsers, } from '../api/UserAPI'

import ProccessData from '../lib/ProccessData'

export class ExportQRCode extends Component {
  constructor(props) {
    super(props)

    this.state = { 
      width: screen.width, 
      height: screen.height,
      users: [],
      loading: true,
      loadingExport: false,    
      infoDialogShow: false,
      infoDescription: ''
    };
  }
  
  async componentDidMount() {
    window.addEventListener('resize', this.updateDimensions);

    let params = {}

    if (this.getParameterByName('uid')) params.uid = this.getParameterByName('uid')
    
    if (this.getParameterByName('uidList')) params.uidList = this.getParameterByName('uidList')
     
    if (this.getParameterByName('startDate') && this.getParameterByName('endDate')) params.updatedAt = `${this.getParameterByName('startDate')},${this.getParameterByName('endDate')}`

    if (this.getParameterByName('updatedAt')) params.updatedAt = this.getParameterByName('updatedAt')

    if (this.getParameterByName('type')) params.type = this.getParameterByName('type')

    if (this.getParameterByName('accountStatus')) params.accountStatus = this.getParameterByName('accountStatus')

    try {
      let resultGet = {}
      resultGet.fetching = false
      resultGet.payload = this.getParameterByName('uid') ? await getUserById({ uid: params.uid }) : await getUsers({ ...params, })
      ProccessData(resultGet, 'get user')
      .then(res => {
        if ((res.data && res.data.length < 1) || !res.data) {
          this.onDialogInfo('Data Not Found')
          window.close()
          return false
        }
        this.setState({ 
          users: res.data,
          loading: false,
        })
      })
      .catch(err => {
        this.onDialogInfo(err.message)
        window.close()
        return false
        this.setState({
          loading: false,
        })
      })
    } catch (e) {
      this.onDialogInfo(e.message)
      window.close()
      return false
      this.setState({ loading: false })
    }
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  updateDimensions = () => {
    this.setState({ width: window.innerWidth, height: window.innerHeight });
  };

  getParameterByName = (name, url = window.location.href) => {
    name = name.replace(/[\[\]]/g, '\\$&');
    var regex = new RegExp('[?&]' + name + '(=([^&#]*)|&|#|$)'),
        results = regex.exec(url);
    if (!results) return null;
    if (!results[2]) return '';
    return decodeURIComponent(results[2].replace(/\+/g, ' '));
  }

  downloadQR = () => {
    this.setState({ loadingExport: true })

    const { users } = this.state
    let zip = new JSZip();
    
    if (users.length > 0)  {
      users.map((data, i) => {
        const canvas = document.getElementById(`qrcode-${i}`);
        const pngUrl = canvas
          .toDataURL("image/png") // .replace("image/png", "image/octet-stream")
          .split(';base64,')[1];
        let filename = 
          data.membershipId ? 
            `${data.membershipId}.png` 
          : 
            `${data.uid}.png`
          
        zip.file(filename, pngUrl, {base64: true});
      })
      zip.generateAsync({type:"blob"})
      .then(function(content) {
        saveAs(content, `User QR Code.zip`);
      });
    } else {
      const canvas = document.getElementById(`qrcode-${users.uid}`);
      const pngUrl = canvas
        .toDataURL('image/png')
        .split(';base64,')[1]; 
      zip.generateAsync({type:"blob"})
      .then(function(content) {
        saveAs(content, users.membershipId ? `${users.membershipId}.zip` : `${users.firstname} ${users.lastname}.zip`);
      });
      // let downloadLink = document.createElement("a");
      // downloadLink.href = pngUrl;
      // downloadLink.download = users.membershipId ? `${users.membershipId}.png` : `${users.firstname} ${users.lastname}.png`;
      // document.body.appendChild(downloadLink);
      // downloadLink.click();
      // document.body.removeChild(downloadLink);
    }

    this.setState({ loadingExport: false })
  };

  render() {
    const { width, loading, users, loadingExport, infoDialogShow, infoDescription, } = this.state
    const { match } = this.props
    return (
      <StyledExportQRCode>
        {/* Dialog info start */}
        <DialogInfo
          isVisible={infoDialogShow}
          textInfo={infoDescription}
          onOkButton={()=> this.setState({infoDialogShow: false})}
        />
        {/* Dialog info end  */}
        {
          loading ?
            <h1>Loading...</h1>
          :
            <div className="qrcode-container">
              {/* <h1 className="title" onClick={this.downloadQR}>Export QR</h1> */}
              <Button 
                text={loadingExport ? `Loading...` : `Export QR Code`}
                textStyle={` font-weight: 800; margin: 0 100px; `}
                containerStyle={` margin: 80px; `}
                onClick={this.downloadQR}
              />
              {
                users.length > 0 ? 
                  users.map((data, index) => {
                    return (
                      <QRCode 
                        value={`${AppConfig.HOSTNAME}/safereturncards/${data.uid}`} 
                        size={width > 440 ? 400 : 200}
                        id={`qrcode-${index}`} 
                        style={{ marginBottom: '60px' }}
                      />
                    )
                  })
                :
                  <QRCode 
                    value={`${AppConfig.HOSTNAME}/safereturncards/${users.uid}`} 
                    size={width > 440 ? 400 : 200}
                    id={`qrcode-${users.uid}`} 
                    style={{ marginBottom: '60px' }}
                  />
              }
            </div>
        }
      </StyledExportQRCode>
    )
  }
}

const mapStateToProps = (state) => ({
  
})

const mapDispatchToProps = {
  
}

export default connect(mapStateToProps, mapDispatchToProps)(ExportQRCode)
