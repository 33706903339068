import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import styled from "styled-components";
import moment from 'moment';

import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

import Dialog from '../modal'
import Button from '../button'

import { SAFE_RETURN_STATUS, USER_TYPE } from '../../constants'

export const DialogChangeReportStatus = ({
  dialogProps,
  onUpdateData,
  safeReturnSelected,
  ...props
}) => {
  const renderText = ({ title, text, textColor = Colors.color12, }) => {
    return (
    <Section textColor={textColor}>
      <div className="title-sr">{title}</div>
      <div className="text-sr">{text}</div>
    </Section>
    )
  }

  useEffect(() => {
    !dialogProps.isVisible && setDialogConfirmation(false)
  }, [dialogProps])
  
  const [dialogConfirmation, setDialogConfirmation] = useState(false)

  const { name, location, phone, createdAt, status, } = 
    safeReturnSelected ? safeReturnSelected.ReturnCard ? safeReturnSelected.ReturnCard 
    : safeReturnSelected
    : {}

  return (
    <div>
      <Dialog
        {...dialogProps}
        width="39%"
        height="auto"
      >
        <StyledContainer style={{ marginTop: '30px', }}>
          {
            [
              renderText({ title: `Reported PLWD`, text: safeReturnSelected && safeReturnSelected.dementia && `${safeReturnSelected.dementia.firstname} ${safeReturnSelected.dementia.lastname}` }),
              renderText({ title: `Reporter Name`, text: name }),
              renderText({ title: `Phone No.`, text: phone }),
              renderText({ title: `Location`, text: location }),
              renderText({ title: `Report Date`, text: moment(createdAt).format(('DD-MM-YY h:mm a')) }),
              renderText({ 
                title: `Status`, 
                text: status === SAFE_RETURN_STATUS.PENDING ? 'Pending' : 'Action Taken', 
                textColor: status === SAFE_RETURN_STATUS.PENDING ? Colors.color14 : Colors.color17, 
              }),
            ]
          }
          <div className="button-container">
            <Button
              text="Cancel"
              buttonStyle={
                `
                  width: 165px;
                `
              }
              onClick={() => {
                dialogProps.isClose()
              }}
            />
            <div style={{ width: '18px', }} />
            {
              (props.account && props.account.payload && props.account.payload.data && props.account.payload.data.account && props.account.payload.data.account.type && props.account.payload.data.account.type !== USER_TYPE.VIEWER) && (
                <Button
                  text={`Change Status`}
                  buttonStyle={
                    `
                      width: 165px;
                    `
                  }
                  onClick={() => {
                    setDialogConfirmation(true)
                  }}
                  disabled={
                    safeReturnSelected ? 
                      safeReturnSelected.ReturnCard ? safeReturnSelected.ReturnCard.status === SAFE_RETURN_STATUS.ACTION_TAKEN 
                    : safeReturnSelected.status === SAFE_RETURN_STATUS.ACTION_TAKEN 
                    : false}
                />
              )
            }
          </div>
        </StyledContainer>
      </Dialog>
      
      <Dialog
        isVisible={dialogConfirmation}
        title={"Change Report Status"}
        description="Are you sure you want to change this Safe Return submission status to Action Taken?"
        isClose={() => {
          setDialogConfirmation(false)
        }}
        height="auto"
        okText="Confirm"
        cancelText="Decline"
        okBordered={false}
        okBackgroundColor={Colors.color11}
        okTextColor="white"
        onOk={onUpdateData}
      />
    </div>
  )
}

DialogChangeReportStatus.propTypes = {
  dialogProps: PropTypes.any,
  onUpdateData: PropTypes.func,
  safeReturnSelected: PropTypes.any,
}

DialogChangeReportStatus.defaultProps = {
  dialogProps: {},
  onUpdateData: () => null,
  safeReturnSelected: null,
}

const mapStateToProps = (state) => ({
  account: state.auth.account
})

const mapDispatchToProps = {
  
}

const StyledContainer = styled.div`
  .button-container {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    position: relative;
    bottom: -30px;
  }
`

const Section = styled.div`
  margin-top: 20px;

  .title-sr {
    ${Fonts.FontRegular}
    font-size: 14px;
    color: ${Colors.color19};
  }

  .text-sr {
    ${Fonts.FontRegular}
    font-size: 14px;
    margin-top: 5px;
    font-weight: bold;
    color: ${props => props.textColor};
  } 
`

export default connect(mapStateToProps, mapDispatchToProps)(DialogChangeReportStatus)
