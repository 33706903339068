import React from 'react';

import Dialog from '../modal';
import Input from '../input';
import FormEditCategory from '../form/FormEditCategory';
export default class DialogAddCategory extends React.Component {
  render() {
    return (
      <div>
        <Dialog {...this.props} width={'36%'} title={this.props.dialogTitle}>
          <FormEditCategory
            {...this.props}
          />
        </Dialog>
      </div>
    )
  }
}