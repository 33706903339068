import { call, put, all, actionChannel} from 'redux-saga/effects'
import axios from 'axios'
import AuthActions from '../redux/AuthRedux'

export function* signin(api, action) {
  let { data } = action
  const response = yield call(api.signin, data)
  if (response.ok) {    
    if (response.data.type === 'SUCCESS') {
      api.api.setHeader('Authorization', `${response.data.data.account.token}`)
      api.apiBenefits.setHeader('Authorization', `${response.data.data.account.token}`)
      api.apiSolutions.setHeader('Authorization', `${response.data.data.account.token}`)
      api.apiNotifications.setHeader('Authorization', `${response.data.data.account.token}`)
      axios.defaults.headers.common = {
        "Authorization": `${response.data.data.account.token}`,
      };
    }
    yield put(AuthActions.signinSuccess(response.data))   
  } else {
    yield put(AuthActions.signinFailure(response))
  }
}

export function* signup(api, action) {
  let { data, type } = action
 
  const response = yield call(api.signup, data)
  if (response.ok) {
    if (response.data.type === 'SUCCESS') {
      api.api.setHeader('Authorization', `${response.data.data.account.token}`)
      api.apiBenefits.setHeader('Authorization', `${response.data.data.account.token}`)
      api.apiSolutions.setHeader('Authorization', `${response.data.data.account.token}`)
      api.apiNotifications.setHeader('Authorization', `${response.data.data.account.token}`)
      axios.defaults.headers.common = {
        "Authorization": `${response.data.data.account.token}`,
      };
    }
    yield put(AuthActions.signupSuccess(response.data))
  } else {
    if (response.status === 400 && response.data.error.name ==="TokenExpiredError"){
      yield put(AuthActions.signout())
      localStorage.clear()
    } else {
      yield put(AuthActions.signupFailure(response))
    }
  }
}

export function* verifyToken(api, action) {
  let { data, type } = action
 
  const response = yield call(api.verifyToken, data)
  if (response.ok) {
    if (response.data.type === 'SUCCESS') {
      api.api.setHeader('Authorization', `${response.data.data.account.token}`)
      api.apiBenefits.setHeader('Authorization', `${response.data.data.account.token}`)
      api.apiSolutions.setHeader('Authorization', `${response.data.data.account.token}`)
      api.apiNotifications.setHeader('Authorization', `${response.data.data.account.token}`)
      axios.defaults.headers.common = {
        "Authorization": `${response.data.data.account.token}`,
      };
    }
    yield put(AuthActions.verifyTokenSuccess(response.data))
  } else {
    yield put(AuthActions.verifyTokenFailure(response))
  }
}

export function* signin2FA(api, action) {
  let { data } = action

  const response = yield call(api.signin2FA, data)
  if (response.ok) {    
    if (response.data.type === 'SUCCESS') {
      api.api.setHeader('Authorization', `${response.data.data.account.token}`)
      api.apiBenefits.setHeader('Authorization', `${response.data.data.account.token}`)
      api.apiSolutions.setHeader('Authorization', `${response.data.data.account.token}`)
      api.apiNotifications.setHeader('Authorization', `${response.data.data.account.token}`)
      axios.defaults.headers.common = {
        "Authorization": `${response.data.data.account.token}`,
      };
    }
    yield put(AuthActions.getQRCodeSuccess(response.data))   
  } else {
    if (response.status === 400 && response.data.error.name ==="TokenExpiredError"){
      yield put(AuthActions.signout())
      localStorage.clear()
    } else {
      yield put(AuthActions.getQRCodeFailure(response))
    }
  }
}

export function* signinSSO(api, action) {
  let { data } = action

  const response = yield call(api.signinSSO, data)
  if (response.ok) {  
    if (response.data.type === 'SUCCESS') {
      api.api.setHeader('Authorization', `${response.data.data.account.token}`)
      api.apiBenefits.setHeader('Authorization', `${response.data.data.account.token}`)
      api.apiSolutions.setHeader('Authorization', `${response.data.data.account.token}`)
      api.apiNotifications.setHeader('Authorization', `${response.data.data.account.token}`)
      axios.defaults.headers.common = {
        "Authorization": `${response.data.data.account.token}`,
      };
    }  
    yield put(AuthActions.checkAdminAccountSuccess(response.data))   
  } else {
    if (response.status === 400 && response.data.error.name ==="TokenExpiredError"){
      yield put(AuthActions.signout())
      localStorage.clear()
    } else {
      yield put(AuthActions.checkAdminAccountFailure(response))
    }
  }
}

export function* verifyQR(api, action) {
  let { data } = action

  const response = yield call(api.verifyQR, data)
  if (response.ok) {    
    if (response.data.type === 'SUCCESS') {
      api.api.setHeader('Authorization', `${response.data.data.account.token}`)
      api.apiBenefits.setHeader('Authorization', `${response.data.data.account.token}`)
      api.apiSolutions.setHeader('Authorization', `${response.data.data.account.token}`)
      api.apiNotifications.setHeader('Authorization', `${response.data.data.account.token}`)
      axios.defaults.headers.common = {
        "Authorization": `${response.data.data.account.token}`,
      };
    }
    yield put(AuthActions.verifyQRSuccess(response.data))   
  } else {
    yield put(AuthActions.verifyQRFailure(response))
  }
}