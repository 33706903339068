import styled from "styled-components";
import { Col, } from 'react-grid-system';
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledCard = styled(Col)`
  margin-bottom: 20px;
  /* @media screen and (max-width: 600px) {
    .column {
      width: 100%;
      display: block;
      margin-bottom: 20px;
    }
  }
  .column-3 {
    float: left;
    width: 33.33%;
    padding: 0 10px;
    margin-bottom: 20px;
  }
  .column-4 {
    float: left;
    width: 25%;
    padding: 0 10px;
    margin-bottom: 20px;
  } */
  .row {margin: 0 -5px;}
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .card {
    box-shadow: ${props => props.transparent ? 'transparent' : '0 4px 8px 0 rgba(0, 0, 0, 0.2)'};
    text-align: left;
    background-color: ${props => props.transparent ? 'transparent' : '#ffffff'};
    border-radius: 15px;
    overflow: hidden;
    opacity: ${props => props.isHide == true ? '1' : '0.3'};
  }
  .image {
    height: 228px;
    width: 100%;
    margin-bottom: 24px;
    background-color: ${Colors.color3};
    object-fit: contain;
    ${props => props.imageStyle}
  }
  .title {
    font-family: NunitoSans;
    ${Fonts.size.size24}
    font-weight: 800;
    color: ${Colors.color2};
    padding-bottom: 5px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 250px;
    ${props => props.titleStyle}
  }
  .description {
    font-family: NunitoSans;
    ${Fonts.size.size16}
    font-weight: bold;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 1;
    -webkit-box-orient: vertical;
    max-width: 250px;
  }
  .action {
    display: flex;
    justify-content: flex-end;
    align-items: flex-end;
    margin-top: 20px;
  }
  .edit {
    width: 11px;
    height: 11px;
  }
  .delete {
    width: 11px;
    height: 11px;
  }
  .bottom-wrapper {
    margin: 0 13px 13px 31px;
  }
  .trash-wrapper {
    background-color: ${Colors.color13};
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
  .edit-wrapper {
    background-color: ${Colors.color14};
    width: 25px;
    height: 25px;
    border-radius: 12.5px;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 6px;
    cursor: pointer;
  }
  .position {
    position: absolute;
    right: -47px;
  }
  ${props => props.additionalStyle}
`