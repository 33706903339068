import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getBenefitsRequest: ['data'],
  getBenefitsSuccess: ['payload'],
  getBenefitsFailure: ['error'],

  addBenefitRequest: ['data'],
  addBenefitSuccess: ['payload'],
  addBenefitFailure: ['error'],

  deleteBenefitRequest: ['data'],
  deleteBenefitSuccess: ['payload'],
  deleteBenefitFailure: ['error'],
  
  updateBenefitRequest: ['data'],
  updateBenefitSuccess: ['payload'],
  updateBenefitFailure: ['error'],

  getCategoriesRequest: ['data'],
  getCategoriesSuccess: ['payload'],
  getCategoriesFailure: ['error'],

  addCategoryRequest: ['data'],
  addCategorySuccess: ['payload'],
  addCategoryFailure: ['error'],

  deleteCategoryRequest: ['data'],
  deleteCategorySuccess: ['payload'],
  deleteCategoryFailure: ['error'],

  updateCategoryRequest: ['data'],
  updateCategorySuccess: ['payload'],
  updateCategoryFailure: ['error'],
})

export const BenefitsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: { fetching: null, error: null, payload: null, data: null },
  addBenefit:  { fetching: null, error: null, payload: null, data: null },
  deleteBenefit:  { fetching: null, error: null, payload: null, data: null },
  updateBenefit:  { fetching: null, error: null, payload: null, data: null },
  benefitCategories: { fetching: null, error: null,  payload: null, data: null },
  addCategory: { fetching: null, error: null,  payload: null, data: null },
  deleteCategory: { fetching: null, error: null,  payload: null, data: null },
  updateCategory: { fetching: null, error: null,  payload: null, data: null }
})

/* ------------- Selectors ------------- */

export const BenefitsSelectors = {
  benefits: state => state.benefits.list,
}

/* ------------- Reducers ------------- */

export const getBenefitsRequest = (state, { data }) => {
  return state.merge({ ...state, list: { ...state.list, fetching: true, data, }})
}

export const getBenefitsSuccess = (state, { payload }) => {
  return state.merge({ ...state, list: {  ...state.list, fetching: false, error: false, payload, }})
}

export const getBenefitsFailure = (state, { error }) => {
  return state.merge({ ...state, list: { ...state.list, fetching: false, error, }})
}

export const addBenefitRequest = (state, { data }) => {
  return state.merge({ ...state, addBenefit: { ...state.addBenefit, fetching: true, data, }})
}

export const addBenefitSuccess = (state, { payload }) => {
  return state.merge({ ...state, addBenefit: {  ...state.addBenefit, fetching: false, error: false, payload, }})
}

export const addBenefitFailure = (state, { error }) => {
  return state.merge({ ...state, addBenefit: { ...state.addBenefit, fetching: false, error, }})
}

export const deleteBenefitRequest = (state, { data }) => {
  return state.merge({ ...state, deleteBenefit: { ...state.deleteBenefit, fetching: true, data, }})
}

export const deleteBenefitSuccess = (state, { payload }) => {
  return state.merge({ ...state, deleteBenefit: {  ...state.deleteBenefit, fetching: false, error: false, payload, }})
}

export const deleteBenefitFailure = (state, { error }) => {
  return state.merge({ ...state, deleteBenefit: { ...state.deleteBenefit, fetching: false, error, }})
}

export const updateBenefitRequest = (state, { data }) => {
  return state.merge({ ...state, updateBenefit: { ...state.updateBenefit, fetching: true, data, }})
}

export const updateBenefitSuccess = (state, { payload }) => {
  return state.merge({ ...state, updateBenefit: {  ...state.updateBenefit, fetching: false, error: false, payload, }})
}

export const updateBenefitFailure = (state, { error }) => {
  return state.merge({ ...state, updateBenefit: { ...state.updateBenefit, fetching: false, error, }})
}

export const getCategoriesRequest = (state, { data }) => {
  return state.merge({ ...state, benefitCategories: { ...state.benefitCategories, fetching: true, data, }})
}

export const getCategoriesSuccess = (state, { payload }) => {
  return state.merge({ ...state, benefitCategories: {  ...state.benefitCategories, fetching: false, error: false, payload, }})
}

export const getCategoriesFailure = (state, { error }) => {
  return state.merge({ ...state, benefitCategories: { ...state.benefitCategories, fetching: false, error, }})
}

export const addCategoryRequest = (state, { data }) => {
  return state.merge({ ...state, addCategory: { ...state.addCategory, fetching: true, data, }})
}

export const addCategorySuccess = (state, { payload }) => {
  return state.merge({ ...state, addCategory: {  ...state.addCategory, fetching: false, error: false, payload, }})
}

export const addCategoryFailure = (state, { error }) => {
  return state.merge({ ...state, addCategory: { ...state.addCategory, fetching: false, error, }})
}

export const deleteCategoryRequest = (state, { data }) => {
  return state.merge({ ...state, deleteCategory: { ...state.deleteCategory, fetching: true, data, }})
}

export const deleteCategorySuccess = (state, { payload }) => {
  return state.merge({ ...state, deleteCategory: {  ...state.deleteCategory, fetching: false, error: false, payload, }})
}

export const deleteCategoryFailure = (state, { error }) => {
  return state.merge({ ...state, deleteCategory: { ...state.deleteCategory, fetching: false, error, }})
}

export const updateCategoryRequest = (state, { data }) => {
  return state.merge({ ...state, updateCategory: { ...state.updateCategory, fetching: true, data, }})
}

export const updateCategorySuccess = (state, { payload }) => {
  return state.merge({ ...state, updateCategory: {  ...state.updateCategory, fetching: false, error: false, payload, }})
}

export const updateCategoryFailure = (state, { error }) => {
  return state.merge({ ...state, updateCategory: { ...state.updateCategory, fetching: false, error, }})
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_BENEFITS_REQUEST]: getBenefitsRequest,
  [Types.GET_BENEFITS_SUCCESS]: getBenefitsSuccess,
  [Types.GET_BENEFITS_FAILURE]: getBenefitsFailure,

  [Types.ADD_BENEFIT_REQUEST]: addBenefitRequest,
  [Types.ADD_BENEFIT_SUCCESS]: addBenefitSuccess,
  [Types.ADD_BENEFIT_FAILURE]: addBenefitFailure,

  [Types.DELETE_BENEFIT_REQUEST]: deleteBenefitRequest,
  [Types.DELETE_BENEFIT_SUCCESS]: deleteBenefitSuccess,
  [Types.DELETE_BENEFIT_FAILURE]: deleteBenefitFailure,

  [Types.UPDATE_BENEFIT_REQUEST]: updateBenefitRequest,
  [Types.UPDATE_BENEFIT_SUCCESS]: updateBenefitSuccess,
  [Types.UPDATE_BENEFIT_FAILURE]: updateBenefitFailure,

  [Types.GET_CATEGORIES_REQUEST]: getCategoriesRequest,
  [Types.GET_CATEGORIES_SUCCESS]: getCategoriesSuccess,
  [Types.GET_CATEGORIES_FAILURE]: getCategoriesFailure,

  [Types.ADD_CATEGORY_REQUEST]: addCategoryRequest,
  [Types.ADD_CATEGORY_SUCCESS]: addCategorySuccess,
  [Types.ADD_CATEGORY_FAILURE]: addCategoryFailure,

  [Types.DELETE_CATEGORY_REQUEST]: deleteCategoryRequest,
  [Types.DELETE_CATEGORY_SUCCESS]: deleteCategorySuccess,
  [Types.DELETE_CATEGORY_FAILURE]: deleteCategoryFailure,

  [Types.UPDATE_CATEGORY_REQUEST]: updateCategoryRequest,
  [Types.UPDATE_CATEGORY_SUCCESS]: updateCategorySuccess,
  [Types.UPDATE_CATEGORY_FAILURE]: updateCategoryFailure,
})
