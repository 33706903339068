import React from "react";
import styled from 'styled-components';
import Spinner from 'react-loader-spinner';

import { Colors } from "../../themes/";

const Container = styled.div`
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 100;
`;

export default function ({ visible, }) {
  return (
      <Container>
        <Spinner visible={visible} type="Puff" color={Colors.color11} height={80} width={80} />
      </Container>
  );
}
