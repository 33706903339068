import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { connect } from "react-redux";

import AuthActions from "../../redux/AuthRedux";

import { StyledSidebar } from "./StyledSidebar";

import {
  adminSidebar,
  partnerSidebar,
  viewerSidebar,
} from "../../data/SidebarData";
import { signOut } from "../../api/AdminAPI";
import {
  getUnreadNotification,
  updateUnreadNotification,
} from "../../api/NotificationAPI.js";

import { USER_TYPE } from "../../constants";

function Sidebar(props) {
  const [unreadMissingPersonNotif, setUnreadMissingPersonNotif] = useState(
    null
  );
  const { inputProps, containerStyle, account, history, signout } = props;
  const accountData = account.payload && account.payload.data.account;
  const sidebar =
    accountData.type === USER_TYPE.ADMIN ||
    accountData.type === USER_TYPE.SUPERADMIN
      ? adminSidebar
      : accountData.type === USER_TYPE.PARTNER
      ? partnerSidebar
      : accountData.type === USER_TYPE.VIEWER
      ? viewerSidebar
      : [];

  useEffect(async () => {
    unreadNotification();

    const intervalId = setInterval(unreadNotification, 3600000); // 3600000 milliseconds = 1 hour
    return () => clearInterval(intervalId); // Clear interval on component unmount
  }, [updateUnreadNotification]);

  const unreadNotification = async () => {
    const data = await getUnreadNotification(
      "?type=BROADCAST_FUNCTION&from=admin"
    );
    setUnreadMissingPersonNotif(data.payload);
  };

  const handleSignOut = async () => {
    if (accountData && accountData.uid) {
      try {
        await signOut({
          uid: accountData.uid,
          action: "Sign Out",
        });
        localStorage.clear();
        // if (accountData.username !== "embreoadmin" && (accountData.type === USER_TYPE.ADMIN || accountData.type === USER_TYPE.SUPERADMIN)){
        //   await Providers.globalProvider.logout()
        // }
      } catch (err) {
        console.log(err.message);
      }
    }
  };

  const handleMissingPerson = async () => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    updateUnreadNotification().then(() => {
      getUnreadNotification("?type=BROADCAST_FUNCTION&from=admin").then(
        (res) => {
          setUnreadMissingPersonNotif(res.payload);
        }
      );
    });
  };

  const notificationStyle = {
    width: "30px", // Adjust the width to your desired size
    height: "30px", // Adjust the height to your desired size
    borderRadius: "50%",
    backgroundColor: "red", // Change the background color as needed
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    color: "white",
    fontSize: "14px",
    marginLeft: "10px",
    marginTop: "-20px",
    marginBottom: "-20px",
  };

  const flexContainer = {
    display: "flex",
    alignItems: "center",
  };

  return (
    <StyledSidebar containerStyle={containerStyle}>
      {sidebar.map((data, i) => {
        if (data.title !== "Missing Person Reports") {
          return (
            <Link
              className={`itemBox ${
                (location.pathname === data.path ||
                  (data.path === "/users" &&
                    location.pathname === "/import-user")) &&
                `activeBox`
              }`}
              key={i.toString()}
              to={data.path}
              onClick={() =>
                window.scrollTo({ top: 0, left: 0, behavior: "smooth" })
              }
            >
              <p className="text">{data.title}</p>
            </Link>
          );
        } else {
          return (
            <Link
              className={`itemBox ${
                (location.pathname === data.path ||
                  (data.path === "/users" &&
                    location.pathname === "/import-user")) &&
                `activeBox`
              }`}
              key={i.toString()}
              to={data.path}
              onClick={handleMissingPerson}
            >
              <span style={flexContainer}>
                <span className="text">{data.title}</span>
                {unreadMissingPersonNotif > 0 && (
                  <span style={notificationStyle}>
                    {unreadMissingPersonNotif}
                  </span>
                )}
              </span>
            </Link>
          );
        }
      })}
      <div
        className={`itemBox`}
        onClick={() => {
          handleSignOut();
          signout();
          history.replace("/");
          {
            /* localStorage.clear() */
          }
        }}
      >
        <p className="text">Sign Out</p>
      </div>
    </StyledSidebar>
  );
}

Sidebar.propTypes = {
  inputProps: PropTypes.any,
  containerStyle: PropTypes.string,
};

Sidebar.defaultProps = {
  inputProps: {},
  containerStyle: ``,
};

const mapStateToProps = (state) => ({
  account: state.auth.account,
});

const mapDispatchToProps = (dispatch) => {
  return {
    signout: () => dispatch(AuthActions.signout()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Sidebar);
