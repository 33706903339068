import React, { Component, useState, useEffect } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react'
import 'react-table-v6/react-table.css'
import '../node_modules/react-vis/dist/style.css';
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";

import AppNavigation from './containers/AppNavigation'
import { Providers, ProviderState } from '@microsoft/mgt-element';

import createStore from './redux';
const { store, persistor } = createStore();

// class App extends Component {
//   render() {
//     return (
//       <Provider store={store}>
//         <PersistGate loading={<h1>LOADING...</h1>} persistor={persistor}  onBeforeLift={() => console.log('init redux persist')}>
//           <AppNavigation />
//         </PersistGate>
//       </Provider>
//     );
//   }
// }
function useIsSignedIn(): [boolean] {
  const [isSignedIn, setIsSignedIn] = useState(false);
  useEffect(() => {
    const updateState = () => {
      const provider = Providers.globalProvider;
      setIsSignedIn(provider && provider.state === ProviderState.SignedIn);
    };

    Providers.onProviderUpdated(updateState);
    updateState();

    return () => {
      Providers.removeProviderUpdatedListener(updateState);
    }
  }, []);

  return [isSignedIn];
}
function App() {
  const [isSignedIn] = useIsSignedIn();

  return (
    <Provider store={store}>
      <PersistGate loading={<h1>LOADING...</h1>} persistor={persistor}  onBeforeLift={() => console.log('init redux persist')}>
        {isSignedIn ?   // Checking if user is signed in that below HTML will be rendered
          <AppNavigation />
          : <AppNavigation />
        }
      </PersistGate>
    </Provider>
);
}

export default App;
