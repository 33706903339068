import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import deviceSize from '../../constants/deviceSize'

export const StyledImportUser = styled.div`
  padding-top: 62px;
  padding-left: 40px;
  background-color: ${Colors.primaryBgColor};
  min-height: 100vh;
  padding-right: 40px;
  overflow: hidden;

  .title {
    ${Fonts.FontRegular};
    ${Fonts.size.size32};
    font-weight: 800;
    color: ${Colors.color2};
  }

  .top-button-container {
    display: flex;
    justify-content: flex-end;
  }

  .button-container {
    background: white;
    padding: 10px 40px;
  }

  .filter-container {
    background: white;
    margin-top: 10px;
  }

  .top-left-container {
    display: flex;
    align-items: center;
    height: 100%;
  }

  .top-right-container {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    height: 100%;
  }

  .arrow-container {
    display: flex;
    align-items: center;
  }

  .arrow-text {
    ${Fonts.FontRegular}
    font-size: 16px;
    font-weight: bold;
  }
  
  .button-search-container {
    width: 116px;
  }

  .search-right-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    @media ${deviceSize.tablet} {
      flex-direction: row-reverse;
      justify-content: space-between;
      align-items: flex-end;
      padding-bottom: 20px;
    }
  }

  .info-text {
    ${Fonts.FontRegular};
    font-size: 16px;
    color: ${Colors.color19};
  }
  
`