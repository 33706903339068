import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledReportMissing = styled.div`
  padding-top: 62px;
  padding-left: 40px;
  background-color: ${Colors.primaryBgColor};
  min-height: 100vh;
  padding-right: 40px;

  .title-container {
    display: flex;
    justify-content: space-between;
  }

  .title {
    ${Fonts.FontRegular};
    ${Fonts.size.size32};
    font-weight: 800;
    color: ${Colors.color2};
  }

  .title-Confirm {
    ${Fonts.FontRegular};
    ${Fonts.size.size28};
    font-weight: 800;
    color: ${Colors.color2};
    text-align: center;
    padding-top: 20px;
    padding-bottom: 10px;
  }

  .title-dialog {
    ${Fonts.FontBold};
    ${Fonts.size.size32};
    color: ${Colors.color};
  }

  .label-dialog {
    color: ${Colors.color19};
    padding-bottom: 5px;
    margin-top: 10px;
  }

  .label-copied {
    color: ${Colors.color13};
  }

  .search-container {
    display: flex;
    flex-direction: row;
    height: 40px;
    margin-top: 20px;
    justify-content: space-between;
    align-items: center;
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    margin-right: 700px;
    margin-top: 35px;
  }

  .buttonSeparator {
    width: 15px;
  }

  .image-container {
    vertical-align: middle;
    display: inline-block;
    width: 50%;
    white-space: nowrap;
    overflow-x: auto;
    overflow-y: hidden;
  }
`

export const ButtonSearch = css`
  width: 80px;
  height: 40px;
  .button-text {
    ${Fonts.size.size16}
  }
`

export const SearchBarStyle = `
  background: ${Colors.color3};
  border: 1.5px solid ${Colors.color19};
  border-radius: 10px 10px;
  height: 38px;
`
export const ButtonOpen= css`
  width: 100px;
  height: 40px;
  border-radius: 10px;
  .button-text {
    ${Fonts.size.size16}
  }
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
`

export const ButtonClosed= css`
  width: 100px;
  height: 40px;
  border-radius: 10px;
  .button-text {
    ${Fonts.size.size16}
  }
  border-top-left-radius: 0;
  border-bottom-left-radius: 0;
  border-bottom-width: 1;
  border-top-width: 0;
  border-right-width: 0;
  border-left-width: 0;
`

export const inputText = `
  background: ${Colors.color32};
  height: 38px;
  margin-top: 5px;
`

export const inputNotes = `
  background: ${Colors.color32};
  height: 180px;
  margin-top: 5px;
`

export const ButtonSaveNotes = css`
  width: 130px;
  height: 40px;
  .button-text {
    ${Fonts.size.size16}
  }
`