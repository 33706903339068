import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledUserQRDetail = styled.div`
  background: ${Colors.primaryBgColor2};
  height: 100vh;
  display: flex;
  justify-content: center;

  .card-container {
    display: flex;
    width: 854px;
    height: 538px;
    margin-top: 50px;
    box-shadow: 3px 3px 10px ${Colors.color20};
  }

  .card-left-section {
    width: 50%;
    background: ${Colors.color30};
    border-radius: 20px 0 0 20px;
    overflow: hidden;
    padding: 50px 53px 67px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .name-photo-wrapper {
      display: flex;
      justify-content: space-between;
      margin-bottom: 25px;
      padding-bottom: 25px;
      border-bottom: 1px solid ${Colors.color31};
    }

    .photo-wrapper {
      width: 65px;
      height: 65px;
    }
  }

  .card-right-section {
    width: 50%;
    background: ${Colors.primaryBgColor};
    border-radius: 0 20px 20px 0;
    overflow: hidden;
    padding: 43px 58px 67px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
  }

  .text-name {
    ${Fonts.FontRegular}
    ${Fonts.size.size28}
    color: ${Colors.color2};
    font-weight: 800;
    margin: 5px 0;
    width: 250px;
  }
`

export const Text = styled.p`
  ${Fonts.FontRegular}
  ${Fonts.size.size18}
  color: ${props => props.color};
  ${props => props.customStyle}
`

export const Label = styled.label`
  ${Fonts.FontRegular}
  ${Fonts.size.size16}
  color: ${Colors.color19};
  font-weight: bold;
`

export const InputStyle = css` 
  background-color: ${Colors.color32}; \
  box-shadow: -3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color20};
  margin: 10px 0 25px 0; 
  .input-field {  
    width: 100%;
    padding: 16px 14px 16px 18px;
    outline: none;
  }
`
