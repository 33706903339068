import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Dialog from '../components/modal'
import Button from '../components/button'
import Input from '../components/input'

import { Container, Row, Col, Hidden, } from 'react-grid-system';

import { StyledEditForm } from './styles/EditFormStyles'
import Form from '../components/form'

import { StyledDialogContent } from '../components/dialog-content/StyledDialogContent'
import { Label, InputStyle } from '../components/form/StyledForm'

import { Images, Colors, } from '../themes'

 class EditContent extends Component {
  constructor(props) {
    super(props)
    this.state = {
      id:'',
      title:'',
      text:'',
      Base64image:null,
      image:null,
      url:''
      
    }

    this.update = false
  }

  onChangeText = (name, value) => {
    this.setState({ [name]: value })
  }

  componentDidMount(){
    // console.log(this.props)
  }

  render() {  
  let imageInput = null
  const { dialogProps, image, } = this.props

  return ( 
    <Dialog
      {...dialogProps}
      title="Edit Banner"
      width="76%"
    > 
      <StyledDialogContent>
        <Row>
          <Col>
            <Label htmlFor="title">Title</Label>
            <Input 
              inputProps={{ 
                id: "title", 
                value:this.props.title,
                name:"title",
                onChange: (e) => this.props.onChangeText('title', e.target.value),  
              }}
              containerStyle={InputStyle}
            />
            <Label htmlFor="description">Description</Label>
            <Input 
              inputProps={{ 
                id: "description",  
                value: this.props.description,
                onChange: (e) => this.props.onChangeText('description', e.target.value),  
              }}
              containerStyle={InputStyle}
            />
            <Label htmlFor="text">Text</Label>
            <Input 
              inputProps={{ 
                id: "text",  
                value: this.props.text,
                onChange: (e) => this.props.onChangeText('text', e.target.value),  
              }}
              containerStyle={InputStyle} multiline
            />
            <Label htmlFor="buttonText">Button Text</Label>
            <Input 
              inputProps={{ 
                id: "buttonText",  
                value: this.props.buttonText,
                onChange: (e) => this.props.onChangeText('buttonText', e.target.value),  
              }}
              containerStyle={InputStyle}
            />
          </Col>
          <Col style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', }}>
            <Label>Image</Label>
            <div className="image-container">
              <img src={image ? image : this.props.image} alt="banner" className="banner-image" />
              <Button
                text="Edit image"
                buttonStyle={` margin-top: 35px; `}
                textStyle={` font-size: 13px; margin: 0 30px; font-weight: 800; `}
                onClick={() => {
                  imageInput.click()
                }}
              />
              <input
                type="file"
                style={{ display: 'none' }}
                ref={input => {
                  imageInput = input;
                }}
                onChange={this.props.onChangeImage}
                accept="image/*"
                required
              />
            </div>
            <Label htmlFor="url">URL</Label>
            <Input 
              inputProps={{ 
                id: "url",  
                value:this.props.url,
                onChange: (e) => this.props.onChangeText('url', e.target.value), 
              }}
              containerStyle={InputStyle}
            />
            <div className="button-wrapper">
              <div style={{ width: '48%' }}>
                <Button
                  text="Discard"
                  textColor={Colors.color21}
                  buttonStyle={` background: ${Colors.color22}; box-shadow: 3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color23}; `}
                />
              </div>
              <div style={{ width: '48%' }}> 
                <Button
                  text={"Save" }
                  buttonStyle={` box-shadow: -3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color23}; `}
                onClick={this.props.onClick}
                />
              </div>
            </div>
          </Col>  
        </Row>
      </StyledDialogContent>
    </Dialog>
  )}
}



export default EditContent


