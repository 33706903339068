import React,  { useState, } from 'react'
import PropTypes from 'prop-types'
import { Container, Row, Col, Hidden, } from 'react-grid-system';
import { Editor } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import { StyledForm, Label, InputStyle, InputReadOnlyStyle, IconX, } from './StyledForm'
import { Images, Colors, } from '../../themes'
import Input from '../input'
import InputTag from '../input/InputTag'
import Button from '../button'

function Form(props) {

  const { 
    title, 
    history, 
    benefitTitle,
    benefitTitleChinese, 
    descriptions, 
    descriptionsChinese,
    vendorName, 
    vendorAddress, 
    vendorContactNumber, 
    vendorWebsite, 
    vendorNameChinese, 
    vendorAddressChinese, 
    vendorContactNumberChinese, 
    vendorWebsiteChinese, 
    onChangeText, 
    images, 
    newImages, 
    onChangeImage, 
    onSelectImage, 
    listingCategory, 
    onSave, 
    loading, 
    action, 
    dropdownData, 
    dropdownPropertyName, 
    onSelectDropdown, 
    selectedCategory, 
    onDeleteImage, 
    onEditorStateChange,
    onEditorChineseStateChange, 
    onDiscard, 
    sendNotifification, 
    onChangeSendNotification, 
    inputTagsProps,
    inputTagsChineseProps
  } = props

  let imagesList = []
  for (let i = 0; i < 3; i++) {
    imagesList.push({ order: 1, imageUrl: images ? images[i] ? images[i].imageUrl : null : null, imageUrlChinese: images ? images[i] ? images[i].imageUrlChinese : null : null, id: images ? images[i] ? images[i].id : null : null  })
  }

  let imageInput = null;
  let imageInputChinese = null;

  const [ dropdownCategory, setDropdownCategory ] = useState(false)
  const [ currentImageId, setCurrentImageId ] = useState(null)

  let buttonDisabled = loading || (benefitTitle === '' && benefitTitleChinese === '') || (!descriptions.getCurrentContent().hasText() && !descriptionsChinese.getCurrentContent().hasText()) || (vendorName === '' && vendorNameChinese === '') ||  (images.length < 1) || !selectedCategory

  // check is is image empty or not, for button validation
  if(title.split(' ')[0] === 'edit' || title.split(' ')[0] === 'Edit') {
    let isImageEmpty = true

    for(let i=0; i < imagesList.length; i++) {
      if(imagesList[i].imageUrl || imagesList[i].imageUrlChinese) {
        isImageEmpty = false
        break;
      }
    }

    buttonDisabled = buttonDisabled || isImageEmpty
  }

  return (
    <StyledForm action={action}>
      <Container fluid>
        {
          action === 'EDIT' &&
          <Row>
            <Col className="title-wrapper">
              <img src={Images.back} className="icon-back" onClick={() => history.goBack()} />
              <h1>{title}</h1>
            </Col>
          </Row>
        }
        <p style={{ color: Colors.color19, marginTop: '30px', fontSize: '26px', fontWeight: 'bold' }}>English</p>
        <Row style={{ marginTop: '39px' }} align="stretch">
          <Col sm={12} lg={6}>
            <Label htmlFor="title">Title</Label>
            <Input 
              inputProps={{ id: "title", value: benefitTitle, onChange: (e) => onChangeText('title', e.target.value) }}
              containerStyle={InputStyle}
              iconRight={action === 'EDIT' ? Images.editBlue : ''} 
            />
            <Label htmlFor="descriptions">Descriptions</Label>
            {/* <Input 
              inputProps={{ id: "descriptions", value: descriptions, onChange: (e) => onChangeText('descriptions', e.target.value) }}
              containerStyle={InputStyle} 
              multiline
              iconRight={action === 'EDIT' ? Images.editBlue : ''} 
              iconRightStyle={` align-self: flex-start; margin-top: 12px; `}
            /> */}
            <Editor
              editorState={descriptions}
              wrapperClassName="editor-wrapper"
              editorClassName="editor-input"
              onEditorStateChange={onEditorStateChange}
              toolbar={{
                fontFamily: {
                  options: ['Arial'],
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                },
                colorPicker: {
                  className: undefined,
                  component: undefined,
                  popupClassName: undefined,
                  colors: [`#000000`],
                },
                fontSize: {
                  options: [14],
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                },
              }}
            />
            <Label htmlFor="listingCategory">Listing Category</Label>
            <Input 
              inputProps={{ id: "listingCategory", readOnly: true, onClick: () => setDropdownCategory(!dropdownCategory), value: selectedCategory ? selectedCategory[dropdownPropertyName] : '' } }
              containerStyle={InputReadOnlyStyle}
              iconRight={Images.polygon}  
              showDropdown={dropdownCategory}
              dropdownData={dropdownData}
              dropdownPropertyName={dropdownPropertyName}
              onSelectDropdown={(data) => {
                setDropdownCategory(false)
                onSelectDropdown(data)
              }}
              dropdownWidth={'100%'}
            />
            <Label htmlFor="vendorName">Vendor Name</Label>
            <Input 
              inputProps={{ id: "vendorName", value: vendorName, onChange: (e) => onChangeText('vendorName', e.target.value) }}
              containerStyle={InputStyle}
              iconRight={action === 'EDIT' ? Images.editBlue : ''} 
            />
            {/* <Label htmlFor="vendorAddress">Vendor Address</Label>
            <Input 
              inputProps={{ id: "vendorAddress", value: vendorAddress, onChange: (e) => onChangeText('vendorAddress', e.target.value) }}
              containerStyle={InputStyle}
              iconRight={action === 'EDIT' ? Images.editBlue : ''} 
            /> */}
            <Label htmlFor="vendorContact">Vendor Contact Number</Label>
            <Input 
              inputProps={{ id: "vendorContact", value: vendorContactNumber && vendorContactNumber.toString(), onChange: (e) => onChangeText('vendorContactNumber', e.target.value), type: 'number' } }
              containerStyle={InputStyle}
              iconRight={action === 'EDIT' ? Images.editBlue : ''} 
            />
            <Label htmlFor="vendorWeb">Vendor Website</Label>
            <Input 
              inputProps={{ id: "vendorWeb", value: vendorWebsite, onChange: (e) => onChangeText('vendorWebsite', e.target.value) } }
              containerStyle={InputStyle}
              iconRight={action === 'EDIT' ? Images.editBlue : ''} 
            />
          </Col>
          <Col sm={12} lg={6} style={{ flexDirection: 'column', justifyContent: 'space-between', display: 'flex' }}>
            <div>
              <Row justify="between" style={{ marginLeft: '2px' }}>
                {
                  imagesList.map((data, index) => {
                    if (index < 1) {
                      return  (
                        <>
                          <Col className="image-big-container" lg={12} 
                            style={{ overflow: 'hidden' }}
                          >   
                            {
                              data.imageUrl &&
                                <IconX src={Images.iconX} onClick={() => onDeleteImage(data, index, 'imageUrl')} />
                            }
                            <img src={data.imageUrl ? data.imageUrl : Images.thumbnail} style={{ width: data.imageUrl ? '100%' : '47px', cursor: 'pointer', }} alt="benefit" onClick={() => {
                              onSelectImage(index)
                              imageInput.click()
                            }} />
                            {
                              !data.imageUrl &&
                                <Button
                                  text="Add images"
                                  buttonStyle={` margin-top: 35px; `}
                                  textStyle={` font-size: 13px; margin: 0 30px; font-weight: 800; `}
                                  onClick={() => {
                                    onSelectImage(index)
                                    imageInput.click()

                                  }}
                                />
                            }
                          </Col>
                        </>
                      ) 
                    }

                    return (
                      <>
                        <Col className="image-small-container" lg={5} sm={12}
                          style={{ overflow: 'hidden', marginBottom: '24px' }}
                        >
                          {
                            data.imageUrl &&
                              <IconX src={Images.iconX} onClick={() => onDeleteImage(data, index, 'imageUrl')} />
                          }
                          <img src={data.imageUrl ? data.imageUrl : Images.thumbnail} style={{ width: data.imageUrl ? '100%' : '47px', cursor: 'pointer', }}  alt="benefit" onClick={() => {
                            onSelectImage(index)
                            imageInput.click()
                          }} 
                          />
                          {
                            !data.imageUrl && 
                              <Button
                                text="Add images"
                                buttonStyle={` margin-top: 15px; padding: 10px 0; `}
                                textStyle={` font-size: 13px; margin: 0 30px; font-weight: 800; `}
                                onClick={() => {
                                  onSelectImage(index)
                                  imageInput.click()
                                  setCurrentImageId(data.id)
                                }}
                              />
                          }
                        </Col>
                      </>
                      
                    )
                  })
                }
                <input
                  type="file"
                  id="benefitImage"
                  style={{ display: 'none' }}
                  ref={input => {
                    imageInput = input;
                  }}
                  onChange={(e) => onChangeImage(e, 'base64Image', currentImageId)}
                  accept="image/*"
                  required
                />
              </Row>
              <Label htmlFor="vendorContact">Tag</Label>
              <InputTag 
                {...inputTagsProps}
                id='Tag'
                containerStyle={InputStyle}
                iconRight={action === 'EDIT' ? Images.editBlue : ''} 
              />
                {/* <Row style={{ marginBottom: '24px',  }}>
                  <Col style={{ display: 'flex', alignItems: 'center', }} lg={12}>
                    <Label htmlFor="notif">Send Notification</Label>
                    <input type="checkbox" id="checkbox-notif" style={{ marginLeft: '11px', }} onChange={(e) => onChangeSendNotification(e.target.checked)} />
                </Col>
                </Row> */}
            </div>
            {/* <div className="button-wrapper">
              <div style={{ width: '48%' }}>
                <Button
                  text="Discard"
                  textColor={Colors.color21}
                  buttonStyle={` background: ${Colors.color22}; box-shadow: 3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color23}; `}
                  onClick={onDiscard}
                />
              </div>
              <div style={{ width: '48%' }}> 
                <Button
                  text={loading ? "Loading..." : "Save" }
                  buttonStyle={` box-shadow: -3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color23}; `}
                  onClick={onSave}
                  disabled={buttonDisabled}
                />
              </div>
            </div> */}
          </Col>
        </Row>
        <hr style={{ marginTop: '20px', backgroundColor: 'red' }}/>
        <p style={{ color: Colors.color19, marginTop: '30px', fontSize: '26px', fontWeight: 'bold' }}>Mandarin</p>
        <Row style={{ marginTop: '39px' }} align="stretch">
          <Col sm={12} lg={6}>
            <Label htmlFor="title">Title</Label>
            <Input 
              inputProps={{ id: "titleChinese", value: benefitTitleChinese, onChange: (e) => onChangeText('titleChinese', e.target.value) }}
              containerStyle={InputStyle}
              iconRight={action === 'EDIT' ? Images.editBlue : ''} 
            />
            <Label htmlFor="descriptions">Descriptions</Label>
            {/* <Input 
              inputProps={{ id: "descriptions", value: descriptions, onChange: (e) => onChangeText('descriptions', e.target.value) }}
              containerStyle={InputStyle} 
              multiline
              iconRight={action === 'EDIT' ? Images.editBlue : ''} 
              iconRightStyle={` align-self: flex-start; margin-top: 12px; `}
            /> */}
            <Editor
              editorState={descriptionsChinese}
              wrapperClassName="editor-wrapper"
              editorClassName="editor-input"
              onEditorStateChange={onEditorChineseStateChange}
              toolbar={{
                fontFamily: {
                  options: ['Arial'],
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                },
                colorPicker: {
                  className: undefined,
                  component: undefined,
                  popupClassName: undefined,
                  colors: [`#000000`],
                },
                fontSize: {
                  options: [14],
                  className: undefined,
                  component: undefined,
                  dropdownClassName: undefined,
                },
              }}
            />
            <Label htmlFor="listingCategory">Listing Category</Label>
            <Input 
              inputProps={{ id: "listingCategoryChinese", readOnly: true, onClick: () => setDropdownCategory(!dropdownCategory), value: selectedCategory ? selectedCategory[dropdownPropertyName] : '' } }
              containerStyle={InputReadOnlyStyle}
              iconRight={Images.polygon}  
              showDropdown={dropdownCategory}
              dropdownData={dropdownData}
              dropdownPropertyName={dropdownPropertyName}
              onSelectDropdown={(data) => {
                setDropdownCategory(false)
                onSelectDropdown(data)
              }}
              dropdownWidth={'100%'}
            />
            <Label htmlFor="vendorName">Vendor Name</Label>
            <Input 
              inputProps={{ id: "vendorNameChinese", value: vendorNameChinese, onChange: (e) => onChangeText('vendorNameChinese', e.target.value) }}
              containerStyle={InputStyle}
              iconRight={action === 'EDIT' ? Images.editBlue : ''} 
            />
            {/* <Label htmlFor="vendorAddress">Vendor Address</Label>
            <Input 
              inputProps={{ id: "vendorAddress", value: vendorAddress, onChange: (e) => onChangeText('vendorAddress', e.target.value) }}
              containerStyle={InputStyle}
              iconRight={action === 'EDIT' ? Images.editBlue : ''} 
            /> */}
            <Label htmlFor="vendorContact">Vendor Contact Number</Label>
            <Input 
              inputProps={{ id: "vendorContactChinese", value: vendorContactNumberChinese && vendorContactNumberChinese.toString(), onChange: (e) => onChangeText('vendorContactNumberChinese', e.target.value), type: 'number' } }
              containerStyle={InputStyle}
              iconRight={action === 'EDIT' ? Images.editBlue : ''} 
            />
            <Label htmlFor="vendorWeb">Vendor Website</Label>
            <Input 
              inputProps={{ id: "vendorWebChinese", value: vendorWebsiteChinese, onChange: (e) => onChangeText('vendorWebsiteChinese', e.target.value) } }
              containerStyle={InputStyle}
              iconRight={action === 'EDIT' ? Images.editBlue : ''} 
            />
          </Col>
          <Col sm={12} lg={6} style={{ flexDirection: 'column', justifyContent: 'space-between', display: 'flex' }}>
            <div>
              <Row justify="between" style={{ marginLeft: '2px' }}>
                {
                  imagesList.map((data, index) => {
                    if (index < 1) {
                      return  (
                        <>
                          <Col className="image-big-container" lg={12} 
                            style={{ overflow: 'hidden' }}
                          >   
                            {
                              data.imageUrlChinese &&
                                <IconX src={Images.iconX} onClick={() => onDeleteImage(data, index, 'imageUrlChinese')} />
                            }
                            <img src={data.imageUrlChinese ? data.imageUrlChinese : Images.thumbnail} style={{ width: data.imageUrlChinese ? '100%' : '47px', cursor: 'pointer', }} alt="benefit" onClick={() => {
                              onSelectImage(index)
                              imageInputChinese.click()
                            }} />
                            {
                              !data.imageUrlChinese &&
                                <Button
                                  text="Add images"
                                  buttonStyle={` margin-top: 35px; `}
                                  textStyle={` font-size: 13px; margin: 0 30px; font-weight: 800; `}
                                  onClick={() => {
                                    onSelectImage(index)
                                    imageInputChinese.click()
                                  }}
                                />
                            }
                          </Col>
                        </>
                      ) 
                    }

                    return (
                      <>
                        <Col className="image-small-container" lg={5} sm={12}
                          style={{ overflow: 'hidden', marginBottom: '24px' }}
                        >
                          {
                            data.imageUrlChinese &&
                              <IconX src={Images.iconX} onClick={() => onDeleteImage(data, index, 'imageUrlChinese')} />
                          }
                          <img src={data.imageUrlChinese ? data.imageUrlChinese : Images.thumbnail} style={{ width: data.imageUrlChinese ? '100%' : '47px', cursor: 'pointer', }}  alt="benefit" onClick={() => {
                            onSelectImage(index)
                            imageInputChinese.click()
                          }} 
                          />
                          {
                            !data.imageUrlChinese && 
                              <Button
                                text="Add images"
                                buttonStyle={` margin-top: 15px; padding: 10px 0; `}
                                textStyle={` font-size: 13px; margin: 0 30px; font-weight: 800; `}
                                onClick={() => {
                                  onSelectImage(index)
                                  imageInputChinese.click()
                                }}
                              />
                          }
                        </Col>
                      </>
                      
                    )
                  })
                }
                <input
                  type="file"
                  id="benefitChineseImage"
                  style={{ display: 'none' }}
                  ref={input => {
                    imageInputChinese = input;
                  }}
                  onChange={(e) => onChangeImage(e, 'base64ImageChinese', currentImageId)}
                  accept="image/*"
                  required
                />
              </Row>
              <Label htmlFor="vendorContact">Tag</Label>
              <InputTag 
                {...inputTagsChineseProps}
                id='TagChinese'
                containerStyle={InputStyle}
                iconRight={action === 'EDIT' ? Images.editBlue : ''} 
              />
                {/* <Row style={{ marginBottom: '24px',  }}>
                  <Col style={{ display: 'flex', alignItems: 'center', }} lg={12}>
                    <Label htmlFor="notif">Send Notification</Label>
                    <input type="checkbox" id="checkbox-notif" style={{ marginLeft: '11px', }} onChange={(e) => onChangeSendNotification(e.target.checked)} />
                </Col>
                </Row> */}
            </div>
            {/* <div className="button-wrapper">
              <div style={{ width: '48%' }}>
                <Button
                  text="Discard"
                  textColor={Colors.color21}
                  buttonStyle={` background: ${Colors.color22}; box-shadow: 3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color23}; `}
                  onClick={onDiscard}
                />
              </div>
              <div style={{ width: '48%' }}> 
                <Button
                  text={loading ? "Loading..." : "Save" }
                  buttonStyle={` box-shadow: -3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color23}; `}
                  onClick={onSave}
                  disabled={buttonDisabled}
                />
              </div>
            </div> */}
          </Col>
        </Row>
        <hr style={{ marginTop: '20px', marginBottom: '60px', backgroundColor: 'red' }}/>
        <Row>
        <Col sm={12} lg={6}>
          <Label htmlFor="notif">Send Notification</Label>
          <input type="checkbox" id="checkbox-notif" style={{ marginLeft: '11px', }} onChange={(e) => onChangeSendNotification(e.target.checked)} />
        </Col>

        <Col sm={12} lg={6} style={{ flexDirection: 'column', justifyContent: 'space-between', display: 'flex' }}>
            <div className="button-wrapper">
              <div style={{ width: '48%' }}>
                <Button
                  text="Discard"
                  textColor={Colors.color21}
                  buttonStyle={` background: ${Colors.color22}; box-shadow: 3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color23}; `}
                  onClick={onDiscard}
                />
              </div>
              <div style={{ width: '48%' }}> 
                <Button
                  text={loading ? "Loading..." : "Save" }
                  buttonStyle={` box-shadow: -3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color23}; `}
                  onClick={onSave}
                  disabled={buttonDisabled}
                />
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </StyledForm>
  )
}

Form.propTypes = {
  title: PropTypes.string,
  benefitTitle: PropTypes.string,
  benefitTitleChinese: PropTypes.string,
  descriptions: PropTypes.string,
  descriptionsChinese: PropTypes.string,
  vendorName: PropTypes.string,
  vendorAddress: PropTypes.string,
  vendorContactNumber: PropTypes.string,
  vendorWebsite: PropTypes.string,
  vendorNameChinese: PropTypes.string,
  vendorAddressChinese: PropTypes.string,
  vendorContactNumberChinese: PropTypes.string,
  vendorWebsiteChinese: PropTypes.string,
  listingCategory: PropTypes.string,
  onChangeText: PropTypes.func,
  images: PropTypes.array,
  newImages: PropTypes.array,
  onChangeImage: PropTypes.func,
  onSelectImage: PropTypes.func,
  onSave: PropTypes.func,
  loading: PropTypes.bool,
  action: PropTypes.oneOf(['ADD', 'EDIT']),
  dropdownData: PropTypes.array,
  dropdownPropertyName: PropTypes.string,
  onSelectDropdown: PropTypes.func,
  selectedCategory: PropTypes.any,
  onDeleteImage: PropTypes.func,
  onEditorStateChange: PropTypes.func,
  onEditorChineseStateChange: PropTypes.func,
  onDiscard: PropTypes.func,
  sendNotifification: PropTypes.bool,
  onChangeSendNotification: PropTypes.func,
  inputTagsProps: PropTypes.any,
  inputTagsChineseProps: PropTypes.any,
}

Form.defaultProps = {
  title: '',
  benefitTitle: '',
  benefitTitleChinese: '',
  descriptions: '',
  descriptionsChinese: '',
  vendorName: '',
  vendorAddress: '',
  vendorContactNumber: '',
  vendorWebsite: '',
  vendorNameChinese: '',
  vendorAddressChinese: '',
  vendorContactNumberChinese: '',
  vendorWebsiteChinese: '',
  listingCategory: '',
  onChangeText: () => null,
  images: [],
  newImages: [],
  onChangeImage: () => null,
  onSelectImage: () => null,
  onSave: () => null,
  loading: false,
  action: 'EDIT',
  dropdownData: [],
  dropdownPropertyName: '',
  onSelectDropdown: () => null,
  selectedCategory: null,
  onDeleteImage: () => null,
  onEditorStateChange: () => null,
  onEditorChineseStateChange: () => null,
  onDiscard: () => null,
  sendNotifification: false,
  onChangeSendNotification: () => null,
  inputTagsProps: {},
  inputTagsChineseProps: {}
}

export default Form

