import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledEditCategory = styled.div`
  min-height: 100vh;
  background-color: ${Colors.primaryBgColor};
  display: flex;
  justify-content: center;
  align-items: center;
`