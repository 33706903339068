import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledNotifications = styled.div`
  padding-top: 62px;
  padding-left: 40px;
  background-color: ${Colors.primaryBgColor};
  min-height: 100vh;
  padding-right: 40px;

  .title-container {
    display: flex;
    justify-content: space-between;
  }

  .title {
    ${Fonts.FontRegular};
    ${Fonts.size.size32};
    font-weight: 800;
    color: ${Colors.color2};
  }

  .buttonContainer {
    display: flex;
    flex-direction: row;
    margin-right: 700px;
    margin-top: 35px;
  }

  .buttonSeparator {
    width: 15px;
  }
`