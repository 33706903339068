import { createReducer, createActions } from 'reduxsauce'
import Immutable from 'seamless-immutable'

/* ------------- Types and Action Creators ------------- */

const { Types, Creators } = createActions({
  getNotificationsRequest: ['data'],
  getNotificationsSuccess: ['payload'],
  getNotificationsFailure: ['error'],
})

export const NotificationsTypes = Types
export default Creators

/* ------------- Initial State ------------- */

export const INITIAL_STATE = Immutable({
  list: { fetching: null, error: null, payload: null, data: null },
})

/* ------------- Selectors ------------- */

export const NotificationsSelectors = {
  notifications: state => state.notifications.list,
}

/* ------------- Reducers ------------- */

export const getNotificationsRequest = (state, { data }) => {
  return state.merge({ ...state, list: { ...state.list, fetching: true, data, }})
}

export const getNotificationsSuccess = (state, { payload }) => {
  return state.merge({ ...state, list: {  ...state.list, fetching: false, error: false, payload, }})
}

export const getNotificationsFailure = (state, { error }) => {
  return state.merge({ ...state, list: { ...state.list, fetching: false, error, }})
}

/* ------------- Hookup Reducers To Types ------------- */

export const reducer = createReducer(INITIAL_STATE, {
  [Types.GET_NOTIFICATIONS_REQUEST]: getNotificationsRequest,
  [Types.GET_NOTIFICATIONS_SUCCESS]: getNotificationsSuccess,
  [Types.GET_NOTIFICATIONS_FAILURE]: getNotificationsFailure,
})
