import React, { Component } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Hidden } from "react-grid-system";
import moment from "moment";
import axios from "axios";
import memoize from "memoize-one";
import _ from "lodash";
import Swal from "sweetalert2";

import { StyledUserDetail, ContainerPLWD } from "./styles/StyledUserDetail";

import { Images, Colors } from "../themes";

import { ACCOUNT_STATUS, USER_TYPE } from "../constants";

import UserActions from "../redux/UserRedux";

import Dialog from "../components/modal";
import Input from "../components/input";
import Button from "../components/button";
import {
  TagCaregiver,
  TagDementia,
  TagPartner,
} from "../components/dialog-tag";
import DialogInfo from "../components/dialog-info";
import DialogTransfer from "../components/dialog-transfer";
import DialogDetailUser from "../components/dialog-detail-user";

import ProccessData from "../lib/ProccessData";
import {
  deleteUser,
  editUser,
  updateAccountStatus,
  getOtherAccount,
  tagAccount,
  deleteOtherAccount,
  getFeatureAnalyticUser,
} from "../api/UserAPI";
import { addNotification } from "../api/NotificationAPI";
import { getAddress } from "../api/UserAPI";

const defaultDialogState = {
  dialogTitle: "",
  titleColor: "",
  dialogDescription: "",
  dialogOkText: "",
  okBorderColor: "",
  okTextColor: "",
  dialogCancelText: "",
  okBordered: true,
  okBackgroundColor: "transparent",
};

const deleteDialogState = {
  dialogTitle: "Delete Account",
  titleColor: Colors.color13,
  dialogDescription: "Are you sure you want to delete this account?",
  dialogOkText: "Delete",
  okBorderColor: Colors.color13,
  okTextColor: Colors.color13,
  dialogCancelText: `Decline`,
  okBordered: true,
  okBackgroundColor: "transparent",
};

const rejectDialogState = {
  dialogTitle: "Reject Account",
  titleColor: Colors.color19,
  dialogDescription: "Are you sure you want to reject this account?",
  dialogOkText: "Reject",
  okBorderColor: Colors.color7,
  okTextColor: Colors.color2,
  dialogCancelText: `Decline`,
  okBordered: true,
  okBackgroundColor: "transparent",
};

const approveDialogState = {
  dialogTitle: "Approve Account",
  titleColor: Colors.color17,
  dialogDescription: "Are you sure you want to approve this account?",
  dialogOkText: "Approve",
  okBorderColor: Colors.color17,
  okTextColor: Colors.color17,
  dialogCancelText: `Decline`,
  okBordered: true,
  okBackgroundColor: "transparent",
};

const sendCardDialogState = {
  dialogTitle: "Send Card",
  titleColor: Colors.color25,
  dialogDescription: "Account status will change to completed after card sent.",
  dialogOkText: "Send",
  okBorderColor: Colors.color25,
  okTextColor: Colors.color25,
  dialogCancelText: `Decline`,
  okBordered: true,
  okBackgroundColor: "transparent",
};

const expiredDialogState = {
  dialogTitle: "Account Expired",
  titleColor: Colors.color21,
  dialogDescription: "Are you sure want to make this account expired?",
  dialogOkText: "Ok",
  okBorderColor: Colors.color21,
  okTextColor: Colors.color21,
  dialogCancelText: `Decline`,
  okBordered: true,
  okBackgroundColor: "transparent",
};

const deleteTagDialogState = {
  dialogTitle: "Delete Tag Account Relationship",
  titleColor: Colors.color13,
  dialogDescription:
    "Are you sure you want to delete this tag account relationship?",
  dialogOkText: "Delete",
  okBorderColor: Colors.color13,
  okTextColor: Colors.color13,
  dialogCancelText: `Decline`,
  okBordered: true,
  okBackgroundColor: "transparent",
};

const interestsData = [
  {
    id: 1,
    name: "Find more information about Dementia",
    selected: false,
  },
  {
    id: 2,
    name: "Get the latest membership benefits",
    selected: false,
  },
  {
    id: 3,
    name: "Connect with other persons with dementia and caregivers",
    selected: false,
  },
  {
    id: 4,
    name: "Get the latest membership benefits",
    selected: false,
  },
  {
    id: 5,
    name:
      "Participate in advocacy efforts (media, talks, speaking opportunities)",
    selected: false,
  },
  {
    id: 6,
    name: "Volunteer Opportunities",
    selected: false,
  },
  {
    id: 7,
    name: "Participate in research",
    selected: false,
  },
];

const transferCG = {
  type: "CAREGIVER",
  title: "Transfer PLWD to new Care Professional",
  searchLabel: "Choose Care Professional",
};

const transferPLWD = {
  type: "DEMENTIA",
  title: "Transfer Care Professional to new PLWD",
  searchLabel: "Choose PLWD",
};

const transferPartner = {
  type: "PARTNER",
  title: "Transfer CG to new Partner",
  searchLabel: "Search Partner account",
};

const alertError = (e) => {
  Swal.fire({
    title: "Error!",
    text: e,
    icon: "error",
    confirmButtonText: "OK",
  });
};

export class UserDetail extends Component {
  static getDerivedStateFromProps(props, state) {
    const {
      checkDropdownValue,
      checkDropdownUppercaseValue,
      languageData,
      typeOfDementiaData,
      stageOfDementiaData,
      mounted,
    } = state;
    const { users, match } = props;

    let returnValue = {};
    if (!mounted) {
      const user =
        users &&
        users.payload &&
        users.payload.data.filter(
          (data) => data.uid === parseInt(match.params.id)
        )[0];

      returnValue = {
        user,
      };

      if (user) {
        const {
          uid,
          createdAt,
          username,
          firstname,
          lastname,
          lastNric,
          gender,
          email,
          phone,
          dementiaDetail,
          streetName,
          unitNumber,
          building,
          blockNumber,
          postalCode,
          cityCountry,
          dementiaHospital,
          existingDementiaSingapore,
          interests,
          prefixCountryNum,
          otherAccount,
          homeNumber,
          displayName,
          type,
          partnerDetail,
          metadata,
          howFindCara,
        } = user;
        returnValue = {
          createdAt,
          uid,
          username,
          user,
          firstname,
          lastname,
          lastNric,
          gender: gender ? { name: gender } : {},
          birth: moment(user.birth).format("DD MMMM YYYY"),
          email,
          phone,
          language: dementiaDetail ? { name: dementiaDetail.language } : {},
          typeOfDementia: dementiaDetail
            ? { name: dementiaDetail.typeDementia }
            : {},
          stageOfDementia: dementiaDetail
            ? { name: dementiaDetail.stageDementia }
            : {},
          streetName,
          unitNumber,
          postalCode,
          cityCountry,
          building,
          blockNumber,
          hospitalName: dementiaHospital ? dementiaHospital.hospitalName : "",
          hospitalDepartment: dementiaHospital
            ? dementiaHospital.hospitalDepartment
            : "",
          symptoms: dementiaHospital ? dementiaHospital.symptoms : "",
          appointment: dementiaHospital
            ? { name: dementiaHospital.appointment }
            : {},
          mounted: true,
          haveUsed: {
            name: existingDementiaSingapore ? "Yes" : "No",
            value: existingDementiaSingapore,
          },
          userInterest:
            interests &&
            interests.split("/***/").reduce((acc, curr) => {
              interestsData.map(
                (data) =>
                  parseInt(data.id) === parseInt(curr) && acc.push(data.name)
              );
              return acc;
            }, []),
          prefixCountryNum,
          otherAccount,
          homeNumber,
          displayName,
          type,
          partnerDetail,
          jobTitle: metadata && metadata.jobTitle ? metadata.jobTitle : null,
          department:
            metadata && metadata.department ? metadata.department : null,
          howFindCara,
        };
      }
    }

    return {
      ...returnValue,
    };
  }
  constructor(props) {
    super(props);

    this.state = {
      uid: null,
      createdAt: "",
      username: "",
      user: null,
      dialogConfirmation: false,
      loading: false,
      firstname: "",
      lastname: "",
      day: "",
      month: "",
      year: "",
      genderDropdown: false,
      gender: {},
      genderData: [
        {
          id: 1,
          name: "male",
        },
        {
          id: 2,
          name: "female",
        },
      ],
      email: "",
      phone: "",
      birth: null,
      jobTitle: "",
      department: "",
      languageDropdown: false,
      language: {},
      languageData: [
        {
          id: 1,
          name: "English",
        },
        {
          id: 2,
          name: "Mandarin",
        },
        {
          id: 3,
          name: "Malay",
        },
        {
          id: 4,
          name: "Tamil",
        },
        {
          id: 5,
          name: "Hokkien",
        },
      ],
      typeOfDementiaDropdown: false,
      typeOfDementia: {},
      typeOfDementiaData: [
        {
          id: 1,
          name: `Alzheimer's Disease`,
        },
        {
          id: 2,
          name: "Vascular Dementia",
        },
        {
          id: 3,
          name: "Frontotemporal Dementia (FTD)",
        },
        {
          id: 4,
          name: "Lewy Body Dementia (LBD)",
        },
        {
          id: 5,
          name: "Mild Cognitive Impairment (MCI)",
        },
      ],
      stageOfDementiaDropdown: false,
      stageOfDementia: {},
      stageOfDementiaData: [
        {
          id: 1,
          name: `MILD`,
        },
        {
          id: 2,
          name: "MODERATE",
        },
        {
          id: 3,
          name: "SEVERE",
        },
      ],
      checkDropdownValue: this.checkDropdownValue,
      checkDropdownUppercaseValue: this.checkDropdownUppercaseValue,
      mounted: false,
      streetName: "",
      unitNumber: "",
      postalCode: "",
      building: "",
      blockNumber: "",
      cityCountry: "",
      hospitalName: "",
      hospitalDepartment: "",
      symptoms: "",
      appointmentDropdown: false,
      appointment: {},
      appointmentData: [
        {
          id: 1,
          name: "DAILY",
        },
        {
          id: 2,
          name: "WEEKLY",
        },
        {
          id: 3,
          name: "MONTHLY",
        },
        {
          id: 4,
          name: "YEARLY",
        },
      ],
      loadingEdit: false,
      dialogReject: false,
      loadingAccountStatus: false,
      ...defaultDialogState,
      tagsId: null,
      otherUid: null,
      otherFirstname: "",
      otherLastname: "",
      relationship: "",
      otherGenderDropdown: false,
      otherGender: {},
      otherBirth: null,
      otherLanguageDropdown: false,
      otherLanguage: {},
      otherEmail: "",
      otherPhone: "",
      otherAccount: [],
      haveUsedDropdown: false,
      haveUsed: {},
      haveUsedData: [
        {
          id: 1,
          name: "Yes",
          value: true,
        },
        {
          id: 2,
          name: "No",
          value: false,
        },
      ],
      userInterest: null,
      howFindCara: "",
      prefixCountryNum: "",
      homeNumber: "",
      displayName: "-",
      dialogTagCaregiver: false,
      dialogTagDementia: false,
      dialogTagPartner: false,
      dialogSuccessTag: false,
      successTagMessage: "",
      loadingTag: false,
      idTag: null,
      type: "",
      infoDialogShow: false,
      infoDescription: "",
      partnerDetail: {},
      dialogTransfer: {
        show: false,
        detail: null,
      },
      singleAccount: null,
      transferDataPLWD: false,
      styleSuccess: "",
      detailUser: false,
      dataDetailUser: null,
      errorMsg: null,
      userAnalytic: {},
    };

    this._onOkDialog = this._onOkDialog.bind(this);
    this._onUpdateAccountStatus = this._onUpdateAccountStatus.bind(this);
    this._onGetFeatureAnaylitcUser = this._onGetFeatureAnaylitcUser.bind(this);
  }

  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    this.props.getUsers({ data: { uid: this.props.match.params.id } });
    this._onGetFeatureAnaylitcUser(this.props.match.params.id);
  }

  componentWillUnmount() {
    this.props.resetUsers();
  }

  onGetAddress = async (value) => {
    try {
      let resultGet = {};
      resultGet.payload = await getAddress({
        searchVal: `${value}`,
      });
      resultGet.payload.type = "SUCCESS";
      ProccessData(resultGet, "get address")
        .then((res) => {
          if (
            res &&
            res.data &&
            res.data.results &&
            res.data.results.length > 0
          ) {
            this.setState((currentState) => {
              return {
                streetName: res.data.results[0].ROAD_NAME,
                blockNumber: res.data.results[0].BLK_NO,
                building: res.data.results[0].BUILDING,
              };
            });
          }
        })
        .catch((err) => {
          alertError(err);
        });
    } catch (e) {
      alertError(e);
    }
  };

  renderList(
    stateName,
    field,
    value,
    className = "",
    dropdown = {},
    inputProps = { type: "text" }
  ) {
    const { action } = this.props.location.state || "";

    const isInput = Object.keys(dropdown).length === 0;
    const type = inputProps;
    return (
      <Row
        style={{ width: "100%", marginLeft: ".1px" }}
        className={`list-container ${action === "DETAIL" ? className : ""}`}
      >
        <Col offset={{ lg: 0.6, xs: 1 }} xs={12}>
          <Row>
            <Col xs={5}>
              <p>{field}</p>
            </Col>
            <Col xs={7}>
              {action === "DETAIL" ? (
                <div style={{ display: "flex" }}>
                  <p style={{ marginRight: "22px" }}>: </p>
                  <p style={{ width: "85%" }}>
                    {value || value === 0 ? value : "-"}
                  </p>
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <p style={{ marginRight: "22px" }}>: </p>
                  <div style={{ width: "84%" }}>
                    <Input
                      inputProps={{
                        value,
                        readOnly: isInput ? false : true,
                        onClick: () => !isInput && dropdown.onClick(),
                        onChange: async (e) => {
                          this.setState({ [stateName]: e.target.value });

                          if (
                            stateName === "postalCode" &&
                            e.target.value !== ""
                          ) {
                            await this.onGetAddress(e.target.value);
                          }
                        },
                        ...inputProps,
                      }}
                      containerStyle={` margin:-8px 0 0 0; `}
                      {...dropdown}
                      dropdownWidth={`100%`}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  deleteUser = async (uid) => {
    this.setState({ loading: true });
    try {
      let resultDelete = {};
      resultDelete.fetching = false;
      resultDelete.payload = await deleteUser({ uidList: uid });
      ProccessData(resultDelete, "delete user")
        .then((res) => {
          this.setState({
            dialogConfirmation: false,
            loading: false,
          });
          this.onDialogInfo("Account Deleted");
          this.props.history.goBack();
        })
        .catch((err) => {
          this.onDialogInfo(err.message);
          this.setState({
            dialogConfirmation: false,
            loading: false,
          });
        });
    } catch (e) {
      this.onDialogInfo(e.message);
      this.setState({
        loading: false,
        dialogConfirmation: false,
      });
    }
  };

  onPressDropdown = (stateName, state) => {
    this.setState({ [stateName]: !state });
  };

  onChangeText = (name, value) => {
    this.setState({ [name]: value });
  };

  onEditAccount = async () => {
    this.setState({ loadingEdit: true });
    let newError = new Error("Error Delete Admin");
    try {
      let resultEdit = {};
      resultEdit.fetching = false;
      const {
        user,
        username,
        firstname,
        lastname,
        phone,
        email,
        gender,
        birth,
        streetName,
        unitNumber,
        postalCode,
        cityCountry,
        building,
        blockNumber,
        lastNric,
        language,
        typeOfDementia,
        stageOfDementia,
        hospitalName,
        hospitalDepartment,
        appointment,
        symptoms,
        otherUid,
        otherFirstname,
        otherLastname,
        relationship,
        otherGender,
        otherBirth,
        otherLanguage,
        otherEmail,
        otherPhone,
        tagsId,
        haveUsed,
        prefixCountryNum,
        homeNumber,
        displayName,
        jobTitle,
        department,
      } = this.state;
      const { uid, type, metadata } = user;

      if (type === USER_TYPE.DEMENTIA || type === USER_TYPE.CAREGIVER) {
        const cgAndPlwdErrorForm = {
          username,
          firstname,
          displayName,
          gender,
          birth,
          streetName,
          postalCode,
          cityCountry,
        };
        const plwdErrorForm = {
          language,
          typeOfDementia,
          stageOfDementia,
          hospitalName,
          hospitalDepartment,
          symptoms,
          lastname,
          lastNric,
        };
        const cgErrorForm = { email, phone, lastNric };
        const mopErrorForm = { email, phone };

        let foundError = false;

        for (const item in cgAndPlwdErrorForm) {
          const itemString =
            cgAndPlwdErrorForm[item] == null ? "" : cgAndPlwdErrorForm[item];
          const itemDisplay = item
            .replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2")
            .trim()
            .replace(
              /(^\w|\s\w)(\S*)/g,
              (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
            );
          if (itemString.length == 0 || itemString == null) {
            alertError(`${itemDisplay} is required`);
            foundError = true;
            break;
          }
        }

        if (type === USER_TYPE.DEMENTIA) {
          for (const item in plwdErrorForm) {
            const itemString =
              plwdErrorForm[item] == null ? "" : plwdErrorForm[item];
            const itemDisplay = item
              .replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2")
              .trim()
              .replace(
                /(^\w|\s\w)(\S*)/g,
                (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
              );
            if (itemString.length == 0 || itemString == null) {
              alertError(`${itemDisplay} is required`);
              foundError = true;
              break;
            }
          }
        }

        if (type === USER_TYPE.CAREGIVER && metadata.MoP === false) {
          for (const item in cgErrorForm) {
            const itemString =
              cgErrorForm[item] == null ? "" : cgErrorForm[item];
            const itemDisplay = item
              .replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2")
              .trim()
              .replace(
                /(^\w|\s\w)(\S*)/g,
                (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
              );
            if (itemString.length == 0 || itemString == null) {
              alertError(`${itemDisplay} is required`);
              foundError = true;
              break;
            }
          }
        }

        if (type === USER_TYPE.CAREGIVER && metadata.MoP === true) {
          for (const item in mopErrorForm) {
            const itemString =
              mopErrorForm[item] == null ? "" : mopErrorForm[item];
            const itemDisplay = item
              .replace(/([A-Z]+)*([A-Z][a-z])/g, "$1 $2")
              .trim()
              .replace(
                /(^\w|\s\w)(\S*)/g,
                (_, m1, m2) => m1.toUpperCase() + m2.toLowerCase()
              );
            if (itemString.length == 0 || itemString == null) {
              alertError(`${itemDisplay} is required`);
              foundError = true;
              break;
            }
          }
        }

        if (foundError) {
          this.setState({
            dialogConfirmation: false,
            loadingEdit: false,
          });

          foundError = false;

          return;
        }
      }

      let data = {
        uid,
        account: {
          username,
          firstname,
          displayName,
          lastname,
          phone,
          email,
          gender: gender.name,
          birth,
          streetName,
          unitNumber,
          postalCode,
          cityCountry,
          building,
          blockNumber,
          lastNric,
          dementiaDetail: {
            language: language.name,
            typeDementia: typeOfDementia.name,
            stageDementia: stageOfDementia.name,
          },
          dementiaHospital: {
            hospitalName,
            hospitalDepartment,
            appointment: appointment.name,
            symptoms,
          },
          lastUpdatedBy: this.props.account.payload.data.account.email,
          existingDementiaSingapore: haveUsed.value,
          prefixCountryNum,
          homeNumber,
          jobTitle,
          department,
        },
      };

      let otherAccountData = {
        uid: otherUid,
        account: {
          firstname: otherFirstname,
          lastname: otherLastname,
          phone: parseInt(otherPhone),
          email: otherEmail,
          gender: otherGender.name,
          birth: otherBirth,
          dementiaDetail: {
            language: otherLanguage.name,
          },

          // tags parameter
          tagsId,
          relationship,
        },
      };

      // edit user itself
      resultEdit.payload = await editUser(data); // Promise.all([editUser(data), editUser(otherAccountData)])
      resultEdit.payload = resultEdit.payload;
      if (resultEdit.payload.type === "ERROR")
        this.setState({ errorMsg: resultEdit.payload.data.message });
      ProccessData(resultEdit, "edit user")
        .then((res) => {
          this.setState({
            loadingEdit: false,
          });
          this.onDialogInfo("Account Updated");
          this.props.history.go(-2);
        })
        .catch((err) => {
          this.onDialogInfo(err.message);
          this.setState({
            dialogConfirmation: false,
            loadingEdit: false,
          });
        });
    } catch (e) {
      this.onDialogInfo(e.message);
      this.setState({
        loadingEdit: false,
      });
    }
  };

  _onOkDialog() {
    switch (this.state.dialogTitle) {
      case "Delete Account":
        this.deleteUser(this.state.uid);
        break;
      case "Reject Account":
        this.setState({ dialogReject: true });
        break;
      case "Approve Account":
        this._onUpdateAccountStatus(
          ACCOUNT_STATUS.PENDING_CARDS,
          "Approve account"
        );
        break;
      case "Send Card":
        this._onUpdateAccountStatus(ACCOUNT_STATUS.COMPLETED, "Send Card");
        break;
      case "Account Expired":
        this._onUpdateAccountStatus(
          ACCOUNT_STATUS.EXPIRED,
          "Set account to expire"
        );
        break;
      case "Delete Tag Account Relationship":
        this.deleteTagAccount(this.state.idTag);
        break;
      default:
        null;
    }
  }

  async _onUpdateAccountStatus(accountStatus, action) {
    this.setState({ loadingAccountStatus: true });
    let newError = new Error("ERROR");
    try {
      let resultUpdate = {};
      resultUpdate.fetching = false;
      resultUpdate.payload = await updateAccountStatus({
        uid: this.state.user.uid,
        accountStatus,
        processedBy:
          accountStatus === ACCOUNT_STATUS.COMPLETED
            ? `${this.props.account.payload.data.account.email}`
            : undefined,
        isArchived: 0,
      });
      ProccessData(resultUpdate, action)
        .then((res) => {
          this.setState({
            dialogConfirmation: false,
            dialogReject: false,
            loadingAccountStatus: false,
          });
          this.onDialogInfo("Success");
          this.props.getUsers({ data: {} });
          this.props.history.goBack();
        })
        .catch((err) => {
          this.onDialogInfo(err);
          this.setState({
            dialogConfirmation: false,
            dialogReject: false,
            loadingAccountStatus: false,
          });
        });
    } catch (e) {
      this.onDialogInfo(e);
      this.setState({
        loadingAccountStatus: false,
        dialogReject: false,
        dialogConfirmation: false,
      });
    }
  }

  async _onGetFeatureAnaylitcUser(uid) {
    try {
      const result = await getFeatureAnalyticUser(uid);
      if (result.data[0]) {
        this.setState({ userAnalytic: result.data[0] });
      }
    } catch (e) {}
  }

  onDialogInfo = (val) => {
    this.setState({ infoDescription: val, infoDialogShow: true });
  };

  onTagAccount = async ({
    user,
    successTagMessage,
    relationship,
    styleSuccess,
  }) => {
    const { uid, type } = this.state;

    this.setState({ loadingTag: true });
    try {
      let resultGet = {};
      resultGet.fetching = false;
      resultGet.payload = await tagAccount({
        data: {
          uidTags: this.state.uid,
          uidTagged: user.uid || user.partnerUid,
          status: "ACCEPTED",
          relationship,
          tagFrom: "ADMIN",
        },
      });
      ProccessData(resultGet, "tag user")
        .then(async ({ data }) => {
          this.setState({
            dialogSuccessTag: true,
            successTagMessage,
            styleSuccess,
          });

          const pushNotification = this.sendPushNotification({
            text: `A new ${
              type === "CAREGIVER" ? "Loved One" : "Caregiver"
            } has been tagged to your account!`,
            name: `ADMIN_TAGS_${uid}_${user.uid}`,
            screenTitle: `${
              type === "CAREGIVER" ? "My Loved Ones" : "My Caregiver"
            }`,
            uidTarget: uid,
          });

          const pushNotification2 = this.sendPushNotification({
            text: `A new ${
              user.type === "CAREGIVER" ? "Loved One" : "Caregiver"
            } has been tagged to your account!`,
            name: `ADMIN_TAGS_${user.uid}_${uid}`,
            screenTitle: `${
              user.type === "CAREGIVER" ? "My Loved Ones" : "My Caregiver"
            }`,
            uidTarget: user.uid,
          });
        })
        .catch((err) => {
          alert(err ? err : "ERROR");
        });
    } catch (e) {
      alert("CONNECTION ERROR " + e);
    }

    this.setState({
      dialogTagCaregiver: false,
      dialogTagDementia: false,
      loadingTag: false,
      dialogTransfer: { show: false },
    });
  };

  applyAsCaregiver = async () => {
    try {
      const { account } = this.props.account.payload.data || {};
      const { user, otherAccount } = this.state;
      let isTagged =
        otherAccount &&
        otherAccount.some(
          (data) =>
            (data.uidTagged === account.uid && data.status === `ACCEPTED`) ||
            (data.uidTags === account.uid && data.status === `ACCEPTED`)
        );

      if (isTagged) {
        alert(`This user has already been tagged`);
      } else {
        let resultGet = {};
        resultGet.fetching = false;
        resultGet.payload = await tagAccount({
          data: {
            uidTags: account.uid,
            uidTagged: user.uid,
            status: "ACCEPTED",
            relationship: "Caseworker",
            tagFrom: "ADMIN",
          },
        });
        ProccessData(resultGet, "tag user")
          .then(async ({ data }) => {
            this.setState({
              dialogSuccessTag: true,
              successTagMessage:
                "Your Organisation has been appointed as PLWD’s Point of Contact",
            });

            const pushNotification = this.sendPushNotification({
              text: `A new Caregiver has been tagged to your account!`,
              name: `ADMIN_TAGS_${account.uid}_${user.uid}`,
              screenTitle: "My Caregiver",
              uidTarget: user.uid,
            });
          })
          .catch((err) => {
            alert(err ? err : "ERROR");
          });
      }
    } catch (e) {
      alert("CONNECTION ERROR " + e);
    }
  };

  deleteTagAccount = async (id) => {
    this.setState({ loading: true });
    try {
      let resultDelete = {};
      resultDelete.fetching = false;
      resultDelete.payload = await deleteOtherAccount({ id });
      ProccessData(resultDelete, "delete tag account")
        .then(async (res) => {
          this.setState({
            dialogConfirmation: false,
            loading: false,
            dialogSuccessTag: true,
            successTagMessage: "Successfully Delete Account Tag",
          });
        })
        .catch((err) => {
          alert(err ? err : "ERROR");
          this.setState({
            dialogConfirmation: false,
            loading: false,
          });
        });
    } catch (e) {
      alert("CONNECTION ERROR " + e);
      this.setState({
        loading: false,
        dialogConfirmation: false,
      });
    }
  };

  sendPushNotification = async ({ text, name, uidTarget, screenTitle }) => {
    const { uid, type } = this.state;

    const data = {
      title: `New Tags Established`,
      text,
      name,
      platform: "Android, iOS",
      type: "ADMIN_TAGS",
      delivery: `NOW`,
      frequency: null,
      dateSend: moment(new Date()),
      uidTarget,
      dataNotification: {
        targetScreen: "MyOtherAccountScreen",
        screenTitle,
      },
    };

    let pushNotification = await addNotification(data);

    return pushNotification;
  };

  _clickDetailUser = (item) => {
    if (item.id) {
      this.setState({ detailUser: true, dataDetailUser: item });
    } else {
      this.setState({ detailUser: false, ...item.item });
    }
  };

  _getUsersss = async (item) => {
    const { singleAccount, otherAccount } = this.state;
    let id = otherAccount[0].id;

    this.setState({ loadingTag: true });
    try {
      let resultGet = {};
      resultGet.fetching = false;
      resultGet.payload = await tagAccount({
        data: {
          uidTags: singleAccount.uid,
          uidTagged: item && item.uid,
          status: "ACCEPTED",
          tagFrom: "ADMIN",
        },
      });
      ProccessData(resultGet, "tag user")
        .then(async ({ data }) => {
          this.setState({
            dialogSuccessTag: true,
            successTagMessage: "Transfer is now processed by CARA admin",
          });

          const pushNotification = this.sendPushNotification({
            text: `A new ${
              singleAccount.type === "CAREGIVER" ? "Loved One" : "Caregiver"
            } has been tagged to your account!`,
            name: `ADMIN_TAGS_${singleAccount.uid}_${item.uid}`,
            screenTitle: `${
              singleAccount.type === "CAREGIVER"
                ? "My Loved Ones"
                : "My Caregiver"
            }`,
            uidTarget: singleAccount.uid,
          });

          const pushNotification2 = this.sendPushNotification({
            text: `A new ${
              item.type === "CAREGIVER" ? "Loved One" : "Caregiver"
            } has been tagged to your account!`,
            name: `ADMIN_TAGS_${item.uid}_${singleAccount.uid}`,
            screenTitle: `${
              item.type === "CAREGIVER" ? "My Loved Ones" : "My Caregiver"
            }`,
            uidTarget: item.uid,
          });

          let resultDelete = {};
          resultDelete.fetching = false;
          resultDelete.payload = await deleteOtherAccount({ id });
          ProccessData(resultDelete, "delete tag account")
            .then(async (res) => {
              this.setState({
                loading: false,
              });
            })
            .catch((err) => {
              alert(err ? err : "ERROR");
              this.setState({
                dialogConfirmation: false,
                loading: false,
              });
            });
        })
        .catch((err) => {
          alert(err ? err : "ERROR");
        });
    } catch (e) {
      alert("CONNECTION ERROR " + e);
    }

    this.setState({
      dialogTagCaregiver: false,
      dialogTagDementia: false,
      loadingTag: false,
      dialogTransfer: { show: false },
      loading: false,
    });
  };

  render() {
    const { history, location, match } = this.props;
    const {
      user,
      createdAt,
      username,
      dialogConfirmation,
      loading,
      languageDropdown,
      language,
      languageData,
      stageOfDementiaDropdown,
      stageOfDementia,
      stageOfDementiaData,
      typeOfDementiaDropdown,
      typeOfDementia,
      typeOfDementiaData,
      firstname,
      lastname,
      lastNric,
      genderDropdown,
      gender,
      genderData,
      birth,
      streetName,
      unitNumber,
      postalCode,
      building,
      blockNumber,
      cityCountry,
      hospitalName,
      hospitalDepartment,
      symptoms,
      email,
      phone,
      appointmentDropdown,
      appointment,
      appointmentData,
      loadingEdit,
      dialogTitle,
      dialogDescription,
      dialogOkText,
      okBorderColor,
      okTextColor,
      titleColor,
      dialogCancelText,
      okBordered,
      dialogReject,
      loadingAccountStatus,
      otherFirstname,
      otherLastname,
      relationship,
      otherGender,
      otherBirth,
      otherLanguage,
      otherEmail,
      otherPhone,
      otherGenderDropdown,
      otherLanguageDropdown,
      otherAccount,
      uid,
      haveUsedDropdown,
      haveUsedData,
      haveUsed,
      userInterest,
      prefixCountryNum,
      homeNumber,
      howFindCara,
      displayName,
      dialogTagCaregiver,
      dialogTagDementia,
      dialogSuccessTag,
      successTagMessage,
      loadingTag,
      infoDialogShow,
      infoDescription,
      partnerDetail,
      dialogTransfer,
      dialogTagPartner,
      transferDataPLWD,
      singleAccount,
      styleSuccess,
      detailUser,
      dataDetailUser,
      errorMsg,
      jobTitle,
      department,
      userAnalytic,
    } = this.state;
    const { accountStatus, metadata } = user || "";

    const status =
      accountStatus === ACCOUNT_STATUS.INCOMPLETE
        ? "New User"
        : accountStatus === ACCOUNT_STATUS.COMPLETED
        ? "Completed"
        : accountStatus === ACCOUNT_STATUS.PENDING_APPROVAL
        ? "Pending approval"
        : accountStatus === ACCOUNT_STATUS.PENDING_CARDS
        ? "Pending cards"
        : accountStatus === ACCOUNT_STATUS.REJECTED
        ? "Rejected"
        : accountStatus === ACCOUNT_STATUS.TO_FOLLOW_UP
        ? "To Follow-up"
        : accountStatus === ACCOUNT_STATUS.EXPIRED
        ? "Expired"
        : "";
    const isDeleted = metadata && metadata.userDeleted;
    const textDeleted = metadata && metadata.userDeletedReason;
    const color =
      accountStatus === ACCOUNT_STATUS.INCOMPLETE ||
      accountStatus === ACCOUNT_STATUS.REJECTED ||
      accountStatus === ACCOUNT_STATUS.EXPIRED ||
      accountStatus === ACCOUNT_STATUS.TO_FOLLOW_UP
        ? Colors.color13
        : accountStatus === ACCOUNT_STATUS.COMPLETED
        ? Colors.color17
        : Colors.color18;

    const { action } = this.props.location.state || "";

    // this.getOtherAccount(user)
    let isCaregiverDetail = user && user.type === "CAREGIVER";

    const isUserDetails = location.pathname === `/user/${match.params.id}`;
    const dataUser = transferDataPLWD ? singleAccount : user;

    const { account } = this.props.account.payload.data || {};
    let newUsername =
      username && username === "TemporaryUsernameCGUnderPartner"
        ? ""
        : username;

    return (
      <StyledUserDetail>
        <Dialog
          isVisible={dialogConfirmation}
          isClose={() => this.setState({ dialogConfirmation: false })}
          title={dialogTitle}
          showXClose={false}
          additionalStyle={{ titleStyle: ` color: ${titleColor}; ` }}
          description={dialogDescription}
          width={`37%`}
          height={`auto`}
          cancelText={dialogCancelText}
          okText={loading || loadingAccountStatus ? `Loading...` : dialogOkText}
          okBorderColor={okBorderColor}
          okTextColor={okTextColor}
          onOk={this._onOkDialog}
          okBordered={okBordered}
        />
        <Dialog
          isVisible={dialogReject}
          isClose={() => this.setState({ dialogReject: false })}
          title="Reject Account"
          description={`Tell the reason why you want to reject this account?`}
          width={`37%`}
          height={`auto`}
          cancelText={`Back`}
          okText={loadingAccountStatus ? "Loading..." : `Submit`}
          onOk={() =>
            this._onUpdateAccountStatus(
              ACCOUNT_STATUS.REJECTED,
              "Reject account"
            )
          }
          okBordered={false}
          okBackgroundColor={Colors.color10}
          okTextColor={Colors.color3}
        >
          {/* Dialog info start */}
          <DialogInfo
            isVisible={infoDialogShow}
            textInfo={infoDescription}
            onOkButton={() => this.setState({ infoDialogShow: false })}
          />
          {/* Dialog info end  */}
          <div>
            <Input
              multiline
              containerStyle={` margin-left: 14px; width: 96%; `}
            />
          </div>
        </Dialog>
        <TagCaregiver
          dialogProps={{
            isVisible: dialogTagCaregiver,
            isClose: () => this.setState({ dialogTagCaregiver: false }),
          }}
          otherAccount={otherAccount}
          loadingTag={loadingTag}
          onTagAccount={this.onTagAccount}
        />
        <TagDementia
          dialogProps={{
            isVisible: dialogTagDementia,
            isClose: () => this.setState({ dialogTagDementia: false }),
          }}
          otherAccount={otherAccount}
          loadingTag={loadingTag}
          onTagAccount={this.onTagAccount}
          user={user}
        />
        <TagPartner
          dialogProps={{
            isVisible: dialogTagPartner,
            isClose: () => this.setState({ dialogTagPartner: false }),
          }}
          otherAccount={otherAccount}
          loadingTag={loadingTag}
          onTagAccount={this.onTagAccount}
        />
        <Dialog
          isVisible={dialogSuccessTag}
          isClose={() => {
            window.location.reload();
            this.setState({ dialogSuccessTag: false });
          }}
          title={successTagMessage}
          showXClose={false}
          additionalStyle={{
            titleStyle: ` color: ${Colors.color2}; text-align: center;  `,
            buttonContainerStyle: ` margin: 35px 120px 10px 120px; `,
          }}
          height={`auto`}
          okText="OK"
          okBordered={false}
          okBackgroundColor={styleSuccess ? Colors.color8 : Colors.color10}
          okTextColor={Colors.color3}
          onOk={() => {
            window.location.reload();
            this.setState({ dialogSuccessTag: false });
          }}
        />
        <DialogTransfer
          dialogProps={{
            isVisible: dialogTransfer.show,
            isClose: () =>
              this.setState({
                dialogTransfer: {
                  ...dialogTransfer,
                  show: false,
                },
                singleAccount: null,
                transferDataPLWD: false,
              }),
            title: dialogTransfer.detail && dialogTransfer.detail.title,
            type: dialogTransfer.detail && dialogTransfer.detail.type,
            searchLabel:
              dialogTransfer.detail && dialogTransfer.detail.searchLabel,
          }}
          user={dataUser}
          otherAccount={otherAccount}
          loadingTag={loadingTag}
          onTagAccount={this.onTagAccount}
          selectedUser={this._getUsersss}
        />
        <DialogDetailUser
          isVisible={detailUser}
          user={dataDetailUser}
          isClose={() => this.setState({ detailUser: false })}
          mainUser={user}
        />
        <Container className="wrapper">
          {user && (
            <div>
              <Row
                align="center"
                style={{ marginBottom: isUserDetails ? 0 : "40px" }}
              >
                <Col lg={0.6} xs={1}>
                  <img
                    src={Images.back}
                    className="icon-back"
                    onClick={() => history.goBack()}
                  />
                </Col>
                <Col lg={11.4} xs={7}>
                  <Row align="center" justify="between">
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="title-wrapper">
                        <h1 className="title-name">{`${user.firstname} ${
                          user.lastname ? user.lastname : ""
                        }`}</h1>
                        {isUserDetails && (
                          <h1 className="title-status">
                            {isDeleted ? "Reason for Deletion: " : "Status:"}
                            <span
                              style={{
                                color: isDeleted ? Colors.color13 : color,
                              }}
                            >
                              {isDeleted ? textDeleted : status}
                            </span>
                          </h1>
                        )}
                      </div>
                      <div className="action">
                        {isUserDetails ? (
                          <>
                            {/* <div onClick={() => this.setState({ dialogTransfer: {
                                  ...dialogTransfer,
                                  show: true,
                                  ...transferCG,
                                }, })}>
                                  <div className="transfer-wrapper">
                                    <img src={Images.transfer} className="transfer" />
                                  </div>
                                </div> */}
                            {account.type !== USER_TYPE.VIEWER &&
                              action !== "EDIT" &&
                              !isDeleted && (
                                <div
                                  onClick={() =>
                                    this.props.history.push({
                                      pathname: `/user/${uid}`,
                                      state: {
                                        action: "EDIT",
                                      },
                                    })
                                  }
                                >
                                  <div className="edit-wrapper">
                                    <img src={Images.edit} className="edit" />
                                  </div>
                                </div>
                              )}
                            {account.type === USER_TYPE.SUPERADMIN ? (
                              <div
                                onClick={() =>
                                  this.setState({
                                    dialogConfirmation: true,
                                    ...deleteDialogState,
                                  })
                                }
                              >
                                <div className="trash-wrapper">
                                  <img src={Images.trash} className="delete" />
                                </div>
                              </div>
                            ) : (
                              account.type === USER_TYPE.PARTNER &&
                              user.type === USER_TYPE.CAREGIVER &&
                              !isDeleted && (
                                <div
                                  onClick={() =>
                                    this.setState({
                                      dialogConfirmation: true,
                                      ...deleteDialogState,
                                    })
                                  }
                                >
                                  <div className="trash-wrapper">
                                    <img
                                      src={Images.trash}
                                      className="delete"
                                    />
                                  </div>
                                </div>
                              )
                            )}
                          </>
                        ) : null}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                {isUserDetails ? (
                  <>
                    <Col offset={{ lg: 0.6, xs: 1 }} xs={12}>
                      <h1 className="subtitle">Personal Details</h1>
                    </Col>
                    {action !== "EDIT" &&
                      this.renderList(
                        "createdAt",
                        "Sign-up Date",
                        moment(createdAt).format("DD MMMM YYYY"),
                        "listDark"
                      )}
                    {[
                      this.renderList("username", "Username", newUsername),
                      this.renderList(
                        "firstname",
                        "First Name",
                        firstname,
                        "list-dark"
                      ),
                      this.renderList("lastname", "Last Name", lastname),
                      user.type == USER_TYPE.CAREGIVER &&
                      user.masterPartnerUid != null
                        ? null
                        : this.renderList(
                            "displayName",
                            "Display Name",
                            displayName
                          ),
                      this.renderList(
                        "gender",
                        "Gender",
                        gender.name ? gender.name : "",
                        "list-dark",
                        {
                          showDropdown: genderDropdown,
                          dropdownData: genderData,
                          dropdownPropertyName: "name",
                          onClick: () =>
                            this.onPressDropdown(
                              "genderDropdown",
                              genderDropdown
                            ),
                          onSelectDropdown: (gender) => {
                            this.setState({ genderDropdown: false, gender });
                          },
                        }
                      ),
                      this.renderList(
                        "lastNric",
                        "Last 4 characters of NRIC",
                        lastNric,
                        "",
                        {},
                        { maxLength: "4" }
                      ),
                      this.renderList(
                        "birth",
                        "Date of Birth",
                        moment(birth).format("DD MMMM YYYY"),
                        "list-dark",
                        {},
                        {
                          type: "date",
                          value: moment(birth).format("YYYY-MM-DD"),
                        }
                      ),
                    ]}
                    {user.type === "DEMENTIA" && [
                      this.renderList(
                        "",
                        "Preferred Language of Communication",
                        language.name ? language.name : "",
                        "",
                        {
                          showDropdown: languageDropdown,
                          dropdownData: languageData,
                          dropdownPropertyName: "name",
                          onClick: () =>
                            this.onPressDropdown(
                              "languageDropdown",
                              languageDropdown
                            ),
                          onSelectDropdown: (language) => {
                            this.setState({
                              languageDropdown: false,
                              language,
                            });
                          },
                        }
                      ),
                      this.renderList(
                        "",
                        "Type of Dementia",
                        typeOfDementia.name ? typeOfDementia.name : "",
                        "list-dark",
                        {
                          showDropdown: typeOfDementiaDropdown,
                          dropdownData: typeOfDementiaData,
                          dropdownPropertyName: "name",
                          onClick: () =>
                            this.onPressDropdown(
                              "typeOfDementiaDropdown",
                              typeOfDementiaDropdown
                            ),
                          onSelectDropdown: (typeOfDementia) => {
                            this.setState({
                              typeOfDementiaDropdown: false,
                              typeOfDementia,
                            });
                          },
                        }
                      ),
                      this.renderList(
                        "",
                        "Stage of Dementia",
                        stageOfDementia.name ? stageOfDementia.name : "",
                        "",
                        {
                          showDropdown: stageOfDementiaDropdown,
                          dropdownData: stageOfDementiaData,
                          dropdownPropertyName: "name",
                          onClick: () =>
                            this.onPressDropdown(
                              "stageOfDementiaDropdown",
                              stageOfDementiaDropdown
                            ),
                          onSelectDropdown: (stageOfDementia) => {
                            this.setState({
                              stageOfDementiaDropdown: false,
                              stageOfDementia,
                            });
                          },
                        }
                      ),
                    ]}
                    {console.log()}
                    {[
                      this.renderList(
                        "email",
                        "Email Address",
                        email,
                        "list-dark"
                      ),
                      this.renderList(
                        "phone",
                        "Phone Number",
                        action === "EDIT"
                          ? phone
                          : `${
                              phone !== "0" && phone
                                ? `${
                                    prefixCountryNum ? prefixCountryNum : ""
                                  } ${phone}`
                                : `-`
                            }`,
                        "list-dark",
                        {},
                        { type: "number" }
                      ),
                      this.renderList(
                        "userInterest",
                        "Interests",
                        userInterest ? userInterest.join(", ") : `-`,
                        "list-dark",
                        {},
                        { type: "text", readOnly: true }
                      ),
                      this.renderList(
                        "homeNumber",
                        "Home Number",
                        homeNumber,
                        ""
                      ),
                      this.renderList(
                        "howDidYouFindAboutCara",
                        "How Did You Find About CARA",
                        howFindCara && howFindCara.length > 0
                          ? howFindCara.join(", ")
                          : null,
                        "",
                        {},
                        { type: "text", readOnly: true }
                      ),
                      this.props.account.payload.data.account.type ==
                      USER_TYPE.PARTNER
                        ? [
                            this.renderList(
                              "jobTitle",
                              "Job Title",
                              jobTitle ? jobTitle : "",
                              "list-dark"
                            ),
                            this.renderList(
                              "department",
                              "Department",
                              department ? department : "",
                              ""
                            ),
                          ]
                        : "",
                    ]}
                    <Col offset={{ lg: 0.6, xs: 1 }} xs={12}>
                      <h1 className="subtitle">Residing Address</h1>
                    </Col>
                    {[
                      this.renderList(
                        "streetName",
                        "Street Name",
                        streetName,
                        "list-dark"
                      ),
                      this.renderList("unitNumber", "Unit Number", unitNumber),
                      this.renderList("building", "Building", building),
                      this.renderList(
                        "blockNumber",
                        "Block Number",
                        blockNumber
                      ),
                      this.renderList(
                        "postalCode",
                        "Postal Code",
                        postalCode,
                        "list-dark"
                      ),
                      this.renderList(
                        "cityCountry",
                        "City/Country",
                        cityCountry
                      ),
                    ]}
                    {!isCaregiverDetail &&
                      action !== "EDIT" &&
                      user.metadata &&
                      user.metadata.cardAddress && (
                        <Row
                          style={{ width: "100%", marginLeft: ".1px" }}
                          className={`list-container`}
                        >
                          {/* <Col offset={{ lg: 0.6, xs: 1 }} xs={12}>
                            <Row>
                              <Col xs={12}>
                                <div>
                                  <div class="checkbox-container">
                                    <label class="checkbox-label">
                                        <input type="checkbox" 
                                          // onClick={(e) => {
                                          //   setState(state => ({
                                          //     ...state,
                                          //     accountData: {
                                          //       ...state.accountData,
                                          //       cardAddress: e.target.checked
                                          //     }
                                          //   }))
                                          // }} checked={cardAddress} 
                                          checked
                                        />
                                        <span class="checkbox-custom rectangular"></span>
                                    </label>
                                    <div class="checkbox-text">Please check this box if you would like CARA Physical card to be sent to another address.</div>
                                  </div>
                                </div>
                              </Col>
                              <Col xs={7}>
                              </Col>
                            </Row>
                          </Col> */}
                          <Col offset={{ lg: 0.6, xs: 1 }} xs={12}>
                            <h1 className="subtitle">Card Address</h1>
                          </Col>
                          {(() => {
                            const { cardAddress } = user.metadata || {};

                            return [
                              this.renderList(
                                "address",
                                "Address",
                                cardAddress.partnerAddress &&
                                  cardAddress.partnerAddress,
                                "list-dark"
                              ),
                              this.renderList(
                                "unitNumber",
                                "Unit Number",
                                cardAddress.partnerBranch &&
                                  cardAddress.partnerBranch
                              ),
                              this.renderList(
                                "postalCode",
                                "Postal Code",
                                cardAddress.partnerPostalCode &&
                                  cardAddress.partnerPostalCode,
                                "list-dark"
                              ),
                            ];
                          })()}
                        </Row>
                      )}
                    {user.type === "DEMENTIA" && [
                      <Col offset={{ lg: 0.6, xs: 1 }} xs={12}>
                        <h1 className="subtitle">Screening Questions</h1>
                      </Col>,
                      this.renderList(
                        "hospitalName",
                        "Hospital Name",
                        hospitalName,
                        "list-dark"
                      ),
                      this.renderList(
                        "hospitalDepartment",
                        "Hospital Department",
                        hospitalDepartment,
                        "list-dark"
                      ),

                      this.renderList(
                        "haveUsed",
                        "Have Used App Before",
                        haveUsed.name ? haveUsed.name : "",
                        "",
                        {
                          showDropdown: haveUsedDropdown,
                          dropdownData: haveUsedData,
                          dropdownPropertyName: "name",
                          onClick: () =>
                            this.onPressDropdown(
                              "haveUsedDropdown",
                              haveUsedDropdown
                            ),
                          onSelectDropdown: (haveUsed) => {
                            this.setState({
                              haveUsedDropdown: false,
                              haveUsed,
                            });
                          },
                        }
                      ),
                      this.renderList(
                        "symptoms",
                        "2 signs & symptoms",
                        symptoms,
                        "list-dark"
                      ),
                    ]}
                    {action !== "EDIT" &&
                      this.renderList(
                        "caraPro",
                        "Care Circle",
                        user.metadata
                          ? user.metadata.caraPro
                            ? "Yes"
                            : "No"
                          : "No",
                        "list-dark"
                      )}
                  </>
                ) : (
                  <>
                    {[
                      this.renderList(
                        "firstname",
                        "First Name",
                        firstname,
                        "list-dark"
                      ),
                      this.renderList("lastname", "Last Name", lastname),
                      this.renderList(
                        "gender",
                        "Gender",
                        gender.name ? gender.name : "-",
                        "list-dark"
                      ),
                      this.renderList("birth", "Date of Birth", birth),
                      this.renderList(
                        "language",
                        "Preferred Language of Communication",
                        language.name ? language.name : "-",
                        "list-dark"
                      ),
                      this.renderList("email", "Email Address", email),
                      this.renderList(
                        "phone",
                        "Phone Number",
                        phone,
                        "list-dark"
                      ),
                    ]}
                  </>
                )}
              </Row>
            </div>
          )}
        </Container>
        <div style={{ height: "20px" }} />
        {isUserDetails &&
          account.type !== USER_TYPE.ADMIN &&
          otherAccount.length > 0 &&
          action !== "EDIT" &&
          otherAccount.map((item, index) => {
            const {
              firstname,
              lastname,
              gender,
              birth,
              dementiaDetail,
              email,
              phone,
            } = item.Account;
            return (
              <>
                <Container className="wrapper">
                  {user && (
                    <Row>
                      <Col
                        offset={{ lg: 0.6, xs: 1 }}
                        xs={11}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          justifyContent: "space-between",
                        }}
                      >
                        <div>
                          <h1 className="subtitle" style={{ marginTop: 0 }}>
                            {isCaregiverDetail
                              ? `Person Living with Dementia ${
                                  index > 0 ? index + 1 : ""
                                }`
                              : account.type === USER_TYPE.PARTNER &&
                                !isCaregiverDetail
                              ? `Care Professional ${
                                  index > 0 ? index + 1 : ""
                                }`
                              : `Caregiver ${index > 0 ? index + 1 : ""}`}
                          </h1>
                        </div>
                        {account.type !== USER_TYPE.PARTNER &&
                          account.type !== USER_TYPE.VIEWER &&
                          account.type !== USER_TYPE.ADMIN && (
                            <div
                              className="trash-wrapper"
                              onClick={() =>
                                this.setState({
                                  dialogConfirmation: true,
                                  idTag: item.id,
                                  ...deleteTagDialogState,
                                })
                              }
                            >
                              <img src={Images.trash} className="delete" />
                            </div>
                          )}
                      </Col>
                      {item.Account.type === USER_TYPE.PARTNER
                        ? this.renderList(
                            "organizationName",
                            "Organization Name",
                            partnerDetail
                              ? partnerDetail.organizationName
                              : otherAccount.length > 0 &&
                                otherAccount[0].Account.type ===
                                  USER_TYPE.PARTNER
                              ? otherAccount[0].Account.partnerDetail
                                  .organizationName
                              : null,
                            "list-dark"
                          )
                        : [
                            this.renderList(
                              "otherFirstname",
                              "First Name",
                              firstname,
                              "list-dark"
                            ),
                            this.renderList(
                              "otherLastname",
                              "Last Name",
                              lastname
                            ),
                            this.renderList(
                              "relationship",
                              `Relationship to ${
                                user.type === "DEMENTIA"
                                  ? "Person with Dementia"
                                  : "Caregiver"
                              }`,
                              item.relationship,
                              "list-dark"
                            ),
                            this.renderList("otherGender", "Gender", gender),
                            this.renderList(
                              "otherBirth",
                              "Date of Birth",
                              moment(birth).format("DD MMMM YYYY"),
                              "list-dark"
                            ),
                            isCaregiverDetail &&
                              this.renderList(
                                "otherLanguage",
                                "Preferred Language of Communication",
                                dementiaDetail ? dementiaDetail.language : "-"
                              ),
                            this.renderList(
                              "otherEmail",
                              "Email Address",
                              email,
                              "list-dark"
                            ),
                            this.renderList(
                              "otherPhone",
                              "Phone Number",
                              phone,
                              "",
                              {},
                              { type: "number" }
                            ),
                          ]}
                    </Row>
                  )}
                </Container>
                <div style={{ height: "20px" }} />
              </>
            );
          })}
        {((isUserDetails && account.type === USER_TYPE.ADMIN) ||
          account.type === USER_TYPE.SUPERADMIN ||
          account.type === USER_TYPE.VIEWER) &&
          !isDeleted && (
            <>
              <Container className="wrapper">
                {user && (
                  <div>
                    <Row>
                      <Col
                        xs={11}
                        style={{
                          display: "flex",
                          alignItems: "flex-start",
                          paddingLeft: "30px",
                        }}
                      >
                        <div>
                          <h1 className="subtitle" style={{ marginTop: 0 }}>
                            {isCaregiverDetail
                              ? `Person Living with Dementia`
                              : account.type === USER_TYPE.PARTNER &&
                                !isCaregiverDetail
                              ? `Care Professional`
                              : `Caregiver`}
                          </h1>
                        </div>
                      </Col>
                    </Row>
                    <Row
                      style={{
                        margin: "10px",
                        justifyContent: "space-between",
                      }}
                    >
                      {otherAccount.length > 0 &&
                        action !== "EDIT" &&
                        otherAccount.map((item, index) => {
                          const { relationship } = item;
                          const {
                            firstname,
                            lastname,
                            gender,
                            birth,
                            dementiaDetail,
                            email,
                            phone,
                            partnerDetail,
                          } = item.Account || {};

                          return (
                            <ContainerPLWD>
                              <div style={{ padding: "30px 20px 20px 20px" }}>
                                <Row>
                                  <Col xs={10}>
                                    <div
                                      onClick={() =>
                                        this._clickDetailUser(item)
                                      }
                                    >
                                      <h4 className="textTitlePlwd">
                                        {partnerDetail
                                          ? partnerDetail.pocName
                                          : `${firstname} ${
                                              lastname ? lastname : ""
                                            }`}
                                      </h4>
                                      <h4 className="textPlwdd">
                                        {relationship ? relationship : "-"}
                                      </h4>
                                    </div>
                                  </Col>
                                  <Col>
                                    <Row>
                                      {account.type !== USER_TYPE.PARTNER &&
                                        account.type !== USER_TYPE.ADMIN &&
                                        account.type !== USER_TYPE.VIEWER && (
                                          <div
                                            className="trash-wrapper"
                                            onClick={() =>
                                              this._clickDetailUser({
                                                item: {
                                                  dialogConfirmation: true,
                                                  idTag: item.id,
                                                  ...deleteTagDialogState,
                                                },
                                              })
                                            }
                                          >
                                            <img
                                              src={Images.trash}
                                              className="delete"
                                            />
                                          </div>
                                        )}
                                      {!isUserDetails && (
                                        <div
                                          className="transfer-wrapper"
                                          style={{ marginLeft: "5px" }}
                                          onClick={() =>
                                            this._clickDetailUser({
                                              item: {
                                                dialogTransfer: {
                                                  ...dialogTransfer,
                                                  show: true,
                                                  ...transferPLWD,
                                                },
                                                singleAccount: item.Account,
                                                transferDataPLWD: true,
                                              },
                                            })
                                          }
                                        >
                                          <img
                                            src={Images.transfer}
                                            className="transfer"
                                          />
                                        </div>
                                      )}
                                    </Row>
                                  </Col>
                                </Row>

                                <div className="textPlwdd">
                                  {email ? email : "-"}
                                </div>
                                <div className="textPlwdd">
                                  {phone ? phone : "-"}
                                </div>
                              </div>
                            </ContainerPLWD>
                          );
                        })}
                    </Row>
                  </div>
                )}
              </Container>
              <div style={{ height: "20px" }} />
            </>
          )}
        {!isUserDetails && (
          <>
            <Container className="wrapper">
              {user && (
                <div>
                  <Row>
                    <Col
                      xs={11}
                      style={{
                        display: "flex",
                        alignItems: "flex-start",
                        paddingLeft: "30px",
                        paddingBottom: "20px",
                      }}
                    >
                      <div>
                        <h1 className="titlePLWD">{`
                            ${user.firstname} ${
                          user.lastname ? user.lastname : ""
                        }'s ${
                          isCaregiverDetail ? "PLWD" : "Care Professional"
                        }`}</h1>
                      </div>
                    </Col>
                  </Row>
                  <Row
                    style={{ margin: "10px", justifyContent: "space-between" }}
                  >
                    {otherAccount.length > 0 &&
                      action !== "EDIT" &&
                      otherAccount.map((item, index) => {
                        const {
                          firstname,
                          lastname,
                          gender,
                          birth,
                          dementiaDetail,
                          email,
                          phone,
                          prefixCountryNum,
                        } = item.Account;

                        return (
                          <>
                            <ContainerPLWD>
                              <div style={{ padding: "30px 20px 20px 20px" }}>
                                <Row>
                                  <Col xs={10}>
                                    <div
                                      onClick={() =>
                                        this._clickDetailUser(item)
                                      }
                                    >
                                      <h4 className="textTitlePlwd">{`${firstname} ${
                                        lastname ? lastname : ""
                                      }`}</h4>
                                    </div>
                                  </Col>
                                  <Col>
                                    <Row>
                                      {account.type !== USER_TYPE.PARTNER && (
                                        <div
                                          className="trash-wrapper"
                                          onClick={() =>
                                            this._clickDetailUser({
                                              item: {
                                                dialogConfirmation: true,
                                                idTag: item.id,
                                                ...deleteTagDialogState,
                                              },
                                            })
                                          }
                                        >
                                          <img
                                            src={Images.trash}
                                            className="delete"
                                          />
                                        </div>
                                      )}
                                      {!isUserDetails && (
                                        <div
                                          className="transfer-wrapper"
                                          style={{ marginLeft: "5px" }}
                                          onClick={() =>
                                            this._clickDetailUser({
                                              item: {
                                                dialogTransfer: {
                                                  ...dialogTransfer,
                                                  show: true,
                                                  detail: isCaregiverDetail
                                                    ? transferCG
                                                    : transferPLWD,
                                                },
                                                singleAccount: item.Account,
                                                transferDataPLWD: true,
                                              },
                                            })
                                          }
                                        >
                                          <img
                                            src={Images.transfer}
                                            className="transfer"
                                          />
                                        </div>
                                      )}
                                    </Row>
                                  </Col>
                                </Row>

                                <div className="textPlwdd">
                                  {email ? email : "-"}
                                </div>
                                <div className="textPlwdd">
                                  {phone
                                    ? `(${prefixCountryNum}) ${phone}`
                                    : "-"}
                                </div>
                              </div>
                            </ContainerPLWD>
                          </>
                        );
                      })}
                  </Row>
                </div>
              )}
            </Container>
            <div style={{ height: "20px" }} />
          </>
        )}
        {isUserDetails && !_.isEmpty(partnerDetail) && (
          <>
            <Container className="wrapper">
              {user && (
                <Row>
                  <Col
                    offset={{ lg: 0.6, xs: 1 }}
                    xs={11}
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      justifyContent: "space-between",
                    }}
                  >
                    <div>
                      <h1
                        className="subtitle"
                        style={{ marginTop: 0 }}
                      >{`Partner`}</h1>
                    </div>
                    {/* <div className="trash-wrapper" onClick={() => this.setState({ dialogConfirmation: true, idTag: partnerDetail.partnerUid, ...deleteTagDialogState, })}>
                        <img src={Images.trash} className="delete" />
                      </div> */}
                  </Col>
                  {[
                    this.renderList(
                      "organizationName",
                      "Organization Name",
                      partnerDetail.organizationName
                        ? partnerDetail.organizationName
                        : null,
                      "list-dark"
                    ),
                  ]}
                </Row>
              )}
            </Container>
            <div style={{ height: "20px" }} />
          </>
        )}

        {!partnerDetail && !user.metadata.department && (
          <Container className="wrapper">
            {user && (
              <div>
                <Row>
                  <Col
                    xs={11}
                    style={{
                      display: "flex",
                      alignItems: "flex-start",
                      paddingLeft: "30px",
                    }}
                  >
                    <div>
                      <h1 className="subtitle" style={{ marginTop: 0 }}>
                        User Analytics
                      </h1>
                    </div>
                  </Col>
                </Row>
                <Row>
                  {[
                    this.renderList(
                      "login",
                      "Login Daily",
                      userAnalytic?.login ? userAnalytic?.login : 0,
                      "list-dark"
                    ),
                    this.renderList(
                      "benefit",
                      "Benefit Clicks",
                      userAnalytic?.benefit ? userAnalytic?.benefit : 0
                    ),
                    this.renderList(
                      "solution",
                      "Solution Clicks",
                      userAnalytic?.solution ? userAnalytic?.solution : 0,
                      "list-dark"
                    ),
                    user.type === USER_TYPE.CAREGIVER &&
                    user.metadata.MoP === false &&
                    !user.metadata.department
                      ? this.renderList(
                          "reportMissingPerson",
                          "Report Missing Person Clicks",
                          userAnalytic?.reportMissingPerson
                            ? userAnalytic?.reportMissingPerson
                            : 0
                        )
                      : null,
                    this.renderList(
                      "myRadio",
                      "My Radio Clicks",
                      userAnalytic?.myRadio ? userAnalytic?.myRadio : 0,
                      "list-dark"
                    ),
                    this.renderList(
                      "bannerContent",
                      "Banner Content Clicks",
                      userAnalytic?.bannerContent
                        ? userAnalytic?.bannerContent
                        : 0
                    ),
                  ]}
                </Row>
              </div>
            )}
          </Container>
        )}
        {user && action === "EDIT" ? (
          <div>
            {errorMsg ? (
              <p style={{ color: "red", textAlign: "center" }}>{errorMsg}</p>
            ) : null}
            <div className="button-wrapper">
              <div style={{ width: "211px" }}>
                <Button
                  text="Discard"
                  textColor={Colors.color11}
                  bordered
                  buttonStyle={` background: ${Colors.primaryBgColor}; `}
                  onClick={() => this.props.history.goBack()}
                />
              </div>
              <div style={{ width: "15px" }} />
              <div style={{ width: "211px" }}>
                <Button
                  text={loadingEdit ? "Loading..." : "Save"}
                  buttonStyle={` box-shadow: -3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color23}; `}
                  onClick={this.onEditAccount}
                  disabled={loadingEdit}
                />
              </div>
            </div>
          </div>
        ) : isUserDetails ? (
          <div className="button-wrapper">
            {/* { 
                user && user.type === 'DEMENTIA' && 
                  <>
                    <Button
                      text={`Tag new Partner as Caregiver`}
                      textColor={Colors.color11}
                      bordered
                      buttonStyle={` background: ${Colors.primaryBgColor}; `}
                      onClick={() => {
                        this.setState({
                          dialogTagPartner: true,
                        })
                      }}
                      buttonStyle={` background: transparent; width: 315px; `}
                    />
                    <div style={{ width: '15px', }} />
                  </>
              } */}
            {account.type === USER_TYPE.PARTNER && !isCaregiverDetail && (
              <>
                {/* <Button
                      text={`Apply as Caregiver`}
                      textColor={Colors.color11}
                      bordered
                      buttonStyle={` background: ${Colors.primaryBgColor}; `}
                      onClick={() => {
                        this.applyAsCaregiver()
                      }}
                      buttonStyle={` background: transparent; width: 315px; `}
                    />
                    <div style={{ width: '15px', }} /> */}
              </>
            )}
            {account.type !== USER_TYPE.PARTNER &&
              account.type !== USER_TYPE.VIEWER &&
              !isDeleted && (
                <Button
                  text={`Tag new ${
                    isCaregiverDetail
                      ? "Person Living with Dementia"
                      : "Caregiver"
                  }`}
                  textColor={Colors.color11}
                  bordered
                  buttonStyle={` background: ${Colors.primaryBgColor}; `}
                  onClick={() => {
                    let dialogName = isCaregiverDetail
                      ? "dialogTagDementia"
                      : "dialogTagCaregiver";
                    this.setState({
                      [dialogName]: true,
                    });
                  }}
                  buttonStyle={` background: transparent; width: 315px; `}
                />
              )}
          </div>
        ) : null}
      </StyledUserDetail>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.user.users,
  account: state.auth.account,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (params) => dispatch(UserActions.getUsersRequest(params)),
    resetUsers: () => dispatch(UserActions.resetUsers()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(UserDetail);

{
  /* this.renderList('appointment', 'Frequency of appointments/next follow-up appointment', appointment.name ? appointment.name : '', '', {
                            showDropdown: appointmentDropdown,
                            dropdownData: appointmentData,
                            dropdownPropertyName: 'name',
                            onClick: () => this.onPressDropdown('appointmentDropdown', appointmentDropdown),
                            onSelectDropdown: (appointment) => {
                              this.setState({ appointmentDropdown: false, appointment, })
                            }
                          }), */
}

// action === 'EDIT' ?
//                               <>
//                                 <div onClick={() => this.props.history.push({
//                                   pathname: `/user/${uid}`,
//                                   state: {
//                                     action: 'EDIT'
//                                   }
//                                   })}
//                                 >
//                                   <div className="edit-wrapper">
//                                     <img src={Images.edit} className="edit" />
//                                   </div>
//                                 </div>
//                                 <div onClick={() => this.setState({ dialogConfirmation: true, ...deleteDialogState, })}>
//                                   <div className="trash-wrapper">
//                                     <img src={Images.trash} className="delete" />
//                                   </div>
//                                 </div>
//                               </>
//                             :
//                               user.accountStatus === ACCOUNT_STATUS.PENDING_APPROVAL ?
//                                 <div className="buttonContainer">
//                                   <div style={{ width: '48%' }}>
//                                     <Button
//                                       text="Reject"
//                                       bordered
//                                       textColor={Colors.color2}
//                                       textStyle={` font-weight: bold; `}
//                                       onClick={() => this.setState({ dialogConfirmation: true, ...rejectDialogState, })}
//                                     />
//                                   </div>
//                                   <div style={{ width: '48%' }}>
//                                     <Button
//                                       text="Approve"
//                                       backgroundColor={Colors.color10}
//                                       textStyle={` font-weight: bold; `}
//                                       onClick={() => this.setState({ dialogConfirmation: true, ...approveDialogState, })}
//                                     />
//                                   </div>
//                                 </div>
//                               :
//                               user.accountStatus === ACCOUNT_STATUS.PENDING_CARDS ?
//                                 <div className="buttonSelfContainer">
//                                   <div style={{ width: '100%' }}>
//                                     <Button
//                                       text="Send Card"
//                                       backgroundColor={Colors.color10}
//                                       textStyle={` font-weight: bold; `}
//                                       onClick={() => this.setState({ dialogConfirmation: true, ...sendCardDialogState, })}
//                                     />
//                                   </div>
//                                 </div>
//                               :
//                               user.accountStatus === ACCOUNT_STATUS.COMPLETED ?
//                                 <div className="buttonSelfContainer">
//                                   <div style={{ width: '100%' }}>
//                                     <Button
//                                       text="Set Expire"
//                                       backgroundColor={Colors.color21}
//                                       textStyle={` font-weight: bold; `}
//                                       onClick={() => this.setState({ dialogConfirmation: true, ...expiredDialogState, })}
//                                     />
//                                   </div>
//                                 </div>
//                               :
//                               user.accountStatus === ACCOUNT_STATUS.REJECTED ?
//                                 <div className="buttonSelfContainer">
//                                   <div style={{ width: '100%' }}>
//                                     <Button
//                                       text="Approve"
//                                       backgroundColor={Colors.color10}
//                                       textStyle={` font-weight: bold; `}
//                                       onClick={() => this.setState({ dialogConfirmation: true, ...approveDialogState, })}
//                                     />
//                                   </div>
//                                 </div>
//                               :
//                                 null
