import React, { useState, } from 'react'
import PropTypes from 'prop-types'

import { StyledPagination } from './StyledPagination'

import Button from '../button'

import { Colors, } from '../../themes'

let sortLimitData = [
  {
    id: 1,
    name: `10`,
    value: 10
  },
  {
    id: 2,
    name: `50`,
    value: 50
  },
  {
    id: 3,
    name: `100`,
    value: 100
  },
  {
    id: 4,
    name: `All Data`,
    value: 99999999
  },
]



// const maxPage = 50;
// let currentPage = 10;

function generatePagination(currentPage, maxPage) {
  const pagination = [];
  
  // Show the first page
  pagination.push(1);

  // Calculate the range around the current page to show 5 pages before and 5 pages after
  let startPage = currentPage - 5;
  let endPage = currentPage + 5;

  // If the startPage is less than 2, adjust the range
  if (startPage < 2) {
    startPage = 2;
    endPage = Math.min(maxPage, 12);
  }

  // If the endPage is greater than maxPage - 1, adjust the range
  if (endPage > maxPage - 1) {
    endPage = maxPage - 1;
    startPage = Math.max(2, maxPage - 11);
  }

  // Add the pages in the calculated range
  for (let i = startPage; i <= endPage; i++) {
    pagination.push(i);
  }

  // Show the last page
  if(pagination.length != 1) {
    pagination.push(maxPage);
  }

  if(pagination[1] !== 2 && pagination.length != 1) {
    pagination.splice(1, 0, '...');
  }

  if(pagination[pagination.length-2] !== maxPage - 1 && pagination.length != 1) {
    pagination.splice(pagination.length-1, 0, '...');
  }

  if(pagination.length === 1 && pagination[0] === 1 && maxPage === 2) {
    pagination.push(maxPage)
  }

  return pagination;
}

function Pagination(props) {
  const { page, onClickPrevious, onClickNext, totalPage, onClickPage, onSelectLimit, showLimit, selectedLimit, totalData, showTotalData } = props 

  const [ showDropdownLimit, setShowDropdownLimit ] = useState(false)
  const [ selectedData, setSelectedData ] = useState(selectedLimit)

  const pagination = generatePagination(parseInt(page), totalPage.length)

  // sort limit data
  if(totalData < 100 && sortLimitData[2]?.id === 3) {
    sortLimitData.splice(2, 1) 
  };

  if(totalData < 50 && sortLimitData[1]?.id === 2) {
    sortLimitData.splice(1, 1);
  }

  if(totalData > 100) sortLimitData = [
    {
      id: 1,
      name: `10`,
      value: 10
    },
    {
      id: 2,
      name: `50`,
      value: 50
    },
    {
      id: 3,
      name: `100`,
      value: 100
    },
    {
      id: 4,
      name: `All Data`,
      value: 99999999
    },
  ]

  if (props.totalPage.length > 0) {
    return (
      <StyledPagination {...props}>
        <div />
        <div className="pagination">
          <p className={parseInt(page) === 1 && 'cursor-blocked'} onClick={parseInt(page) > 1 && onClickPrevious}>&laquo;</p>
          {
            pagination.length > 0 && pagination.map(number => {
              return (
                isNaN(number) ? (
                  <p className={page}>{number}</p>
                ) : (
                  <a onClick={() => onClickPage(number)} className={page && parseInt(page) === number ? 'active' : ''}>{number}</a>
                )
              )
            })
          }
          <p className={parseInt(page) === totalPage.length && 'cursor-blocked'} onClick={parseInt(page) < totalPage.length && onClickNext}>&raquo;</p>
        </div>
        {
          showTotalData && 
          <p style={{color: Colors.color5}}>Show {selectedLimit === 'All Data' ? 1 : (page * selectedLimit + 1) - selectedLimit} - {(page * selectedLimit) < totalData ? (page * selectedLimit) : totalData} of {totalData} items</p>
        }
        {
          showLimit &&
          <div>
            <Button 
              text={selectedData}
              bordered
              textColor={Colors.color12}
              buttonStyle={` font-weight: bold; width: 106px; height: 40px; display: flex; justify-content: space-around; `}
              iconRight
              onClick={() => setShowDropdownLimit(!showDropdownLimit)}
              showDropdown={showDropdownLimit}
              onSelectSort={(data) => {
                setSelectedData(data.name)
                setShowDropdownLimit(!showDropdownLimit)
                onSelectLimit(data)
              }}
              dropdownData={sortLimitData}
              selectedData={selectedData}
            />
          </div>
        }
      </StyledPagination>
    )
  }

  return null
}





Pagination.propTypes = {
  page: PropTypes.any,
  onClickPrevious: PropTypes.func,
  onClickNext: PropTypes.func,
  onClickPage: PropTypes.func,
  totalPage: PropTypes.array,
  onSelectLimit: PropTypes.func,
  showLimit: PropTypes.bool,
  selectedLimit: PropTypes.any,
  totalData: PropTypes.number,
  showTotalData: PropTypes.bool
}

Pagination.defaultProps = {
  page: '0',
  onClickPrevious: () => null,
  onClickNext: () => null, 
  onClickPage: () => null,
  totalPage: [],
  onSelectLimit: () => null,
  showLimit: false,
  selectedLimit: `10`,
  totalData: 0,
  showTotalData: false
}

export default Pagination
