import React, {Component, useEffect} from 'react'
import {
    GridContextProvider,
    GridDropZone,
    GridItem,
    swap
  } from "react-grid-dnd";
import axios from 'axios';
import {connect} from 'react-redux';
import { Container, Row, Col, Hidden, } from 'react-grid-system';

import { Colors, Fonts, Scale, Images } from '../../themes';
import { StyledGrid } from './StyledGrid';
import EditContent from '../../containers/EditContent';
// import AppConfig from '../../config/AppConfig';
import AppConfig from '../../config.js'
import Button from '../button';
import { USER_TYPE } from '../../constants';
   
const benefitsUrl = AppConfig.BENEFITS_URL

class Example extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dataBanner: [],
      dialogAddBanner: false,
      dialogEditBanner: false,
      title: '',
      description: '',
      id: '',
      text: '',
      url: '',
      image: '',
      buttonText: '',
      base64Image:'',
      infoDialogShow: false,
      infoDescription: '',
      showButton: false,
    }
  }

  componentDidMount() {
    const {bannerData} = this.props

    if (bannerData) {
      this.setState({dataBanner: bannerData})
    }
    axios.get(`${benefitsUrl}/banners`)
    .then(res=> {
      this.setState({dataBanner:res.data.payload.data})
    })
  }

  

  onChange = (sourceId, sourceIndex, targetIndex, targetId) => {
    const {dataBanner} = this.state

    const nextState = swap(dataBanner, sourceIndex, targetIndex);
    this.setState({dataBanner: nextState, showButton: true})
  }

  onChangeText = (name, value) => {
    this.setState({ [name]: value })
  }

  onClick = () => {
    axios.patch(`${benefitsUrl}/banner/${this.state.id}`,{
      title: this.state.title,
      description: this.state.description,
      text: this.state.text,
      url: this.state.url,
      base64Image:this.state.base64Image,
      buttonText: this.state.buttonText,
    }).then( res => {
      if (res.data.type === 'ERROR') {
        const desc = res.data.error ? res.data.error : 'Error'
        this.onDialogInfo(desc)
      } else {
        this.setState({ 
          dialogAddBanner: false, 
        })
      }
    })
    axios.get(`${benefitsUrl}/banners`)
    .then(res=> {
      this.setState({dataBanner:res.data.payload.data, dialogEditBanner: false, })
    })
    
  this.show_item_after_edit()
  }
  
  handleChangeI = (e) => {
    let file = e.target.files[0];
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onloadend = () => {
    this.setState({
      image: URL.createObjectURL(e.target.files[0]),
      
        base64Image: reader.result
      });
    }
  }

  show_item_after_delete= () => {
    setTimeout(()=>{
      axios.get(`${benefitsUrl}/banners`)
      .then(res=> {
        this.setState({dataBanner:res.data.payload.data})
      })
    },500)
  }

  onDelete(id) {
    //function props to delete
    const confirmation = confirm('Are you sure want to delete this banner?')
    
    if (confirmation) {
      axios.delete(`${benefitsUrl}/banner/${id}`)
      .then(res=>{          
      }) 
      this.show_item_after_delete()
    }    
  }

  onSave = () => {
    axios.patch(`${benefitsUrl}/bannerOrder`, {
      dataOrder: this.state.dataBanner
    }).then(res => {
      if(res.data.type === 'ERROR') {
        const desc = res.data.error ? res.data.error : 'Error'
        this.onDialogInfo(desc)
      } else {
        this.setState({dialogAddBanner: false})
      }
    })
    // axios.get(`${benefitsUrl}/banners`).then( res => {
    //   this.setState({dataBanner: res.data.payload.data, dialogEditBanner: false, showButton: false})
    // })
    this.setState({dialogEditBanner: false, showButton: false})
  }

  render() {
    const {dataBanner, id, url, text, image, description, buttonText, dialogEditBanner, title, showButton} = this.state
    return (
      <div>
        <EditContent 
            title={title}  
            id={id} 
            url={url} 
            text={text} 
            image={image} 
            description={description}
            buttonText={buttonText}
            onClick={this.onClick} 
            onChangeImage={this.handleChangeI} 
            onChangeText={this.onChangeText}
            dialogProps={{
              isVisible: dialogEditBanner,  
              isClose: () => this.setState({ dialogEditBanner: false, })
            }}
        />
        {
          showButton && 
          <Col lg={3}>
            <Button 
              text="Save"
              backgroundColor={Colors.color10}
              textColor={"#fff"}
              buttonStyle={{marginBottom: "10px"}}
              onClick={() => this.onSave() }
            />
          </Col>
        }
        {
           (this.props.account && this.props.account.payload && this.props.account.payload.data && this.props.account.payload.data.account && this.props.account.payload.data.account.type && this.props.account.payload.data.account.type !== USER_TYPE.VIEWER) &&
           <p style={{color: Colors.color13, fontSize: Fonts.size.size14, fontWeight: 'bold', paddingBottom: "20px"}}>{`*Drag and drop to reorder the positions`}</p>
        }
        <GridContextProvider onChange={this.onChange} >
          <GridDropZone
            id="banner"
            boxesPerRow={3}
            rowHeight={300}
            style={{flex: 1,
              height: "1200px",
              // border: "1px solid rgba(0, 0, 0, 1)",
              borderRadius: "1rem", 
            }}
            disableDrag={ (this.props.account && this.props.account.payload && this.props.account.payload.data && this.props.account.payload.data.account && this.props.account.payload.data.account.type && this.props.account.payload.data.account.type !== USER_TYPE.VIEWER) ? false : true}
          >
            {dataBanner.map((item, index) => (
              <GridItem key={index} 
                // style={{border: "1px solid rgba(0, 0, 0, 1)"}}
              >
                  <StyledGrid>
                    <div className='card' style={{backgroundImage: 'url(' + item.imageUrl + ')', backgroundSize: 'contain', backgroundPosition: 'center', backgroundRepeat: 'no-repeat'}}>
                      {/* <img src={item.imageUrl} alt="content" className="image" /> */}
                    </div>
                    <div className='card2'>
                      <p className="title">{item.title}</p>
                      <div className="action">
                        <div className='detail-action'>
                          {
                             (this.props.account && this.props.account.payload && this.props.account.payload.data && this.props.account.payload.data.account && this.props.account.payload.data.account.type && this.props.account.payload.data.account.type !== USER_TYPE.VIEWER) && (
                              <div onClick={() => this.setState({
                                dialogEditBanner: true, 
                                title:item.title, 
                                id:item.id,
                                text:item.text,
                                url:item.url,
                                image:item.imageUrl,
                                description: item.description,
                                buttonText: item.buttonText,
                              })}>
                                <div className="edit-wrapper">
                                  <img src={Images.edit} className="edit" />
                                </div>
                              </div>
                             )
                          }
                          {
                            ( (this.props.account && this.props.account.payload && this.props.account.payload.data && this.props.account.payload.data.account && this.props.account.payload.data.account.type && this.props.account.payload.data.account.type === USER_TYPE.SUPERADMIN)) && (
                              <div onClick={() => this.onDelete(item.id)}>
                                <div className="trash-wrapper">
                                  <img src={Images.trash} className="delete" />
                                </div>
                              </div>
                            )
                          }
                        </div>
                        <div>
                          <div className="number-order">
                            <p className="text-order">{`No. ${index + 1}`}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </StyledGrid>
              </GridItem>
            ))}
          </GridDropZone>
        </GridContextProvider>      
      </div>
    )
  }
}

const mapStateToProps = (state) => ({
  account: state.auth.account
})

export default connect(mapStateToProps, null)(Example);