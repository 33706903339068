import React from 'react';

import Dialog from '../modal';
import Input from '../input';
import Form from '../form'
export default class DialogAdmin extends React.Component {
  render() {
    return (
      <div>
        <Dialog {...this.props} width={'80%'}>
          <Form
            {...this.props}
          />
        </Dialog>
      </div>
    )
  }
}