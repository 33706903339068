import React from 'react'
import PropTypes from 'prop-types'

import { StyledInput, StyledDropdown } from "./StyledInput";

function Input({ 
  inputProps, 
  containerStyle, 
  multiline, 
  rows, 
  iconRight, 
  iconRightStyle, 
  showDropdown, 
  dropdownWidth, 
  onSelectDropdown, 
  dropdownData, 
  dropdownPropertyName,
  iconLeft, 
  iconLeftStyle,
  iconRightClick,
  onKeyDown
}) {
  return (
    <>
      <StyledInput containerStyle={containerStyle} iconRightStyle={iconRightStyle} dropdownData={dropdownData} iconLeftStyle={iconLeftStyle}>
        {
          iconLeft !== '' &&
            <img src={iconLeft} className="icon-left" />
        }
        {
          multiline ?
            <textarea {...inputProps} className="input-field" rows={rows} />
          :
            <input {...inputProps} className="input-field" onKeyDown={onKeyDown}/>
        }
        {
          iconRight !== '' &&
            <img src={iconRight} className="icon-right" onClick={iconRightClick ? iconRightClick : null}/>
        }
      </StyledInput>
      <StyledDropdown showDropdown={showDropdown} dropdownWidth={dropdownWidth}>
        <ul>
          {
            dropdownData && dropdownData.map((data, index) => {
              return (
                <li key={index.toString()} onClick={() => onSelectDropdown(data)}>{data[dropdownPropertyName]}</li>
              )
            })
          }
        </ul>
      </StyledDropdown>
    </>
  )
}

Input.propTypes = {
  inputProps: PropTypes.any,
  containerStyle: PropTypes.string,
  multiline: PropTypes.bool,
  rows: PropTypes.number,
  iconRight: PropTypes.string,
  iconRightStyle: PropTypes.string,
  showDropdown: PropTypes.bool,
  dropdownData: PropTypes.array,
  dropdownWidth: PropTypes.string,
  onSelectDropdown: PropTypes.func,
  dropdownPropertyName: PropTypes.string,
  iconLeft: PropTypes.string,
  iconLeftStyle: PropTypes.string,
}

Input.defaultProps = {
  inputProps: {},
  containerStyle: ``,
  multiline: false,
  rows: 6,
  iconRight: '',
  iconRightStyle: ``,
  showDropdown: false,
  dropdownData: [],
  dropdownWidth: '136px',
  onSelectDropdown: () => null,
  dropdownPropertyName: '',
  iconLeft: ``,
  iconLeftStyle: ``,
}

export default Input
