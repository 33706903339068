import React from 'react';
import Modal from 'react-awesome-modal';
import DatePicker from "react-datepicker";
import styled from "styled-components";
import moment from 'moment'
import { addDays } from "date-fns"

import Input from '../input';
import Button from '../button';
import Button2 from '../button2';
import {StyledFilter} from './StyledFilter';
import close from '../../assets/images/close.png';
import { Row, Col } from 'react-grid-system';

import {Colors, Fonts} from '../../themes'
import "react-datepicker/dist/react-datepicker.css";



const CustomInput = React.forwardRef((props, ref) => {
  return (
    <CustomDatePickDiv>
      <Row>
        <label onClick={props.onClick} ref={ref}>
          <Col>
          {props.value || props.placeholder}
          </Col> 
        </label>
      </Row>
    </CustomDatePickDiv>
  );
});

const MyContainer = ({ className, children }) => {
  return (
    <div style={{ marginLeft: "180px"}}>
      <CustomDatePickDiv className={className}>
        <div style={{ position: "relative" }}>{children}</div>
      </CustomDatePickDiv>
    </div>
  );
};

const MyContainer2 = ({ className, children }) => {
  return (
    <div style={{ marginLeft: "400px"}}>
      <CustomDatePickDiv className={className}>
        <div style={{ position: "relative" }}>{children}</div>
      </CustomDatePickDiv>
    </div>
  );
};

const CustomDatePickDiv = styled.div`
  background-color: white;
  border-radius: 10px;
  label{
    padding: 1px 35px 0px 5px;
    font-family: ${Fonts.FontRegular};
    font-weight: bold;
    color: ${Colors.color2};
    resize: none;
    ${Fonts.size.size20}}
  }
`;

export default class DialogFilterTime extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      startDateReport: "",
      endDateReport: "",
      date: "",
      dateNow: "",
      sortDropdown: false,
      documentType: {}
    }
  }

  componentDidUpdate(prevState) {
    const {startDateReport, endDateReport} = this.props.data
    const {startDateReport: sd, endDateReport: ed} = this.state;

    if(startDateReport !== prevState.startDateReport) {
      this.setState({
        startDateReport: startDateReport
      })
    }

    if(endDateReport !== prevState.endDateReport) {
      this.setState({
        endDateReport: endDateReport
      })
    }

    if (new Date(sd) > new Date(ed)) {
      this.setState({endDateReport: sd})
    }

    if (sd === '' && ed) {
      this.setState({startDateReport: ed})
    }

    if (sd && ed === '') {
      this.setState({endDateReport: sd})
    }

  }

  _cekCallback() {
    if (this._flag) return

    this._flag = setTimeout(() => {
      const {startDateReport, endDateReport} = this.state
      this.props.form({ startDateReport, endDateReport})
      this._flag = null
    }, 500)
  }

  applyDate = async () => {
    const {startDateReport, endDateReport} = this.state
    await this.props.form({startDateReport, endDateReport})
    this.props.isClose()
    this.props.onClickApply()
  }

  handleClick = async () => {
    const data = {
      startDateReport: moment(this.state.startDateReport).format('YYYY-MM-DD'),
      endDateReport: moment(this.state.endDateReport).format('YYYY-MM-DD'),
      exportType: this.state.documentType.sortBy
    }

    this.applyDate;
    return await this.props.downloadReport(data);
    // let blob = new Blob([resultExport], {
    //   type: ''
    // });
    // const url = window.URL.createObjectURL(blob);
    // const link = document.createElement('a');
    // link.href = url;
    // link.setAttribute('download', `User data.csv`);
    // document.body.appendChild(link);
    // link.click();
  }

  render() {
    const {haveDate, onClickApply, downloadLoading} = this.props
    const {startDateReport, endDateReport, sortDropdown, documentType} = this.state
    // this._cekCallback()

    return (
      <StyledFilter>
        <Modal 
          visible={this.props.isVisible} 
          width="400px"
          effect="fadeInUp" 
          onClonClickAway={this.props.isClose}
        >
          <div className="" style={{overflow: 'auto', padding: "20px 25px 20px 25px"}}>
            <div style={{display:'flex', justifyContent: 'space-between'}}>
              <p className="title" style={{padding: 0}} >Download Report</p>
              <div style={{width: "20px", height: "20px", borderRadius: "10px", backgroundColor: "#c4b2cf" }} onClick={this.props.isClose}>
                <p style={{cursor: 'pointer'}} className="close">X</p>
              </div>
            </div>

            <div style={{display: "flex", justifyContent: "space-between", marginBottom: "25px"}}>
              <div>
                <p className="label">Start Date</p>
                <Row className="input" style={{margin:'auto', border: '1px solid #B1C2D8', boxSizing: 'border-box', borderRadius: '7px', fontSize: '12px'}}>
                  <DatePicker showMonthDropdown showYearDropdown customInput={<CustomInput />} filterDate = {(date) => {return moment() > date;}} placeholderText="dd-mm-yyyy"  dateFormat="dd-MM-yyyy" selected={this.state.startDateReport ? this.state.startDateReport : null} 
                        onChange={date => { this.setState({ startDateReport:date }) }} showPopperArrow={false} dropdownMode="select" popperPlacement="bottom-end" calendarContainer={MyContainer}/>
                </Row>
              </div>
              <div>
                <p className="label">End Date</p>
                <Row className="input" style={{margin:'auto', border: '1px solid #B1C2D8', boxSizing: 'border-box', borderRadius: '7px'}}>
                  <DatePicker required showMonthDropdown showYearDropdown customInput={<CustomInput />} maxDate={addDays(new Date(), 0)} placeholderText="dd-mm-yyyy"  dateFormat="dd-MM-yyyy" selected={this.state.endDateReport ? this.state.endDateReport : null} 
                        minDate={ startDateReport ? new Date(startDateReport): null}
                        onChange={date => { this.setState({endDateReport: date })}} showPopperArrow={false} dropdownMode="select" popperPlacement="bottom-end" calendarContainer={MyContainer2} />
                </Row>
              </div>
            </div>

            <div 
              style={{
                marginBottom : '20px'
              }}
            >
              <Button2
                  text={documentType.name? documentType.name : 'Please select'}
                  bordered
                  textColor={Colors.color2}
                  buttonStyle={` font-weight: bold; width: 106px; height: 40px; display: flex; justify-content: space-around; `}
                  iconRight
                  dropdownData={dropdownData}
                  onClick={() => this.setState({ sortDropdown: sortDropdown === true ? false : true })}
                  showDropdown={sortDropdown}
                  buttonStyle={{width: '100%'}}
                  onSelectSort={(data) => {
                    this.setState({ sortDropdown: false, documentType: data })
                  }}
                  selectedData={documentType.name}
                  isDownloadReport
              />
            </div>

            {/* error message */}
            {
              this.props.downloadErrorMessage && (
                <div>
                  <p 
                  style={{
                    fontSize: '14px',
                    color: 'red',
                    textAlign: 'center',
                    marginBottom: '20px'
                  }}
                >
                  {this.props.downloadErrorMessage}
                </p>
                </div>
              )
            }

            <Button
              text={downloadLoading ? 'Loading...' : 'Download'}
              textColor={Colors.color3}
              buttonStyle={` font-weight: bold; height: 40px; display: flex; justify-content: space-around; `}
              onClick={this.handleClick}
              disabled={ 
                !startDateReport ||
                !endDateReport ||
                !documentType.name ||
                downloadLoading
              }
            />
          </div>
        </Modal>
      </StyledFilter>
    )
  }
}

const dropdownData = [
  {
    id: 1,
    name: 'Approved PLWD',
    sortBy: 'APPROVED_PLWD',
  },
  {
    id: 2,
    name: 'Approved CG',
    sortBy: 'APPROVED_CG',
  },
  {
    id: 3,
    name: 'PLWD Profile Picture',
    sortBy: 'PLWD_PROFILE_PICTURE',
  },
  {
    id: 4,
    name: 'PLWD Multiple CG',
    sortBy: 'PLWD_MULTIPLE_CG',
  },
  {
    id: 5,
    name: 'CG Multiple PLWD',
    sortBy: 'CG_MULTIPLE_PLWD',
  },
  {
    id: 6,
    name: 'Incomplete Same Address',
    sortBy: 'INCOMPLETE_SAME_ADDRESS',
  },
  {
    id: 7,
    name: 'MOP',
    sortBy: 'MOP',
  },
  {
    id: 8,
    name: 'CG With No PLWD',
    sortBy: 'CG_WITH_NO_PLWD',
  },
  {
    id: 9,
    name: 'PLWD With No CG',
    sortBy: 'PLWD_WITH_NO_CG',
  }
]