import React, { Component } from 'react'
import { Container, Row, Col, Hidden, } from 'react-grid-system';

import { StyledUserQRDetail, Text, Label, InputStyle, } from './styles/StyledUserQRDetail'

import Input from '../components/input'
import Button from '../components/button'

import DialogInfo from '../components/dialog-info'

import { Images, Colors, Fonts, } from '../themes/'

import { getUserById } from '../api/UserAPI'

export class UserQRDetail extends Component {
  constructor(props) {
    super(props)

    this.state = {
      user: null,
      dialogInfo: {
        onShow: false,
        description: '', 
       },
    }
  }


  componentDidMount() {
    getUserById({ uid: this.props.match.params.uid })
    .then(res => {
      this.setState({ user: res.data })
    })
    .catch(e => {
      this.onDialogInfo(e.message)
    })
  }

  render() {
    const { user, dialogInfo } = this.state
    const { firstname, lastname, photo } = user || ''


    return (
      <StyledUserQRDetail>
        {/* Dialog info start */}
        <DialogInfo
          isVisible={infoDialogShow}
          textInfo={infoDescription}
          onOkButton={()=> this.setState({infoDialogShow: false})}
        />
        {/* Dialog info end  */}
        <div className="card-container">
          <div className="card-left-section">
            <div>
              <div className="name-photo-wrapper">
                <div>
                  <Text color={Colors.color19}  >My name is</Text>
                  <h1 className="text-name">{user && `${firstname} ${lastname}`}</h1>
                  <Text color={Colors.color11} customStyle={` font-weight: bold; `}>I am living with dementia</Text>
                </div>
                <div className="photo-wrapper">
                  <img src={photo ? photo : Images.defaultPhoto} style={{  width: '100%', height: '100%',  borderRadius: '50%', background: Colors.color33, resizeMode: 'cover', }} />
                </div>
              </div>
              <div>
                <Text color={Colors.color2} customStyle={` font-weight: bold; `} >Please be patient as i may:</Text>
                <div style={{ marginTop: '10px', }}>
                  <Text color={Colors.color2} customStyle={` font-weight: bold; font-size: 14px; margin-bottom: 5px; `}>• Repeat questions</Text>
                  <Text color={Colors.color2} customStyle={` font-weight: bold; font-size: 14px; margin-bottom: 5px; `}>• Forget what you have told me</Text>
                  <Text color={Colors.color2} customStyle={` font-weight: bold; font-size: 14px; margin-bottom: 5px; `}>• Take longer to make a decision</Text>
                  <Text color={Colors.color2} customStyle={` font-weight: bold; font-size: 14px; `}>• Seem confused or gets easily upset</Text>
                </div>
              </div>
            </div>
            <div>
              <Text color={Colors.color11} customStyle={` font-weight: bold; font-size: 14px; `}>If this is an emergency,<br />please call the relevant authorities</Text>
            </div>
          </div>
          <div className="card-right-section">
            <div>
              <Text color={Colors.color2}>If you notice that I might need help from someone else,<br /><span style={{ fontWeight: 'bold', }}>please help fill out details below </span>and my loved one will get connected with you.</Text>
              <div style={{ marginTop: '25px', }}>
                <Label htmlFor="name">Your name*</Label>
                <Input containerStyle={InputStyle} inputProps={{ id: 'name' }} />
                <Label htmlFor="phone">Your mobile no.*</Label>
                <Input containerStyle={InputStyle} inputProps={{ id: 'phone', type: 'number' }} />
                <Label htmlFor="location">Your location</Label>
                <Input containerStyle={InputStyle} inputProps={{ id: 'location' }} />
              </div>
            </div>
            <div>
              <Button
                text="Submit"
              />
            </div>
          </div>
        </div>
      </StyledUserQRDetail>
    )
  }
}

export default UserQRDetail
