import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledBenefits = styled.div`
  min-height: 100vh;
  background-color: ${Colors.primaryBgColor};

  .container {
    width: 100%;
    margin: 0;
    padding-left: 40px;
    padding-right: 74px;
  }
  .row {
    margin: auto;
  }
  .row:after {
    content: "";
    display: table;
    clear: both;
  }
  .header {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
    padding: 62px 0 25px 0;
  }
  .btn-create {
    padding: 10px;
    padding-left: 30px;
    padding-right: 30px;
    border-radius: 10px;
  }
  .button-wrapper {
    display: flex;
    flex-direction: row;
  }
  
  .center {
    text-align: center;
  }

  .pagination {
    display: inline-block;
    margin: 20px 0; 
  }

  .pagination a, .pagination p {
    color: ${Colors.color};
    float: left;
    padding: 8px 16px;
    text-decoration: none;
    transition: background-color .3s;
    border: 1px solid ${Colors.color19};
    margin: 0 4px;
    cursor: pointer;
  }

  .pagination a.active {
    background-color: ${Colors.color11};
    color: ${Colors.color3};
    border: 1px solid ${Colors.color11};
  }

  .pagination a:hover:not(.active), .pagination p:hover:not(.cursor-blocked) {
    background-color: ${Colors.color5}; 
    color: ${Colors.color3};
  }

  .cursor-blocked {
    cursor: not-allowed !important;
  }
`