import React, { Component } from 'react';
import { connect } from 'react-redux';
import { StyledDashboardPartner } from './styles/StyledDashboardPartner';
import moment from 'moment';

import axios from 'axios';

import { Colors, Images } from '../themes';
import Chart from '../components/Chart';
import Button from '../components/button';
import Dialog from '../components/dialog-filter-date';
import DialogDownloadReport from '../components/dialog-filter-download-report'

import DashboardAction from '../redux/DashboardPartner';
import AuthActions from '../redux/AuthRedux'

import StyledLongCard from './../components/long-card'
import NumericInfo from './../components/numeric-info'

import { USER_TYPE } from '../constants';

import { downloadReport } from '../api/AdminAPI';

const color = {
  purple: '#502773'
}

const dropdownData = [
  {
    id: 1,
    name: 'Januari',
    sortBy: 'name,asc',
  },
]

const dropdownProfileData = [
  {
    id: 1,
    name: 'Profile',
  },
  {
    id: 2,
    name: 'Logout',
  }
]

class DashboardPartner extends Component {
  static getDerivedStateFromProps(props, state) {
    const {dashboard} = props

    let returnValue = {}

    if (!dashboard.fetching) {
      if(dashboard.payload) {
        if(dashboard.payload.type === 'SUCCESS') {
          let dashboardData = dashboard.payload.data
          returnValue = {
            loading: false,
            dashboardData,
          }
        } 
      }
    }

    return {
      ...returnValue
    }
  }
  constructor(props) {
    super(props);
    this.state = {
      sortDropdown: false,
      sortBy: {},
      dashboardData: {},
      dailySignUp: {},
      loading: false,
      profileDropdown: false,
      showFilter: false,
      showFilterDownloadReport: false,
      startDate: "",
      endDate: "",
      startDateReport: "",
      endDateReport: "",
      downloadLoading: false,
      downloadErrorMessage: "",
    }
    this.cekData = this.cekData.bind(this);
  }
  async componentDidMount() {
    this.setState({loading: true})
    this.props.getDashboard()
    this.props.getDailySignUp()
    // axios.get(`${process.env.REACT_APP_ACCOUNT_URL}/admin/users?createdAt=now`).then(res => this.setState({dailySignUp: res.data.data}))
  }
  
  cekData(data) {
    const {startDate, endDate} = data

    if(startDate !== "" && endDate !== "") {
      this.setState({startDate: startDate, endDate: endDate})
    }
    if(startDate && endDate === "") {
      this.setState({startDate: startDate, endDate: new Date()})
    }
    if(startDate === "" && endDate) {
      this.setState({startDate: "", endDate: ""})
    }
    if(startDate === "" && endDate === "") {
      this.setState({startDate: "", endDate: ""})
    }

  }

  _onClickApply() {
    const {startDate, endDate} = this.state
    if(startDate !== "" && endDate !== "") {
      this.props.getDashboard({
        createdAt: [startDate, endDate]
      });
      this.props.getDailySignUp({
        createdAt : [startDate, endDate]
      })
    } else {
      this.props.getDashboard()
      this.props.getDailySignUp()
    }
    this.setState({
      showFilter: false
    })
  }

  downloadReport = async (data) => {
    try {
      this.setState({ downloadLoading: true, downloadErrorMessage: '' })
      let resultExport = {}

      resultExport = await downloadReport(data)

      let blob = new Blob([resultExport], {
        type: ''
      });
      const url = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = url;
      link.setAttribute('download', `Report_${data.exportType}_from_${data.startDateReport}_to_${data.endDateReport}.csv`);
      document.body.appendChild(link);
      link.click();
      this.setState({ downloadLoading: false,  downloadErrorMessage: '' })
    } catch (e) {
      this.setState({ downloadLoading: false, downloadErrorMessage: e.message})
          // this.onDialogInfo(e.message)
    }
  }

  // _onClickApply2() {
  //   const {startDateReport, endDateReport} = this.state
  //   if(startDateReport !== "" && endDate !== "") {
  //     this.props.getDashboard({
  //       createdAt: [startDate, endDate]
  //     });
  //     this.props.getDailySignUp({
  //       createdAt : [startDate, endDate]
  //     })
  //   } else {
  //     this.props.getDashboard()
  //     this.props.getDailySignUp()
  //   }
  //   this.setState({
  //     showFilter: false
  //   })
  // }

  
  render() {
    const {sortDropdown, sortBy, dashboardData, loading, profileDropdown, showFilter, showFilterDownloadReport, startDate, endDate, startDateReport, endDateReport, downloadLoading, downloadErrorMessage } = this.state;
    const dailySignUp = this.props.daily.daily
    const { account } = this.props.account.payload.data || {}
    
    let plwd = 0
    let cg = 0
    let mop = 0


    if (this.props.daily.daily && this.props.daily.daily.length > 0) {
      dailySignUp.map(item => {
        if(item.type === 'CAREGIVER') cg = item.count
        else if(item.type === 'MOP') mop = item.count
        else if(item.type === 'DEMENTIA') plwd = item.count
      })
    }

    console.log(this.props.dashboard.payload)

    let sameDate = new Date(this.state.startDate).getTime() === new Date(this.state.endDate).getTime();

    return (
      <StyledDashboardPartner>
        <Dialog 
          isVisible={showFilter}
          isClose={() => this.setState({showFilter: false})}  
          onClickApply={() => this._onClickApply()}
          form={this.cekData}
          data={[startDate, endDate]}
        />
        <DialogDownloadReport
          isVisible={showFilterDownloadReport}
          isClose={() => this.setState({showFilterDownloadReport: false})}  
          data={[startDateReport, endDateReport]}
          downloadReport={this.downloadReport}
          downloadLoading={downloadLoading}
          downloadErrorMessage={downloadErrorMessage}
        />
        <div className="title">
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between'
            }}
          >
            <div>
              <h1>Dashboard</h1>
            </div>
            <div
              style={{
                marginRight: '46px'
              }}
            >
              {
                account.type !== 'VIEWER' && 
                <Button
                  text='Download Report'
                  buttonStyle={{padding: '15px 15px'}}
                  onClick={() => this.setState({showFilterDownloadReport: true})}
                />
              }
            </div>
          </div>
          
        </div>
        {!this.props.dashboard.payload || !this.props.daily.daily ?
          <p>Loading ...</p> :
          <div>
            <StyledLongCard>
            <img src={Images.kotakKuning} style={{position: 'absolute', right: 10, top: 70}} />              
            <img src={Images.kotakKanan} style={{position: 'absolute', right: 0}} />
              <div style={{gap: '20px'}} className="calendar-comp">
                <div onClick={() => this.setState({showFilter: true})} style={{display: 'flex', gap: '10px', justifyContent: 'center'}} className="date-range">
                    <img src={Images.calendar} />
                    <p style={{marginBottom: '0px', color: '#502773', marginTop: '2px'}} >Date Range</p>
                </div>
                <div style={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
                  <p style={{color: '#502773'}} className="date">{(startDate && endDate) !== '' ? !sameDate ? `${moment(startDate).format('ll')} - ${moment(endDate).format('ll')}` : `${moment(startDate).format('ll')}` : 'All Time'}</p>
                </div>
              </div>
              <div className="xax">
                <h1>Safe Return</h1>
              </div>
              <div style={{margin: '2rem 0 0 3rem', display: 'flex', width: '90%', justifyContent: 'space-between'}}>
                <NumericInfo image={Images.phone} bgColor="#E6D9F2" numeric={this.props.dashboard.payload.data.callByCG} description="Call by Caregiver" color={color.purple} />
                <NumericInfo image={Images.phone2} bgColor="#FCCFCF" numeric={this.props.dashboard.payload.data.callByHelper} description="Call by MoP" color={color.purple} />
                <NumericInfo image={Images.qr} bgColor="#D5F6E3" numeric={this.props.dashboard.payload.data.qrCodeScanned} description="QR Scanned" color={color.purple} />
                <NumericInfo image={Images.form} bgColor="#FDEBCE" numeric={this.props.dashboard.payload.data.safeReturn.total} description="Form Submission" color={color.purple}/>
              </div>
            </StyledLongCard>
            <StyledLongCard purple>
              <div style={{padding: '4rem 0 0 3rem', fontWeight: 'bold', fontSize: '24px'}}>
                <h1 style={{color: 'white'}} >Daily Sign Up</h1>
              </div>
              <div style={{margin: '3rem 0 0 3rem', display: 'flex', width: '80%', justifyContent: 'space-between'}}>
                <NumericInfo image={Images.brain} bgColor="#3D1E57" numeric={plwd} description="PLWD" color="white" />
                <NumericInfo image={Images.heart} bgColor="#F1F1F1" numeric={cg} description="Caregiver" color="white" />
                <NumericInfo image={Images.mop} bgColor="#FAD1D1" numeric={mop} description="MoP" color="white" />
              </div>
            </StyledLongCard>
          </div>
        }
      </StyledDashboardPartner>
    )
  }
}

const mapStateToProps = state => {
  return {
    dashboard: state.dashboard.dashboard,
    account: state.auth.account,
    daily: state.dashboard
  }
}

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    getDashboard: (params) => dispatch(DashboardAction.getDashboardRequest(params)),
    signout: () => dispatch(AuthActions.signout()),
    getDailySignUp : (params) => dispatch(DashboardAction.getDailySignUpRequest(params))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DashboardPartner);