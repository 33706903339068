import React from 'react'
import PropTypes from 'prop-types'

import {Container, StyledButton, StyledDropdown } from './StyledButton'
import { Colors, Images, } from '../../themes'
import edit from '../../assets/images/edit.png'

function Button({ text, iconRightStyle, onClick, buttonStyle, textStyle, backgroundColor, textColor, bordered, borderColor, iconRight, iconRightImage, containerStyle, showDropdown, dropdownData, dropdownData2, dropdownHeight, onSelectSort, disabled, dropdownWidth, selectedData, sortPropertyName, styleHoverDropdown, iconLeftImage, iconLeftStyle, isPlwd, dropdowStyle}) {
  return (
    <Container containerStyle={containerStyle}>
      <StyledButton onClick={disabled ? null : onClick} buttonStyle={buttonStyle} backgroundColor={backgroundColor} textColor={textColor} bordered={bordered} borderColor={borderColor} textStyle={textStyle} disabled={disabled} iconRightStyle={iconRightStyle} iconLeftStyle={iconLeftStyle}>
        {
          iconLeftImage &&
          <img src={iconLeftImage} alt="icon" className="icon-left"  />
        }
        <p className="button-text">{text}</p>
        {
          iconRight &&
          <img src={iconRightImage} alt="icon" className="icon-right"  />
        }
      </StyledButton>
      <StyledDropdown showDropdown={showDropdown} styleHoverDropdown={styleHoverDropdown} dropdownHeight={dropdownHeight} dropdownWidth={dropdownWidth} dropdowStyle={dropdowStyle}>
        <ul>
          {
            isPlwd ? 
            (
              dropdownData2.map((data, index) => {
                return (
                  <div style={{ background: selectedData === data[sortPropertyName] ? Colors.color10  : Colors.color3 }}>
                    <li key={index.toString()} onClick={() => onSelectSort(data)} style={{ color: selectedData === data[sortPropertyName] ? Colors.color3 : '' }}>{data[sortPropertyName]}</li>
                  </div>
                )
              })
            )
            :
            (
              dropdownData.map((data, index) => {
                return (
                  <div style={{ background: selectedData === data[sortPropertyName] ? Colors.color10  : Colors.color3 }}>
                    <li key={index.toString()} onClick={() => onSelectSort(data)} style={{ color: selectedData === data[sortPropertyName] ? Colors.color3 : '' }}>{data[sortPropertyName]}</li>
                  </div>
                )
              })
            )
          }
        </ul>
      </StyledDropdown>
    </Container>
  )
}

Button.propTypes = {
  text: PropTypes.string,
  onClick: PropTypes.func,
  buttonStyle: PropTypes.any,
  textStyle: PropTypes.any,
  backgroundColor: PropTypes.string,
  textColor: PropTypes.string,
  bordered: PropTypes.bool,
  borderColor: PropTypes.string,
  iconRight: PropTypes.bool,
  iconRightImage: PropTypes.string,
  containerStyle: PropTypes.string,
  showDropdown: PropTypes.bool,
  dropdownData: PropTypes.array,
  dropdownData2: PropTypes.array,
  dropdownHeight: PropTypes.string,
  onSelectSort: PropTypes.func,
  disabled: PropTypes.bool,
  dropdownWidth: PropTypes.string,
  selectedData: PropTypes.string,
  iconLeftImage: PropTypes.any,
  iconLeftStyle: PropTypes.string,
  isPlwd: PropTypes.bool,
}

Button.defaultProps = {
  text: '',
  onClick: () => null,
  buttonStyle: '',
  buttonStyle: '',
  textStyle: '',
  backgroundColor: Colors.color8,
  textColor: Colors.color3,
  bordered: false,
  borderColor: Colors.color11,
  iconRight: false,
  iconRightImage: Images.polygon,
  iconRightStyle: 'width: 12px; height: 8px;',
  containerStyle: '',
  showDropdown: false,
  dropdownData: [
    {
      id: 1,
      name: 'Status',
      sortBy: 'accountStatus,desc',
    },
    {
      id: 2,
      name: 'A-Z',
      sortBy: 'firstname,asc',
    },
    {
      id: 3,
      name: 'Z-A',
      sortBy:  'firstname,desc'
    },
    {
      id: 4,
      name: 'Last Updated',
      sortBy: 'updatedAt,desc',
    }
  ],
  dropdownData2: [
    {
      id: 1,
      name: 'Status',
      sortBy: 'accountStatus,desc',
    },
    {
      id: 2,
      name: 'Status',
      sortBy: 'accountStatus,asc',
    },
    {
      id: 3,
      name: 'A-Z firstname',
      sortBy: 'firstname,asc',
    },
    {
      id: 4,
      name: 'Z-A firstname',
      sortBy:  'firstname,desc'
    },
    {
      id: 5,
      name: 'A-Z lastname',
      sortBy: 'lastname,asc',
    },
    {
      id: 6,
      name: 'Z-A lastname',
      sortBy:  'lastname,desc'
    },
    {
      id: 7,
      name: 'Approval Date ASC',
      sortBy: 'approvalDate,asc',
    },
    {
      id: 8,
      name: 'Approval Date DESC',
      sortBy:  'approvalDate,desc'
    },
    {
      id: 9,
      name: 'Signup Date ASC',
      sortBy: 'createdAt,asc',
    },
    {
      id: 10,
      name: 'Signup Date DESC',
      sortBy:  'createdAt,desc'
    },
    {
      id: 11,
      name: 'Last Updated',
      sortBy: 'updatedAt,desc',
    },
    {
      id: 12,
      name: 'Only Created by Partner',
      sortBy: 'ONLY_CREATED_BY_PARTNER',
    }
  ],
  dropdownHeight: '110px',
  onSelectSort: () => null,
  disabled: false,
  dropdownWidth: '200px',
  selectedData: '',
  sortPropertyName: 'name',
  styleHoverDropdown:'',
  iconLeftImage: null,
  iconLeftStyle: 'width: 12px; height: 8px;',
}

export default Button

