import React, { Component } from 'react'
import { connect } from 'react-redux'
import memoize from "memoize-one"
import { AllCheckerCheckbox, Checkbox, CheckboxGroup } from '@createnl/grouped-checkboxes';
import { Container, Row, Col } from 'react-grid-system'
import moment from 'moment';

import { StyledSafeReturn } from './styles/StyledSafeReturn'
import { ButtonDementia, SearchBarStyle, } from '../components/table/StyledTable'

import Table from '../components/table'
import Pagination from '../components/pagination'
import DialogChangeReportStatus from '../components/dialog-change-report-status'
import Input from '../components/input'
import Button from '../components/button'
import { alertError } from '../components/alert'

import { Colors, Images, } from '../themes'

import ProccessData from '../lib/ProccessData'

import SafeReturnActions from '../redux/SafeReturnRedux'

// import AppConfig from '../config/AppConfig'
import AppConfig from '../config.js'

import { SAFE_RETURN_STATUS, USER_TYPE, } from '../constants/'

import { update, changeSafeReturnStatus } from '../api/SafeReturnAPI'

import { partnerColumns, adminColumns } from '../data/SafeReturnColumns';

export class SafeReturn extends Component {
  constructor(props) {
    super(props) 
    
    const { safeReturn } = this.props

    this.state = {
      safeReturnData: [],
      paramsGet: {
        searchMax: AppConfig.DEFAULT_LIMIT_USER,
        searchPage: (safeReturn.data && safeReturn.data.params) ? safeReturn.data.params.searchPage : 1,
      },
      selectedData: [],
      dialogChangeStatus: false,
      idSafeReturn: null,
      safeReturnSelected: null,
      searchFilter: '',
      sortDropdown: false,
      sortBy: {},
      sortSelected: ''
    }

    this.getSafeReturnToRedux = this.getSafeReturnToRedux.bind(this)
  }


  componentDidMount() {
    const { safeReturn } = this.props

    this.getSafeReturnToRedux({ params: (safeReturn.data && safeReturn.data.params) ? { ...safeReturn.data.params, searchFilter: '', sortBy: '' } : this.state.paramsGet })
  }

  setData = memoize((data) => {
    ProccessData(data, 'get safe return')
    .then(res => {
      this.setState({
        safeReturnData: res.data.rows,
      })
    })
    .catch(err => {
      alert(err)
      this.setState({
        errorText: err ? err : 'Something wrong, please check your internet connection',
      })
    })
  })

  componentWillUnmount() {
    /// clear pagination when unmount
    this.getSafeReturnToRedux({ params: null })
  }

  handlePagination = async (searchPage)=> {
    const { safeReturn, } = this.props
    
    this.getSafeReturnToRedux({ 
      params: (safeReturn.data && safeReturn.data.params) ? { ...safeReturn.data.params, searchPage } : this.state.paramsGet
    })
  };

  getSafeReturnToRedux({ params, }) {
    const { getSafeReturn } = this.props
    getSafeReturn({ 
      params,
    })
  }

  onCheckboxChange = (checkboxes) => {
    let selectedData = []

    checkboxes.map(data => {
      if (data.checked) {
        selectedData.push(data.value)
      }
    })

    this.setState({ selectedData })
  }  

  onPressEnterSearch = (e) => {
    if(e.key === 'Enter') {
      this.onSearch()
    }
  }

  tableHeader = () => {
    const { safeReturn } = this.props
    const { sortDropdown, sortBy } = this.state
    return (
      <Container fluid style={{ margin: 0, padding: 0, }}>
        <Row className="table-header-container" nogutter>
          <Col md={5}>
            <div style={{ display: 'flex', alignItems: 'center', height: '80%' }}>
              <span className="submitted-text">Total submitted so far:</span>
              <span className="submitted-text2">   {safeReturn.payload ? safeReturn.payload.data.count : '0'}</span>
            </div>
          </Col>
          {
            (this.props.account && this.props.account.payload && this.props.account.payload.data && this.props.account.payload.data.account && this.props.account.payload.data.account.type && this.props.account.payload.data.account.type !== USER_TYPE.VIEWER) && (
              <Col md={7}>
                <div className="searchbar-container" style={{ paddingRight: 0, display:'flex', alignItems: 'center', padding: '0 15px 0 0' }}>
                  <Input
                    containerStyle={SearchBarStyle}
                    inputProps={{ 
                      placeholder: "Search", 
                      onChange: (e) => this.setState({ searchFilter: e.target.value })
                    }}
                    onKeyDown={(e) => this.onPressEnterSearch(e)} 
                  />
                  <div className="buttonSeparator" />
                  <Button 
                    text="Search"
                    buttonStyle={ButtonDementia}
                    backgroundColor={Colors.color8}
                    textColor=  {Colors.color3}
                    onClick={this.onSearch}
                  />
                  <div className="buttonSeparator" />
                  {/* <Button 
                    text="Sort By"
                    bordered
                    textColor={Colors.color12}
                    buttonStyle={` font-weight: bold; width: 106px; height: 40px; display: flex; justify-content: space-around; `}
                    iconRight
                    dropdownData={dropdownData}
                    onClick={() => this.setState({ sortDropdown: sortDropdown === true ? false : true })}
                    showDropdown={sortDropdown}
                    onSelectSort={(data) => {
                      this.getSafeReturnToRedux({ params: (safeReturn.data && safeReturn.data.params) ?  { ...safeReturn.data.params, sortBy: data.sortBy, }  : this.state.paramsGet })
                      this.setState({ sortDropdown: false, sortSelected: data.sortBy })
                    }}
                    selectedData={sortBy.name}
                  /> */}
                </div>
              </Col>
            )
          }
        </Row>
      </Container>
    )
  }

  onUpdateData = async () => {
    try {
      const resultUpdate = {}
      resultUpdate.payload = await changeSafeReturnStatus({ 
        params: { id: this.state.idSafeReturn, },
        data: { status: SAFE_RETURN_STATUS.ACTION_TAKEN },
        type: this.props.account.payload && this.props.account.payload.data.account.type,
      })
      ProccessData(resultUpdate, 'update safe return')
      .then(res => {
        const { safeReturn } = this.props
        this.getSafeReturnToRedux({ params: (safeReturn.data && safeReturn.data.params) ? safeReturn.data.params : this.state.paramsGet })
      })
      .catch(err => {
        alertError({ text: err ? err : 'Something wrong, please check your internet connection', })
      })
      this.setState({
        dialogChangeStatus: false,
      })
    } catch (e) {
      alertError({ text: e, })
      this.setState({
        dialogChangeStatus: false,
      })
    }
  }

  onSearch = () => {
    const { safeReturn, } = this.props
    const { searchFilter } = this.state

    this.getSafeReturnToRedux({ params: (safeReturn.data && safeReturn.data.params) ?  { ...safeReturn.data.params, searchFilter, }  : this.state.paramsGet })
  }

  onSort = (data) => {
    const { safeReturn } = this.props
    this.getSafeReturnToRedux({ params: (safeReturn.data && safeReturn.data.params) ?  { ...safeReturn.data.params, sortBy: data, }  : this.state.paramsGet })
    this.setState({ sortSelected: data })
  }

  render() {
    const { safeReturnData, dialogChangeStatus, sortSelected} = this.state
    const { safeReturn } = this.props
    this.setData(safeReturn)

    const totalPages = []

    if (safeReturn.payload) {
      for (let i = 1; i <=  Math.ceil(safeReturn.payload.data.count / this.state.paramsGet.searchMax); i++) {
        totalPages.push(i);
      }
    }

    const currentPage = (safeReturn.data && safeReturn.data.params) ? safeReturn.data.params.searchPage : 1

    return (
      <StyledSafeReturn>
        <DialogChangeReportStatus
          dialogProps={{
            isVisible: dialogChangeStatus,
            isClose: () => this.setState({ dialogChangeStatus: false }),
            title: 'Change Report Status'
          }}
          onUpdateData={this.onUpdateData}
          safeReturnSelected={this.state.safeReturnSelected}
        />
        <div className="title-container">
          <h1 className="title">Safe Return Management</h1>
        </div>
        <CheckboxGroup onChange={this.onCheckboxChange}>
          <Table 
            columns={((this.props.account.payload) && (this.props.account.payload.data.account.type === USER_TYPE.ADMIN || this.props.account.payload.data.account.type === USER_TYPE.SUPERADMIN || this.props.account.payload.data.account.type === USER_TYPE.VIEWER)) ? 
              adminColumns({
                onClickColumn: (row) => {
                  this.setState({ 
                    dialogChangeStatus: true, 
                    idSafeReturn: row.original.id,
                    safeReturnSelected: row.original
                  })
                },
                sortBy:sortSelected,
                onSort:this.onSort
              })
            : 
              partnerColumns({
                onClickColumn: (row) => {
                  this.setState({ 
                    dialogChangeStatus: true, 
                    idSafeReturn: row.original.idSafeReturn,
                    safeReturnSelected: row.original
                  })
                },
                sortBy:sortSelected,
                onSort:this.onSort
              })
            }
            responsive
            data={safeReturnData}
            loading={safeReturn.fetching}
            dataLimit={AppConfig.DEFAULT_LIMIT_USER}
            pageSize={(safeReturn.data && safeReturn.data.params) ? safeReturn.data.params.searchMax : AppConfig.DEFAULT_LIMIT_USER}
            tableProps={{ showPageSizeOptions: false, showPagination: false,}}
            tableHeader={this.tableHeader()}
          />
        </CheckboxGroup>
        <Pagination
          page={currentPage}
          onClickPrevious={() => this.handlePagination(parseInt(currentPage) - 1)}
          onClickNext={() => this.handlePagination(parseInt(currentPage) + 1)}
          onClickPage={(number) => this.handlePagination(number)}
          totalPage={totalPages}
        />
      </StyledSafeReturn>
    )
  }
}

const mapStateToProps = (state) => ({
  safeReturn: state.safeReturn.list,
  account: state.auth.account,
})

const mapDispatchToProps = (dispatch) => {
  return {
    getSafeReturn: (params) => dispatch(SafeReturnActions.getSafeReturnRequest(params)),
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(SafeReturn)


const dropdownData = [
  {
    id: 1,
    name: 'Reporter Name, Ascending',
    sortBy: 'name,asc',
  },
  {
    id: 2,
    name: 'Reporter Name, Descending',
    sortBy: 'name,desc',
  },
  {
    id: 3,
    name: 'Phone, Ascending',
    sortBy: 'phone,asc',
  },
  {
    id: 4,
    name: 'Phone, Descending',
    sortBy: 'phone,desc',
  },
  {
    id: 5,
    name: 'Location, Ascending',
    sortBy: 'location,asc',
  },
  {
    id: 6,
    name: 'Location, Descending',
    sortBy: 'location,desc',
  },
  {
    id: 8,
    name: 'Report Date, Ascending',
    sortBy: 'createdAt,asc',
  },
  {
    id: 9,
    name: 'Report Date, Descending',
    sortBy: 'createdAt,desc',
  },
  {
    id: 10,
    name: 'Status, Ascending',
    sortBy: 'status,asc',
  },
  
  {
    id: 11,
    name: 'Status, Decending',
    sortBy: 'status,desc',
  },
]