import React, { useState, } from 'react'

import { StyledLogin, Wrapper,  buttonAllAccounts, buttonAllAplicants } from './styles/StyledLogin'
import { Typography, Colors } from '../themes'
import Container from '../components/container'
import Input from '../components/input'
import Button from '../components/button'

import { getSingleAccount } from '../api/UserAPI'
import ProccessData from '../lib/ProccessData'

const { Title, SubTitle, ErrorText } = Typography

function ShowUsername({
  location,
  history
}) { 
  const { organizationName, Account } = location.state.account || {}
  return (
    <Container>
      <StyledLogin>
        <Wrapper>
          <Title customStyle={` color: black; `}>Hello, {organizationName}</Title>
          <SubTitle customStyles={` color: black; margin-top: 10px; `}>Your Username is:</SubTitle>
          <Input 
            inputProps={{ 
              placeholder: "",
              value: Account && Account.username, 
              maxLength: 50, 
              disabled: true,
            }} 
            containerStyle={` margin-bottom: 33px; .input-field { cursor: not-allowed; } `} 
          />
          <Button 
            text={`Back to Login screen`}
            onClick={() => history.go(-2)}
          />
        </Wrapper>
      </StyledLogin>
    </Container>
  )
}

export default ShowUsername
