import { put, select } from 'redux-saga/effects'
import axios from 'axios'
import { AuthSelectors } from '../redux/AuthRedux'
import StartupActions from '../redux/StartupRedux'

// process STARTUP actions
export function * startup (api, action) {
  const account = yield select(AuthSelectors.account)
  if (account.payload) {
    if (account.payload.type === "SUCCESS") {
      if (account.payload.data.account) {
        api.api.setHeader('Authorization', `${account.payload.data.account.token}`)
        api.apiBenefits.setHeader('Authorization', `${account.payload.data.account.token}`)
        api.apiSolutions.setHeader('Authorization', `${account.payload.data.account.token}`)
        api.apiNotifications.setHeader('Authorization', `${account.payload.data.account.token}`)
        axios.defaults.headers.common = {
          "Authorization": `${account.payload.data.account.token}`,
        };
        yield put(StartupActions.initFinish())
      }
    }
  } else {
    yield put(StartupActions.initFinish())
  }
}
