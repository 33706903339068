import React from 'react';
import Modal from 'react-awesome-modal';
import moment from 'moment';

import {Colors, Images} from '../../themes';
import {StyledDialogDetailUser} from './StyledDialogDetail'
import { Container, Row, Col, Hidden, } from 'react-grid-system';
import UserDetail from '../../containers/UserDetail';

function DialogDetailUser({
  isVisible,
  isClose,
  user,
  mainUser,
}) {

  return (
    <StyledDialogDetailUser>
      <Modal
        visible={isVisible} 
        width="700px"
        effect="fadeInUp" 
        onClonClickAway={isClose}
      >
      { user && user.Account &&
        <div className="container-modal" style={{overflow: 'auto', padding: "20px 25px 20px 25px"}}>
          <div style={{display: 'flex', justifyContent: 'space-between', flexDirection: 'row', alignItems: 'center'}}>
            <div style={{display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'space-between'}}>
              <p className="title">{user && user.Account && user.Account.partnerDetail ? user.Account.partnerDetail.pocName : `${user.Account.firstname} ${user.Account.lastname}`}</p>
              <p className="symbol">|</p>
              <p className="title2">{mainUser.displayName}</p>
            </div>
            <img className="close" src={Images.iconClose2} alt="close" onClick={isClose}/>
          </div>

          <div className="card-container">
            <Row className="paddingDetail">
              <Col xs={5}>
                <p className="textDetail">Gender</p>
              </Col>
              <Col xs={7}>
                <Row>
                  <p className="textDetail2">:</p>
                  <p className="textDetail">{user.Account.gender}</p>
                </Row>
              </Col>
            </Row>
            <Row className="paddingDetail">
              <Col xs={5}>
                <p className="textDetail">Last 4 Character of NRIC</p>
              </Col>
              <Col xs={7}>
                <Row>
                  <p className="textDetail2">:</p>
                  <p className="textDetail">{user.Account.lastNric ? user.Account.lastNric : "-"}</p>
                </Row>
              </Col>
            </Row>
            <Row className="paddingDetail">
              <Col xs={5}>
                <p className="textDetail">Date of Birth</p>
              </Col>
              <Col xs={7}>
                <Row>
                  <p className="textDetail2">:</p>
                  <p className="textDetail">{user.Account.birth ? moment(user.Account.birth).format('DD MMMM YYYY') : "-"}</p>
                </Row>
              </Col>
            </Row>
            {user.relationship === "DEMENTIA" &&
            <div>
              <Row className="paddingDetail">
                <Col xs={5}>
                  <p className="textDetail">Preffered Language of Communication</p>
                </Col>
                <Col xs={7}>
                  <Row>
                    <p className="textDetail2">:</p>
                    <p className="textDetail">{user.dementiaDetail.language ? user.dementiaDetail.language : "-"}</p>
                  </Row>
                </Col>
              </Row>
              <Row className="paddingDetail">
                <Col xs={5}>
                  <p className="textDetail">Type of Dementia</p>
                </Col>
                <Col xs={7}>
                  <Row>
                    <p className="textDetail2">:</p>
                    <p className="textDetail">{user.dementiaDetail.typeDementia ? user.dementiaDetail.typeDementia : "-"}</p>
                  </Row>
                </Col>
              </Row>
              <Row className="paddingDetail">
                <Col xs={5}>
                  <p className="textDetail">Stage of Dementia</p>
                </Col>
                <Col xs={7}>
                  <Row>
                    <p className="textDetail2">:</p>
                    <p className="textDetail">{user.dementiaDetail.stageDementia ? user.dementiaDetail.stageDementia : "-"}</p>
                  </Row>
                </Col>
              </Row>
            </div>
            }
            
            <Row className="paddingDetail">
              <Col xs={5}>
                <p className="textDetail">Email Address</p>
              </Col>
              <Col xs={7}>
                <Row>
                  <p className="textDetail2">:</p>
                  <p className="textDetail">{user.Account.email ? user.Account.email : "-"}</p>
                </Row>
              </Col>
            </Row>
            <Row className="paddingDetail">
              <Col xs={5}>
                <p className="textDetail">Phone number</p>
              </Col>
              <Col xs={7}>
                <Row>
                  <p className="textDetail2">:</p>
                  <p className="textDetail">{user.Account.phone ? user.Account.phone : "-"}</p>
                </Row>
              </Col>
            </Row>
          </div>

          <div style={{padding: "30px 0px"}}>
            <hr style={{color: Colors.color40}} />
          </div>
          <div>
            <Row className="paddingDetail">
              <Col xs={5}>
                <p className="textDetail">Street Name</p>
              </Col>
              <Col xs={7}>
                <Row>
                  <p className="textDetail2">:</p>
                  <p className="textDetail">{user.Account.streetName ? user.Account.streetName : "-"}</p>
                </Row>
              </Col>
            </Row>
            <Row className="paddingDetail">
              <Col xs={5}>
                <p className="textDetail">Unit Number</p>
              </Col>
              <Col xs={7}>
                <Row>
                  <p className="textDetail2">:</p>
                  <p className="textDetail">{user.Account.unitNumber ? user.Account.unitNumber : "-"}</p>
                </Row>
              </Col>
            </Row>
            <Row className="paddingDetail">
              <Col xs={5}>
                <p className="textDetail">Postal Code</p>
              </Col>
              <Col xs={7}>
                <Row>
                  <p className="textDetail2">:</p>
                  <p className="textDetail">{user.Account.postalCode ? user.Account.postalCode : "-"}</p>
                </Row>
              </Col>
            </Row>
            <Row className="paddingDetail">
              <Col xs={5}>
                <p className="textDetail">City / Country</p>
              </Col>
              <Col xs={7}>
                <Row>
                  <p className="textDetail2">:</p>
                  <p className="textDetail">{user.Account.cityCountry ? user.Account.cityCountry : "-"}</p>
                </Row>
              </Col>
            </Row>
          </div>
        </div>
        }
      </Modal>
    </StyledDialogDetailUser>
  )
}

export default DialogDetailUser