import React, { Component, Fragment } from "react";
import { connect } from "react-redux";
import { Container, Row, Col, Hidden } from "react-grid-system";
import moment from "moment";
import axios from "axios";
import memoize from "memoize-one";
import _ from "lodash";
import RadioButton from "../components/radio-button/RadioButton";

import { StyledUserDetail } from "./styles/StyledUserDetail";

import { Images, Colors, Fonts } from "../themes";

import { ACCOUNT_STATUS, USER_TYPE } from "../constants";

import UserActions from "../redux/UserRedux";
import AuthActions from "../redux/AuthRedux";

import Dialog from "../components/modal";
import DialogInfo from "../components/dialog-info";
import Input from "../components/input";
import Button from "../components/button";

import ProccessData from "../lib/ProccessData";
import {
  deleteUser,
  editUser,
  updateAccountStatus,
  getOtherAccount,
} from "../api/UserAPI";
import memoizeOne from "memoize-one";

export class AdminDetail extends Component {
  static getDerivedStateFromProps(props, state) {
    const {
      checkDropdownValue,
      checkDropdownUppercaseValue,
      languageData,
      typeOfDementiaData,
      stageOfDementiaData,
      mounted,
    } = state;
    const { users, match } = props;

    let returnValue = {};

    if (!mounted) {
      const user =
        users &&
        users.payload &&
        users.payload.data.filter(
          (data) => data.uid === parseInt(match.params.id)
        )[0];

      returnValue = {
        user,
      };

      if (user) {
        const { uid, firstname, username, phone, email } = user;
        returnValue = {
          uid,
          user,
          firstname,
          username,
          email,
          phone,
          roleType: user.type,
          checkedadmin: user.type === USER_TYPE.ADMIN,
          checkedsuperadmin: user.type === USER_TYPE.SUPERADMIN,
          checkedviewer: user.type === USER_TYPE.VIEWER,
          mounted: true,
        };
      }
    }

    return {
      ...returnValue,
    };
  }
  constructor(props) {
    super(props);

    this.state = {
      uid: null,
      user: null,
      dialogConfirmation: false,
      loading: false,
      firstname: "",
      username: "",
      emai: "",
      phone: "",
      mounted: false,
      loadingEdit: false,
      dialogReject: false,
      loadingAccountStatus: false,
      dialogInfo: {
        onShow: false,
        description: "",
      },
      checkedsuperadmin: false,
      checkedadmin: false,
      checkedviewer: false,
      roleType: false,
      roleTypes: [
        {
          value: "SUPER_ADMIN",
          name: "Super Admin",
        },
        {
          value: "ADMIN",
          name: "Regular Admin",
        },
        {
          value: "VIEWER",
          name: "Viewer",
        },
      ],
    };

    this._onOkDialog = this._onOkDialog.bind(this);
    this._onUpdateAccountStatus = this._onUpdateAccountStatus.bind(this);
  }

  componentDidMount() {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    // this.props.getUsers({ data: { type: 'ADMIN', } })
    this.props.getUsers({ data: { type: ["ADMIN", "VIEWER", "SUPER_ADMIN"] } });
  }

  componentDidUpdate(prevProps, prevState) {
    const { users, match } = this.props;
    const user =
      users &&
      users.payload &&
      users.payload.data.filter(
        (data) => data.uid === parseInt(match.params.id)
      )[0];

    const prevPropsUsers = prevProps.users;
    const prevPropsMatch = prevProps.match;
    const prevPropsUser =
      prevPropsUsers &&
      prevPropsUsers.payload &&
      prevPropsUsers.payload.data.filter(
        (data) => data.uid === parseInt(match.params.id)
      )[0];

    if (user?.type !== prevPropsUser?.type) {
      if (user?.type === "SUPER_ADMIN") {
        this.setState({
          checkedsuperadmin: true,
          checkedadmin: false,
          checkedviewer: false,
        });
      } else if (user?.type === "ADMIN") {
        this.setState({
          checkedsuperadmin: false,
          checkedadmin: true,
          checkedviewer: false,
        });
      } else if (user?.type === "VIEWER") {
        this.setState({
          checkedsuperadmin: false,
          checkedadmin: false,
          checkedviewer: true,
        });
      }
    }
  }

  handleRadioButton = (e) => {
    this.setState((state) => ({
      roleType: e.target.value,
      checkedadmin: false,
      checkedsuperadmin: false,
      checkedviewer: false,
      [`checked${e.target.value.toLowerCase().split("_").join("")}`]: !state[
        `checked${e.target.value.toLowerCase().split("_").join("")}`
      ],
    }));
  };

  renderList(
    stateName,
    field,
    value,
    className = "",
    dropdown = {},
    inputProps = { type: "text" }
  ) {
    const { action } = this.props.location.state || "";

    const isInput = Object.keys(dropdown).length === 0;
    const type = inputProps;
    return (
      <Row
        style={{ width: "100%", marginLeft: ".1px" }}
        className={`list-container ${action === "DETAIL" ? className : ""}`}
      >
        <Col offset={{ lg: 0.6, xs: 1 }} xs={12}>
          <Row>
            <Col xs={5}>
              <p>{field}</p>
            </Col>
            <Col xs={7}>
              {action === "DETAIL" ? (
                <div style={{ display: "flex" }}>
                  <p style={{ marginRight: "22px" }}>: </p>
                  <p>{value ? value : "-"}</p>
                </div>
              ) : (
                <div style={{ display: "flex" }}>
                  <p style={{ marginRight: "22px" }}>: </p>
                  <div style={{ width: "84%" }}>
                    <Input
                      inputProps={{
                        value,
                        readOnly: isInput ? false : true,
                        onClick: () => !isInput && dropdown.onClick(),
                        onChange: (e) =>
                          this.setState({ [stateName]: e.target.value }),
                        ...inputProps,
                      }}
                      containerStyle={` margin:-8px 0 0 0; `}
                      {...dropdown}
                      dropdownWidth={`100%`}
                    />
                  </div>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    );
  }

  deleteUser = async (uid) => {
    this.setState({ loading: true });
    let newError = new Error("Error Delete Account");
    try {
      let resultDelete = {};
      resultDelete.fetching = false;
      resultDelete.payload = await deleteUser({ uidList: uid });
      ProccessData(resultDelete, "delete user")
        .then((res) => {
          this.setState({
            dialogConfirmation: false,
            loading: false,
          });
          this.onDialogInfo("Account Deleted");
          this.props.history.goBack();
        })
        .catch((err) => {
          this.onDialogInfo(err);
          this.setState({
            dialogConfirmation: false,
            loading: false,
          });
        });
    } catch (e) {
      this.onDialogInfo(e.message);
      this.setState({
        loading: false,
        dialogConfirmation: false,
      });
    }
  };

  onPressDropdown = (stateName, state) => {
    this.setState({ [stateName]: !state });
  };

  onChangeText = (name, value) => {
    this.setState({ [name]: value });
  };

  onEditAccount = async () => {
    this.setState({ loadingEdit: true });
    try {
      let resultEdit = {};
      resultEdit.fetching = false;
      const { user, firstname, phone, username, email } = this.state;
      const { uid } = user;

      let data = {
        uid,
        account: {
          type: this.state.roleType,
          firstname,
          phone: parseInt(phone),
          username,
          email,
          lastUpdatedBy: this.props.account.payload.data.account.username,
        },
      };
      // edit user itself
      resultEdit.payload = await editUser(data);
      resultEdit.payload = resultEdit.payload;
      ProccessData(resultEdit, "edit user")
        .then((res) => {
          this.setState({
            loadingEdit: false,
          });
          this.onDialogInfo("Account Updated");
          //  this.props.history.go(-2)
        })
        .catch((err) => {
          this.onDialogInfo(err);
          this.setState({
            dialogConfirmation: false,
            loadingEdit: false,
          });
        });
    } catch (e) {
      this.onDialogInfo(e.message);
      this.setState({
        loadingEdit: false,
      });
    }
  };

  _onOkDialog() {
    switch (this.state.dialogTitle) {
      case "Delete Account":
        this.deleteUser(this.state.uid);
        break;
      case "Reject Account":
        this.setState({ dialogReject: true });
        break;
      case "Approve Account":
        this._onUpdateAccountStatus(
          ACCOUNT_STATUS.PENDING_CARDS,
          "Approve account"
        );
        break;
      case "Send Card":
        this._onUpdateAccountStatus(ACCOUNT_STATUS.COMPLETED, "Send Card");
        break;
      case "Account Expired":
        this._onUpdateAccountStatus(
          ACCOUNT_STATUS.EXPIRED,
          "Set account to expire"
        );
        break;
      default:
        null;
    }
  }

  async _onUpdateAccountStatus(accountStatus, action) {
    this.setState({ loadingAccountStatus: true });

    try {
      let resultUpdate = {};
      resultUpdate.fetching = false;
      resultUpdate.payload = await updateAccountStatus({
        uid: this.state.user.uid,
        accountStatus,
        processedBy:
          accountStatus === ACCOUNT_STATUS.COMPLETED
            ? `${this.props.account.payload.data.account.username}`
            : undefined,
        isArchived: 0,
      });
      ProccessData(resultUpdate, action)
        .then((res) => {
          this.setState({
            dialogConfirmation: false,
            dialogReject: false,
            loadingAccountStatus: false,
          });
          this.onDialogInfo(`${action} Success`);
          this.props.getUsers({ data: {} });
          this.props.history.goBack();
        })
        .catch((err) => {
          this.onDialogInfo(err);
          this.setState({
            dialogConfirmation: false,
            dialogReject: false,
            loadingAccountStatus: false,
          });
        });
    } catch (e) {
      this.onDialogInfo(e.message);
      this.setState({
        loadingAccountStatus: false,
        dialogReject: false,
        dialogConfirmation: false,
      });
    }
  }

  onDialogInfo = (val) => {
    this.setState({ infoDescription: val, infoDialogShow: true });
  };

  onChangeText = (prop, value) => {
    this.setState({
      [prop]: value,
    });
  };

  render() {
    const { history } = this.props;
    const {
      user,
      dialogConfirmation,
      loading,
      dialogInfo,
      infoDialogShow,
      infoDescription,
      firstname,
      username,
      email,
      phone,
      uid,
      loadingEdit,
    } = this.state;
    const { accountStatus } = user || "";

    const disabledButton = !email || !firstname || loadingEdit;

    const { action } = this.props.location.state || "";

    return (
      <StyledUserDetail>
        {/* Dialog info start */}
        <DialogInfo
          isVisible={infoDialogShow}
          textInfo={infoDescription}
          onOkButton={() => {
            if (
              this.props.account &&
              this.props.account.payload &&
              this.props.account.payload.data &&
              this.props.account.payload.data.account &&
              this.props.account.payload.data.account.uid &&
              Number(this.props.account.payload.data.account.uid) ===
                Number(user.uid)
            ) {
              this.setState({ infoDialogShow: false });
              this.props.signOut();
              this.props.history.replace("/");
            } else {
              this.setState({ infoDialogShow: false });
            }
          }}
        />
        {/* Dialog info end  */}
        <Container className="wrapper">
          {user && (
            <div>
              <Row align="center">
                <Col lg={0.6} xs={1}>
                  <img
                    src={Images.back}
                    className="icon-back"
                    onClick={() => {
                      history.goBack();
                      this.setState({ user: null });
                    }}
                  />
                  {/* <button onClick={() => { this.setState({username:'', firstname: '', email: '', phone: '', type: '',  checkedsuperadmin: false, checkedadmin: false, checkedviewer: false,})}}>helo</button> */}
                </Col>
                <Col lg={11.4} xs={7}>
                  <Row align="center" justify="between">
                    <Col
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <div className="title-wrapper">
                        <h1 className="title-name">{`${user.firstname} ${
                          user.lastname ? user.lastname : ""
                        }`}</h1>
                      </div>
                      <div className="action">
                        {/* <div onClick={() => this.props.history.push({
                            pathname: `/user/${uid}`,
                            state: {
                              action: 'EDIT'
                            }
                            })}
                          >
                            <div className="edit-wrapper">
                              <img src={Images.edit} className="edit" />
                            </div>
                          </div>
                          <div onClick={() => this.setState({ dialogConfirmation: true, ...deleteDialogState, })}>
                            <div className="trash-wrapper">
                              <img src={Images.trash} className="delete" />
                            </div>
                          </div> */}
                      </div>
                    </Col>
                  </Row>
                </Col>
              </Row>
              <Row>
                <Col offset={{ lg: 0.6, xs: 1 }} xs={12}>
                  <h1 className="title-name title-admin">Admin Type :</h1>
                  <div
                    style={{
                      display: "flex",
                      width: "40%",
                      justifyContent: "space-between",
                    }}
                  >
                    {this.state.roleTypes.map((item, index) => (
                      <Fragment key={String(index)}>
                        <RadioButton
                          name={`role`}
                          value={item.value}
                          onChange={(e) => {
                            this.handleRadioButton(e);
                            this.onChangeText("type", e.target.value);
                          }}
                          placeholder={item.name}
                          checked={
                            this.state[
                              `checked${item.value
                                .toLowerCase()
                                .split("_")
                                .join("")}`
                            ]
                          }
                        />
                      </Fragment>
                    ))}
                  </div>
                </Col>
              </Row>
              <Row>
                <Col offset={{ lg: 0.6, xs: 1 }} xs={12}>
                  <h1 className="subtitle">Admin Details</h1>
                </Col>
                {[
                  // this.renderList('username', 'Username', username, 'list-dark'),
                  this.renderList("firstname", "Name", firstname, "list-dark"),
                  this.renderList("email", "Email", email, "list-dark"),
                  this.renderList(
                    "phone",
                    "Phone Number",
                    phone,
                    "",
                    {},
                    { type: "number" }
                  ),
                ]}
              </Row>
            </div>
          )}
        </Container>
        <div style={{ height: "20px" }} />
        {user && action === "EDIT" && (
          <div className="button-wrapper">
            <div style={{ width: "211px" }}>
              <Button
                text="Discard"
                textColor={Colors.color11}
                bordered
                buttonStyle={` background: ${Colors.primaryBgColor}; `}
                onClick={() => this.props.history.goBack()}
              />
            </div>
            <div style={{ width: "15px" }} />
            <div style={{ width: "211px" }}>
              <Button
                text={loadingEdit ? "Loading..." : "Save"}
                buttonStyle={` box-shadow: -3px -3px 10px ${Colors.color3}, 3px 3px 10px ${Colors.color23}; `}
                onClick={this.onEditAccount}
                disabled={disabledButton}
              />
            </div>
          </div>
        )}
      </StyledUserDetail>
    );
  }
}

const mapStateToProps = (state) => ({
  users: state.user.users,
  account: state.auth.account,
});

const mapDispatchToProps = (dispatch) => {
  return {
    getUsers: (params) => dispatch(UserActions.getUsersRequest(params)),
    signOut: () => dispatch(AuthActions.signout()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(AdminDetail);
