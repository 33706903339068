import { AllCheckerCheckbox, Checkbox, } from '@createnl/grouped-checkboxes';

import { Images } from '../themes'

export const columnsRelationship = ({ dementiaList, onClickColumn, }) => {
  return  [
    {
      Header: props => <div style={{ display: 'flex', margin: '10px 0', justifyContent: 'center', alignItems: 'center', }}> <AllCheckerCheckbox style={{ cursor: 'pointer', }} /></div>,
      Cell: props => <div style={{ display: 'flex', margin: '10px 0', justifyContent: 'center', alignItems: 'center', }}>  <Checkbox style={{ cursor: 'pointer', }} value={props.original}/></div>, 
      headerClassName: 'table-header',
      className: 'column-checkbox',
      width: 40,
      sortable: false,    
      resizable: false,
    },
    {
      Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>{`First Name`}</span>,
      Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{props.original.firstname}</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,       
      resizable: false,
    }, 
    {
      Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>{`Last Name`}</span>,
      Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{props.original.lastname ? props.original.lastname : '-'}</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,       
      resizable: false,
    }, 
    {
      Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>{`Membership ID`}</span>,
      Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{props.original.membershipId ? props.original.membershipId : `-`}</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,      
      resizable: false, 
    }, 
    {
      Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>{`Email Address`}</span>,
      Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{props.original.email ? props.original.email : `-`}</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,      
      resizable: false, 
    }, 
    {
      Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>{
        dementiaList ? `Tagged CG` : `Tagged PWD`
      }</span>,
      Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{
        props.original.otherAccount && 
          props.original.otherAccount.length > 0 ? 
            props.original.otherAccount[0].Account.uid ? 
              `${props.original.otherAccount[0].Account.firstname} ${props.original.otherAccount[0].Account.lastname}` 
            : '-' 
          : '-'
      }</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,      
      resizable: false, 
    }, 
    {
      Header: props => <span style={{ display: 'inline-block', margin: '10px 0' }}>{`Phone No.`}</span>,
      Cell: props => <span className="column-text" onClick={() => onClickColumn(props)}>{props.original.phone ? props.original.phone : `-`}</span>, 
      headerClassName: 'table-header',
      className: 'column',
      sortable: false,      
      resizable: false, 
    }, 
    {
      headerClassName: 'table-header',
      className: 'actions-column',
      width: 40,
      Cell: row => {
        return (
           <div className="icon-container">
            <div className="dots-wrapper">
              <img src={Images.threeDots} className="delete" />
            </div>
           </div>
        )
      },
      sortable: false,      
      resizable: false,
    }
  ]
}