import styled, { css } from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledForm = styled.div`
  background-color: ${Colors.color3};
  margin: ${props => props.action === 'EDIT' ? `0 132px 0 70px` : `0`}; 
  border-radius: 15px;
  padding: ${props => props.action === 'EDIT' ? `43px 30px 41px 15px` : `0`};
  .icon-back {
    width: 10px;
    height: 20px;
    margin-right: 39px;
    cursor: pointer;
  }

  .title-wrapper {
    display: flex;
    align-items: center;
  }

  h1 {
    ${Fonts.FontRegular}
    ${Fonts.size.size32}
    color: ${Colors.color2};
    font-weight: 800;
  }

  .image-big-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 272px;
    background-color: ${Colors.color6};
    margin-bottom: 15px;
  }

  .image-small-container {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    height: 140px;
    background-color: ${Colors.color6};
  }

  .button-wrapper {
    display: flex;
    justify-content: space-between;
    width: 100%;
  }

  .editor-input {
    padding: 16px 14px 16px 18px;
    outline: none;
    border: 1px solid ${Colors.color20};
    background-color: ${Colors.color3}; 
    border-radius: 10px;
  }

  .editor-wrapper {
    margin: 10px 0 25px 0; 
  }
`

export const Label = styled.label`
  ${Fonts.FontRegular}
  ${Fonts.size.size16}
  color: ${Colors.color19};
  font-weight: bold;
`

export const InputStyle = css` 
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin: 10px 0 25px 0; 
  .input-field {  
    width: 100%;
    padding: 16px 14px 16px 18px;
    outline: none;
  }
`

export const InputReadOnlyStyle = css`
  background-color: ${Colors.color3}; 
  border: 1px solid ${Colors.color20};
  margin: 10px 0 25px 0; 
  .input-field { cursor: pointer; } 
`

export const IconX = styled.img`
    position: absolute;
    width: 30px;
    z-index: 2;
    cursor: pointer;
    right: 4px; 
    top: 8px;
    ${props => props.position}
`