import styled from "styled-components";
import { Colors, Fonts, Scale } from '../../themes';
import { deviceSize } from '../../constants/deviceSize'

export const StyledPdfFile = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  
  .container-header {
    display: flex;
    width: 100%;
    height: 80px;
    background-color: ${Colors.orange};
    justify-content: center;
    align-items: center;
  }
  .title {
    color: ${Colors.color3};
    font-size: 30px;
    font-weight: 800;
    text-align: center;
  }
  .name {
    color: ${Colors.color13};
    font-size: 28px;
    font-weight: 800;
    text-align: center;
    margin-top: 20px;
    margin-bottom: 5px;
  }
  .label {
    color: ${Colors.color};
    font-size: 18px;
    line-height: 27px;
    font-weight: 800;
  }
  .container-detail {
    margin: 20px 80px;
  }
  .container-row {
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: space-between;
    margin-top: 20px;
    margin-bottom: 20px;
  }
  .container-place {
    width: 90%;
    height: auto;
    background-color: ${Colors.yellow};
    padding: 20px;
    border-radius: 10px;
  }
  .container-traits {
    width: 90%;
    height: auto;
    background-color: ${Colors.color9};
    padding: 20px;
    border-radius: 10px;
    margin-top: 10px;
  }
  .container-traitsText {
    width: 90%;
    background-color: ${Colors.color3};
    border-radius: 10px;
    padding: 20px;
    height: auto;
  }
  .container-image {
    width: 49%;
    flex-direction: column;
  }
  .row-image {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: flex-start;
  }
  .image {
    width: 140px;
    height: 190px;
    object-fit: cover;
  }
  .container-contact {
    padding: 20px 110px;
  }
`