import React, { Fragment } from "react";
import { connect } from "react-redux";
import memoize from "memoize-one";
import moment from "moment";
import { Container, Row, Col, Hidden } from "react-grid-system";
import {
  EditorState,
  convertToRaw,
  ContentState,
  convertFromRaw,
} from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import imageCompression from "browser-image-compression";
import { USER_TYPE } from "../constants";

import { Typography, Colors } from "../themes";
import {
  ButtonDementia,
  SearchBarStyle,
} from "../components/table/StyledTable";
import Card from "../components/card";
import Dialog from "../components/dialogBenefit";
import Button from "../components/button";
import DialogAddCategory from "../components/dialogBenefit/DialogAddCategory";
import Pagination from "../components/pagination";
import DialogInfo from "../components/dialog-info";
import DialogPopUp from "../components/modal";
import Input from "../components/input";

import BenefitsActions from "../redux/BenefitsRedux";

import { StyledBenefits } from "./styles/StyledBenefits";

import ProccessData from "../lib/ProccessData";

import {
  getTotalBenefits,
  getTotalBenefitCategories,
  getAllBenefitCategories,
} from "../api/BenefitsAPI";
import {
  addNotification,
  deleteNotificationByQuery,
} from "../api/NotificationAPI";

// import AppConfig from '../config/AppConfig'
import AppConfig from "../config.js";

const { Title, SubTitle } = Typography;

const defaultState = {
  // for benefits
  title: "",
  titleChinese: "",
  descriptions: EditorState.createEmpty(),
  descriptionsChinese: EditorState.createEmpty(),
  vendorName: "",
  vendorAddress: "",
  vendorContactNumber: "",
  vendorWebsite: "",
  vendorNameChinese: "",
  vendorAddressChinese: "",
  vendorContactNumberChinese: "",
  vendorWebsiteChinese: "",
  listingCategory: "",
  images: [],
  newImages: [],
  imageIndex: null,
  tags: [],
  tagsChinese: [],
  loading: false,
  selectedCategory: null,
  sendNotification: false,
  searchFilter: "",
  dialogPopUp: {
    show: false,
    title: "Confirm Listing",
    content: "Are you sure want to create this listing?",
    content2: "Any empty information will only appear in English.",
    okText: "Yes, Confirm",
    cancelText: "Cancel",
    state: "listing",
  },
};

const defaultCategoryState = {
  dialogCategory: false,
  categoryTitle: "",
  categoryTitleChinese: "",
  categoryImage: null,
  categoryImageChinese: null,
  categoryNewImage: null,
  categoryNewImageChinese: null,
};

class Benefits extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dialog: false,
      benefitsData: [],
      loading: false,
      sortDropdown: false,
      show: "BENEFITS",
      categories: [],
      allCategories: [],
      loadingPagination: true,
      loadingPaginationCategories: true,
      totalData: 0,
      totalDataCategories: 0,
      sortBy: {},
      ...defaultState,
      ...defaultCategoryState,
      dialogInfo: {
        onShow: false,
        description: "",
      },
    };

    this.onDelete = this.onDelete.bind(this);
    this.closeModal = this.closeModal.bind(this);
    this.openModal = this.openModal.bind(this);
    this.onDiscard = this.onDiscard.bind(this);
    this.onSearch = this.onSearch.bind(this);
    this.add = false;
    this.delete = false;
    this.addCategory = false;
    this.deleteCategory = false;
    this.page = 1;
    this.pageCategories = 1;
  }

  static getDerivedStateFromError(error) {
    // Update state so the next render will show the fallback UI.
  }

  htmltoText = (html) => {
    let text = html;
    text = text.replace(/\n/gi, "");
    text = text.replace(/<style([\s\S]*?)<\/style>/gi, "");
    text = text.replace(/<script([\s\S]*?)<\/script>/gi, "");
    text = text.replace(/<a.*?href="(.*?)[\?\"].*?>(.*?)<\/a.*?>/gi, " $2 $1 ");
    text = text.replace(/<\/div>/gi, "\n\n");
    text = text.replace(/<\/li>/gi, "\n");
    text = text.replace(/<li.*?>/gi, "  *  ");
    text = text.replace(/<\/ul>/gi, "\n\n");
    text = text.replace(/<\/p>/gi, "\n\n");
    text = text.replace(/<br\s*[\/]?>/gi, "\n");
    text = text.replace(/<[^>]+>/gi, "");
    text = text.replace(/^\s*/gim, "");
    text = text.replace(/ ,/gi, ",");
    text = text.replace(/ +/gi, " ");
    text = text.replace(/\n+/gi, "\n\n");
    // this is for remove &nbsp;
    text = text.replace(/&nbsp;/g, "");
    return text;
  };

  onDelete(id) {
    //function props to delete

    const confirmation = confirm(
      this.state.show === "BENEFITS"
        ? "Are you sure want to delete this benefit?"
        : "Are you sure want to delete this category?"
    );

    if (confirmation) {
      this.setState({ loading: true });
      if (this.state.show === "BENEFITS") {
        this.props.deleteBenefit({ id });
        deleteNotificationByQuery({ name: `BENEFIT_${id}` }).then(
          (res) => (this.page = 1)
        );
        this.delete = true;
      } else {
        this.props.deleteCategory({ id });
        this.deleteCategory = true;
        this.pageCategories = 1;
      }
    }
  }

  openModal() {
    const dialog = this.state.show === "BENEFITS" ? "dialog" : "dialogCategory";
    this.setState({
      [dialog]: true,
    });
  }

  closeModal() {
    const dialog = this.state.show === "BENEFITS" ? "dialog" : "dialogCategory";
    this.setState({
      [dialog]: false,
    });
  }

  componentDidMount() {
    this.setState({ loading: true });
    this.props.getBenefits({
      data: { page: this.page, limit: AppConfig.DEFAULT_LIMIT },
    });
    this.props.getBenefitCategories({
      data: { page: this.pageCategories, limit: 12 },
    });
    this.getTotalBenefits();
    this.getTotalBenefitCategories();
    this.getAllBenefitCategories();
  }

  componentWillUnmount() {
    localStorage.setItem("benefitPage", 1);
  }

  setData = memoize((data) => {
    ProccessData(data, "get benefits")
      .then((res) => {
        this.setState({
          loading: false,
          errorText: "",
          benefitsData: res.payload.data,
        });
      })
      .catch((err) => {
        this.onDialogInfo(err);
        this.setState({
          errorText: err
            ? err
            : "Something wrong, please check your internet connection",
          loading: false,
        });
      });
  });

  getAfterDelete = memoize((data) => {
    if (this.delete) {
      ProccessData(data, "delete benefit")
        .then((res) => {
          this.props.getBenefits({ data: {} });
          this.onDialogInfo("Data deleted");
          this.delete = false;
        })
        .catch((err) => {
          this.onDialogInfo(err);
          this.setState({
            errorText: err
              ? err
              : "Something wrong, please check your internet connection",
            loading: false,
          });
          this.delete = false;
        });
    }
  });

  addResult = memoize((data) => {
    const { fetching, payload, error } = data;
    if (this.add) {
      ProccessData(data, "add benefit")
        .then(async (res) => {
          const benefit = res.payload.data[0];
          if (this.state.sendNotification) {
            const data = {
              title: `Latest benefit at ${benefit.title}`,
              text: `${this.htmltoText(
                benefit.BenefitContent.content
              ).substring(0, 50)}...`,
              finishedPicture: benefit.BenefitImages[0].imageUrl,
              name: `BENEFIT_${benefit.id}`,
              platform: "Android, iOS",
              type: "BENEFITS",
              delivery: `NOW`,
              frequency: null,
              dateSend: moment(new Date()),
              accountType: "ALL",
              age: "ALL",
              typeDementia: "ALL",
              language: "ALL",
              gender: "ALL",
              stageDementia: "ALL",
              dataNotification: {
                targetScreen: "BenefitDetailScreen",
                benefitId: `${benefit.id}`,
              },
            };
            let pushNotification = await addNotification(data);
            if (pushNotification.type === "ERROR") {
              this.onDialogInfo("Failed to send push notification");
            }
          }
          this.setState({
            loading: true,
            errorText: "",
            dialog: false,
            ...defaultState,
          });
          this.props.getBenefits({ data: {} });
          this.onDialogInfo("Data Added");
          this.add = false;
        })
        .catch((err) => {
          this.onDialogInfo(err);
          this.setState({
            errorText: err
              ? err
              : "Something wrong, please check your internet connection",
            loading: false,
            dialog: false,
          });
          this.add = false;
        });
    }
  });

  setCategoriesData = memoize((data) => {
    ProccessData(data, "get benefit category")
      .then((res) => {
        this.setState({
          loading: false,
          errorText: "",
          categories: res.payload.data,
        });
      })
      .catch((err) => {
        this.onDialogInfo(err);
        this.setState({
          errorText: err
            ? err
            : "Something wrong, please check your internet connection",
          loading: false,
        });
      });
  });

  addCategoryResult = memoize((data) => {
    if (this.addCategory) {
      ProccessData(data, "add benefit category")
        .then((res) => {
          this.setState({
            loading: true,
            errorText: "",
            dialogCategory: false,
            ...defaultCategoryState,
          });
          this.props.getBenefitCategories({ data: {} });
          this.onDialogInfo("Data Added");
          this.addCategory = false;
        })
        .catch((err) => {
          this.onDialogInfo(err);
          this.setState({
            errorText: err
              ? err
              : "Something wrong, please check your internet connection",
            loading: false,
            dialogCategory: false,
            ...defaultCategoryState,
          });
          this.addCategory = false;
        });
    }
  });

  getCategoryAfterDelete = memoize((data) => {
    if (this.deleteCategory) {
      ProccessData(data, "delete benefit category")
        .then((res) => {
          this.props.getBenefitCategories({ data: {} });
          this.onDialogInfo("Data deleted");
          this.deleteCategory = false;
        })
        .catch((err) => {
          this.onDialogInfo(err);
          this.setState({
            errorText: err
              ? err
              : "Something wrong, please check your internet connection",
            loading: false,
          });
          this.deleteCategory = false;
        });
    }
  });

  onChangeText = (name, value) => {
    this.setState({ [name]: value });
  };

  onChangeImage = async (e, imageUrlKey) => {
    const { imageIndex } = this.state;
    const imageFile = e.target.files[0];

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1920,
      useWebWorker: true,
    };

    try {
      const compressedFile = await imageCompression(imageFile, options);
      const reader = new FileReader();
      reader.onloadend = () => {
        let newImages = [...this.state.newImages];
        let images = [...this.state.images];
        let image = images[imageIndex];
        let changedImage = {
          ...image,
          [imageUrlKey === "base64ImageChinese"
            ? "imageUrlChinese"
            : "imageUrl"]: reader.result,
        };

        if (newImages.length === 0) {
          newImages.push({ order: 1, [imageUrlKey]: reader.result });
        } else {
          for (let i = 0; i < newImages.length; i++) {
            if (!newImages[i][imageUrlKey]) {
              newImages[i][imageUrlKey] = reader.result;
              break;
            } else if (i === newImages.length - 1) {
              newImages.push({ order: 1, [imageUrlKey]: reader.result });
              break;
            } else {
              continue;
            }
          }
        }

        images.splice(imageIndex, 1, changedImage);

        this.setState({ images, newImages });
        e.target.value = null;
      };

      reader.readAsDataURL(compressedFile);
    } catch (error) {}
  };

  onAdd = () => {
    const {
      selectedCategory,
      id,
      title,
      titleChinese,
      descriptions,
      descriptionsChinese,
      vendorName,
      vendorAddress,
      vendorContactNumber,
      vendorWebsite,
      vendorNameChinese,
      vendorAddressChinese,
      vendorContactNumberChinese,
      vendorWebsiteChinese,
      newImages,
      tags,
      tagsChinese,
      dialogPopUp,
    } = this.state;
    this.setState({
      loading: true,
      dialogPopUp: { ...dialogPopUp, show: false },
    });

    const content = draftToHtml(convertToRaw(descriptions.getCurrentContent()));
    const contentChinese = draftToHtml(
      convertToRaw(descriptionsChinese.getCurrentContent())
    );

    this.props.addBenefit({
      id,
      data: {
        categoryId: selectedCategory.id,
        title,
        titleChinese,
        content,
        contentChinese,
        iconUrl: "",
        vendor: {
          name: vendorName,
          address: vendorAddress,
          contactNumber: parseInt(vendorContactNumber),
          website: vendorWebsite,
          nameChinese: vendorNameChinese,
          addressChinese: vendorAddressChinese,
          contactNumberChinese: parseInt(vendorContactNumberChinese),
          websiteChinese: vendorWebsiteChinese,
        },
        images: newImages.length > 0 ? newImages : null,
        tags: tags.map((item) => item.text).join("--"),
        tagsChinese: tagsChinese.map((item) => item.text).join("--"),
      },
    });

    this.add = true;
  };

  onDiscard = () => {
    this.setState(defaultState);
    this.setState(defaultCategoryState);
    this.closeModal();
  };

  // onChangeCategoryImage = (e, stateTarget) => {
  //   console.log("MASUK => ", e.target.value)
  //   console.log("STATE => ", stateTarget)
  //   const reader = new FileReader()
  //   const file = e.target.files[0]

  //   let data = {}

  //   if(stateTarget === 'categoryImageChinese') {
  //     data = {
  //       categoryNewImageChinese: reader.result,
  //       categoryNewImage: reader.result
  //     }
  //   } else {
  //     data = {
  //       categoryImage: reader.result,
  //       categoryNewImage: reader.result
  //     }
  //   }

  //   console.log("INI DATA => ", data)

  //   reader.onloadend = () => {
  //     this.setState(data)
  //   }

  //   reader.readAsDataURL(file);
  // };
  onChangeCategoryImage = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        categoryImage: reader.result,
        categoryNewImage: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };

  onChangeCategoryImageChinese = (e) => {
    const reader = new FileReader();
    const file = e.target.files[0];

    reader.onloadend = () => {
      this.setState({
        categoryImageChinese: reader.result,
        categoryNewImageChinese: reader.result,
      });
    };

    reader.readAsDataURL(file);
  };

  onValidationCategory = () => {
    const {
      categoryTitle,
      categoryTitleChinese,
      categoryNewImage,
      categoryNewImageChinese,
    } = this.state;

    if (
      (!categoryTitle && !categoryTitleChinese) ||
      (!categoryNewImage && !categoryNewImageChinese) ||
      (!categoryTitleChinese && !categoryNewImageChinese)
    ) {
      return this.setState({
        dialogPopUp: {
          ...this.state.dialogPopUp,
          show: true,
          title: "Confirm Category",
          content: "Are you sure want to create this category?",
          content2: "Any empty information will only appear in English.",
          state: "category",
        },
      });
    } else if (!categoryTitle && !categoryNewImage) {
      return this.setState({
        dialogPopUp: {
          ...this.state.dialogPopUp,
          show: true,
          title: "Confirm Category",
          content: "Are you sure want to create this category?",
          content2: "Any empty information will only appear in Mandarin.",
          state: "category",
        },
      });
    } else {
      return this.onAddCategory();
    }
  };

  onValidationListing = () => {
    const {
      selectedCategory,
      id,
      title,
      titleChinese,
      descriptions,
      descriptionsChinese,
      vendorName,
      vendorAddress,
      vendorContactNumber,
      vendorWebsite,
      vendorNameChinese,
      vendorAddressChinese,
      vendorContactNumberChinese,
      vendorWebsiteChinese,
      newImages,
      tags,
      tagsChinese,
    } = this.state;

    if (
      !selectedCategory ||
      (!title && !titleChinese) ||
      (!descriptions && !descriptionsChinese) ||
      (!vendorName && !vendorNameChinese) ||
      (!vendorContactNumber && !vendorContactNumberChinese) ||
      (!vendorWebsite && !vendorWebsiteChinese) ||
      !newImages ||
      (!tags && !tagsChinese) ||
      (!titleChinese &&
        !vendorNameChinese &&
        !vendorContactNumberChinese &&
        !vendorWebsiteChinese)
    ) {
      return this.setState({
        dialogPopUp: {
          ...this.state.dialogPopUp,
          show: true,
          title: "Confirm Listing",
          content: "Are you sure want to create this listing?",
          state: "listing",
          content2: "Any empty information will only appear in English.",
        },
      });
    } else if (
      !title &&
      !vendorName &&
      !vendorContactNumber &&
      !vendorWebsite
    ) {
      return this.setState({
        dialogPopUp: {
          ...this.state.dialogPopUp,
          show: true,
          title: "Confirm Listing",
          content: "Are you sure want to create this listing?",
          state: "listing",
          content2: "Any empty information will only appear in Mandarin.",
        },
      });
    } else {
      return this.onAdd();
    }
  };

  onAddCategory = () => {
    const {
      categoryTitle,
      categoryTitleChinese,
      categoryNewImage,
      categoryNewImageChinese,
      dialogPopUp,
    } = this.state;

    this.setState({
      loading: true,
      dialogPopUp: { ...dialogPopUp, show: false },
    });

    this.props.addCategory({
      data: {
        title: categoryTitle ? categoryTitle : "",
        titleChinese: categoryTitleChinese ? categoryTitleChinese : "",
        description: "",
        order: 1,
        base64Image: categoryNewImage ? categoryNewImage : "",
        base64ImageChinese: categoryNewImageChinese
          ? categoryNewImageChinese
          : "",
      },
    });

    this.addCategory = true;
  };

  onDeleteImage = (imageData, imageIndex) => {
    let images = [...this.state.images];
    let newImages = [...this.state.newImages];

    images.splice(imageIndex, 1);
    newImages.splice(imageIndex, 1);

    this.setState({ images, newImages });
  };

  onEditorStateChange = (descriptions) => {
    this.setState({
      descriptions,
    });
  };

  onEditorChineseStateChange = (descriptionsChinese) => {
    this.setState({
      descriptionsChinese,
    });
  };

  getTotalBenefits = async () => {
    try {
      const { searchFilter } = this.state;
      let resultGet = {};
      resultGet.fetching = false;
      resultGet.payload = await getTotalBenefits({
        limit: 99999999,
        title: `${searchFilter}`,
      });
      ProccessData(resultGet, "get total data")
        .then((res) => {
          this.setState({
            loadingPagination: false,
            totalData: res.payload.data.length,
          });
        })
        .catch((err) => {
          this.onDialogInfo(err);
          this.setState({
            loadingPagination: false,
          });
        });
    } catch (e) {
      this.onDialogInfo(e.message);
      this.setState({
        loadingPagination: false,
      });
    }
  };

  getTotalBenefitCategories = async () => {
    try {
      const { searchFilter } = this.state;
      let resultGet = {};
      resultGet.fetching = false;
      resultGet.payload = await getTotalBenefitCategories({
        limit: 99999999,
        title: `${searchFilter}`,
      });
      ProccessData(resultGet, "get total data")
        .then((res) => {
          this.setState({
            loadingPaginationCategories: false,
            totalDataCategories: res.payload.data.length,
          });
        })
        .catch((err) => {
          this.onDialogInfo(err);
          this.setState({
            loadingPaginationCategories: false,
          });
        });
    } catch (e) {
      this.onDialogInfo(e.message);
      this.setState({
        loadingPaginationCategories: false,
      });
    }
  };

  getAllBenefitCategories = async () => {
    try {
      let resultGet = {};
      resultGet.fetching = false;
      resultGet.payload = await getAllBenefitCategories({});
      ProccessData(resultGet, "get total data")
        .then((res) => {
          this.setState({
            allCategories: res.payload.data,
          });
        })
        .catch((err) => {
          this.onDialogInfo(err);
        });
    } catch (e) {
      this.onDialogInfo(e.message);
    }
  };

  onPressPreviousPage = () => {
    this.setState({ loading: true });
    let newPage = parseInt(this.page) - 1;
    this.page = newPage;
    const { searchFilter } = this.state;
    // localStorage.setItem("benefitPage", newPage)

    if (this.state.sortBy) {
      this.props.getBenefits({
        data: {
          page: newPage,
          limit: AppConfig.DEFAULT_LIMIT,
          sortBy: this.state.sortBy.sortBy,
          title: `${searchFilter}`,
        },
      });
    } else {
      this.props.getBenefits({
        data: {
          page: newPage,
          limit: AppConfig.DEFAULT_LIMIT,
          title: `${searchFilter}`,
        },
      });
    }
  };

  onPressNextPage = () => {
    this.setState({ loading: true });
    let newPage = parseInt(this.page) + 1;
    this.page = newPage;
    const { searchFilter } = this.state;
    // localStorage.setItem("benefitPage", newPage)

    if (this.state.sortBy) {
      this.props.getBenefits({
        data: {
          page: newPage,
          limit: AppConfig.DEFAULT_LIMIT,
          sortBy: this.state.sortBy.sortBy,
          title: `${searchFilter}`,
        },
      });
    } else {
      this.props.getBenefits({
        data: {
          page: newPage,
          limit: AppConfig.DEFAULT_LIMIT,
          title: `${searchFilter}`,
        },
      });
    }
  };

  onPressPreviousPageCategories = () => {
    this.setState({ loading: true });
    let newPage = parseInt(this.pageCategories) - 1;
    this.pageCategories = newPage;
    const { searchFilter } = this.state;

    this.props.getBenefitCategories({
      data: {
        page: newPage,
        limit: AppConfig.DEFAULT_LIMIT,
        title: `${searchFilter}`,
      },
    });
  };

  onPressNextPageCategories = () => {
    this.setState({ loading: true });
    let newPage = parseInt(this.pageCategories) + 1;
    this.pageCategories = newPage;
    const { searchFilter } = this.state;

    this.props.getBenefitCategories({
      data: {
        page: newPage,
        limit: AppConfig.DEFAULT_LIMIT,
        title: `${searchFilter}`,
      },
    });
  };

  onPressPage = (page) => {
    this.setState({ loading: true });
    this.page = page;
    const { searchFilter } = this.state;
    // localStorage.setItem("benefitPage", page)

    if (this.state.sortBy) {
      this.props.getBenefits({
        data: {
          page,
          limit: AppConfig.DEFAULT_LIMIT,
          sortBy: this.state.sortBy.sortBy,
          title: `${searchFilter}`,
        },
      });
    } else {
      this.props.getBenefits({
        data: {
          page,
          limit: AppConfig.DEFAULT_LIMIT,
          title: `${searchFilter}`,
        },
      });
    }
  };

  onPressPageCategories = (page) => {
    this.setState({ loading: true });
    this.pageCategories = page;
    const { searchFilter } = this.state;
    this.props.getBenefitCategories({
      data: { page, limit: 12, title: `${searchFilter}` },
    });
  };

  onDialogInfo = (val) => {
    this.setState({ infoDescription: val, infoDialogShow: true });
  };

  onSearch = async () => {
    const { safeReturn } = this.props;
    const { searchFilter } = this.state;

    this.setState({
      loading: true,
      loadingPagination: true,
      loadingPaginationCategories: true,
    });

    await this.props.getBenefits({ data: { title: `${searchFilter}` } });
    await this.props.getBenefitCategories({
      data: { title: `${searchFilter}` },
    });
    await this.getTotalBenefits();
    await this.getTotalBenefitCategories();

    this.setState({
      loading: false,
      loadingPagination: false,
      loadingPaginationCategories: false,
    });
  };

  onSort = async (data) => {
    const { safeReturn } = this.props;
    const { searchFilter } = this.state;

    this.setState({ loading: true, sortBy: data });
    this.props.getBenefits({
      data: {
        sortBy: data.sortBy,
        page: this.page,
        limit: AppConfig.DEFAULT_LIMIT,
      },
    });

    this.setState({
      loading: true,
      loadingPagination: true,
      loadingPaginationCategories: true,
    });

    await this.props.getBenefits({
      data: {
        sortBy: data.sortBy,
        page: this.page,
        limit: AppConfig.DEFAULT_LIMIT,
        title: `${searchFilter}`,
      },
    });
    await this.props.getBenefitCategories({
      data: {
        sortBy: data.sortBy,
        page: this.page,
        limit: AppConfig.DEFAULT_LIMIT,
        title: `${searchFilter}`,
      },
    });

    this.setState({
      loading: false,
      loadingPagination: false,
      loadingPaginationCategories: false,
    });
  };

  onPressEnterSearch = (event) => {
    if (event.key === "Enter") {
      return this.onSearch();
    }
  };

  render() {
    const {
      benefitsData,
      infoDialogShow,
      infoDescription,
      loading,
      sortDropdown,
      title,
      titleChinese,
      descriptions,
      descriptionsChinese,
      vendorName,
      vendorAddress,
      vendorContactNumber,
      vendorWebsite,
      vendorNameChinese,
      vendorAddressChinese,
      vendorContactNumberChinese,
      vendorWebsiteChinese,
      images,
      newImages,
      imageIndex,
      listingCategory,
      selectedCategory,
      show,
      categories,
      categoryTitle,
      categoryTitleChinese,
      categoryImage,
      categoryImageChinese,
      categoryNewImage,
      loadingPagination,
      loadingPaginationCategories,
      totalData,
      totalDataCategories,
      sortBy,
      sendNotification,
      tags,
      tagsChinese,
      dialogPopUp,
      allCategories,
    } = this.state;
    const {
      benefits,
      deleteBenefitResult,
      history,
      benefitCategories,
      deleteCategoryResult,
    } = this.props;

    this.setData(benefits);
    this.getAfterDelete(deleteBenefitResult);
    this.addResult(this.props.addBenefitResult);
    this.setCategoriesData(benefitCategories);
    this.addCategoryResult(this.props.addCategoryResult);
    this.getCategoryAfterDelete(deleteCategoryResult);

    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(totalData / AppConfig.DEFAULT_LIMIT); i++) {
      pageNumbers.push(i);
    }

    const pageNumberCategories = [];
    for (
      let i = 1;
      i <= Math.ceil(totalDataCategories / AppConfig.DEFAULT_LIMIT);
      i++
    ) {
      pageNumberCategories.push(i);
    }

    return (
      <StyledBenefits>
        <Dialog
          isVisible={this.state.dialog}
          isClose={() => this.closeModal()}
          onDiscard={() => this.onDiscard()}
          title="Create New Benefit"
          action="ADD"
          benefitTitle={title}
          benefitTitleChinese={titleChinese}
          descriptions={descriptions}
          descriptionsChinese={descriptionsChinese}
          vendorName={vendorName}
          vendorAddress={vendorAddress}
          vendorContactNumber={vendorContactNumber}
          vendorWebsite={vendorWebsite}
          vendorNameChinese={vendorNameChinese}
          vendorAddressChinese={vendorAddressChinese}
          vendorContactNumberChinese={vendorContactNumberChinese}
          vendorWebsiteChinese={vendorWebsiteChinese}
          listingCategory={listingCategory}
          onChangeText={this.onChangeText}
          images={images}
          newImages={newImages}
          onChangeImage={this.onChangeImage}
          onSelectImage={(imageIndex) => this.setState({ imageIndex })}
          // onSave={this.onAdd}
          onSave={this.onValidationListing}
          loading={loading}
          dropdownData={allCategories ? allCategories : []}
          dropdownPropertyName="title"
          onSelectDropdown={(selectedCategory) =>
            this.setState({ selectedCategory })
          }
          selectedCategory={selectedCategory}
          onDeleteImage={this.onDeleteImage}
          onEditorStateChange={this.onEditorStateChange}
          onEditorChineseStateChange={this.onEditorChineseStateChange}
          onChangeSendNotification={(sendNotification) =>
            this.setState({ sendNotification })
          }
          inputTagsProps={{
            tagsData: tags,
            onAddTag: (tag) => {
              this.setState((state) => ({ tags: [...state.tags, tag] }));
            },
            onDeleteTag: (i) => {
              this.setState({
                tags: tags.filter((tag, index) => index !== i),
              });
            },
            onDragTag: (tag, currPos, newPos) => {
              const tags = [...this.state.tags];
              const newTags = tags.slice();

              newTags.splice(currPos, 1);
              newTags.splice(newPos, 0, tag);

              // re-render
              this.setState({ tags: newTags });
            },
            onClearData: () =>
              this.setState({ dialog: false, ...defaultState }),
          }}
          inputTagsChineseProps={{
            tagsData: tagsChinese,
            onAddTag: (tag) => {
              this.setState((state) => ({
                tagsChinese: [...state.tagsChinese, tag],
              }));
            },
            onDeleteTag: (i) => {
              this.setState({
                tagsChinese: tags.filter((tag, index) => index !== i),
              });
            },
            onDragTag: (tag, currPos, newPos) => {
              const tags = [...this.state.tags];
              const newTags = tags.slice();

              newTags.splice(currPos, 1);
              newTags.splice(newPos, 0, tag);

              // re-render
              this.setState({ tagsChinese: newTags });
            },
            onClearData: () =>
              this.setState({ dialog: false, ...defaultState }),
          }}
        />
        <DialogAddCategory
          isVisible={this.state.dialogCategory}
          action="ADD"
          dialogTitle="Create New Category"
          isClose={() => this.closeModal()}
          onDiscard={() => this.onDiscard()}
          title={categoryTitle}
          titleChinese={categoryTitleChinese}
          stateTitle={"categoryTitle"}
          stateTitleChinese={"categoryTitleChinese"}
          image={categoryImage}
          imageChinese={categoryImageChinese}
          loading={loading}
          onChangeText={this.onChangeText}
          onChangeImage={this.onChangeCategoryImage}
          onChangeImageChinese={this.onChangeCategoryImageChinese}
          // onSave={this.onAddCategory}
          onSave={this.onValidationCategory}
        />
        {/* Dialog info start */}
        <DialogInfo
          isVisible={infoDialogShow}
          textInfo={infoDescription}
          onOkButton={() => this.setState({ infoDialogShow: false })}
        />
        {/* Dialog info end  */}
        <DialogPopUp
          isVisible={dialogPopUp.show}
          okText={dialogPopUp.okText}
          cancelText={dialogPopUp.cancelText}
          onOk={
            dialogPopUp.state === "category"
              ? this.onAddCategory
              : dialogPopUp.state === "listing"
              ? this.onAdd
              : ""
          }
          onCancel={() =>
            this.setState({ dialogPopUp: { ...dialogPopUp, show: false } })
          }
          isClose={() =>
            this.setState({ dialogPopUp: { ...dialogPopUp, show: false } })
          }
          height={"250px"}
          okBackgroundColor={Colors.color42}
          okTextColor={"white"}
        >
          <p
            style={{
              color: Colors.color19,
              fontSize: "26px",
              fontWeight: "800",
            }}
          >
            {dialogPopUp.title}
          </p>
          <p
            style={{
              color: Colors.color2,
              fontSize: "17px",
              fontWeight: "700",
              marginTop: "30px",
            }}
          >
            {dialogPopUp.content2}
          </p>
          <p
            style={{
              color: Colors.color2,
              fontSize: "17px",
              fontWeight: "700",
              marginTop: "10px",
            }}
          >
            {dialogPopUp.content}
          </p>
        </DialogPopUp>
        <div className="container">
          <div className="header">
            <Title customStyle={` color: black; `}>Benefits</Title>
            <div className="button-wrapper">
              {show === "BENEFITS"
                ? this.props.account &&
                  this.props.account.payload &&
                  this.props.account.payload.data &&
                  this.props.account.payload.data.account &&
                  this.props.account.payload.data.account.type &&
                  this.props.account.payload.data.account.type !==
                    USER_TYPE.VIEWER && (
                    <>
                      {/* <Button 
                        text="Sort by"
                        bordered
                        textColor={Colors.color12}
                        buttonStyle={` font-weight: bold; width: 136px; display: flex; justify-content: space-around; `}
                        iconRight
                        onClick={() => this.setState({ sortDropdown: !sortDropdown })}
                        showDropdown={sortDropdown}
                        dropdownData={[
                          {
                            id: 1,
                            name: 'Title, A-Z',
                            sortBy: 'title,asc' 
                          },
                          {
                            id: 2,
                            name: 'Title, Z-A',
                            sortBy: 'title,desc'
                          }
                        ]}
                        dropdownHeight={`60px`}
                        onSelectSort={(data) => {
                          this.setState({ loading: true, sortBy: data, })
                          this.props.getBenefits({ data: { sortBy: data.sortBy, page: this.page, limit: AppConfig.DEFAULT_LIMIT } })
                        }}
                        selectedData={sortBy.name}
                      /> */}
                      <div style={{ width: 25 }} />
                      <Button
                        text={"Create New Listing"}
                        buttonStyle={` width: 315px; `}
                        textStyle={` font-weight: 800; `}
                        onClick={() => this.openModal()}
                      />
                    </>
                  )
                : this.props.account &&
                  this.props.account.payload &&
                  this.props.account.payload.data &&
                  this.props.account.payload.data.account &&
                  this.props.account.payload.data.account.type &&
                  this.props.account.payload.data.account.type ===
                    USER_TYPE.SUPERADMIN && (
                    <Button
                      text={"Create New Category"}
                      buttonStyle={` width: 315px; `}
                      textStyle={` font-weight: 800; `}
                      onClick={() => this.openModal()}
                    />
                  )}
            </div>
          </div>
          <Row style={{ marginBottom: "30px" }}>
            <Col lg={3}>
              <Button
                text="Listings"
                backgroundColor={
                  show === "BENEFITS" ? Colors.color10 : Colors.color9
                }
                textColor={show === "BENEFITS" ? undefined : Colors.color7}
                onClick={() => {
                  this.setState({ show: "BENEFITS" });
                  this.props.getBenefits({
                    data: {
                      page: this.page,
                      limit: AppConfig.DEFAULT_LIMIT,
                      title: `${this.state.searchFilter}`,
                    },
                  });
                  this.props.getBenefitCategories({
                    data: {
                      page: this.pageCategories,
                      limit: AppConfig.DEFAULT_LIMIT,
                      title: `${this.state.searchFilter}`,
                    },
                  });
                  this.getTotalBenefits();
                }}
              />
            </Col>
            <Col lg={3}>
              <Button
                text="Categories"
                backgroundColor={
                  show === "CATEGORIES" ? Colors.color10 : Colors.color9
                }
                textColor={show === "CATEGORIES" ? undefined : Colors.color7}
                onClick={() => {
                  this.setState({ show: "CATEGORIES" });
                  this.props.getBenefits({
                    data: {
                      page: this.page,
                      limit: AppConfig.DEFAULT_LIMIT,
                      title: `${this.state.searchFilter}`,
                    },
                  });
                  this.props.getBenefitCategories({
                    data: {
                      page: this.pageCategories,
                      limit: AppConfig.DEFAULT_LIMIT,
                      title: `${this.state.searchFilter}`,
                    },
                  });
                  this.getTotalBenefitCategories();
                }}
              />
            </Col>
            <Col lg={6} style={{ marginTop: "-8px" }}>
              <div
                className="searchbar-container"
                style={{
                  paddingRight: 0,
                  display: "flex",
                  alignItems: "center",
                }}
              >
                <Input
                  containerStyle={SearchBarStyle}
                  inputProps={{
                    placeholder: "Search",
                    onChange: (e) =>
                      this.setState({ searchFilter: e.target.value }),
                  }}
                  onKeyDown={(e) => this.onPressEnterSearch(e)}
                />
                <div style={{ marginLeft: "10px" }} />
                <Button
                  text="Search"
                  buttonStyle={ButtonDementia}
                  backgroundColor={Colors.color8}
                  textColor={Colors.color3}
                  onClick={this.onSearch}
                />
                <div style={{ marginLeft: "10px" }} />
                <Button
                  text="Sort by"
                  bordered
                  textColor={Colors.color12}
                  buttonStyle={` font-weight: bold; width: 136px; display: flex; justify-content: space-around; `}
                  iconRight
                  onClick={() => this.setState({ sortDropdown: !sortDropdown })}
                  showDropdown={sortDropdown}
                  dropdownData={dropdownData}
                  dropdownHeight={`60px`}
                  onSelectSort={(data) => this.onSort(data)}
                  selectedData={sortBy.name}
                />
              </div>
            </Col>
          </Row>
          {loading ? (
            <div>LOADING...</div>
          ) : show === "BENEFITS" ? (
            <div className="row">
              <Container>
                <Row>
                  {benefitsData.length > 0 ? (
                    benefitsData.map((data, i) => {
                      return (
                        <Card
                          {...data}
                          onEdit={() => history.push(`/benefit/${data.id}`)}
                          // onEdit={() => console.log("DATA id => ", data.id)}
                          onDelete={() => this.onDelete(data.id)}
                          image={
                            data.BenefitImages.length > 0 &&
                            (data.BenefitImages[0].imageUrl ||
                              (data.BenefitImages[0].imageUrlChinese &&
                                data.BenefitImages[0].imageUrlChinese))
                              ? data.BenefitImages[0].imageUrl ||
                                data.BenefitImages[0].imageUrlChinese
                              : null
                          }
                          content={
                            data.BenefitContent && data.BenefitContent.content
                          }
                          key={i.toString()}
                          tabActive={"benefits"}
                          isBenefits
                        />
                      );
                    })
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "5vh",
                        width: "100vw",
                        textAlign: "center",
                      }}
                    >
                      <p>Data not found</p>
                    </div>
                  )}
                </Row>
              </Container>
              {!loadingPagination && pageNumbers.length > 1 && (
                <Pagination
                  page={this.page}
                  onClickPrevious={this.onPressPreviousPage}
                  onClickNext={this.onPressNextPage}
                  onClickPage={(number) => this.onPressPage(number)}
                  totalPage={pageNumbers}
                />
              )}
            </div>
          ) : (
            <div className="row">
              <Container>
                <Row>
                  {categories.length > 0 ? (
                    categories.map((data, i) => {
                      return (
                        <Card
                          {...data}
                          onEdit={() =>
                            history.push(`/benefit-category/${data.id}`)
                          }
                          onDelete={() => this.onDelete(data.id)}
                          column={3}
                          image={
                            data.imageUrl ? data.imageUrl : data.imageUrlChinese
                          }
                          imageStyle={` width: 120px; height: 120px; margin: 0 auto; `}
                          titleStyle={` margin-top: 20px; border-top: 1px solid #CDD6E2; padding-top: 18px; `}
                          key={i.toString()}
                          tabActive={"categoryBenefits"}
                          isBenefits
                        />
                      );
                    })
                  ) : (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        height: "5vh",
                        width: "100vw",
                        textAlign: "center",
                      }}
                    >
                      <p>Data not found</p>
                    </div>
                  )}
                </Row>
                {!loadingPaginationCategories &&
                  pageNumberCategories.length > 1 &&
                  show !== "SOLUTIONS" && (
                    <div className="center">
                      <div className="pagination">
                        <p
                          className={
                            parseInt(this.pageCategories) === 1 &&
                            "cursor-blocked"
                          }
                          onClick={
                            parseInt(this.pageCategories) > 1 &&
                            this.onPressPreviousPageCategories
                          }
                        >
                          &laquo;
                        </p>
                        {pageNumberCategories.map((number) => {
                          return (
                            <a
                              onClick={() => this.onPressPageCategories(number)}
                              className={
                                this.pageCategories &&
                                parseInt(this.pageCategories) === number
                                  ? "active"
                                  : ""
                              }
                            >
                              {number}
                            </a>
                          );
                        })}
                        <p
                          className={
                            parseInt(this.pageCategories) ===
                              pageNumberCategories.length && "cursor-blocked"
                          }
                          onClick={
                            parseInt(this.pageCategories) <
                              pageNumberCategories.length &&
                            this.onPressNextPageCategories
                          }
                        >
                          &raquo;
                        </p>
                      </div>
                    </div>
                  )}
              </Container>
            </div>
          )}
        </div>
      </StyledBenefits>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    benefits: state.benefits.list,
    deleteBenefitResult: state.benefits.deleteBenefit,
    addBenefitResult: state.benefits.addBenefit,
    benefitCategories: state.benefits.benefitCategories,
    addCategoryResult: state.benefits.addCategory,
    deleteCategoryResult: state.benefits.deleteCategory,
    account: state.auth.account,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getBenefits: (params) =>
      dispatch(BenefitsActions.getBenefitsRequest(params)),
    deleteBenefit: (params) =>
      dispatch(BenefitsActions.deleteBenefitRequest(params)),
    addBenefit: (params) => dispatch(BenefitsActions.addBenefitRequest(params)),
    getBenefitCategories: (params) =>
      dispatch(BenefitsActions.getCategoriesRequest(params)),
    addCategory: (params) =>
      dispatch(BenefitsActions.addCategoryRequest(params)),
    deleteCategory: (params) =>
      dispatch(BenefitsActions.deleteCategoryRequest(params)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Benefits);

const dropdownData = [
  {
    id: 1,
    name: "Title, A-Z",
    sortBy: "title,asc",
  },
  {
    id: 2,
    name: "Title, Z-A",
    sortBy: "title,desc",
  },
  {
    id: 3,
    name: "Oldest data",
    sortBy: "createdAt,asc",
  },
  {
    id: 4,
    name: "Newest data",
    sortBy: "createdAt,desc",
  },
];
